import React from 'react';
import { I18n } from '@lingui/react';

export interface AbbrProps extends Omit<React.HTMLProps<HTMLElement>, 'title'> {
  /** The visible content */
  children: React.ReactNode;
  /**
   * The expanded text. Should be a string, but lingui message descriptors are
   * also supported.
   */
  title: string | { id: string };
}

/**
 * Label an abbreviation with it's expanded text. This is both mouse user and
 * screen reader friendly, but not ideal for touch users.
 *
 * @since 1.0.0
 */
export const Abbr = ({ children, title, ...rest }: AbbrProps) => {
  if (typeof title === 'string') {
    return (
      <abbr title={title} aria-label={title} {...rest}>
        {children}
      </abbr>
    );
  }

  return (
    <I18n>
      {({ i18n }) => {
        const text = i18n ? i18n._(title) : title && title.id;

        return (
          <abbr title={text} aria-label={text} {...rest}>
            {children}
          </abbr>
        );
      }}
    </I18n>
  );
};
