import * as React from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import {
  SearchDialog,
  SearchDialogProps,
  useSearchDialogState,
  SearchDialogDisclosure,
  SearchDialogDisclosureProps,
  SearchDialogRenderPreviewProps,
} from '@oms/ui-search-dialog';
import { RenderItem } from './RenderItem';
import {
  useSearchDialogQuery,
  MINIMUM_QUERY_LENGTH,
  UseSearchDialogQueryOptions,
} from './useSearchDialogQuery';

const sectionHeadings = {
  OTHER: <Trans>Other</Trans>,
  EQUITIES: <Trans>Equities</Trans>,
  PCC: <Trans>PCCs</Trans>,
  ETNS: <Trans>ETNs</Trans>,
  RIGHTS: <Trans>Rights</Trans>,
};

const defaultColumns = [
  'COUNTRY',
  'ORGANISATION_NUMBER',
  'ITEM',
  'SECTOR',
  'ITEM_SECTOR',
  'LONG_NAME',
  'TYPE',
  'ISIN',
  'LAST',
  'CHANGE',
  'CHANGE_PCT',
  'CLOSE',
  'CLOSENZ',
  'CLOSENZ_CA',
  'LAST',
  'LASTNZ',
  'LASTNZ_CA',
  'DESCRIPTION_NO',
  'ORGANISATION_NUMBER',
  'info.LOGO_FILENAME',
  'info.DESCRIPTION_NO',
];

export type Suggestion<T extends Record<string, any> = {}> = {
  ITEM?: string;
  ITEM_SECTOR?: string;
  LONG_NAME?: string;
  TYPE: Type;
  CHANGE_PCT?: number;
} & T;

type Type = keyof typeof sectionHeadings;

type OmittedProps =
  | 'items'
  | 'itemToString'
  | 'status'
  | 'groupByKey'
  | 'groupToString'
  | 'onInputValueChange';
interface SmartSearchProps
  extends UseSearchDialogQueryOptions,
    Omit<SearchDialogProps, OmittedProps> {
  /** The initial value to display in the input field */
  initialValue?: string;
  /** Will be passed to the component */
  className?: string;
  onChange?: (changes: Suggestion) => void;
  /** Shows last searched stocks in a list before you start to type */
  showSearchHistory?: boolean;
}

/**
 * A component which renders an interactive search dialog with suggestions as the
 * user types. The matches are sorted based on an algorithm that prioritizes
 * exact matches, followed by substring matches that start with the query,
 * followed by other substring matches.
 * *
 * The component will query `{baseUrl}/server/suggest` for the suggestions. See
 * [Context](#!/Context) for how to set `baseUrl`
 *
 * @since 2.6.0
 * @see See [the @oms/ui-search-dialog docs](https://)
 * for more information on available props.
 */
const SmartSearchDialog = ({
  className,
  limit = 15,
  group = 'SEARCH',
  meta = false,
  searchFields,
  view,
  columns = defaultColumns,
  onChange,
  renderPreview,
  renderItem = RenderItem,
  dialogState,
  showSearchHistory,
  ...searchProps
}: SmartSearchProps) => {
  const { data = [], status, onInputValueChange } = useSearchDialogQuery({
    group,
    limit,
    meta,
    searchFields,
    view,
    columns,
    visible: dialogState.visible,
  });

  // For now we keep the history in memory, otherwise we have to include a way for the user to purge their history.
  const [history, setHistory] = React.useState<Array<Suggestion>>([]);

  const handleSetHistory = (changes: Suggestion) => {
    setHistory((prevHistory) => {
      // We do not want to store the change as the data goes stale quickly
      const { CHANGE_PCT: _CHANGE_PCT, ...instrument } = changes;
      const exists = prevHistory.some(
        (entry) => entry.ITEM_SECTOR === instrument.ITEM_SECTOR,
      );
      if (exists) {
        return prevHistory;
      } else if (prevHistory.length === 10) {
        return [instrument, ...prevHistory.slice(0, 9)];
      } else {
        return [instrument, ...prevHistory];
      }
    });
  };

  const handleChange = React.useCallback(
    (changes: Suggestion) => {
      if (showSearchHistory) {
        handleSetHistory(changes);
      }
      onChange?.(changes);
    },
    [onChange, showSearchHistory],
  );

  const items = showSearchHistory && data.length < 1 ? history : data;

  return (
    <I18n>
      {({ i18n }) => {
        const i18nToString = (message: MessageDescriptor): string =>
          i18n ? i18n._(message) : message.id;
        return (
          <SearchDialog
            aria-label={i18nToString(t`Search`)}
            className={'smart-search ' + className}
            status={status}
            onChange={handleChange}
            onInputValueChange={onInputValueChange}
            minimumQueryLength={MINIMUM_QUERY_LENGTH}
            items={items}
            itemToString={(item: any) => item?.LONG_NAME}
            groupByKey="TYPE"
            groupToString={(groupValue) => (sectionHeadings as any)[groupValue]}
            renderItem={renderItem}
            renderPreview={renderPreview}
            onPress
            text={{
              placeholder: i18nToString(t`Ticker/company`),
              clearButtonLabel: i18nToString(t`Clear selection`),
              closeButtonLabel: i18nToString(t`Close`),
              select: i18nToString(t`to select`),
              navigate: i18nToString(t`to navigate`),
              close: i18nToString(t`to close`),
              noData: i18nToString(t`No match found for query`),
              minimumQueryLength: i18nToString(
                t`Type 2 or more characters to start searching`,
              ),
              error: i18nToString(t`Oops something went wrong`),
            }}
            dialogState={dialogState}
            {...searchProps}
          />
        );
      }}
    </I18n>
  );
};

const useSmartSearchState = useSearchDialogState;
const SmartSearchDisclosure = SearchDialogDisclosure;
const useSmartSearchImperativeHandle = () => {
  const [handlers, setHandle] = React.useState<any>({ reset: () => void {} });
  return [setHandle, handlers] as [any, { reset?: () => void }];
};

interface SmartSearchDialogDisclosureProps
  extends SearchDialogDisclosureProps {}
interface SmartSearchDialogRenderPreviewProps
  extends SearchDialogRenderPreviewProps {}

export {
  useSmartSearchState,
  useSmartSearchImperativeHandle,
  SmartSearchDisclosure,
  SmartSearchDialog,
  SmartSearchProps,
  SmartSearchDialogDisclosureProps,
  SmartSearchDialogRenderPreviewProps,
};
