import styled from '@xstyled/emotion';
import { height, minHeight, maxHeight, compose, th } from '@xstyled/system';
import { mixins } from '@oms/ui-utils';

const sizing = compose(height, minHeight, maxHeight);

export const Box = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: ${th('colors.text') ?? '#333'};
  background-color: ${th('colors.chart-background') ?? 'transparent'};

  .oms-stockchart-tooltip-button {
    all: unset;
    padding: 0.5rem 1rem;
    color: text;
    cursor: pointer;
    min-width: 5rem;
    font-family: body;
  }

  [data-highcharts-chart] {
    /** dimensions need to be defined right here */
    ${sizing}
    position: relative;
  }

  .chart:fullscreen {
    width: 100%;
    height: 100%;
  }

  .chart {
    width: 100%;
    float: left;
    height: 400px;
    position: relative;
  }
  /** Highcharts customization */

  .highcharts-grid-line,
  .highcharts-grid.highcharts-yaxis-grid .highcharts-grid-line,
  .highcharts-grid.highcharts-xaxis-grid .highcharts-grid-line {
    stroke: currentColor;
    color: ${th('colors.chart-grid-line') ?? th('colors.border') ?? '#dbdbdb'};
  }

  /** Stock tools gui */
  .highcharts-series-type-ohlc,
  .highcharts-save-chart,
  .highcharts-full-screen,
  .highcharts-indicators,
  .highcharts-separator {
    display: none;
  }

  .highcharts-draw-mode {
    cursor: crosshair;
  }

  .highcharts-bindings-wrapper * {
    box-sizing: content-box;
  }

  .highcharts-bindings-wrapper {
    display: block;
    width: 40px;
    height: 100%;
    position: absolute;
    z-index: 10;
  }

  .highcharts-stocktools-popup {
    width: 100%;
  }

  .highcharts-menu-wrapper {
    float: left;
    width: 40px;
    height: calc(100% - 50px);
    overflow: hidden;
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 10px;
  }

  .highcharts-bindings-wrapper .highcharts-submenu-wrapper {
    display: none;
    position: absolute;
    z-index: 10;
    left: 0px;
    top: 0px;
    background: transparent;
    width: 40px;
  }

  .highcharts-bindings-wrapper .highcharts-arrow-wrapper {
    text-align: center;
    width: 40px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 1.5em;
  }

  .highcharts-bindings-wrapper .highcharts-arrow-wrapper > div {
    cursor: pointer;
  }

  .highcharts-bindings-wrapper .highcharts-arrow-wrapper {
    text-align: center;
    width: 40px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 1.5em;
  }

  .highcharts-bindings-wrapper .highcharts-arrow-wrapper > div {
    cursor: pointer;
  }

  .highcharts-bindings-wrapper .highcharts-arrow-down {
    background-size: cover;
    transform: rotate(90deg);
  }

  .highcharts-bindings-wrapper .highcharts-arrow-up {
    background-size: cover;
    outline: none;
    display: inline-block;
    width: 25px;
    cursor: pointer;
    user-select: none;
    transform: rotate(-90deg);
  }

  .highcharts-bindings-wrapper .highcharts-arrow-right {
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: contain;
  }

  .highcharts-bindings-wrapper .highcharts-arrow-left.highcharts-arrow-right {
    transform: rotate(0deg);
  }

  .highcharts-bindings-wrapper .highcharts-arrow-left {
    transform: rotate(180deg);
  }

  .highcharts-bindings-wrapper ul {
    width: 40px;
    /* 30px spacing for arrows to scroll */
    margin: 0px;
    padding: 0px;
    float: left;
    transition: margin 250ms;
  }

  .highcharts-bindings-wrapper > ul {
    width: 40px;
    position: relative;
  }

  .highcharts-bindings-wrapper .highcharts-stocktools-toolbar li {
    list-style: none;
    margin-bottom: 3px;
    padding: 0px;
    clear: both;
    width: 100%;
    height: 40px;
    cursor: pointer;
    position: relative;
    /** custom */
    background-color: ${th('colors.chart-toolbar-item') ??
      th('colors.surface-hover') ??
      '#F8FAFB'};
    border-radius: md;
  }

  .highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn {
    cursor: default;
  }

  .highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn
    > .highcharts-menu-item-btn {
    opacity: 0.5;
  }

  .highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn.highcharts-active {
    background: #f7f7f7;
  }

  .highcharts-bindings-wrapper
    .highcharts-stocktools-toolbar
    li.highcharts-disabled-btn
    .highcharts-menu-item-btn:hover {
    background-color: transparent;
  }

  .highcharts-bindings-wrapper li > span.highcharts-menu-item-btn {
    display: block;
    float: left;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .highcharts-submenu-wrapper li > span.highcharts-menu-item-btn {
    width: 40px;
  }

  .highcharts-bindings-wrapper li > span.highcharts-submenu-item-arrow {
    float: left;
    width: 10px;
    height: 100%;
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .highcharts-bindings-wrapper li.highcharts-separator {
    height: 15px;
    background-color: transparent;
    width: 36px;
    pointer-events: none;
  }

  .highcharts-bindings-wrapper
    li.highcharts-separator
    > span.highcharts-menu-item-btn {
    width: 100%;
  }

  .highcharts-bindings-wrapper
    li.highcharts-active
    > span.highcharts-menu-item-btn,
  .highcharts-bindings-wrapper li > span.highcharts-menu-item-btn:hover,
  .highcharts-bindings-wrapper .highcharts-arrow-wrapper > div:hover,
  .highcharts-bindings-wrapper li.highcharts-active,
  .highcharts-toggle-toolbar:hover {
    /*background-color: blue;
    transition: background-color 100ms;*/
  }

  .highcharts-toggle-toolbar {
    position: absolute;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background: ${th('colors.chart-toggle-toolbar') ?? '#f7f7f7'};
    background-size: cover;
  }

  .highcharts-hide {
    display: none;
  }

  .highcharts-bindings-wrapper li:hover,
  .highcharts-submenu-item-arrow:hover {
    background-color: ${th('colors.chart-toolbar-item-hover') ??
      th('colors.surface-hover') ??
      '#F8FAFB'};
    transition: background-color 100ms;
  }

  .highcharts-bindings-wrapper .highcharts-arrow-down,
  .highcharts-bindings-wrapper .highcharts-arrow-up {
    width: 50%;
    height: 20px;
    float: left;
  }

  /**Stock tools customization */
  .highcharts-toggle-toolbar.highcharts-arrow-left {
    /* background-color: transparent;
    top: 7px !important;
    left: 15px !important;
    width: 1.5rem !important;
    height: 1.5rem !important;*/
  }

  /** Stock chart popup */
  .highcharts-popup.highcharts-annotation-toolbar {
    right: 10%;
    left: auto;
    height: 40px;
    overflow: hidden;
    padding-right: 40px;
    width: auto;
    min-width: 0;
  }

  .highcharts-popup.highcharts-annotation-toolbar button {
    margin-top: 0px;
  }

  .highcharts-popup.highcharts-annotation-toolbar > span {
    display: block;
    float: left;
    padding: 12px;
  }

  .highcharts-popup {
    ${mixins.panel}
    display: none;
    font-family: ${th('fonts.body') ?? 'system-ui'};
    font-size: 0.876em;
    max-height: 90%;
    top: 5%;
    left: 15%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 75%;
    min-width: 300px;
    max-width: 600px;
    position: absolute;
    z-index: 100;
  }

  .highcharts-popup div,
  .highcharts-popup span {
    box-sizing: content-box;
  }

  .highcharts-popup input,
  .highcharts-popup label,
  .highcharts-popup select {
    clear: both;
    float: left;
    width: 100%;
    margin-bottom: 10px;
  }

  .highcharts-popup select {
    color: ${th('colors.text')};
    background-color: ${th('colors.input')};
    outline-width: 0;
    outline-color: transparent;
    border: 1px solid;
    border-color: ${th('colors.border')};

    &:focus {
      background-color: ${th('colors.input-focus')};
      box-shadow: focus;
      border-color: ${th('colors.border-focus')};
    }
  }

  .highcharts-popup input {
    color: ${th('colors.text')};
    background-color: ${th('colors.input')};
    outline-width: 0;
    outline-color: transparent;
    border: 1px solid;
    border-color: ${th('colors.input-border') ?? th('colors.border')};
    padding: 5px;
    width: calc(100% - 12px);

    &:focus {
      background-color: ${th('colors.input-focus')};
      box-shadow: focus;
      border-color: ${th('colors.border-focus')};
    }
  }

  .highcharts-popup-lhs-col,
  .highcharts-popup-rhs-col {
    padding: 20px;
    height: calc(100% - 84px);
    /* 44px - padding, 40px - tabs*/
    float: left;
  }

  .highcharts-popup-lhs-col.highcharts-popup-lhs-full {
    width: calc(100% - 52px);
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 140px);
    border: none;
    padding: 0px 20px 20px 20px;
  }

  .highcharts-popup-lhs-col.highcharts-popup-lhs-full
    + .highcharts-popup-bottom-row {
    width: calc(100% - 32px);
  }

  .highcharts-popup-lhs-col {
    clear: both;
    width: calc(30% - 44px);
    border-right: 1px solid #e6e6e6;
  }

  .highcharts-popup-bottom-row {
    float: left;
    padding: 0px 20px 10px;
    width: calc(100% - 40px);
  }

  .highcharts-popup-rhs-col {
    width: calc(70% - 40px);
  }

  .highcharts-popup-rhs-col-wrapper {
    float: left;
    width: calc(100% - 20px);
    padding-right: 20px;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .highcharts-popup-rhs-col-wrapper h3 {
    margin-top: 0px;
    padding-bottom: 0px;
  }

  .highcharts-bindings-wrapper ul.highcharts-indicator-list,
  .highcharts-indicator-list {
    float: left;
    color: #666;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
  }

  .highcharts-indicator-list li {
    cursor: pointer;
    padding: 0px 0px 5px 0px;
    margin: 0px;
    width: 100%;
    height: auto;
    overflow: hidden;
    word-break: break-all;
  }

  .highcharts-indicator-list li:hover {
    background-color: #e6ebf5;
  }

  .highcharts-tab-item {
    background-color: #f7f7f7;
    cursor: pointer;
    display: block;
    float: left;
    padding: 10px;
    height: 20px;
  }

  .highcharts-tab-item.highcharts-tab-item-active {
    background-color: #e6ebf5;
  }

  .highcharts-tab-item-content {
    display: none;
    float: left;
    height: 100%;
    overflow: hidden;
    width: 100%;
    border-top: 1px solid #e6e6e6;
  }

  .highcharts-tab-item-show {
    display: block;
  }

  .highcharts-popup-close {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    padding: 10px;
    top: 0%;
    right: 0%;
    color: ${th('colors.icon-primary')};
  }

  .highcharts-popup-close:hover,
  .highcharts-popup *:not(.highcharts-popup-bottom-row) button:hover,
  .highcharts-popup button.highcharts-annotation-edit-button:hover,
  .highcharts-popup button.highcharts-annotation-remove-button:hover {
    &:hover {
      background-color: ${th('colors.icon-hover') ?? '#e6ebf5'};
    }
  }

  .highcharts-popup button {
    float: right;
    border: none;
    background: transparent;
    color: ${th('colors.icon-primary')};
    margin-left: 5px;
    margin-top: 12px;
  }

  .highcharts-popup-bottom-row button {
    ${th('button.default')}
    ${th('button.variants.primary')}
    ${th('button.sizes.md')}
  }

  .highcharts-popup button:first-child {
    margin-left: 0;
  }

  .highcharts-tab-disabled {
    color: #ccc;
  }

  /* annotation edit small popup */
  .highcharts-popup button.highcharts-annotation-edit-button,
  .highcharts-popup button.highcharts-annotation-remove-button {
    width: 20px;
    height: 40px;
    padding: 20px;
  }

  .highcharts-popup button.highcharts-annotation-edit-button {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }

  .highcharts-popup button.highcharts-annotation-remove-button {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }

  .highcharts-popup .highcharts-annotation-title {
    display: block;
    float: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    width: 100%;
  }

  .highcharts-popup .highcharts-popup-main-title {
    border-bottom: 1px solid #e6e6e6;
    margin: 0px 0px 20px 0px;
    padding: 8px 0px 6px 20px;
  }

  .highcharts-indicator-title {
    float: left;
    padding-bottom: 15px;
  }

  .highcharts-annotation-shapes,
  .highcharts-label {
    color: ${th('colors.text')};
    /* Applies a color (default text color) to all svg text nodes */
    text {
      color: inherit !important;
    }
    /* Applies a color (default text color) to all line segments without a class */
    path:not([class]) {
      stroke: currentColor !important;
    }
  }

`;
