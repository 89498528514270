import * as React from 'react';
import template from 'url-template';
import { MessageDescriptor } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { useComponentsContext } from '@oms/components-config-context';
import { Search, SearchProps } from '@oms/ui-search';
import { useQuery } from 'react-query';
import { RenderItem } from './RenderItem';

type Suggestion = {
  ITEM?: string;
  ITEM_SECTOR?: string;
  LONG_NAME?: string;
  TYPE: Type;
  CHANGE_PCT?: number;
};

export interface SuggestionOptions {
  suggestion: any;
  suggestionValue: any;
  suggestionIndex: number;
  sectionIndex: number | null;
  method: 'click' | 'enter';
}

type Type = keyof typeof sectionHeadings;

type OmittedProps =
  | 'items'
  | 'itemToString'
  | 'status'
  | 'groupByKey'
  | 'groupToString'
  | 'onInputValueChange';
export interface SuggestProps extends Omit<SearchProps, OmittedProps> {
  /** The group that will be used to fetch possible matches */
  group?: string;
  /** Max number of entries to show */
  limit?: number;
  /** Include metadata with the result */
  meta?: boolean;
  /** The fields to use when checking the query for a match */
  searchFields?: string;
  /**
   * Override the default view of the returned data. You will probably not need
   * this
   */
  view?: 'REALTIME' | 'REALTIME_OR_EOD' | 'DELAYED' | 'DELAYED_OR_EOD' | 'EOD';
  /**
   * The fields that are made available to the component and the
   * `onSuggestionSelected` method. These are the same columns as in other
   * components.
   */
  columns?: string;
  /** The initial value to display in the input field */
  initialValue?: string;
  /** Will be passed to the component */
  className?: string;
  onChange?: (changes: Suggestion) => void;
  id?: string;
  /** Id of the label that describes the element */
  labelId?: string;
  /** Shows last searched stocks in a list before you start to type */
  showSearchHistory?: boolean;
  /** Other props are forwarded to Search */
  [prop: string]: any;
}

const urlPattern = template.parse(
  '{+baseUrl}/suggest{?group,query,limit,meta,searchFields,view,columns}',
);

const sectionHeadings = {
  OTHER: <Trans>Other</Trans>,
  EQUITIES: <Trans>Equities</Trans>,
  PCC: <Trans>PCCs</Trans>,
  ETNS: <Trans>ETNs</Trans>,
  RIGHTS: <Trans>Rights</Trans>,
};

const fetcher = async (
  baseUrl: string,
  query: string | undefined,
  group: SuggestProps['group'],
  limit: SuggestProps['limit'],
  meta: SuggestProps['meta'],
  searchFields: SuggestProps['searchFields'],
  view: SuggestProps['view'],
  columns: SuggestProps['columns'],
) => {
  const url = urlPattern.expand({
    baseUrl,
    query,
    group,
    limit,
    meta,
    searchFields,
    view,
    columns,
  });
  if (!url) {
    Promise.reject();
  }
  return await fetch(url, {
    credentials: 'include',
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
    return response.json();
  });
};

const MINIMUM_QUERY_LENGTH = 2;

/**
 * A component which renders an interactive search field with suggestions as the
 * user types. The matches are sorted based on an algorithm that prioritizes
 * exact matches, followed by substring matches that start with the query,
 * followed by other substring matches.
 *
 * From version 1.1 onwards results are also grouped by instrument type.
 *
 * The component will query `{baseUrl}/server/suggest` for the suggestions. See
 * [Context](#!/Context) for how to set `baseUrl`
 *
 * Several other components in the component library use `<Suggest>` to provide
 * search for instruments. It is possible to override the `<Suggest>` component
 * that these components use and provide your own (or configure the existing
 * one) by passing a replacement to the `Suggest` property of the component
 * context provider.
 *
 * The component passes all unknown props to the search component.
 *
 * @since 1.0.0
 * @see See [the @oms/ui-search docs](https://)
 * for more information on available props.
 */
export const Suggest = ({
  className = 'Suggest',
  limit = 15,
  group = 'SEARCH',
  meta = false,
  searchFields,
  view,
  columns = 'ITEM,SECTOR,LONG_NAME,TYPE,ISIN,CHANGE_PCT',
  onChange,
  imperativeHandle,
  showSearchHistory,
  enableTabToSelect,
  ...searchProps
}: SuggestProps) => {
  const { baseUrl } = useComponentsContext();
  const [query, setQuery] = React.useState<string>('');
  const { data = [], status } = useQuery(
    [baseUrl, query, group, limit, meta, searchFields, view, columns],
    fetcher,
    {
      enabled: query.length >= MINIMUM_QUERY_LENGTH,
    },
  );

  // For now we keep the history in memory, otherwise we have to include a way for the user to purge their history.
  const [history, setHistory] = React.useState<Array<Suggestion>>([]);

  const handleSetHistory = (changes: Suggestion) => {
    setHistory((prevHistory) => {
      // We do not want to store the change as the data goes stale quickly
      const { CHANGE_PCT: _CHANGE_PCT, ...instrument } = changes;
      const exists = prevHistory.some(
        (entry) => entry.ITEM_SECTOR === instrument.ITEM_SECTOR,
      );
      if (exists) {
        return prevHistory;
      } else if (prevHistory.length === 10) {
        return [instrument, ...prevHistory.slice(0, 9)];
      } else {
        return [instrument, ...prevHistory];
      }
    });
  };

  const handleChange = React.useCallback(
    (changes: Suggestion) => {
      if (showSearchHistory) {
        handleSetHistory(changes);
      }
      onChange?.(changes);
    },
    [onChange, showSearchHistory],
  );

  const items = showSearchHistory && data.length < 1 ? history : data;

  return (
    <>
      <I18n>
        {({ i18n }) => {
          const i18nToString = (message: MessageDescriptor): string =>
            i18n ? i18n._(message) : message.id;

          return (
            <Search
              imperativeHandle={imperativeHandle}
              className={className}
              status={status}
              placeholder={i18nToString(t`Ticker/company`)}
              onChange={handleChange}
              onInputValueChange={(changes: any) => setQuery(changes)}
              /*
               * onInputValueChange is debounced
               * and will not be called until the minimum query length condition is met.
               */
              minimumQueryLength={MINIMUM_QUERY_LENGTH}
              minimumQueryLengthMessage={i18nToString(
                t`Type 2 or more characters to start searching`,
              )}
              noDataMessage={i18nToString(t`No match found for query`)}
              errorMessage={i18nToString(t`Oops something went wrong`)}
              ariaClearButtonLabel={i18nToString(t`Clear`)}
              items={items}
              itemToString={(item: any) => item?.LONG_NAME}
              groupByKey="TYPE"
              groupToString={(groupValue) =>
                (sectionHeadings as any)[groupValue]
              }
              renderItem={RenderItem}
              enableTabToSelect={enableTabToSelect}
              {...searchProps}
            />
          );
        }}
      </I18n>
    </>
  );
};

export const useSuggestImperativeHandle = () => {
  const [handlers, setHandle] = React.useState<any>({ reset: () => void {} });
  return [setHandle, handlers] as [any, { reset?: () => void }];
};
