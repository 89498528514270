import React, { forwardRef } from 'react';
import { Trans } from '@lingui/macro';
import { MenuItemHTMLProps } from '@oms/ui-menu-bar';
import { Icon, light } from '@oms/ui-icon';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import {
  useFullscreen,
  useStockChartContainerSize,
} from '../StockChartContainer';

interface FullscreenProps extends MenuItemHTMLProps {}

export const Fullscreen = forwardRef(function Fullscreen(
  props: FullscreenProps,
  ref: React.Ref<any>,
) {
  const fullscreen = useFullscreen();
  const { containerWidth } = useStockChartContainerSize();

  return (
    <button
      ref={ref}
      {...props}
      onClick={
        !fullscreen?.isFullscreen ? fullscreen?.request : fullscreen?.exit
      }
    >
      <Icon
        icon={fullscreen?.isFullscreen ? light.faCompress : light.faExpand}
        color="inherit"
      />
      {containerWidth === 'sm' ? (
        <VisuallyHidden>
          <Trans>Fullscreen</Trans>
        </VisuallyHidden>
      ) : (
        <Trans>Fullscreen</Trans>
      )}
    </button>
  );
});
