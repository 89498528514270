import * as R from 'reakit/Menu';
import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';
import { mixins } from '@oms/ui-utils';

const MenuBar = styled(R.MenuBar)`
  ${mixins.borderBox}
  display: flex;
  ${th('menuBar.default')}
` as typeof R.MenuBar;

const MenuBarItem = styled(R.MenuItem)`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  ${th('menuBar.barItem')}
` as typeof R.MenuItem;

const Menu = styled(R.Menu)`
  ${mixins.borderBox}
  display: flex;
  flex-direction: column;
  min-width: 10rem;
  ${mixins.panel}
  z-index:2;
  ${th('panel')}
  ${th('menu.default')};
  [tabindex='0'] {
    ${th('menu.item.highlighted')}
  }
` as typeof R.Menu;

const MenuGroup = styled(R.MenuGroup)`
  display: inherit;
  flex-direction: inherit;
` as typeof R.MenuGroup;

const MenuButton = styled(R.MenuButton)`` as typeof R.MenuButton;

const MenuItem = styled(R.MenuItem)`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  ${mixins.anchor}
  display:flex;
  padding: 2 2 2 6;
  line-height: 1;
  :focus {
    box-shadow: none;
  }
  ${th('menu.item.default')}
` as typeof R.MenuItem;

const MenuItemCheckbox = styled(R.MenuItemCheckbox)`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  ${mixins.anchor}
  display:flex;
  padding: 2 2 2 6;
  line-height: 1;
  &:focus {
    box-shadow: none;
  }
  &[role='menuitemcheckbox'][aria-checked='true'] {
    color: selected;
  }
  &[role='menuitemcheckbox'][aria-checked='true']::before {
    content: '✓';
    position: absolute;
    transform: translateX(-120%);
    ${th('menu.checkbox.checked')}
  }
  ${th('menu.item.default')}
  ${th('menu.checkbox.default')}
` as typeof R.MenuItemCheckbox;

const MenuItemRadio = styled(R.MenuItemRadio)`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  ${mixins.anchor}
  display:flex;
  padding: 2 2 2 6;
  line-height: 1;
  &:focus {
    box-shadow: none;
  }
  &[role='menuitemradio'][aria-checked='true'] {
    color: selected;
  }
  &[role='menuitemradio'][aria-checked='true']::before {
    content: '•';
    position: absolute;
    transform: translateX(-200%) scale(1.2);
    ${th('menu.radio.checked')}
  }
  ${th('menu.item.default')}
  ${th('menu.radio.default')}
` as typeof R.MenuItemRadio;

const MenuSeparator = styled(R.MenuSeparator)`
  border-width: 0px 0px 1px;
  border-color: border;
  margin: 0.5em 0px;
  width: auto;
  height: 0px;
  ${th('menu.separator')}
` as typeof R.MenuSeparator;

interface MenuItemHTMLProps extends R.MenuItemHTMLProps {}

const useMenuState = R.useMenuState;
const useMenuBarState = R.useMenuBarState;

export {
  MenuBar,
  Menu,
  MenuGroup,
  MenuBarItem,
  MenuButton,
  MenuItem,
  MenuItemCheckbox,
  MenuItemRadio,
  MenuSeparator,
  useMenuState,
  useMenuBarState,
  MenuItemHTMLProps,
};
