/**
 * Returns the class that corresponds to the value's change.
 * A positive `trend` will return pos, a negative `trend` will return
 * neg, and a flat trend will return `flat`.
 *
 * @param {number} value - The trend number that will be tested.
 * @return {object} An object that can be fed into classNames.
 */
export function trendClass(value: number) {
  return {
    flat: value === 0 || !value,
    pos: value > 0,
    neg: value < 0,
  };
}

/**
 * Returns a Font Awesome class that corresponds to the change in value.
 *
 * @param {number} value - The trend number that will be tested.
 * @return {object} An object that can be fed into classNames.
 */
export function trendIcon(value: number) {
  return {
    fa: true,
    'fa-arrow-circle-up': value > 0,
    'fa-arrow-circle-down': value < 0,
    'fa-arrow-circle-right': value === 0 || !value,
  };
}
