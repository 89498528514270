import { LOCAL_COLORS } from '../constants';

export function getIndicatorColor(index: number) {
  return LOCAL_COLORS[index % LOCAL_COLORS.length];
}

export function getComparisonColor(index: number) {
  return [...LOCAL_COLORS].reverse()[index % LOCAL_COLORS.length];
}

export function opacify(hex: string, opacityPart: string | number = 77) {
  return `${hex}${opacityPart}`;
}

/**
 * This utility turns a css variable back to a normal hex/rgb value.
 *
 * The use of css variables can be turned on/off in the theme config.
 * If turned on useTheme returns the css variables instead of the raw color values.
 * @example "var(--xstyled-colors-neg", #e61437)" -> #e61437
 * */
export function parseColor(color: string) {
  const regex = new RegExp(/^var\(.*,.*\)/);
  const isCSSVar = regex.test(color);
  if (!isCSSVar) return color;
  const start = color.indexOf(',');
  const end = color.lastIndexOf(')');
  const value = color.slice(start + 1, end).trim();
  return value;
}
