import React, { createContext, useContext, useCallback } from 'react';
import { useRadioState, Radio, RadioGroup } from 'reakit/Radio';
import { useCompositeState, Composite, CompositeItem } from 'reakit/Composite';
import flatten from 'react-flatten-children';
import { useId } from '@oms/ui-utils';
import { Td, Th, ThProps, TdProps, DataType } from '@oms/ui-table';
import { useLink } from '@oms/ui-components-context';

import * as S from './styles';
import { InteractiveListProps, InteractiveListRowProps } from './types';

const InteractiveListContext = createContext<any>({
  id: '',
});
InteractiveListContext.displayName = 'InteractiveListContext';

/** @deprecated */
export function InteractiveList({
  children,
  onSelect,
  initialSelected,
  ...props
}: InteractiveListProps) {
  const id = useId('interactive-list');
  const radio = useRadioState({
    orientation: 'vertical',
    state: initialSelected,
  });
  const composite = useCompositeState();
  return (
    <InteractiveListContext.Provider
      value={{ id, selectable: onSelect, radio, composite }}
    >
      <S.InteractiveList role="table" {...props}>
        {children}
      </S.InteractiveList>
    </InteractiveListContext.Provider>
  );
}

export function InteractiveListHead({ children, ...props }: any) {
  const { id } = useContext(InteractiveListContext);
  return (
    <S.InteractiveListHead id={id} role="rowgroup" {...props}>
      {React.Children.map(flatten(children), (child: any) =>
        React.cloneElement(child, { header: true }),
      )}
    </S.InteractiveListHead>
  );
}

export function InteractiveListBody({ children }: any) {
  const { id, selectable, radio, composite } = useContext(
    InteractiveListContext,
  );
  if (selectable) {
    return (
      <RadioGroup
        as="ul"
        data-row-group=""
        aria-describedby={id}
        style={{
          display: 'table-row-group',
        }}
        {...radio}
      >
        {children}
      </RadioGroup>
    );
  }
  return (
    <Composite
      as="div"
      data-row-group=""
      aria-describedby={id}
      role="rowgroup"
      style={{
        display: 'table-row-group',
      }}
      {...composite}
    >
      {children}
    </Composite>
  );
}

export function InteractiveListRow({
  children,
  linkTo,
  value,
  header,
  ...props
}: InteractiveListRowProps) {
  const { selectable: onSelect, radio, composite } = useContext(
    InteractiveListContext,
  );
  const Link = useLink();
  const handleClick = useCallback(() => {
    onSelect && onSelect(value);
  }, [onSelect, value]);

  // Component is reused as child of InteractiveListHead
  if (header) {
    return (
      <S.InteractiveListRow role="row" data-row="">
        {children}
      </S.InteractiveListRow>
    );
  } else if (onSelect) {
    return (
      <Radio
        value={value}
        {...radio}
        onClick={handleClick}
        unstable_checkOnFocus={false}
      >
        {(elementProps) => (
          <S.InteractiveListRow
            as="li"
            data-row=""
            {...props}
            {...(elementProps as any)}
          >
            {children}
          </S.InteractiveListRow>
        )}
      </Radio>
    );
  } else {
    return (
      <CompositeItem {...composite}>
        {(elementProps) => (
          <S.InteractiveListRow
            as={Link}
            data-row=""
            role="row"
            to={linkTo}
            {...props}
            {...(elementProps as any)}
          >
            {children}
          </S.InteractiveListRow>
        )}
      </CompositeItem>
    );
  }
}

export function InteractiveListHeadingCell({ children, ...props }: ThProps) {
  return (
    <Th
      as="div"
      data-heading-cell=""
      style={{
        display: 'table-cell',
      }}
      {...props}
    >
      {children}
    </Th>
  );
}

export function InteractiveListCell<Type extends DataType>({
  children,
  ...props
}: TdProps<Type>) {
  const { selectable } = useContext(InteractiveListContext);
  return (
    <Td
      as="div"
      role={!selectable && 'cell'}
      data-cell=""
      style={{
        display: 'table-cell',
      }}
      {...props}
    >
      {children}
    </Td>
  );
}
