import React, { useState } from 'react';
import { Abbr } from '@oms/components-typography';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { formatLink } from '@oms/utils';
import { Trans, t } from '@lingui/macro';
import {
  Table,
  MemoizedTd as Td,
  Th,
  TableToggleRowsButton,
} from '@oms/ui-table';
import { NoData, Loading } from '@oms/components-core';

const MOSTTRADED = 'mosttraded';

export const defaultSpec = {
  initiatorComponent: 'MostTraded',
  source: 'feed.ose.quotes.EQUITIES',
  columns: 'ITEM, ITEM_SECTOR, LONG_NAME, TURNOVER, CHANGE_PCT',
  ranking: '-TURNOVER',
  filter: 'TURNOVER>n0',
  limit: 5,
};

type BuildSpecArguments = {
  limit?: number;
  spec?: Spec;
};

const buildSpec = ({ limit, spec }: BuildSpecArguments) => {
  return {
    ...defaultSpec,
    limit: limit && Number.isFinite(limit) ? limit : undefined,
    ...spec,
  };
};

export interface UseMostTradedArguments {
  spec?: Spec;
  options?: Options;
  /**
   * The `limit` to use when the component is expanded. Can be overridden using
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
}

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `MostTraded` component.
 */
export const useMostTraded = ({
  spec,
  options,
  expandedLimit = 15,
  initialExpanded,
  initialLimit = 5,
  onExpandToggled,
}: UseMostTradedArguments = {}) => {
  const [limit, setLimit] = useState(
    initialExpanded ? expandedLimit : initialLimit,
  );
  const [expanded, setExpanded] = useState(initialExpanded);

  const toggleExpanded = () => {
    const newValue = !expanded;
    setExpanded(newValue);
    setLimit(newValue ? expandedLimit : initialLimit);

    if (onExpandToggled) {
      onExpandToggled({ newValue });
    }
  };
  const builtSpec = buildSpec({ limit, spec });
  const { items, ...values } = useJaws(builtSpec, options);
  const showType = builtSpec.columns.includes('TYPE');

  return {
    ...values,
    showType,
    expanded,
    toggleExpanded,
    data: items
      .sortBy((item: any) => item.get('TURNOVER'))
      .reverse()
      .toJS(),
  };
};

export interface MostTradedProps {
  /** A spec to override the default values of the component. */
  spec?: Spec & {
    /** The source to fetch data from. <i>Default: feed.ose.quotes.EQUITIES</i> */
    source?: string;
    /** The number of rows in the table. <i>Default: 5</i> */
    limit?: number;
    /** The field the selection should be ranked on. <i>Default: -TURNOVER</i> */
    ranking?: string;
    /** The filter used to reduce the dataset. <i>Default: TURNOVER>n0</i> */
    filter?: string;
  };
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * The `limit` to use when the component is expanded. Can be overridden usen
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  /**
   * When set to `true`, the component will render a button in the table footer
   * which expands to see all available levels when clicked.
   */
  showMoreButton?: boolean;
  linkTo?: any;
  /** Will be passed to the `<table>` */
  className?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
}

/**
 * A component which lists the most traded companies measured in
 * <code>TURNOVER</code> of the current day.
 *
 * @since 1.0.0
 */
export const MostTraded = ({
  linkTo = '/instrument/{ITEM_SECTOR}',
  className = 'MostTraded',
  spec,
  showMoreButton = false,
  disableLoading = false,
  initialLimit = 5,
  initialExpanded = false,
  expandedLimit = 15,
  onExpandToggled,
}: MostTradedProps) => {
  const {
    resource,
    data,
    expanded,
    toggleExpanded,
    initialized,
    emptyData,
  } = useMostTraded({
    spec,
    initialLimit,
    expandedLimit,
    initialExpanded,
    onExpandToggled,
  });

  const entries = Object.entries(data);
  const rows = Array(expanded ? expandedLimit : initialLimit)
    .fill(null)
    .map((_, i) => entries[i] || null);

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No data found`} />;
  }

  return (
    <>
      <Table className={className} width="100%">
        <thead>
          <tr>
            <Th type="text">
              <Trans>Name</Trans>
            </Th>
            <Th type="numberWithUnit">
              <Trans>Turnover</Trans>
            </Th>
            <Th type="percent">
              <Abbr title={t`Change in percent`}>
                <Trans>+/-%</Trans>
              </Abbr>
            </Th>
          </tr>
        </thead>
        <tbody>
          {rows.map((entry: any, index) => {
            if (!entry)
              return (
                <tr key={`emtpy-row-${index}`} data-testid={`row-${index}`}>
                  <Td />
                  <Td />
                  <Td />
                </tr>
              );

            const [key, item] = entry;
            const { CHANGE_PCT, ITEM, TURNOVER, LONG_NAME } = item;

            return (
              <tr key={key} data-testid={`row-${index}`}>
                <Td
                  type="symbol"
                  linkTo={formatLink(linkTo, item)}
                  ticker={ITEM}
                  name={LONG_NAME}
                />
                <Td type="numberWithUnit">{TURNOVER}</Td>
                <Td type="percent" trend>
                  {CHANGE_PCT}
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {showMoreButton && (
        <TableToggleRowsButton
          onClick={toggleExpanded}
          aria-controls={MOSTTRADED}
          style={{ width: '100%' }}
          aria-label={
            expanded
              ? 'Toggle trades, show additional content'
              : 'Toggle trades, hide additional content'
          }
        >
          {expanded ? <Trans>Show fewer</Trans> : <Trans>Show more</Trans>}
        </TableToggleRowsButton>
      )}
    </>
  );
};
