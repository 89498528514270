import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  useRadioState,
  Radio,
  RadioGroup,
  RadioStateReturn,
  RadioProps,
} from 'reakit/Radio';

import * as S from './styles';

const ContentSwitcherContext = createContext<RadioStateReturn>(
  {} as RadioStateReturn,
);
ContentSwitcherContext.displayName = 'ContentSwitcherContext';

export interface UseContentSwitcherOptions {
  orientation?: 'vertical' | 'horizontal';
  initialSelected: string | number;
}

export function useContentSwitcher({
  orientation = 'horizontal',
  initialSelected,
}: UseContentSwitcherOptions) {
  const [selected, setSelected] = useState(() => initialSelected);
  const onChange = useCallback((value: string | number | undefined) => {
    setSelected(value as any);
  }, []);
  return { selected, initialSelected, onChange, orientation };
}

export interface ContentSwitcherProps {
  children: ReturnType<typeof ContentSwitcherOption>[];
  /** Should match one of the `ContentSwitcherOption` values */
  initialSelected: string | number;
  onChange: (value?: string | number) => void;
  orientation?: 'vertical' | 'horizontal';
  variant?: 'primary' | 'secondary' | 'tertiary';
  selected?: string | number;
  'aria-label'?: string;
  'aria-labelledby'?: string;
}

/**
 * Use a contents switchers when you want alternative views of the same content.
 * For example, use a content switcher if you have a single category, such as “Funds” and you want to divide it into views such as “All”, “ASK”, and “IPS”
 * or if your have a chart and you want to alter the time series with options such as "1D", “5D”, and “1M”.
 */
export function ContentSwitcher({
  children,
  onChange,
  initialSelected,
  orientation = 'horizontal',
  variant = 'primary',
  ...props
}: ContentSwitcherProps) {
  const radio = useRadioState(() => ({
    orientation,
    state: initialSelected,
  }));
  useEffect(() => {
    onChange(radio.state);
  }, [radio.state]);
  return (
    <RadioGroup
      as={S.ContentSwitcher}
      aria-label="radio group"
      {...props}
      flexDirection={orientation === 'horizontal' ? 'row' : 'column'}
      data-orientation={orientation}
      variant={variant}
      {...radio}
    >
      <ContentSwitcherContext.Provider value={radio}>
        {children}
      </ContentSwitcherContext.Provider>
    </RadioGroup>
  );
}

export interface ContentSwitcherOptionProps {
  children?: ReactNode;
  value: RadioProps['value'];
}

export function ContentSwitcherOption({
  children,
  value,
}: //...props
ContentSwitcherOptionProps) {
  const radio = useContext(ContentSwitcherContext);
  const getContent = () => {
    if (children) return children;
    if (typeof value === 'string' || typeof value === 'number') return value;
    return null;
  };
  return (
    <Radio as={S.ContentSwitcherOption} {...radio} value={value}>
      {getContent()}
    </Radio>
  );
}
