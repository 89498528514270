import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import proj4 from 'proj4';
import includeHighmaps from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';

import ChartWrapper from './ChartWrapper';
import predefinedIndices from './indices';
import buildDefaultOptions from './buildDefaultOptions';
import { UseIndexMapReturn } from './useIndexMap';
import { Region } from './IndexMap';

// init the module
includeHighmaps(Highcharts);

// Required to support lat/lon points
if (typeof window !== 'undefined') {
  window.proj4 = window.proj4 || proj4;
}

/**
 * This is a function instead of a key/value mapping to ensure the import
 * statements are lazily evaluated
 */
const getMapForRegion = (region: Region) => {
  if (!region) {
    throw new Error('No region prop was passed to the IndexMap component');
  }
  if (typeof region === 'string') {
    return import(`@highcharts/map-collection/custom/${region}.geo.json`);
  }

  return { default: region };
};

const buildPoints = ({
  values: input,
  dataPoints,
}: {
  values: UseIndexMapReturn['data'];
  dataPoints: IndexMapComponentProps['dataPoints'];
}) => {
  const mergedIndices: Record<
    string,
    {
      name?: string;
      lat: number;
      lon: number;
      dataLabel: {
        bottom?: boolean;
        top?: boolean;
        left?: boolean;
        right?: boolean;
        hidePoint?: boolean;
      };
      className?: string;
    }
  > = { ...predefinedIndices, ...dataPoints };

  return Object.values(input).map((item) => {
    const ITEM_SECTOR = item.ITEM_SECTOR;
    const { LONG_NAME } = item;

    if (!(ITEM_SECTOR in mergedIndices)) return undefined;

    const { name, lat, lon, dataLabel, className } = mergedIndices[ITEM_SECTOR];
    const { top, bottom, left, right, hidePoint } = dataLabel;

    if (!lat || !lon) {
      throw new Error(
        `The ticker ${LONG_NAME} (${ITEM_SECTOR}) is not supported in IndexMap as of yet. Try defining the index' latitude and longditude in the indices prop or get in touch with OMS with a feature request.`,
      );
    }

    return {
      id: ITEM_SECTOR,
      name: name || LONG_NAME,
      lat,
      lon,
      className: classNames(className, {
        'index-map__label--top': top,
        'index-map__label--bottom': bottom,
        'index-map__label--left': left,
        'index-map__label--right': right,
        'index-map__label--hidePoint': hidePoint,
      }),
      values: item,
    };
  });
};

export interface IndexMapComponentProps {
  /** The resulting dataset of a jaws subscription */
  values: UseIndexMapReturn['data'];
  /** A class that will be passed to the chart wrapper */
  className?: string;
  /** A class that will be passed to every label in the chart */
  labelClassName?: string;
  /**
   * Any overrides to the highmaps config object. Will be merged into the
   * default configuration object provided with the component. See
   * https://api.highcharts.com/highmaps/ for configuration options.
   */
  initialOptions?: any;
  /**
   * The region the map should render, usually a continent or `'world'` for the
   * whole world
   */
  region?: Region;
  /**
   * Override the location of indices on the map (or define new indices). This
   * `ITEM_SECTOR`s that are present in this set will be placed onto the map,
   * overriding the predefined location when present.
   *
   * This may be useful when you want to cluster labels in a different manner,
   * or if the predefined locations are too dense/sparse for the map region
   * you've selected.
   */
  dataPoints?: {
    [itemSector: string]: {
      /** The latitude on which to render the point, specified in decimal */
      lat: number;
      /** The longditude on which to render the point, specified in decimal */
      lon: number;
      /**
       * An object containing bools of the keys `top`, `bottom`, `left` and
       * `right`. These determine at which corner of the point the label should
       * be shown, for example by setting the keys `top` and `left` to render the
       * label above and to the left of the point.
       */
      dataLabel: {
        top?: boolean;
        bottom?: boolean;
        left?: boolean;
        right?: boolean;
      };
      /** An extra class which will be set on the label's wrapping element */
      className: string;
    };
  };
  /**
   * A callback method for whenever the index bubbles (labels) are clicked.
   * Providing this prop will make the chart render the labels as buttons,
   * putting them into the tab order.
   */
  onInstrumentSelected?: (
    /** The selected instrument */
    instrument: {
      /** The ID of the instrument */
      ITEM_SECTOR: string;
      /** The name of the instrument */
      LONG_NAME: string;
    },
    /** The target element of the interaction */
    target: HTMLElement,
  ) => void;
}

/**
 *  **This component is part of the map package and requires the corresponding license**
 *
 * The "view" of the IndexMap component. This component does no data fetching
 * (other than extracting the map file), so you are responsible for fetching the
 * data yourself and passing it to the component.
 *
 * @see Is a part of the [IndexMap](/#!/IndexMap) component.
 * @since 1.1.0
 */
export const IndexMapComponent = ({
  values,
  region = 'world',
  initialOptions,
  className = 'IndexMapComponent',
  labelClassName = 'index-map__label',
  onInstrumentSelected,
  dataPoints = predefinedIndices,
}: IndexMapComponentProps) => {
  const [options, setOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    setOptions(
      Highcharts.merge(
        buildDefaultOptions({ labelClassName, onInstrumentSelected }),
        initialOptions,
      ),
    );
  }, [initialOptions, labelClassName, onInstrumentSelected]);

  useEffect(() => {
    const updateSeries = async () => {
      if (!Object.keys(values).length) return;

      const mapPoints = buildPoints({ values, dataPoints });

      setOptions((options: any) => {
        const newSeries = [
          {
            type: 'mappoint',
            name: 'Indices',
            allowPointSelect: true,
            data: mapPoints,
          },
        ];

        const prevMap = options?.series?.find((s: any) => s.type === 'map');
        if (prevMap) {
          newSeries.unshift(prevMap);
        }

        return {
          ...options,
          series: newSeries,
        };
      });
    };

    updateSeries();
  }, [dataPoints, values]);

  useEffect(() => {
    const updateMap = async () => {
      const { default: map } = await getMapForRegion(region);

      setOptions((options: any) => {
        const newSeries = [
          {
            type: 'map',
            name: 'Basemap',
            showInLegend: false,
            mapData: map,
          },
        ];

        const prevMappoint = options?.series?.find(
          (s: any) => s.type === 'mappoint',
        );
        if (prevMappoint) {
          newSeries.push(prevMappoint);
        }
        console.log('newSeries', newSeries);

        return {
          ...options,
          series: newSeries,
        };
      });
    };

    updateMap();
  }, [region]);

  const handleClick = (event: any) => {
    const { target } = event;
    const button = target.closest('button');

    if (!onInstrumentSelected) return;
    if (!button) return;

    const { itemSector } = button.dataset;
    const jawsData = Object.values(values).find(
      (instrument) => instrument.ITEM_SECTOR === itemSector,
    );

    if (!jawsData) return;
    onInstrumentSelected(jawsData, button);
  };

  return (
    <ChartWrapper
      labelClassName={labelClassName}
      clickable={!!onInstrumentSelected}
      onClick={handleClick}
    >
      {options?.series?.length === 2 && (
        <HighchartsReact
          highcharts={Highcharts}
          constructorType="mapChart"
          options={options}
          containerProps={{ className }}
        />
      )}
    </ChartWrapper>
  );
};
