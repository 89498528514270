import PropTypes from 'prop-types';
import React from 'react';
import Immutable from 'immutable';
import Highcharts from 'highcharts/highstock';
import indicators from 'highcharts/indicators/indicators-all';
import { logger } from '@oms/utils';

import defaultOptions, { globalOptions } from './options';

const log = logger('Chart');

indicators(Highcharts);
Highcharts.setOptions(globalOptions);

export class Chart extends React.Component {
  state = {
    prevRect: {},
  };

  chartInstance = null;

  static propTypes = {
    options: PropTypes.object,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  componentDidMount() {
    this.renderChart();
  }

  componentDidUpdate() {
    const { loading, options, parentRect } = this.props;
    const { prevRect } = this.state;
    if (loading) {
      this.chartInstance.showLoading();
    } else {
      this.chartInstance.hideLoading();
    }

    const chartHeight = this.chartInstance.options?.chart?.height;
    const parentRectChanged =
      prevRect?.height !== parentRect?.height ||
      prevRect?.width !== parentRect?.width;
    if (!loading && !chartHeight && parentRectChanged) {
      log('Reflowing chart because of parent rect change', parentRect);
      this.chartInstance.reflow();
      this.setState({ prevRect: parentRect });
    }

    log('Updating chart with options', options);
    this.chartInstance.update(options, true, true, true);
  }

  componentWillUnmount() {
    if (this.chartInstance) this.chartInstance.destroy();
  }

  setChartRef = (ref) => {
    this.chartRef = ref;
  };

  renderChart = () => {
    if (process.env.NODE_ENV === 'test') return;
    if (this.chartInstance) this.chartInstance.destroy();

    const { options } = this.props;

    const chartOptions = Immutable.fromJS(defaultOptions)
      .mergeDeep(options)
      .setIn(['chart', 'renderTo'], this.chartRef)
      .toJS();

    log('Rendering chart with options', chartOptions);
    this.chartInstance = new Highcharts.StockChart(chartOptions);
  };

  render() {
    return (
      <>
        <div ref={this.setChartRef} style={{ height: '100%' }} />
        {this.props.jawsQuery &&
          React.cloneElement(this.props.jawsQuery, {
            chartInstance: this.chartInstance,
          })}
      </>
    );
  }
}
