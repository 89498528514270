import React, { Suspense, useState } from 'react';
import { Spec } from '@oms/jaws-react';
import { QuotelistToolbar } from './QuotelistToolbar';
import { QuotelistComponent } from './QuotelistComponent';
import { Container } from './styles';
import { useQuotelist } from './useQuotelist';
import { Meta } from './types';
import { Presets } from './filters';

export interface QuotelistProps {
  /**
   * Columns that are only included in the spec for metadata purposes. In other
   * words, these columns are not rendered out into the list.
   */
  exclude?: string[];
  /**
   * A spec to override the default values of the component. Most of the spec
   * is being controlled by the QuotelistToolbar component, so you may need to implement
   * your own QuotelistToolbar component if you wish to have more fine-grained control
   * over the spec.
   *
   * @see See the [QuotelistFetcher](/#!/QuotelistFetcher) component for available
   * options.
   */
  initialSpec?: Spec & {
    /**
     * Determines which columns are fetched, but also which are shown when the
     * component mounts. Provided as a normal Jaws spec comma-separated
     * columns string. If the column is supported it will be shown in the
     * table.
     */
    columns?: string;
  };
  /**
   * When activated, this flag will render the `QuotelistToolbar` on the left hand side
   * of the `QuotelistComponent` as a sidebar. This allows for a more fluid
   * desktop experience.
   */
  withSidebar?: boolean;
  /**
   * Determines how the `QuotelistComponent` component should render. Passing
   * `'simple'` will make the table scroll using a simple overflow along the
   * X-axis on mobile. `'flipped'` will "turn the table on its side" on
   * smaller screens, making for a more mobile optimized behavior on long
   * lists.
   */
  variant?: 'normal' | 'flipped';
  /**
   * When enabled, this option will make the corresponding row and cells flash
   * whenever an update to an instrument streams in from Jaws. In practice this
   * means whenever the price of an instrument goes up, that cell will flash
   * green, down for red and gray for an update to the row that did not affect
   * the given cell.
   *
   * Behind the scenes this sets the `update-pos`, `update-neg` and
   * `update-flat` classes on each cell, calculated based on wether or not the
   * value in that cell has changed up, down or not since the last value.
   * These classes can be overridden using global CSS with specificity greater
   * than 0.2.0.
   *
   * When streaming is disabled, this flag is a no-op.
   */
  highlightChanges?: boolean;
  /**
   * The links on the instruments in the table.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/docs/Glossary.md#locationdescriptor).
   */
  linkTo?: any;
  /**
   * Determines which quotelist and filters should be rendered. If you require a
   * more fine-grained set of instruments you may have to implement a custom
   * `QuotelistToolbar` component.
   */
  preset?:
    | 'ose-stocks'
    | 'sse-nordic-stocks'
    | 'sse-stocks'
    | 'cse-stocks'
    | 'commodities'
    | 'nor-indices'
    | 'interests';
  /**
   * When passed, this object will render an extra cell at the end of each
   * instrument's row containing contextual actions to that component.
   *
   * The documentation page's rendering of the type is bugged, the correct
   * type of this props is more akin to:
   *
   * ```
   * interface MenuProp {
   *   buttonContent: ReactNode,
   *   actions: Array<Action>,
   * }
   *
   * interface Action {
   *   label: String,
   *   to?: String | {
   *     pathname: String,
   *     query?: String
   *   },
   *   onClick?: Function,
   * }
   * ```
   *
   * The onClick function is passed the selected instrument as the first
   * argument and the event as the second argument.
   *
   * `to`-links are similar as other `linkTo` props, being RFC6570 compatible
   * templates.
   *
   * Be aware that passing this object as an inline object in the JSX will
   * break memoization and degrade performance as the table components rely on
   * a shallow prop equality comparison. It is therefore recommended to pass
   * this as either a `const` declared at the top of the file or a state
   * field to ensure the reference is stable.
   */
  menu?: {
    buttonContent: string;
    actions: {
      label: string;
      to: any;
    }[];
  };
  /**
   * A callback that will be invoked after the preset spec has been loaded and
   * data has been requested. Includes some metadata like the spec and which
   * filters are selected.
   *
   * @param {Object} spec - The Jaws spec that has been loaded
   * @param {Object} meta - Metadata about the state of the filters component
   * @param {Object} meta.selectedMarket - The current selected market/selection. Contains a label and a value
   * @param {Object} meta.selectedList - The current selected list of the market Contains a label and a value
   * @param {Object} meta.selectedSector - The current selected sector (SECTOR_INDEX_MEMBER_OF for OB). Contains a label and a value
   */
  onLoad?: (spec: Spec, meta: Meta) => void;
  /**
   * A callback that will be invoked whenever the spec changes. This
   * essentially means whenever a filter is changed by a user. Includes some
   * metadata like the spec and which filters are selected.
   *
   * @param {Object} spec - The Jaws spec that has been loaded
   * @param {Object} meta - Metadata about the state of the filters component
   * @param {Object} meta.selectedMarket - The current selected market/selection. Contains a label and a value
   * @param {Object} meta.selectedList - The current selected list of the market Contains a label and a value
   * @param {Object} meta.selectedSector - The current selected sector (SECTOR_INDEX_MEMBER_OF for OB). Contains a label and a value
   */
  onSpecChange?: (spec: Spec, meta: Meta) => void;
  /** A class that will be passed to the wrapping element */
  className?: string;
  /** A class that will be passed to the QuotelistToolbar component */
  toolbarClassName?: string;
  /** A class that will be passed to the QuotelistComponent component */
  quotelistClassName?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /**
   * The fallback loading component for the news table component. By default
   * it does not render any loading component.
   */
  fallback?: React.ReactNode;
  /** @ignore Needs to be more thought out / polished to be part of official API*/
  presetModifier?: (preset: Presets[string]) => Presets[string];
}

/**
 * Prints out a complete quotelist of a preset list of instruments. A preset is
 * passed using the `preset` prop, and the `Quotelist` component will handle
 * setting up the correct filters and resubscribing based on the user's
 * preferences.
 *
 * The `Quotelist` component takes a position of ease-of-use over ultimate
 * flexibility. If you require more customization that the props of the
 * component and CSS allows you, look into implementing one of the components
 * yourself and composing it together with the other parts of this component,
 * `QuotelistComponent`, `QuotelistFetcher` and `QuotelistToolbar`.
 *
 * It is possible to use the `columns` specified in the `spec` to determine
 * which columns should be rendered on load, but the user can add or remove
 * columns at a later time using the columns selector.
 *
 * @since 1.1.0
 */
export const Quotelist = ({
  className = 'Quotelist',
  disableLoading = false,
  exclude = ['ITEM_SECTOR', 'SECTOR'],
  highlightChanges = false,
  initialSpec,
  linkTo = '/instrument/{ITEM_SECTOR}',
  menu,
  onLoad,
  onSpecChange,
  preset = 'ose-stocks',
  quotelistClassName = 'QuotelistComponent',
  toolbarClassName = 'QuotelistToolbar',
  variant = 'normal',
  withSidebar = false,
  fallback = <div />,
  presetModifier,
}: QuotelistProps) => {
  const [spec, setSpec] = useState<Spec>();

  const initSpec = (spec: Spec, meta: Meta) => {
    setSpec(spec);

    if (onLoad) {
      onLoad(spec, meta);
    }
  };

  const handleSpecChange = (spec: Spec, meta: Meta) => {
    setSpec(spec);

    if (onSpecChange) {
      onSpecChange(spec, meta);
    }
  };

  const jawsProps = useQuotelist({
    spec: { initiatorComponent: 'Quotelist', ...spec },
  });

  return (
    <Container className={className} withSidebar={withSidebar}>
      <QuotelistToolbar
        className={toolbarClassName}
        initialSpec={initialSpec}
        preset={preset}
        onLoad={initSpec}
        onSpecChange={handleSpecChange}
        presetModifier={presetModifier}
      />
      {!!spec && (
        <Suspense fallback={fallback}>
          <QuotelistComponent
            {...jawsProps}
            className={quotelistClassName}
            columns={spec.columns}
            exclude={exclude}
            variant={variant}
            highlightChanges={highlightChanges}
            linkTo={linkTo}
            menu={menu}
          />
        </Suspense>
      )}
    </Container>
  );
};
