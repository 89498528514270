import * as React from 'react';
import { formatNumber } from '@oms/utils';
import { SearchDialogRenderItemProps } from '@oms/ui-search-dialog';
import { Icon, light } from '@oms/ui-icon';
import { useForkRef } from '@oms/ui-utils';
import { Text } from '@oms/ui-text';
import { useDoubleClick } from './utils';

export const getColor = (value: number) => {
  return Math.sign(value) === 1
    ? 'text-pos'
    : Math.sign(value) === -1
    ? 'text-neg'
    : 'text';
};

export const RenderItem = React.forwardRef(function RenderItem(
  {
    item,
    inputValue,
    isHighlighted,
    isSelected,
    itemProps,
    itemToString,
    ItemContainer,
    HighLightedText,
    HighlightedText = HighLightedText,
    isMobile,
    onClick,
    onPreview,
    hasPreview,
    selectIcon: _selectIcon,
    ...containerProps
  }: SearchDialogRenderItemProps,
  ref: React.Ref<any>,
) {
  const innerRef = React.useRef<any>(null);
  const forkedRef = useForkRef(ref, innerRef);

  useDoubleClick({
    ref: innerRef,
    latency: 300,
    onSingleClick: hasPreview ? () => onPreview(item) : onClick,
    onDoubleClick: hasPreview ? onClick : undefined,
  });

  if (!HighlightedText) {
    return null;
  }

  return (
    <ItemContainer
      ref={forkedRef}
      {...containerProps}
      data-highlighted={isHighlighted}
      data-selected={isSelected}
      onClick={() => onPreview(item)}
    >
      <div>
        <HighlightedText
          display="block"
          search={inputValue || ''}
          autoEscape={true}
        >
          {item?.ITEM}
        </HighlightedText>
        <HighlightedText
          display="block"
          search={inputValue || ''}
          autoEscape={true}
          variant="body1"
        >
          {item?.LONG_NAME || ''}
        </HighlightedText>
      </div>
      {!isSelected && !isHighlighted && !hasPreview && item?.CHANGE_PCT ? (
        <Text variant="body1" ml="auto" color={getColor(item?.CHANGE_PCT)}>
          {formatNumber(item?.CHANGE_PCT)} %
        </Text>
      ) : null}
      {isSelected && !isHighlighted ? (
        <Icon ml="auto" icon={light.faCheck} />
      ) : null}
      {isHighlighted ? (
        <Icon
          ml="auto"
          size="lg"
          mr={2}
          icon={light.faLevelDown}
          rotation={90}
        />
      ) : null}
    </ItemContainer>
  );
});
