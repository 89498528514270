import React, { useEffect, useState, RefObject } from 'react';
import fscreen from 'fscreen';
import { wrapEvent, forwardRefWithAs } from '@oms/ui-utils';
import { light } from '@oms/ui-icon';
import { IconButton, IconButtonProps } from '@oms/ui-icon-button';

export type FullscreenReturnState = {
  isFullscreen: boolean;
  request: () => void;
  exit: () => void;
  isSupported: boolean;
};

/**
 * useFullscreen
 * @memberof Fullscreen
 */
export const useFullScreen = (ref: RefObject<any>): FullscreenReturnState => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handler = () => {
      setIsFullscreen(fscreen.fullscreenElement !== null);
    };

    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener('fullscreenchange', handler);
    }
    return () => fscreen.removeEventListener('fullscreenchange', handler);
  }, []);

  return {
    isFullscreen,
    request: () => fscreen.requestFullscreen(ref.current),
    exit: fscreen.exitFullscreen,
    isSupported: fscreen.fullscreenEnabled,
  };
};

export type FullscreenButtonProps = FullscreenReturnState;

/**
 * Component that toggles the fullscreen mode
 * @memberof Fullscreen
 */
export const FullscreenButton = forwardRefWithAs<FullscreenButtonProps, any>(
  function FullscreenButton(
    {
      localRef,
      isFullscreen,
      request,
      exit,
      isSupported,
      as: Button,
      onClick,
      ...props
    },
    ref,
  ) {
    const toggle = isFullscreen ? exit : request;
    if (Button) {
      return (
        <Button ref={ref} onClick={wrapEvent(onClick, toggle)} {...props} />
      );
    }
    return (
      <IconButton
        ref={ref}
        onClick={
          (wrapEvent(onClick, toggle) as unknown) as IconButtonProps['onClick']
        }
        {...props}
        icon={isFullscreen ? light.faCompress : light.faExpand}
        tooltip={isFullscreen ? 'exit' : 'fullscreen'}
      />
    );
  },
);
