import React from 'react';
import ReactDOM from 'react-dom';
import { useTheme } from '@xstyled/emotion';
import { useStockChartController } from '../StockChartController';
import { useStockChartContainerNode } from '../StockChartContainer';
import {
  useSmartSearchState,
  SmartSearchDisclosure,
  SmartSearchDialog,
} from '@oms/components-smart-search';
import { Chip } from '@oms/ui-chip';

interface MainChipProps {
  /** Setting this option to false disables the main instrument selector*/
  allowChangeMainInstrument?: boolean;
  /** The group that will be used to fetch possible matches */
  searchGroup?: string;
}

export function MainChip({
  allowChangeMainInstrument = true,
  searchGroup,
}: MainChipProps) {
  const controller = useStockChartController();
  const dialog = useSmartSearchState({ modal: false });
  const node = useStockChartContainerNode();
  const theme = useTheme();
  if (!allowChangeMainInstrument) {
    return (
      <Chip
        tabIndex={-1}
        style={{ cursor: 'default' }}
        leftElement={
          <svg
            style={{
              color: theme?.colors['chart-1'] ?? '#00685e',
              width: '9px',
            }}
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            viewBox="0 0 512 512"
          >
            <defs />
            <path
              fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
            />
          </svg>
        }
      >
        {controller.state.main.ticker}
      </Chip>
    );
  }
  return (
    <>
      <SmartSearchDisclosure
        {...dialog}
        as={Chip}
        leftElement={
          <svg
            style={{
              color: theme?.colors['chart-1'] ?? '#00685e',
              width: '9px',
            }}
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            viewBox="0 0 512 512"
          >
            <defs />
            <path
              fill="currentColor"
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
            />
          </svg>
        }
      >
        {controller.state.main.ticker}
      </SmartSearchDisclosure>
      {node
        ? ReactDOM.createPortal(
            <SmartSearchDialog
              group={searchGroup}
              dialogState={dialog}
              onChange={(change) => {
                const payload = {
                  itemSector: change.ITEM_SECTOR!,
                  ticker: change.ITEM!,
                  name: change.LONG_NAME!,
                  instrumentType: change.TYPE!,
                };
                if (
                  'ITEM_SECTOR' in change &&
                  typeof change.ITEM_SECTOR === 'string'
                ) {
                  controller.setMain(payload);
                }
              }}
              aria-label="Check for Updates"
              unstable_orphan={true}
            />,
            node,
          )
        : null}
    </>
  );
}
