import React, { createContext, useContext } from 'react';
import { Icon, light } from '@oms/ui-icon';
import { mapProps, SystemProps } from '@oms/ui-utils';
import { Text } from '@oms/ui-text';
import * as S from './styles';

type Variant = 'check' | 'bullet' | 'dash' | 'number' | 'alphabet';

const ListContext = createContext<[boolean, Variant]>([false, 'check']);
ListContext.displayName = 'ListContext';
export interface ListProps extends Omit<SystemProps, 'as'> {
  children: React.ReactNode;
  variant?: Variant;
}

/**
 * Lists are vertical groupings of related content. List items begin with either a number, letter, dash, bullet or check(mark).
 * List of different variants can be nested.
 *
 * The `alphabet`and `number`variant use the `ol` element.
 */
export function List({ children, variant = 'check', ...props }: ListProps) {
  const [nested] = useContext(ListContext);
  const isOrdered = variant === 'number' || variant === 'alphabet';
  return (
    <S.List
      as={isOrdered ? 'ol' : 'ul'}
      {...props}
      pt={nested ? 4 : 0}
      pl={nested ? 4 : 0}
    >
      <ListContext.Provider value={[true, variant]}>
        {React.Children.map(children, (child, index) =>
          React.cloneElement(child as any, { variant, index }),
        )}
      </ListContext.Provider>
    </S.List>
  );
}

export interface ListItemProps {
  children: React.ReactNode;
  variant?: Variant;
  count?: number;
  index?: number;
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

const renderAddon = mapProps({
  check: <Icon icon={light.faCheckCircle} />,
  bullet: '•',
  dash: '‒',
});

const renderOrdered = (variant: string, index: number) =>
  variant === 'number' ? `${index + 1}.` : `${alphabet[index]}.`;

export function ListItem({ children, variant, index }: any) {
  const isOrdered = variant === 'number' || variant === 'alphabet';
  return (
    <S.ListItem>
      <Text
        as="div"
        display="flex"
        fontWeight="bold"
        role={!isOrdered ? 'presentation' : undefined}
      >
        {isOrdered ? renderOrdered(variant, index) : renderAddon(variant)}
      </Text>
      <Text as="div" ml={4}>
        {children}
      </Text>
    </S.ListItem>
  );
}
