import moment from 'moment-timezone';

export const globalOptions = {
  lang: {
    loading: 'Loading...',
    months: [
      'januar',
      'februar',
      'mars',
      'april',
      'mai',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'desember',
    ],
    shortMonths: [
      'jan',
      'feb',
      'mar',
      'apr',
      'mai',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'des',
    ],
    weekdays: [
      'Søndag',
      'Mandag',
      'Tirsdag',
      'Onsdag',
      'Torsdag',
      'Fredag',
      'Lørdag',
    ],
    decimalPoint: ',',
    rangeSelectorZoom: 'Historisk periode',
    resetZoom: 'Reset zoom',
    resetZoomTitle: 'Reset zoom til 1:1',
    thousandsSep: ' ',
  },
};

export default {
  time: {
    useUTC: true,
    getTimezoneOffset(time: any) {
      return 0 - moment.tz(time, 'Europe/Oslo').utcOffset();
    },
  },
  stockTools: {
    gui: {
      enabled: false,
    },
  },
  chart: {
    height: 300,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  navigator: {
    enabled: true,
  },
  plotOptions: {
    series: {
      gapSize: 0,
      dataGrouping: {
        enabled: false,
      },
      tooltip: {
        valueDecimals: 2,
      },
      threshold: null,
    },
    flags: {
      cursor: 'pointer',
      animation: {
        duration: 200,
      },
    },
  },
  rangeSelector: {
    enabled: false,
  },
  scrollbar: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
    shared: true,
    useHTML: 'false',
    borderWidth: 0,
    crosshairs: true,
    valueDecimals: 2,
    dateTimeLabelFormats: {
      millisecond: '%A, %b %e, %H:%M:%S.%L',
      second: '%A, %b %e, %H:%M:%S',
      minute: '%A, %b %e, %H:%M',
      hour: '%A, %b %e, %H:%M',
      day: '%A, %b %e, %Y',
      week: '%A, %b %e, %Y',
      month: '%B %Y',
      year: '%Y',
    },
  },
  xAxis: {
    lineWidth: 2,
    lineColor: '#dadada',
    minorTickColor: '#000000',
    ordinal: false,
    startOnTick: false,
    gapGridLineWidth: 0,
    gapSize: 0,
  },
  zoom: {
    enabled: false,
  },
};
