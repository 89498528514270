import * as React from 'react';
import { SystemProps, mixins } from '@oms/ui-utils';
import styled from '@xstyled/emotion';
import { CountryCodeA2, CountryCodeA3 } from 'CountryCodes';
const getCountryISO2 = require('country-iso-3-to-2');

const Box = styled.box`
  ${mixins.borderBox}
  svg {
    ${mixins.media}
  }
`;

export interface FlagProps extends SystemProps {
  /** Country ISO code (supports both alpha2 and alpha3 codes)
   * @see https://www.iban.com/country-codes
   */
  countryCode: CountryCodeA2 | CountryCodeA3;
  size?: 'sm' | 'md' | 'lg';
}
const useFlag = (countryCode?: string) => {
  const [flag, setFlag] = React.useState();

  const fetchCountry = async (countryCode: string) => {
    const alpha2CountryCode =
      countryCode.length === 3 ? getCountryISO2(countryCode) : countryCode;

    const { default: importedFlag } = await import(
      `./flags/${alpha2CountryCode.trim().toLowerCase()}.js`
    );
    setFlag(importedFlag);
  };

  React.useEffect(() => {
    if (countryCode) {
      fetchCountry(countryCode);
    }
  }, [countryCode]);

  return flag;
};

const sizes = {
  sm: '1.5rem',
  md: '2rem',
  lg: '2.5rem',
};

export const Flag: React.FC<FlagProps> = ({
  countryCode,
  size = 'md',
  width = sizes[size],
  ...boxProps
}: FlagProps) => {
  const Icon = useFlag(countryCode);
  if (!Icon) return null;

  return (
    <Box
      display="block"
      width={width}
      {...boxProps}
      border="sm"
      borderColor="border"
    >
      {Icon}
    </Box>
  );
};
