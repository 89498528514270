import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';
import { mixins } from '@oms/ui-utils';

export const MenuBar = styled.div`
  ${mixins.borderBox}
  display: flex;
  align-items: center;
  overflow-x: auto;
  ${th('menuBar.default')}
`;

export const DateRangeButton = styled.button`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  padding:1 2;
  display:flex;
  align-items:center;
  &:hover {
      background-color:surface-hover;
  }
  &[aria-selected="true"]{
      color:selected;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 4;
  grid-auto-flow: column;
  margin-left: auto;
  align-items: center;
`;
