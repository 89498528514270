import * as React from 'react';
import template from 'url-template';
import { useComponentsContext } from '@oms/components-config-context';
import { useQuery } from 'react-query';

export const MINIMUM_QUERY_LENGTH = 2;

const urlPattern = template.parse(
  '{+baseUrl}/suggest{?group,query,limit,meta,searchFields,view,columns,leftJoins}',
);

export type UseSearchDialogQueryOptions = {
  /** The group that will be used to fetch possible matches */
  group?: string;
  /** Max number of entries to show */
  limit?: number;
  /** Include metadata with the result */
  meta?: boolean;
  /** The fields to use when checking the query for a match */
  searchFields?: string;
  /**
   * Override the default view of the returned data. You will probably not need
   * this
   */
  view?: 'REALTIME' | 'REALTIME_OR_EOD' | 'DELAYED' | 'DELAYED_OR_EOD' | 'EOD';
  /**
   * The fields that are made available to the component and the
   * `onSuggestionSelected` method. These are the same columns as in other
   * components.
   */
  columns?: string | string[];
  visible?: boolean;
};

type FetcherOptions = UseSearchDialogQueryOptions & {
  baseUrl: string;
  query: string | undefined;
};

const fetcher = async ({
  baseUrl,
  query,
  group,
  limit,
  meta,
  searchFields,
  view,
  columns,
}: FetcherOptions) => {
  const url = urlPattern.expand({
    baseUrl,
    query,
    group,
    limit,
    meta,
    searchFields,
    view,
    columns,
    leftJoins:
      'feed.ob.companyinfo.ORGANISATION_NUMBERS ' +
      'as info on ORGANISATION_NUMBER',
  });
  if (!url) {
    Promise.reject();
  }
  return await fetch(url, {
    credentials: 'include',
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`${response.status} - ${response.statusText}`);
    }
    return response.json();
  });
};

export function useSearchDialogQuery({
  group,
  limit,
  meta,
  searchFields,
  view,
  columns,
  visible,
}: UseSearchDialogQueryOptions) {
  const { baseUrl } = useComponentsContext();
  const [query, setQuery] = React.useState<string>('');
  const response = useQuery(
    [
      'smart-search',
      { baseUrl, query, group, limit, meta, searchFields, view, columns },
    ],
    () =>
      fetcher({
        baseUrl,
        query,
        group,
        limit,
        meta,
        searchFields,
        view,
        columns: Array.isArray(columns) ? columns.join() : columns,
      }),
    {
      enabled: query.length >= MINIMUM_QUERY_LENGTH,
    },
  );

  React.useEffect(() => {
    if (!visible) {
      setQuery('');
    }
  }, [visible]);

  const onInputValueChange = React.useCallback((changes: any) => {
    setQuery(changes);
  }, []);

  return { ...response, onInputValueChange };
}
