export default {
  'OSEBX.OSE': {
    // Oslo
    lat: 59.911491,
    lon: 10.757933,
    dataLabel: {
      top: true,
      left: true,
    },
    className: 'OSEBX',
  },
  'OMXS30.SSE': {
    // Stockholm
    lat: 59.334591,
    lon: 18.06324,
    dataLabel: {
      bottom: true,
      right: true,
    },
    className: 'OMXN40',
  },
  'OMXN40.OMX': {
    // Stockholm
    lat: 59.334591,
    lon: 18.06324,
    dataLabel: {
      top: true,
      right: true,
    },
    className: 'OMXN40',
  },
  'I:RTSI.MISX': {
    // Moscow
    lat: 55.751244,
    lon: 37.618423,
    dataLabel: {
      top: true,
      right: true,
    },
    className: 'RTSI',
  },
  'I:DAX.GER': {
    // Frankfurt
    lat: 50.110924,
    lon: 8.682127,
    dataLabel: {
      bottom: true,
      right: true,
    },
    className: 'DAX',
  },
  'I:N100.EUR': {
    // Amsterdam
    lat: 52.371807,
    lon: 4.896029,
    dataLabel: {
      bottom: true,
      left: true,
    },
    className: 'N100',
  },
  'I:UKX.FTSE': {
    // London
    lat: 51.509865,
    lon: -0.118092,
    dataLabel: {
      top: true,
      left: true,
    },
    className: 'UKX',
  },
  'I:COMP.NAS': {
    // New York
    lat: 40.73061,
    lon: -73.935242,
    dataLabel: {
      top: true,
      left: true,
    },
    className: 'COMP',
  },
  'I:SP500.SP': {
    // New York
    lat: 40.73061,
    lon: -73.935242,
    dataLabel: {
      top: true,
      left: true,
    },
    className: 'SP500',
  },
  'I:DJI.DJ': {
    // New York
    lat: 40.73061,
    lon: -73.935242,
    dataLabel: {
      bottom: true,
      left: true,
    },
    className: 'DJI',
  },
  'I:HSI.HSI': {
    // Hong Kong
    lat: 22.302711,
    lon: 114.177216,
    dataLabel: {
      bottom: true,
      right: true,
    },
    className: 'HSI',
  },
  'NI225.OSA': {
    // Tokyo
    lat: 35.652832,
    lon: 139.839478,
    dataLabel: {
      top: true,
      right: true,
    },
    className: 'NI225',
  },
  'C:PBROUSDBR\\SP.IDCENE': {
    // Northen North Sea
    lat: 70.353172,
    lon: 11.782606,
    dataLabel: {
      top: true,
      right: true,
    },
    className: 'BrentSpot',
  },
  'C:PWTIUSDBR\\SP.IDCENE': {
    // Someplace south of Texas
    name: 'WTI',
    lat: 10,
    lon: -100,
    dataLabel: {
      bottom: true,
      left: true,
      hidePoint: true,
    },
    className: 'WTI',
  },
};
