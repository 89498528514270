import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { JawsApi } from '@oms/jaws-react';
import { Loading, NoData } from '@oms/components-core';
import {
  InteractiveList,
  InteractiveListHead,
  InteractiveListBody,
  InteractiveListRow,
  InteractiveListHeadingCell,
  InteractiveListCell,
} from '@oms/ui-interactive-list';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { TableToggleRowsButton } from '@oms/ui-table';

import { Article } from './NewsArchive';

const NEWS = 'news-interactive-list';

interface NewsTableProps {
  data?: { [key: string]: Article };
  /** A className that will be passed to the table */
  className?: string;
  /**
   * The initial limit of news that will be shown in the archive. This is also
   * the amount that will be incremented when more items are shown
   */
  initialLimit?: number;
  /**
   * A function which makes the data this component fetches available to the
   * wrapping component. The function is invoked with two arguments
   *
   * @param {Immutable.Map} items The items as they will be rendered in the
   * chart. This means they are sorted as well.
   * @param {Function} setItemAsSelected A function which, when passed one of
   * values from the `items` argument, will mark an item as selected internally
   * in the `NewsTable` component. This can be used for initializing a
   * `NewsArticle` somewhere and then calling this function to set the item as
   * selected in the table as well.
   */
  onDataChanged?: (
    data: { [key: string]: Article },
    setItemAsSelected: (key: string) => void,
  ) => void;
  /**
   * When an element in the list is selected this function will be called.
   *
   * @param {Immutable.Map} article The article that was selected.
   * @param {Event} event The click event along with the target of the click
   * event. This is particularily useful for returning focus to this element
   * once the user is done with reading the provided article.
   */
  onSelect?: (article: Article, event: any) => void;
  /** Disables the loading indicator */
  disableLoading?: boolean;
}

/**
 * The table section of the `NewsArchive` component. This component takes a set
 * of filters and fetches a set of news from the server.
 *
 * @see Is a part of the [NewsArchive](/#!/NewsArchive) component.
 * @since 1.0.0
 */
const NewsTable = ({
  data = {},
  className = 'NewsTable',
  initialLimit = 15,
  disableLoading = false,
  onDataChanged,
  onSelect,
  initialized,
  resource,
  emptyData,
  jawsFetching,
}: NewsTableProps &
  Pick<JawsApi, 'initialized' | 'resource' | 'emptyData' | 'jawsFetching'>) => {
  /**
   * TODO, Make NewsTable a controlled component
   */
  const initialSelected = Object.keys(data)[0];

  const [_selectedKey = initialSelected, setSelectedKey] = useState<
    string | undefined
  >();
  const [limit, setLimit] = useState(initialLimit);

  useEffect(() => {
    // Reset limit on filters change
    setLimit(initialLimit);
  }, [initialLimit, jawsFetching]);

  const handleSelect = (key: string) => {
    if (onSelect) onSelect(data[key], {});
    setSelectedKey(key);
  };

  useEffect(() => {
    // Pass data and selectedKey setter to parent.
    if (onDataChanged) onDataChanged(data, setSelectedKey);
  }, [data, onDataChanged]);

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No news found`} />;
  }

  return (
    <>
      <VisuallyHidden id="news-table" aria-live="polite">
        <Trans>Search result</Trans>, {Object.keys(data).length} items
      </VisuallyHidden>
      <InteractiveList
        id={NEWS}
        aria-labelledby="news-table"
        className={className}
        layout="auto"
        onSelect={handleSelect}
      >
        <InteractiveListHead>
          <InteractiveListRow>
            <InteractiveListHeadingCell>
              <Trans>Time</Trans>
            </InteractiveListHeadingCell>
            <InteractiveListHeadingCell>
              <Trans>Ticker</Trans>
            </InteractiveListHeadingCell>
            <InteractiveListHeadingCell>
              <Trans>Headline</Trans>
            </InteractiveListHeadingCell>
          </InteractiveListRow>
        </InteractiveListHead>
        <InteractiveListBody>
          {Object.entries(data)
            .slice(0, limit)
            .map(([key, values]) => {
              const isMultiple =
                values?.COMPANY_TICKER &&
                values?.COMPANY_TICKER.split(',').length > 1;

              return (
                <InteractiveListRow key={key} value={key}>
                  <InteractiveListCell type="dateTime">
                    {values?.TIME}
                  </InteractiveListCell>
                  <InteractiveListCell>
                    {isMultiple ? (
                      <Trans>Various</Trans>
                    ) : (
                      values?.COMPANY_TICKER || ''
                    )}
                  </InteractiveListCell>
                  <InteractiveListCell>{values?.HEADLINE}</InteractiveListCell>
                </InteractiveListRow>
              );
            })}
        </InteractiveListBody>
      </InteractiveList>
      {Object.keys(data).length > limit && (
        <TableToggleRowsButton
          aria-controls={NEWS}
          style={{ width: '100%' }}
          onClick={() => {
            setLimit((currentLimit) => currentLimit + 15);
          }}
        >
          <Trans>Show more news</Trans>
        </TableToggleRowsButton>
      )}
    </>
  );
};

export default NewsTable;
