import React from 'react';
import styled from '@xstyled/emotion';
import { forwardRefWithAs } from '@oms/ui-utils';

const Box = styled.button`
  height: 3rem;
  min-width: 20rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3 6;
  background-color: surface-1;
  border: sm;
  border-color: border;
  box-shadow: md;
  cursor: text;
`;

const Kbd = styled.kbd`
  border: sm;
  border-color: border;
  border-radius: md;
  padding: 1;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 2;
`;

const SearchBox = forwardRefWithAs<any, 'button'>(function SearchBox(
  props: any,
  ref,
) {
  return (
    <Box ref={ref} {...props}>
      <span>Search</span>
      <span>
        <Kbd>⌥</Kbd>
        <Kbd>s</Kbd>
      </span>
    </Box>
  );
});

export { SearchBox };
