import { IndicatorType } from '../StockChartController';
import { INDICATOR_LABELS, INDICATOR_LABELS_T } from '../constants';

export function getIndicatorLabel(
  indicator: IndicatorType | 'rsi' | 'momentum' | 'roc',
) {
  return INDICATOR_LABELS[indicator];
}

export function getIndicatorLabelString(
  indicator: IndicatorType | 'rsi' | 'momentum' | 'roc',
) {
  return INDICATOR_LABELS_T[indicator];
}
