import React from 'react';
import { I18n } from '@lingui/react';
import { t } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { Wrap, WrapItem } from '@oms/ui-wrap';
import { Chip } from '@oms/ui-chip';
import { Icon, light } from '@oms/ui-icon';
import { useStockChartController } from '../StockChartController';
import {
  getComparisonColor,
  getIndicatorColor,
  getIndicatorLabelString,
} from '../utils';
import { MainChip } from './Main';

interface StockChartActiveItemsProps {
  /** Setting this option to false disables the main instrument selector*/
  allowChangeMainInstrument?: boolean;
  /** The group that will be used to fetch possible matches */
  searchGroup?: string;
}

export function StockChartActiveItems({
  allowChangeMainInstrument = true,
  searchGroup,
}: StockChartActiveItemsProps) {
  const controller = useStockChartController();

  return (
    <I18n>
      {({ i18n }) => {
        const i18nToString = (message: MessageDescriptor): string =>
          i18n ? i18n._(message) : message.id;

        return (
          <Wrap gap={4} p={2} pb={0} overflow="hidden">
            <WrapItem>
              <MainChip
                searchGroup={searchGroup}
                allowChangeMainInstrument={allowChangeMainInstrument}
              />
            </WrapItem>
            {controller.state.comparisons.map((comparison, index) => (
              <WrapItem>
                <Chip
                  key={comparison.ticker}
                  aria-label={`${i18nToString(t`Remove`)} ${
                    comparison.ticker
                  } `}
                  onClick={() =>
                    controller.removeComparison(comparison.itemSector)
                  }
                  leftElement={
                    <svg
                      style={{ color: getComparisonColor(index), width: '9px' }}
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      viewBox="0 0 512 512"
                    >
                      <defs />
                      <path
                        fill="currentColor"
                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                      />
                    </svg>
                  }
                  rightElement={<Icon icon={light.faTimes} />}
                >
                  {comparison.ticker}
                </Chip>
              </WrapItem>
            ))}
            {controller.state.indicators.map((indicator, index) => {
              const indicatorLabel = i18nToString(
                getIndicatorLabelString(indicator.indicatorType),
              );

              return (
                <WrapItem>
                  <Chip
                    key={
                      indicator.indicatorType + indicator.interval.toString()
                    }
                    aria-label={`${i18nToString(t`Remove`)} ${indicatorLabel} ${
                      indicator.interval
                    }`}
                    onClick={() => controller.removeIndicator(indicator)}
                    leftElement={
                      <svg
                        style={{
                          color: getIndicatorColor(index),
                          width: '9px',
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        viewBox="0 0 512 512"
                      >
                        <defs />
                        <path
                          fill="currentColor"
                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                        />
                      </svg>
                    }
                    rightElement={<Icon icon={light.faTimes} />}
                  >
                    {indicatorLabel} {indicator.interval}
                  </Chip>
                </WrapItem>
              );
            })}
          </Wrap>
        );
      }}
    </I18n>
  );
}
