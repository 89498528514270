import React, { useState } from 'react';
import moment from 'moment';
import { Link } from '@oms/ui-link';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { getItemFromItemSector, formatLink } from '@oms/utils';
import { Table, Td, Th, TableToggleRowsButton } from '@oms/ui-table';
import { Trans, t } from '@lingui/macro';
import { NoData, Loading } from '@oms/components-core';

const CALENDAR = 'calendar';

const defaultSpec = {
  initiatorComponent: 'Calendar',
  source: 'feed.calendar.ALL',
  start: moment
    .utc()
    .subtract({ day: 1 })
    .valueOf(),
  type: 'history',
  columns:
    'TICKER, CALENDAR_EVENT_DATE,' +
    'CALENDAR_EVENT_HEADING_NO, CALENDAR_EVENT_URL_NO,' +
    'ose.ITEM_SECTOR as OSE_ITEM_SECTOR,' +
    'oax.ITEM_SECTOR as OAX_ITEM_SECTOR,' +
    'merk.ITEM_SECTOR as MERK_ITEM_SECTOR',
  leftJoins:
    'feed.ose.quotes.EQUITIES+PCC+ETPS+RIGHTS+WARRANTS:DELAYED as ose on TICKER,' +
    'feed.oax.quotes.EQUITIES+RIGHTS:DELAYED as oax on TICKER,' +
    'feed.merk.quotes.EQUITIES+PCC+RIGHTS:DELAYED as merk on TICKER',
  limit: 5,
  filter: 'ITEM_SECTOR==sAll.OMS',
};

type BuildArguments = {
  itemSector?: string;
  spec?: Spec;
  limit?: number;
};

const buildSpec = ({ itemSector, spec, limit }: BuildArguments) => {
  const item = itemSector ? getItemFromItemSector(itemSector) : null;

  return {
    ...defaultSpec,
    filter: item
      ? `ITEM_SECTOR==sAll.OMS&&TICKER==s${item}`
      : 'ITEM_SECTOR==sAll.OMS',
    limit: limit && Number.isFinite(limit) ? limit : undefined,
    ...spec,
  };
};

interface UseCalendarArguments {
  spec?: Spec;
  itemSector?: string;
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * The `limit` to use when the component is expanded. Can be overridden using
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
  /** Options used in fetch: `forceHTTPConnection` and `disableAutoSubscribe` */
  options?: Options;
}

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `Calendar` component.
 */
export const useCalendar = (
  {
    spec,
    itemSector,
    initialLimit = 5,
    initialExpanded,
    expandedLimit = 15,
    onExpandToggled,
    options = { forceHTTPConnection: true },
  }: UseCalendarArguments = { itemSector: '' },
) => {
  const [limit, setLimit] = useState(
    initialExpanded ? expandedLimit : initialLimit,
  );
  const [expanded, setExpanded] = useState(initialExpanded);

  const toggleExpanded = () => {
    const newValue = !expanded;
    setExpanded(newValue);
    setLimit(newValue ? expandedLimit : initialLimit);

    if (onExpandToggled) {
      onExpandToggled({ newValue });
    }
  };

  const builtSpec = buildSpec({ itemSector, limit, spec });
  const { items, ...values } = useJaws(builtSpec, options);
  const moreThenFiveRows = items.size >= 5;
  const showType = builtSpec.columns.includes('TYPE');

  /* Remove data with Empty Tickers */
  const data = items
    .filter((item) => item.get('TICKER'))
    .sortBy((item) => item.get('CALENDAR_EVENT_DATE'));

  return {
    ...values,
    emptyData: values.emptyData || !data.size,
    columns: showType ? 4 : 3,
    toggleExpanded,
    moreThenFiveRows,
    expanded,
    data: data.sortBy((item) => item.get('TIME')).toJS(),
  };
};

export interface CalendarProps {
  /** A spec to override the default values of the component. */
  spec?: Spec & {
    /** The source to fetch data from. _Default: feed.ose.quotes.EQUITIES_ */
    source?: string;
    /** The number of rows in the table. _Default: 5_ */
    limit?: number;
    /** The date to start the requested period. _Default: one day ago_ */
    start?: string | number;
    /** The date to stop the requested period. _Default: undefined_ */
    stop?: string | number;
  };
  /**
   * When defined, the component will show CAs for the specified instrument
   * only. This is rougly equal to adding it to the `spec`'s filter, only it
   * also makes sure the "Ticker"-column is hidden.
   */
  itemSector?: string;
  /**
   * A momentjs format string that the component will use to display dates.
   * Default of `L` is a localized short format based on the configured momentjs
   * locale.
   */
  dateFormat?: string;
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  linkTo?: any;
  /**
   * The page that is linked to on when clicking on a news story. If `null`,
   * then a link to newsweb will be used when available.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  newsItemLink?: any;
  /**
   * The page that the *More news* link will link to. If `null`, then this link
   * will not be rendered.
   *
   * The two accepted inputs are the same as the react-router `to`-prop.
   * Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  archiveLink?: any;
  /** Will be passed to the component */
  className?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /**
   * When set to `true`, the component will render a button in the table footer
   * which expands to see all available levels when clicked.
   */
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * The `limit` to use when the component is expanded. Can be overridden usen
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;

  showMoreButton?: boolean;
}

/* eslint-disable react/no-array-index-key */
/**
 * A component which lists upcoming corporate actions from the coporate actions
 * calendar.
 *
 * For showing corporate actions for only one instrument, see the `itemSector`
 * prop.
 *
 * @since 1.0.0
 */
export const Calendar = ({
  archiveLink = null,
  newsItemLink = '/calendar?filter=TICKER==s{TICKER}',
  itemSector,
  linkTo = '/instrument/{ITEM_SECTOR}',
  className = 'Calendar',
  spec,
  disableLoading = false,
  dateFormat = 'L',
  initialLimit = 5,
  initialExpanded = false,
  expandedLimit = 15,
  showMoreButton = false,
  onExpandToggled,
}: CalendarProps) => {
  const {
    resource,
    data,
    initialized,
    emptyData,
    expanded,
    moreThenFiveRows,
    toggleExpanded,
  } = useCalendar({
    spec,
    itemSector,
    initialLimit,
    initialExpanded,
    expandedLimit,
    onExpandToggled,
  });

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No events found`} />;
  }

  return (
    <>
      <Table className={className} width="100%">
        <thead>
          <tr>
            <Th type="dateTime">
              <Trans>Date</Trans>
            </Th>
            {!itemSector && (
              <Th type="text">
                <Trans>Ticker</Trans>
              </Th>
            )}
            <Th type="text">
              <Trans>Headline</Trans>
            </Th>
          </tr>
        </thead>
        <tbody>
          {Object.entries<any>(data).map(([key, values], index) => {
            const {
              CALENDAR_EVENT_DATE,
              CALENDAR_EVENT_HEADING_NO,
              CALENDAR_EVENT_URL_NO,
              MERK_ITEM_SECTOR: MERK,
              OSE_ITEM_SECTOR: OSE,
              OAX_ITEM_SECTOR: OAX,
            } = values;

            let ticker = values.TICKER;

            const ITEM_SECTOR = [MERK, OSE, OAX].find(
              (maybe) => !!maybe && maybe !== 'All.OMS',
            );

            const variables = {
              ...values,
              ITEM_SECTOR,
            };

            if (ITEM_SECTOR) {
              ticker = (
                <Link variant="link" to={formatLink(linkTo, variables)}>
                  {ticker}
                </Link>
              );
            }

            let newsItem;
            if (newsItemLink) {
              newsItem = (
                <Link variant="link" to={formatLink(newsItemLink, variables)}>
                  {CALENDAR_EVENT_HEADING_NO}
                </Link>
              );
            } else if (CALENDAR_EVENT_URL_NO) {
              newsItem = (
                <Link as="a" href={CALENDAR_EVENT_URL_NO} variant="link">
                  {CALENDAR_EVENT_HEADING_NO}
                </Link>
              );
            } else {
              newsItem = CALENDAR_EVENT_HEADING_NO;
            }

            return (
              <tr key={index}>
                <td>
                  <time data-testid={`time-${index}`}>
                    {moment
                      .utc(CALENDAR_EVENT_DATE, 'YYYYMMDD')
                      .format(dateFormat)}
                  </time>
                </td>
                {!itemSector && <Td type="text">{ticker}</Td>}
                <Td type="text" truncate title={CALENDAR_EVENT_HEADING_NO}>
                  {newsItem}
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {showMoreButton && moreThenFiveRows && (
        <TableToggleRowsButton
          onClick={toggleExpanded}
          aria-controls={CALENDAR}
          style={{ width: '100%' }}
          aria-label={
            expanded
              ? 'Toggle trades, show additional content'
              : 'Toggle trades, hide additional content'
          }
        >
          {expanded ? <Trans>Show fewer</Trans> : <Trans>Show more</Trans>}
        </TableToggleRowsButton>
      )}
      {archiveLink !== null && (
        <nav className="Calendar__navigation" data-testid="calendar-navigation">
          <Link variant="link" to={archiveLink}>
            <Trans>To archive</Trans>
          </Link>
        </nav>
      )}
    </>
  );
};

export default Calendar;
