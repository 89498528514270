import React from 'react';
import { forwardRefWithAs, Size, splitProps, systemProps } from '@oms/ui-utils';
import { TextInput } from '@oms/ui-text-input';
import { ReactDatePickerProps } from 'react-datepicker';
import { enGB, registerLocale } from './locales';
import * as S from './styles';

if (process.env.NODE_ENV !== 'test') {
  require('react-datepicker/dist/react-datepicker.css');
}

registerLocale('en-GB', enGB);

export function AdvancedHeader({
  changeMonth,
  changeYear,
  date,
  decreaseMonth,
  endYear,
  increaseMonth,
  locale,
  startYear,
}: // previousMonthButtonLabel
// nextMonthButtonLabel
// previousYearButtonLabel
// nextYearButtonLabel
any) {
  /*
  TODO
  @oms/ui-select
  Month Picker
  Year Picker
  startYear
  endYear
  */
  console.log({
    changeMonth,
    changeYear,
    date,
    decreaseMonth,
    endYear,
    increaseMonth,
    locale,
    startYear,
  });
  return (
    <div>
      <button onClick={decreaseMonth}>prev</button>
      <select
      // months
      />
      <select
      // years
      />
      <button onClick={increaseMonth}>next</button>
    </div>
  );
}

type SupportedDatePickerProps = Omit<
  ReactDatePickerProps,
  'selected' | 'placeholderText' | 'onChange' | 'value'
>;

export interface DateInputProps extends SupportedDatePickerProps {
  value?: Date; //ReactDatePickerProps['selected'];
  placeholder?: ReactDatePickerProps['placeholderText'];
  onChange?: ReactDatePickerProps['onChange'];
  /** Format of the string is based on Unicode Technical Standard.
   * @see https://date-fns.org/v2.18.0/docs/format */
  dateFormat?: ReactDatePickerProps['dateFormat'];
  size?: Size;
  /**
   * Work in progress
   * advancedHeader?: boolean;
   */
}

export const DateInput = forwardRefWithAs<DateInputProps, 'input'>(
  function DateInput(
    {
      value,
      placeholder,
      size,
      renderCustomHeader,
      isClearable,
      as: _as,
      type: _type,
      dateFormat = 'P',
      locale = 'en-GB',
      ...props
      /*advancedHeader,*/
    },
    ref,
  ) {
    const [system, input] = splitProps(props, systemProps as any);
    return (
      <S.DatePicker
        {...input}
        ref={ref}
        selected={value}
        onChange={((input as unknown) as any).onChange}
        placeholderText={placeholder}
        showPopperArrow={false}
        popperContainer={S.PopperContainer}
        calendarContainer={props.inline ? S.CalendarContainer : undefined}
        isClearable={isClearable && !!value}
        customInput={<TextInput size={size} {...(system as any)} />}
        dateFormat={dateFormat}
        locale={locale}
        // previousMonthButtonLabel
        // nextMonthButtonLabel
        // previousYearButtonLabel
        // nextYearButtonLabel
        /*
        renderCustomHeader={
          advancedHeader
            ? customHeaderProps => (
                <AdvancedHeader
                  {...customHeaderProps}
                  // previousMonthButtonLabel={input["previousMonthButtonLabel"]}
                  // nextMonthButtonLabel={input["nextMonthButtonLabel"]}
                  // previousYearButtonLabel={input["previousYearButtonLabel"]}
                  // nextYearButtonLabel={input["nextYearButtonLabel"]}
                />
              )
            : renderCustomHeader
        }
        */
        autoComplete="off"
      />
    );
  },
);

DateInput.displayName = 'DateInput';
