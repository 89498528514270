import { Spec, Options, useJaws } from '@oms/jaws-react';
import moment from 'moment';
import { FilterState } from './NewsFilters';

const columns = [
  'REFERENCEID',
  'COMPANY_TICKER',
  'TIME',
  'HEADLINE',
  'TEXT',
  'URL',
  'ose.ITEM_SECTOR as OSE_ITEM_SECTOR',
  'oax.ITEM_SECTOR as OAX_ITEM_SECTOR',
  'merk.ITEM_SECTOR as MERK_ITEM_SECTOR',
];

const leftJoins = [
  'feed.ose.quotes.EQUITIES+PCC+ETPS+RIGHTS+WARRANTS as ose on COMPANY_TICKER',
  'feed.oax.quotes.EQUITIES+RIGHTS as oax on COMPANY_TICKER',
  'feed.merk.quotes.EQUITIES+PCC+RIGHTS as merk on COMPANY_TICKER',
];

export const defaultSpec = {
  initiatorComponent: 'NewsTable',
  source: 'feed.news.ob.ALL',
  columns: columns.join(','),
  leftJoins: leftJoins.join(','),
  space: 'TICK',
  type: 'history',
  ranking: '-TIME',
  start: moment()
    .subtract(2, 'days')
    .valueOf(),
};

const filterBuilder = (filters: Partial<FilterState> = {}) => {
  const { ticker, headline, referenceId } = filters;
  let filter = 'ITEM==sAll';
  if (ticker) {
    filter += `&&COMPANY_TICKER==s${ticker}`;
  }
  if (headline) {
    filter += `&&HEADLINE##s${headline}`;
  }
  if (referenceId) {
    filter += `&&REFERENCEID==s${referenceId}`;
  }

  return filter;
};

type BuildSpecArguments = {
  spec?: Spec;
  filters?: FilterState;
};

const buildSpec = ({ spec, filters }: BuildSpecArguments) => {
  return {
    ...defaultSpec,
    filter: filterBuilder(filters),
    ...(filters?.start ? { start: moment(filters?.start).valueOf() } : {}),
    ...(filters?.stop ? { stop: moment(filters?.stop).valueOf() } : {}),
    ...spec,
  };
};

interface UseNewsArguments {
  spec?: Spec;
  options?: Options;
  filters?: FilterState;
}

export const useNews = ({ spec, filters, options }: UseNewsArguments) => {
  const { items, ...values } = useJaws(buildSpec({ spec, filters }), options);

  return {
    ...values,
    data: items
      .sortBy((item) => item.get('TIME'))
      .reverse()
      ?.toJS(),
  };
};

export default useNews;
