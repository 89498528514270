/* eslint-disable react/no-find-dom-node */

import React from 'react';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { t } from '@lingui/macro';
import { NoData, Loading } from '@oms/components-core';
import { useResizeObserver } from '@oms/ui-element-query';
import { Box } from '@oms/ui-box';

import { findColor } from '../Treemap/utils';
import Treemap from '../Treemap';
import * as styles from '../styles';

const defaultColors = {
  /** Color used for positive numbers */
  pos: '#008031',
  /** Color used for negative numbers */
  neg: '#e61437',
  /** Color used for flat numbers */
  flat: 'white',
  /**
   * Color gradually blended into the pos/neg color as color moves toward max
   * pos/neg value
   */
  blend: 'white',
  /**
   * The threshold at which to blend colors. Higher numbers mean a more
   * agressive blending
   */
  blendThreshold: 1.2,
};

const SECTORS = [
  'OTECG.OSE',
  'OTELG.OSE',
  'OHCG.OSE',
  'OFING.OSE',
  'OREG.OSE',
  'OCDG.OSE',
  'OCSG.OSE',
  'OING.OSE',
  'OBMG.OSE',
  'OENG.OSE',
  'OUTG.OSE',
];

const defaultSpec = {
  initiatorComponent: 'Sectors',
  columns: 'ITEM_SECTOR, ITEM, CHANGE_PCT, TURNOVER, LONG_NAME',
  itemSector: SECTORS.join(','),
};

type Colors = Partial<typeof defaultColors>;

type UseSectorsData = {
  spec?: Spec;
  options?: Options;
  colors?: Colors;
  transformOptions?: TransformOptions;
};

const useSectorsData = ({
  spec,
  options,
  colors,
  transformOptions = {},
}: UseSectorsData) => {
  const { items, ...jawsData } = useJaws({ ...defaultSpec, ...spec }, options);

  const largestValue = Math.max(
    ...items.map((item) => item.get('TURNOVER', 0)).toArray(),
  );

  const backgroundColors = { ...defaultColors, ...colors };

  const transformedItems = items
    .filter((item) => {
      if (transformOptions.equalNodeSizing) return true;
      return item.get('TURNOVER') >= largestValue / 6;
    })
    .sortBy((item) => {
      if (transformOptions.equalNodeSizing) return item.get('CHANGE_PCT');
      return item.get('TURNOVER');
    })
    .map((item) => {
      const values = item.toJS();
      const background = findColor(values.CHANGE_PCT, backgroundColors);
      const size = transformOptions.equalNodeSizing ? 1 : values.TURNOVER;

      return {
        name: values.LONG_NAME,
        size,
        change: values.CHANGE_PCT,
        background: background.hex(),
        color: background.light() ? 'black' : 'white',
        data: values,
      };
    })
    .toArray();

  const data = {
    name: 'Root',
    children: transformedItems,
  };

  return {
    ...jawsData,
    data,
  };
};

type TransformOptions = {
  equalNodeSizing?: boolean;
};

interface UseSectorsArguments {
  spec?: Spec;
  options?: Options;
  height?: number;
  colors?: Colors;
  transformOptions?: TransformOptions;
}

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `Sectors` component.
 */
export const useSectors = ({
  spec,
  options,
  height = 300,
  colors,
  transformOptions = {},
}: UseSectorsArguments = {}) => {
  const { data, ...jawsData } = useSectorsData({
    spec,
    options,
    colors,
    transformOptions,
  });

  return {
    ...jawsData,
    data,
  };
};

interface SectorsProps {
  /** A spec to override the default values of the component. */
  spec?: Spec & {
    /** The itemSectors used to fetch the result set. _Default:
     * "OTECG.OSE,OTELG.OSE,OHCG.OSE,OFING.OSE,OREG.OSE,
     *  OCDG.OSE,OCSG.OSE,OING.OSE,OBMG.OSE,OENG.OSE,OUTG.OSE"
     */
    itemSector?: string;
    /** The columns to fetch for each itemSector
     * _Default: 'ITEM_SECTOR, ITEM, CHANGE_PCT, TURNOVER, LONG_NAME'
     */
    columns?: string;
  };
  /**
   * The click handler for when a `<button>` node is clicked. When this is not
   * provided, the nodes will be rendered as non-clickable `<div>`s.
   */
  onNodeSelected?: (data: {
    data: {
      name: string;
      size: number;
      change: number;
      background: string;
      color: string;
      id: string;
      data: {
        ITEM: string;
        TURNOVER: number;
        LONG_NAME: string;
        ITEM_SECTOR: string;
        CHANGE_PCT: number;
      };
      /** More d3 metadata */
      [d3metaDataProp: string]: any;
    };
  }) => void;
  /** The colors used for the background colour of the cells in the chart */
  colors?: Colors;
  /**
   * The requested height of the chart in pixels. The width will be calculated
   * based on the width of the containing element.
   */
  height?: number;
  /** Will be passed to the component */
  className?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /**
   * Normally the nodes are sized based on TURNOVER
   * If set to true, disables this size difference in the graph.
   */
  equalNodeSizing?: boolean;
}

/**
 * A Treemap which displays the main sectors from specified exchange/source.
 * The sectors are sized based on their `TURNOVER` (can be disabled with prop `equalNodeSizing`),
 * with more traded sectors being scaled to be larger than their peers. The sectors are coloured based
 * on today's aggregated `CHANGE_PCT` on the sector.
 *
 * @since 1.0.0
 */
export const Sectors = ({
  className = 'Sectors',
  spec,
  height = 350,
  colors = defaultColors,
  onNodeSelected,
  disableLoading = false,
  equalNodeSizing = false,
}: SectorsProps) => {
  const { resource, data, initialized, emptyData } = useSectors({
    spec,
    height,
    colors,
    transformOptions: {
      equalNodeSizing,
    },
  });

  const [rootRef, observedEntry] = useResizeObserver<HTMLDivElement>();

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData || !data) {
    return <NoData text={t`No sectors found`} />;
  }

  return (
    <Box
      css={styles.Container}
      className={className}
      ref={rootRef}
      height={height}
    >
      <Treemap
        items={data}
        width={observedEntry?.contentRect?.width}
        height={observedEntry?.contentRect?.height}
        onNodeClicked={onNodeSelected}
      />
    </Box>
  );
};

export default Sectors;
