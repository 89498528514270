import DOMPurify from 'dompurify';

/**
 * Sanitize input and return object prepared
 * for react dangerouslySetInnerHTML
 *
 * @param {string} htmlString - A string containing HTML to be sanitized
 * @return {object} A sanitized HTML string in an object with the
 * shape { __html: string }
 */
export default function createMarkup(htmlString: string) {
  if (!htmlString) return;

  if (!DOMPurify.isSupported || !DOMPurify.sanitize) {
    // Tests (and older browsers) do not support DOMPurify,
    // so add a fallback to make code run
    return { __html: htmlString };
  }

  return { __html: DOMPurify.sanitize(htmlString) };
}

/**
 * Returns a boolean indicating whether or not we consider the string ti
 * be markup.
 *
 * This is a very simple check, and may fail corner cases. It only
 * checks if the first non-whitespace character is a literal &lt;.
 *
 * @param {string} markup - The string to be tested for being markup.
 * @return {boolean} Whether or not the string is markup.
 */
export function isMarkup(markup: string) {
  // Play it safe and only accept strings
  if (typeof markup !== 'string') return false;

  // Do a very basic string check - is the first non-whitespace char a "<"?
  return /^\s*\</.test(markup);
}
