import React from 'react';
import { MemoizedTd as Td } from '@oms/ui-table';
import * as S from './styles';

type Item = {
  ASK: number;
  ASKNBR: number;
  ASKVOL: number;
  BID: number;
  BIDNBR: number;
  BIDVOL: number;
  percentOfMax: {
    bid: number;
    ask: number;
  };
};

type RowProps = {
  hasLevel2: boolean;
  item: Item;
  index: number;
};

const numberFormatOptions = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 6,
};

export const Row: React.FC<RowProps> = (props) => {
  const { item, hasLevel2 = false, index } = props;
  const values = item;

  // Sanity check for values over 100
  const bid = Math.min(values.percentOfMax.bid, 100);
  const ask = Math.min(values.percentOfMax.ask, 100);

  return (
    <tr data-testid={`row-${index}`}>
      {hasLevel2 && (
        <Td type="integer" className="BIDNBR">
          {values.BIDNBR || 0}
        </Td>
      )}
      <Td
        type={values.BIDVOL > 1000000 ? 'number' : 'integer'}
        className="BIDVOL"
      >
        {values.BIDVOL || 0}
      </Td>
      <Td type="number" formatOptions={numberFormatOptions} className="BID">
        {values.BID}
      </Td>
      <Td className="bars" textAlign="right" pr={0}>
        <S.BarWrapper className="bars--wrapper">
          <S.Bar
            aria-label={`${bid} %`}
            backgroundColor="pos"
            style={{
              transform: `scaleX(${bid / 100})`,
              transformOrigin: '100%',
            }}
          />
        </S.BarWrapper>
      </Td>
      <Td className="bars" textAlign="left" pl={0}>
        <S.BarWrapper className="bars--wrapper">
          <S.Bar
            aria-label={`${ask} %`}
            backgroundColor="neg"
            style={{
              transform: `scaleX(${ask / 100})`,
              transformOrigin: '0%',
            }}
          />
        </S.BarWrapper>
      </Td>
      <Td type="number" formatOptions={numberFormatOptions} className="ASK">
        {values.ASK}
      </Td>
      <Td
        type={values.ASKVOL > 1000000 ? 'number' : 'integer'}
        className="ASKVOL"
      >
        {values.ASKVOL || 0}
      </Td>
      {hasLevel2 && (
        <Td type="integer" className="ASKNBR">
          {values.ASKNBR || 0}
        </Td>
      )}
    </tr>
  );
};
