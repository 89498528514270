import { Spec, useJaws, Options } from '@oms/jaws-react';
import Immutable from 'immutable';

const defaultSpec = {
  initiatorComponent: 'useTickers',
  columns:
    'ITEM, SECTOR, ITEM_SECTOR, LONG_NAME, LAST, CHANGE_PCT, CHANGE, TIME, OPEN, HIGH, LOW, CLOSE, VOLUME, TURNOVER',
  itemSector:
    'OSEBX.OSE, OBX.OSE, OMXS30.OMX, OMXN40.OMX, I:DAX.GER, I:N100.EUR, I:DJI.DJ, I:COMP.NAS, I:SP500.SP, I:HSI.HSI, NI225.OSA',
};

type BuildSpecArguments = {
  itemSector?: string;
  spec?: Spec;
};

const buildSpec = ({ itemSector, spec }: BuildSpecArguments) => ({
  ...defaultSpec,
  itemSector,
  ...spec,
});

export interface UseTickersArguments {
  spec?: Spec;
  options?: Options;
  itemSector?: string;
}

export interface itemProps {
  CHANGE: number;
  CHANGE_PCT: number;
  HIGH: number;
  ITEM: string;
  ITEM_SECTOR: string;
  LAST: number;
  LONG_NAME: string;
  LOW: number;
  OPEN: number;
  SECTOR: string;
  TIME: number;
  generator: number;
}

const sortByOrder = (
  items: Immutable.Map<any, any> | undefined | any,
  sortOrder: string[],
) => {
  const result: itemProps[] = [];
  sortOrder.forEach(function(key: string) {
    items?.some(function(item: any) {
      if (item?.get('ITEM_SECTOR') === key) {
        result.push(item.toJS());
        return true;
      }
    });
  });
  return result;
};

export const useTickers = ({
  spec,
  options,
  itemSector = defaultSpec.itemSector,
}: UseTickersArguments) => {
  const { items, ...values } = useJaws(
    buildSpec({ itemSector, spec }),
    options,
  );

  const sortOrder = itemSector.replace(/ /g, '').split(',');
  const data = sortByOrder(items, sortOrder);

  return {
    ...values,
    items: Immutable.fromJS(data),
    data,
  };
};

export default useTickers;
