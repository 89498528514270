import React from 'react';
import { SystemProps } from '@oms/ui-utils';
import * as S from './styles';

export interface TextMarqueeProps extends SystemProps {
  children: React.ReactNode;
  /** Play speed in seconds, default = 10 */
  speed?: number;
  contentWidth?: SystemProps['width'];
}

export function TextMarquee({
  speed = 10,
  contentWidth = '120vw',
  children,
  ...systemProps
}: TextMarqueeProps) {
  return (
    <S.Marquee {...systemProps}>
      <S.MarqueeTextContent width={contentWidth} data-speed={speed}>
        {children}
      </S.MarqueeTextContent>
    </S.Marquee>
  );
}
