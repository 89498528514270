import React from 'react';
import { Spec, Options } from '@oms/jaws-react';
import { useJaws } from '@oms/jaws-react';
import { useLanguage } from '@oms/ui-language-context';
import { Loading, NoData } from '@oms/components-core';
import { Flag } from './Flag';
import { Trans, t } from '@lingui/macro';
import { HeaderQuoteProps, buildSpec, mapSector } from './utils';

import { Box } from '@oms/ui-box';
import { Stack } from '@oms/ui-stack';
import { Wrap } from '@oms/ui-wrap';

import { Text } from '@oms/ui-text';
import { Td } from '@oms/ui-table';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { formatNumber, decimalPlaces } from '@oms/utils';

interface UseHeaderQuoteArguments {
  spec?: Spec;
  itemSector?: string;
  options?: Options;
}
export const useHeaderQuote = ({
  itemSector,
  options,
  spec,
}: UseHeaderQuoteArguments) => {
  const { items, ...values } = useJaws(
    buildSpec({ itemSector, spec }),
    options,
  );

  return {
    ...values,
    data: items.first()?.toJS(),
  };
};

/**
 * A component that gives a quick overview of a stock, including meta data
 * about the company if it exist.
 *
 * @since 2.0.0
 */
export const HeaderQuote = ({
  className = 'HeaderQuote',
  itemSector,
  spec,
  disableLoading = false,
  marketplaceNameGetter = mapSector,
  companyNameHeadingLevel = 'h1',
  tickerAsHeading = false,
  headingLeftElement = null,
}: HeaderQuoteProps) => {
  const { data, initialized, resource, emptyData } = useHeaderQuote({
    itemSector,
    spec,
  });
  const language = useLanguage();
  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No data found`} />;
  }

  const {
    CHANGE,
    CHANGE_PCT,
    CLOSE,
    CLOSENZ,
    CLOSENZ_CA,
    COUNTRY,
    DESCRIPTION_EN,
    DESCRIPTION_NO,
    ITEM,
    LAST,
    LASTNZ,
    LASTNZ_CA,
    LONG_NAME,
    SECTOR,
  } = data;
  const last = LAST || LASTNZ_CA || LASTNZ || CLOSE || CLOSENZ_CA || CLOSENZ;
  const showDescription = DESCRIPTION_EN?.length || DESCRIPTION_NO?.length;

  return (
    <Wrap className={className} gap={4}>
      <Stack className="HeaderQuote__overview">
        <Box display="flex">
          {headingLeftElement}
          <Text
            data-testid="name"
            as={companyNameHeadingLevel}
            variant="heading2"
            ml={headingLeftElement ? 2 : 0}
          >
            {tickerAsHeading ? ITEM : LONG_NAME}
          </Text>
        </Box>

        <Box display="flex" alignItems="center">
          <Flag countryCode={COUNTRY} height={28} width={42} mr={2} />
          <Box display="flex" flexDirection="column">
            <Text variant="caption">{tickerAsHeading ? LONG_NAME : ITEM}</Text>
            <Text variant="body2">{marketplaceNameGetter(SECTOR)}</Text>
          </Box>
        </Box>
      </Stack>

      <Stack className="HeaderQuote__marketData" alignment="end">
        <Text variant="heading2" data-testid="last">
          {last ? formatNumber(last, decimalPlaces(last)) : '-'}
        </Text>

        <table>
          <thead>
            <tr>
              <VisuallyHidden as="th">
                <Trans>Change</Trans>
              </VisuallyHidden>
              <VisuallyHidden as="th">
                <Trans>Change %</Trans>
              </VisuallyHidden>
            </tr>
          </thead>
          <tbody>
            <tr>
              <Td
                data-testid="change"
                pl={2}
                type="number"
                fractionDigits={decimalPlaces(CHANGE || 0)}
                trend
              >
                {CHANGE || 0}
              </Td>
              <Td data-testid="changePct" pl={2} type="percent" trend>
                {CHANGE_PCT || 0}
              </Td>
            </tr>
          </tbody>
        </table>
      </Stack>
      {showDescription ? (
        <Box
          className="HeaderQuote__description"
          flex="1 0 20rem"
          style={{ paddingTop: '3px' }}
        >
          <Text data-testid="description" variant="body2" maxWidth="50rem">
            {/^(no|nb)/i.test(language) ? DESCRIPTION_NO : DESCRIPTION_EN}
          </Text>
        </Box>
      ) : null}
    </Wrap>
  );
};

export default HeaderQuote;
