import React, { Suspense, SuspenseProps } from 'react';

/**
 * Renders a suspense container in a backwards compatible way.
 * @since 1.0.0
 */
export const SuspenseWrapper = ({
  fallback,
  children,
  ...props
}: SuspenseProps) => {
  if ('Suspense' in React) {
    return (
      <Suspense fallback={fallback} {...props}>
        {children}
      </Suspense>
    );
  }

  return <>{children}</>;
};
