import Highcharts from 'highcharts/highstock';
import { PERIODS, BAR_SERIES, POINTS } from '../constants';
import { DateRangeSelection, LineStyle } from '../StockChartController';
import {
  buildURL,
  getSeries,
  getSpaceFromSelection,
  getSeriesFromSelection,
  getBreaks,
  fetchJaws,
  getStartValue,
  getStopValue,
  FetchError,
  getRequestInit,
} from './utils';

type DomainUrl = string;
type BaseUrl = string;
type GraphDataUrl = string;
type JawsUrl = string;

type FetchMetaDataOptions = {
  itemSector: string;
  domainUrl: DomainUrl;
  baseUrl: BaseUrl;
};
type FetchMetaDataReturn = {
  item: string;
  sources: string[];
  LONG_NAME: string;
  itemSector: string;
  lists: string[];
  groups: string[];
  type: string; // "INDICES" | "EQUITIES"
  sector: string;
  ISIN: string;
};
export async function fetchMetaData({
  itemSector,
  domainUrl,
  baseUrl,
}: FetchMetaDataOptions): Promise<FetchMetaDataReturn> {
  const url = buildURL(domainUrl, {
    baseUrl,
    query: { itemSector },
  });
  const result = await fetch(
    url,
    getRequestInit({
      credentials: 'include',
    }),
  );
  if (!result.ok) {
    const errorText = await result.text();
    throw new FetchError({
      message: `Failed to fetch instrument metadata for ${itemSector}. ${errorText}`,
      method: 'fetchInstrumentMetadata',
      response: result,
    });
  }
  const instrument = (await result.json())[0];
  return instrument;
}

type FetchGraphDataOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  graphdataUrl: GraphDataUrl;
  baseUrl: BaseUrl;
  lineStyle: LineStyle;
};
export async function fetchGraphData({
  itemSector,
  dateRangeSelection,
  graphdataUrl,
  baseUrl,
}: FetchGraphDataOptions) {
  const url = buildURL(graphdataUrl, {
    baseUrl,
    itemSector: encodeURI(itemSector),
    space: getSpaceFromSelection(dateRangeSelection),
    series: getSeriesFromSelection(dateRangeSelection),
    query: {
      period: PERIODS[dateRangeSelection],
      points: POINTS[dateRangeSelection],
    },
  });
  const result = await fetch(
    url,
    getRequestInit({
      credentials: 'include',
    }),
  );
  if (!result.ok) {
    const errorText = await result.text();
    throw new FetchError({
      message: `Failed to fetch instrument graphdata for ${itemSector}. ${errorText}`,
      method: 'fetchGraphData',
      response: result,
    });
  }
  const { series, buckets } = await getSeries(await result.json());
  const breaks = getBreaks(buckets);
  return {
    breaks,
    series: series[0],
  };
}

type FetchCandleStickDataOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  graphdataUrl: GraphDataUrl;
  baseUrl: BaseUrl;
};
export async function fetchCandleStickData({
  itemSector,
  dateRangeSelection,
  graphdataUrl,
  baseUrl,
}: FetchCandleStickDataOptions) {
  const url = buildURL(graphdataUrl, {
    baseUrl,
    itemSector: encodeURI(itemSector),
    space: 'day',
    series: '(OPEN,HIGH,LOW,CLOSE_CA)',
    query: {
      period: PERIODS[dateRangeSelection],
      points: POINTS[dateRangeSelection],
    },
  });
  const result = await fetch(
    url,
    getRequestInit({
      credentials: 'include',
    }),
  );
  if (!result.ok) {
    const errorText = await result.text();
    throw new FetchError({
      message: `Failed to fetch instrument candlesticks for ${itemSector}. ${errorText}`,
      method: 'fetchCandleStickData',
      response: result,
    });
  }
  const { series, buckets } = await getSeries(await result.json());
  const breaks = getBreaks(buckets);
  return {
    breaks,
    series: series[0],
  };
}

type FetchBarDataOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  graphdataUrl: GraphDataUrl;
  baseUrl: BaseUrl;
  type: keyof typeof BAR_SERIES;
};
export async function fetchBarData({
  itemSector,
  dateRangeSelection,
  graphdataUrl,
  baseUrl,
  type,
}: FetchBarDataOptions) {
  const url = buildURL(graphdataUrl, {
    baseUrl,
    itemSector,
    space: getSpaceFromSelection(dateRangeSelection),
    series: `(${BAR_SERIES[type]})`,
    query: {
      period: PERIODS[dateRangeSelection],
      points: POINTS[dateRangeSelection],
    },
  });

  const result = await fetch(
    url,
    getRequestInit({
      credentials: 'include',
    }),
  );

  if (!result.ok) {
    const errorText = await result.text();
    throw new FetchError({
      message: `Failed to fetch instrument bars data for ${itemSector}. ${errorText}`,
      method: 'fetchBarData',
      response: result,
    });
  }

  const { series } = await getSeries(await result.json());
  return { series: series[0] };
}

type FetchNewsOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  jawsUrl: JawsUrl;
  baseUrl: BaseUrl;
} & Record<string, any>;

export function fetchNewsData({
  itemSector,
  dateRangeSelection,
  baseUrl,
  jawsUrl,
  ...rest
}: FetchNewsOptions) {
  return fetchJaws({
    initiatorComponent: 'AnalysisDataFetcher',
    component: 'analysisChartNews',
    urlTemplate: jawsUrl,
    baseUrl,
    itemSector,
    start: getStartValue(dateRangeSelection),
    stop: getStopValue(dateRangeSelection),
    ...rest,
  });
}

export async function fetchNewsDataTest({
  itemSector,
  dateRangeSelection,
  baseUrl,
  jawsUrl,
  name,
  mainSeriesId,
  ...rest
}: any) {
  const result = await fetchJaws({
    initiatorComponent: 'AnalysisDataFetcher',
    component: 'analysisChartNews',
    urlTemplate: jawsUrl,
    baseUrl,
    itemSector,
    start: getStartValue(dateRangeSelection),
    stop: getStopValue(dateRangeSelection),
    ...rest,
  });

  // type Meta ?
  const data: Highcharts.SeriesOptionsType & { [key: string]: any } = {
    id: 'custom',
    type: 'flags',
    onSeries: mainSeriesId,
    name: `${name} news`,
    data: result?.rows?.map((entry: any) => ({
      ...entry.values,
      title: '@',
    })),
    shape: 'squarepin',
    color: 'red',
    meta: {
      type: 'addition',
      addition: 'news',
      // requiresNewYAxis
    },
  };
  return data;
}

type FetchDividendsOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  jawsUrl: JawsUrl;
  baseUrl: BaseUrl;
} & Record<string, any>;

export function fetchDividendsData({
  itemSector,
  dateRangeSelection,
  baseUrl,
  jawsUrl,
  ...rest
}: FetchDividendsOptions) {
  return fetchJaws({
    initiatorComponent: 'AnalysisDataFetcher',
    component: 'analysisChartDividends',
    urlTemplate: jawsUrl,
    baseUrl,
    itemSector,
    start: getStartValue(dateRangeSelection),
    stop: getStopValue(dateRangeSelection),
    ...rest,
  });
}

type FetchCompanyInfoOptions = {
  itemSector: string;
  jawsUrl: DomainUrl;
  baseUrl: BaseUrl;
};
type FetchCompanyInfoReturn = {
  item: string;
  sources: string[];
  LONG_NAME: string;
  itemSector: string;
  lists: string[];
  groups: string[];
  type: string; // "INDICES" | "EQUITIES"
  sector: string;
  ISIN: string;
  SECTOR: string;
  sector_open_time: number;
  sector_close_time: number;
  CLOSE: number;
  CLOSENZ: number;
  CLOSENZ_CA: number;
  HIGH: number;
  LOW: number;
};
export async function fetchCompanyInfoData({
  itemSector,
  jawsUrl,
  baseUrl,
}: FetchCompanyInfoOptions): Promise<FetchCompanyInfoReturn> {
  const url = buildURL(jawsUrl, {
    baseUrl,
    spec: {
      itemSector,
      columns:
        'ITEM,SECTOR,CLOSE,CLOSENZ,CLOSENZ_CA,HIGH,LOW,sector_open_time,sector_close_time',
    },
  });
  const result = await fetch(
    url,
    getRequestInit({
      credentials: 'include',
    }),
  );
  if (!result.ok) {
    const errorText = await result.text();
    throw new FetchError({
      message: `Failed to fetch instrument companyInfo for ${itemSector}. ${errorText}`,
      method: 'fetchCompanyInfodata',
      response: result,
    });
  }
  const data = await result.json();
  return data.rows[0]?.values;
}
