import React from 'react';
import { forwardRefWithAs, SystemProps, mixins } from '@oms/ui-utils';
import styled from '@xstyled/emotion';
import { th, variant } from '@xstyled/system';

export interface BadgeProps extends SystemProps {
  variant?: string;
}

export const StyledBadge = styled.box`
  ${mixins.borderBox}
  padding: 0.0625rem 0.1875rem;
  width: max-content;
  ${th('text.variants.body1')}
  ${th('badge.default')}
  ${variant({
    key: 'badge.variants',
    default: 'primary',
  })}
`;

export interface BadgeProps extends SystemProps {}

export const Badge = forwardRefWithAs<BadgeProps, 'div'>((props, ref) => (
  <StyledBadge ref={ref} {...props} />
));
