import { useQueries } from 'react-query';
import { useComponentsContext } from '@oms/components-config-context';
import { fetchGraphData, fetchCandleStickData } from './fetchers';
import { getSeriesQueryKey, getStaleTime, queryOptionsBuilder } from './utils';
import { DateRangeSelection } from '../StockChartController';
import { useStockChartController } from '../StockChartController';

import { MAIN_SERIES_ID } from '../constants';

type UseGraphDataQueryOptions = {
  itemSector: string | string[];
  dateRangeSelection: DateRangeSelection;
  lineStyle?: string;
};
export function useGraphDataQueries({
  itemSector: itemSectorOption,
  dateRangeSelection,
  lineStyle = 'line',
}: UseGraphDataQueryOptions) {
  if (
    dateRangeSelection === 'SELECTION_INTRADAY_ONE_HOUR' ||
    dateRangeSelection === 'SELECTION_INTRADAY_TREE_HOURS'
  ) {
    dateRangeSelection = 'SELECTION_INTRADAY';
  }

  const { graphdataUrl, baseUrl } = useComponentsContext();
  return useQueries(
    [itemSectorOption].flat().map((itemSector) => ({
      queryKey: getSeriesQueryKey({
        itemSector,
        dateRangeSelection,
        lineStyle,
      }),
      queryFn: () =>
        lineStyle === 'candlestick'
          ? fetchCandleStickData({
              itemSector,
              dateRangeSelection,
              graphdataUrl,
              baseUrl,
            })
          : fetchGraphData({
              itemSector,
              dateRangeSelection,
              graphdataUrl,
              baseUrl,
              lineStyle,
            }),
      staleTime: getStaleTime(dateRangeSelection),
    })),
  );
}

export type UseCustomQueriesOptions = ReturnType<typeof queryOptionsBuilder>[];

export function useCustomQueries(queries: UseCustomQueriesOptions) {
  const { ErrorBoundary: _E, Suggest: _S, ...rest } = useComponentsContext();
  const { state } = useStockChartController();
  return useQueries(
    queries
      .map((queryOptionsBuilder) =>
        queryOptionsBuilder({
          mainSeriesId: MAIN_SERIES_ID,
          controller: state,
          ...rest,
        }),
      )
      .filter((queryOptions) => queryOptions.enabled === true),
  );
}

/*
// createSeriesQueryOptions
// seriesQueryOptionsBuilder

const custom = createSeriesQueryOptions(
  ({ baseUrl, jawsUrl, controller, mainSeriesId }) => ({
    queryKey: createQueryKey('2newsData', {
      itemSector: controller.main.itemSector,
      dateRangeSelection: controller.dateRangeSelection,
    }),
    queryFn: () => {
      const response = await fetch("....params")
      const data = await response.json()

      const x:QuerySeriesFetcherReturn = {
        data,
        id,
        type:"flags",
        shape:"",
        color:""
      }
      
      return x
    }
      ,
    staleTime: getStaleTime(controller.dateRangeSelection),
    enabled: controller.additions.includes('custom'),
  }),
);




const customQueryOptions = queryOptionsBuilder(BuilderFunction)

customQueryOptions.queryFn = Promise<QuerySeriesFetcherReturn>

customQueries={[customQueryOptions]}

useCustomQueries(customQueries)

<StockChart 
  seriesQueryOptions={[]}
  chartInstance={ref}
/>


<StockChart 
  seriesQueryOptions={[]}
>
  <AComponent />
</StockChart>
*/
