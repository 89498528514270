import React from 'react';
import Highcharts from 'highcharts';
import { useComponentsContext } from '@oms/components-config-context';
import { useResizeObserver } from '@oms/ui-element-query';
import { InstrumentChartFetcher } from './fetcher';
import { Chart } from './Chart';
import buildOptions from './buildOptions';
import { COLORS, SELECTION_WEEK, SELECTION } from './constants';
import { Spec } from '@oms/jaws-react';
import { JawsQuery } from './JawsQuery';
import * as styles from './styles';

export interface InstrumentChartProps {
  /** The main series of the chart */
  itemSector: string;
  /** The period to display in the chart */
  selection?: SELECTION;
  /** Enable streamin */
  enableStreaming?: boolean;
  /** The className to pass to the component */
  className?: string;
  /**
   * A list of CSS color strings that will be used as the stroke color on the
   * series in the chart. When not provided, a default defined in constants.js
   * will be used.
   *
   * When all colors in the set have been used, it wil loop around and use
   * colors from the start of the set.
   */
  seriesColors?: string[];
  /**
   * Any overrides to the highcharts config object. Will be merged into the
   * default configuration object provided with the component. See
   * https://api.highcharts.com/highstock/ for configuration options.
   */
  options?: object;
  /**
   * The `onError` prop provides a way to override the default `<ErrorBoundary>`
   * behavior for this component and render a different fallback UI when the
   * component throws an error.
   *
   * @param {Object} errorDescriptor - An object containing details of the error
   * @param {Error} errorDescriptor.error - The error that was thrown
   * @param {Function} errorDescriptor.reset - Resets the error and attempts
   * another render
   * @returns {React.Component} - The fallback UI that is rendered on an error
   */
  onError?: (errorDescriptor: {
    error: Error;
    reset: () => void;
  }) => React.ReactNode;
}

const columns = [
  'CHANGE',
  'CHANGE_PCT',
  'CLOSE',
  'CLOSENZ',
  'CLOSENZ_CA',
  'ITEM',
  'LAST',
  'LASTNZ',
  'LASTNZ_CA',
  'OPEN',
  'HIGH',
  'LOW',
  'TIME',
  'VOLUME',
  'TURNOVER',
  'ITEM_SECTOR',
];

export function buildSpec({ itemSector }: Spec): Spec {
  return {
    initiatorComponent: 'StockChartQuery',
    itemSector,
    columns: columns.join(),
  };
}

/**
 * A component that renders a chart of an instrument's development over the
 * given time period.
 *
 * `InstrumentChartFetcher` does not use `JawsProvider` and is configured via
 * `ComponentsContext`.
 *
 * @since 1.1.0
 */
export const InstrumentChart = ({
  className = 'InstrumentChart',
  itemSector,
  seriesColors = COLORS,
  options,
  selection = SELECTION_WEEK,
  enableStreaming = false,
  onError,
}: InstrumentChartProps) => {
  const { ErrorBoundary } = useComponentsContext();
  const [rootRef, observedEntry] = useResizeObserver<HTMLDivElement>(
    ['left', 'top'],
    200,
  );

  return (
    <ErrorBoundary component="InstrumentChart" onError={onError}>
      <InstrumentChartFetcher itemSector={itemSector} selection={selection}>
        {({ main, compares, additions, breaks, loading, refetch }) => {
          // ref can not be placed on a conditional element
          if (!main || !main.instrument) return <div ref={rootRef} />;
          const defaultOptions = buildOptions({
            main,
            selection,
            compares,
            additions,
            colors: seriesColors,
            breaks,
          });

          return (
            <div
              ref={rootRef}
              className={className}
              css={styles.InstrumentChart}
            >
              <Chart
                options={Highcharts.merge(defaultOptions, options)}
                loading={loading}
                parentRect={observedEntry.contentRect}
                jawsQuery={
                  enableStreaming && (
                    <JawsQuery
                      itemSector={itemSector}
                      enabled={selection === 'SELECTION_INTRADAY'}
                      maxPointsFactor={1.5}
                      refetch={refetch}
                    />
                  )
                }
              />
            </div>
          );
        }}
      </InstrumentChartFetcher>
    </ErrorBoundary>
  );
};
