/**
 * This function takes a bank account number and adds periods to positions 5 and 7.
 * E.g: formatBankAccountNo("12345678901") -> "1234.56.78901"
 * @param {string} bankAccountNo 11 chars long bank account number
 */
export default function(bankAccountNo: string) {
  if (typeof bankAccountNo !== 'string') return bankAccountNo;
  if (bankAccountNo.length !== 11) return bankAccountNo;

  const parts = {
    start: bankAccountNo.substring(0, 4),
    middle: bankAccountNo.substring(4, 6),
    end: bankAccountNo.substring(6),
  };

  return Object.values(parts).join('.');
}
