import classNames from 'classnames';
import { formatDate, formatNumber, trendClass } from '@oms/utils';

export default ({ labelClassName, onInstrumentSelected }) => ({
  title: {
    text: undefined,
  },
  chart: {
    style: {
      fontFamily: 'inherit',
      fontSize: 'inherit',
    },
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  plotOptions: {
    mappoint: {
      enableMouseTracking: false,
      dataLabels: {
        useHTML: true,
        allowOverlap: true,
        formatter() {
          const {
            ITEM_SECTOR,
            LAST,
            LASTNZ,
            CHANGE_PCT,
            sector_close_time: closeTimestamp,
          } = this.point.values;
          const changeClass = trendClass(CHANGE_PCT);
          const now = new Date();
          const closeTime = new Date(closeTimestamp);
          const isAfterClose = now > closeTime;
          const classes = classNames(
            labelClassName,
            this.point.className,
            changeClass,
          );
          const element = onInstrumentSelected ? 'button' : 'div';

          if (isAfterClose) {
            return `
              <${element} class="closed ${classes}" data-item-sector="${ITEM_SECTOR}">
                <div class="index-map__name">${this.point.name}</div>
                <div class="index-map__last">${formatNumber(LASTNZ, 2)}</div>
                <div class="index-map__change">${formatNumber(
                  CHANGE_PCT,
                  2,
                )}%</div>
                <div>Stengt ${formatDate(closeTime)}</div>
                <div class="index-map__arrow"></div>
              </${element}>
            `;
          }

          return `
            <${element} class="${classes}" data-item-sector="${ITEM_SECTOR}">
              <div class="index-map__name">${this.point.name}</div>
              <div class="index-map__last">${formatNumber(LAST, 2)}</div>
              <div class="index-map__change">${formatNumber(
                CHANGE_PCT,
                2,
              )}%</div>
              <div class="index-map__arrow"></div>
            </${element}>
          `;
        },
      },
    },
  },
});
