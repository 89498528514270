import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import Highcharts from 'highcharts/highstock';
import { useJaws } from '@oms/jaws-react';
import produce from 'immer';
import { DateRangeSelection, LineStyle } from '../StockChartController';
import { POINTS } from '../constants';
import { getSeriesQueryKey } from './utils';

import { Spec } from '@oms/jaws-react';

const MAX_POINTS_FACTOR = 1.5;

const columns = [
  'CHANGE',
  'CHANGE_PCT',
  'CLOSE',
  'CLOSENZ',
  'CLOSENZ_CA',
  'ITEM',
  'LAST',
  'LASTNZ',
  'LASTNZ_CA',
  'OPEN',
  'HIGH',
  'LOW',
  'TIME',
  'VOLUME',
  'TURNOVER',
  'ITEM_SECTOR',
];

export function buildSpec({ itemSector }: Spec): Spec {
  return {
    initiatorComponent: 'StockChartQuery',
    itemSector,
    columns: columns.join(),
  };
}

type JawsUpdateProps = {
  chartInstance: Highcharts.Chart | undefined;
  dateRangeSelection: DateRangeSelection;
  itemSector: string | string[];
  lineStyle: LineStyle;
  maxPointsFactor?: number;
};
export const JawsQuery = React.memo(function JawsQuery({
  chartInstance,
  dateRangeSelection,
  itemSector: itemSectorProp,
  lineStyle,
  maxPointsFactor = MAX_POINTS_FACTOR,
}: JawsUpdateProps) {
  // Streaming is only enabled on intra day graphs

  const enabled =
    chartInstance && dateRangeSelection.toLowerCase().includes('intraday');

  const queryClient = useQueryClient();
  const { initialized, hasData, items } = useJaws(
    buildSpec({
      itemSector: [itemSectorProp].flat().join(),
    }),
  );

  useEffect(() => {
    const updatePlotlines = ({ high, low }: Record<'high' | 'low', number>) => {
      const yAxis = chartInstance?.yAxis[0];
      const options = produce(yAxis?.options, (draft) => {
        const newHigh = draft?.plotLines?.[1];
        const newLow = draft?.plotLines?.[2];
        if (newHigh && 'value' in newHigh) newHigh.value = high;
        if (newLow && 'value' in newLow) newLow.value = low;
      });
      if (options) {
        yAxis?.update(options);
      }
    };

    const updateSeries = (itemSector: string) => {
      const series = chartInstance?.series?.find((seriesEntry) => {
        return (seriesEntry.userOptions as any).meta.itemSector === itemSector;
      });
      const isMainSeries = (series?.userOptions as any)?.meta?.type === 'main';

      if (series && 'points' in series) {
        const data: Record<string, any> =
          (Array.isArray(itemSectorProp)
            ? items
                .find((item: any) => item.get('ITEM_SECTOR') === itemSector)
                ?.toJS()
            : items.first()?.toJS()) || {};

        const {
          TIME,
          LASTNZ_CA,
          LASTNZ,
          LAST = LASTNZ || LASTNZ_CA,
          HIGH,
          LOW,
        } = data;

        const hasTimeAndLast = !!TIME && !!LAST;
        const hasHighAndLow = !!HIGH && !!LOW;
        const currentLength = series.data.length;
        const lastPoint = series.data[currentLength - 1];

        if (currentLength > POINTS[dateRangeSelection] * maxPointsFactor) {
          queryClient.invalidateQueries(
            getSeriesQueryKey({ itemSector, dateRangeSelection, lineStyle }),
          );
        } else if (hasTimeAndLast && TIME > lastPoint?.x) {
          series.addPoint([TIME, LAST], true, true);
          if (isMainSeries && hasHighAndLow) {
            updatePlotlines({ high: HIGH, low: LOW });
          }
        }
      }
    };

    if (enabled && initialized && hasData) {
      [itemSectorProp].flat().forEach((entry) => {
        updateSeries(entry);
      });
    }
  }, [
    initialized,
    hasData,
    items,
    enabled,
    chartInstance,
    itemSectorProp,
    dateRangeSelection,
    lineStyle,
    maxPointsFactor,
    queryClient,
  ]);

  return null;
});
