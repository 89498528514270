import React from 'react';
import { Trans } from '@lingui/macro';
import { formatNumber, formatInteger } from '@oms/utils';

import * as S from './styles';

type AuctionRowProps = {
  price: number;
  volume: number;
};

export const AuctionRow = ({ price, volume }: AuctionRowProps) => (
  <tr className="auction" data-testid="auction">
    <td colSpan={8}>
      <S.Box>
        <div>
          <b>
            <Trans>Auction</Trans>
          </b>
        </div>
        <div>
          <b>
            <Trans>Indicative price</Trans>:
          </b>{' '}
          {formatNumber(price)}
        </div>
        <div>
          <b>
            <Trans>Indicative volume</Trans>:
          </b>{' '}
          {formatInteger(volume)}
        </div>
      </S.Box>
    </td>
  </tr>
);
