import getColor from 'color';

const LABEL_VISIBILITY_THRESHOLD = 20;
const LABEL_NAME_FONT_SIZE_THRESHOLD = 14;

function shouldRotate(d) {
  return (d.y1 - d.y0) / (d.x1 - d.x0) > 1.5;
}

function calculateFontSize(d) {
  return Math.max(
    Math.min(30, (d.y1 - d.y0) / 10),
    LABEL_NAME_FONT_SIZE_THRESHOLD,
  );
}

export function findColor(change, colors) {
  if (!colors) {
    throw new Error('Colors are required');
  }

  const { pos, neg, flat, blend, blendThreshold } = colors;

  let color;
  if (change == null || change === 0) {
    color = getColor(flat);
  } else if (change <= -blendThreshold) {
    color = getColor(neg);
  } else if (change >= blendThreshold) {
    color = getColor(pos);
  } else if (change > 0) {
    color = getColor(pos).mix(getColor(blend), blendThreshold - change);
  } else {
    color = getColor(neg).mix(getColor(blend), blendThreshold - -change);
  }

  return color;
}

export function orientation(element) {
  element
    .style('transform', (d) => (shouldRotate(d) ? 'rotate(-90deg)' : 'none'))
    .style('width', (d) => `${shouldRotate(d) ? d.y1 - d.y0 : d.x1 - d.x0}px`)
    .style('height', (d) => `${shouldRotate(d) ? d.x1 - d.x0 : d.y1 - d.y0}px`)
    .style(
      'margin-left',
      (d) => `${shouldRotate(d) ? (d.x1 - d.x0 - (d.y1 - d.y0)) / 2 : 0}px`,
    )
    .style(
      'margin-top',
      (d) => `${shouldRotate(d) ? (d.y1 - d.y0 - (d.x1 - d.x0)) / 2 : 0}px`,
    );
}

export function position(element) {
  element
    .style('left', (d) => `${d.x0}px`)
    .style('top', (d) => `${d.y0}px`)
    .style('width', (d) => `${Math.max(0, d.x1 - d.x0)}px`)
    .style('height', (d) => `${Math.max(0, d.y1 - d.y0)}px`);
}

export function resizeName(element) {
  element
    .style('font-size', (d) => `${calculateFontSize(d)}px`)
    .style('line-height', (d) => `${calculateFontSize(d)}px`)
    .style('visibility', (d) =>
      d.x1 < LABEL_VISIBILITY_THRESHOLD && d.y1 < LABEL_VISIBILITY_THRESHOLD
        ? 'hidden'
        : 'visible',
    );
}

export function resizeChange(element) {
  element
    .style('font-size', (d) => `${calculateFontSize(d)}px`)
    .style('line-height', (d) => `${calculateFontSize(d)}px`)
    .style('visibility', (d) =>
      d.x1 < LABEL_VISIBILITY_THRESHOLD && d.y1 < LABEL_VISIBILITY_THRESHOLD
        ? 'hidden'
        : 'visible',
    );
}
