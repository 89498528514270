// eslint-disable-next-line import/prefer-default-export
export const getSource = (sector = '') => {
  switch (sector) {
    case 'ose':
      return `feed.${sector}.trades.EQUITIES+PCC+ETPS+RIGHTS`;
    case 'oax':
      return `feed.${sector}.trades.EQUITIES+RIGHTS`;
    case 'merk':
      return `feed.${sector}.trades.EQUITIES+PCC+RIGHTS`;
    default:
      return `feed.${sector}.trades.EQUITIES`;
  }
};
