import Highcharts from 'highcharts/highstock';
import { UseQueryOptions, QueryKey } from 'react-query';
import { ContextTypes } from '@oms/components-config-context';
import moment from 'moment';
import template from 'url-template';
import {
  DateRangeSelection,
  StockChartControllerState,
} from '../StockChartController';
import {
  SELECTION_INTRADAY,
  SELECTION_WEEK,
  MOMENT_PERIODS,
} from '../constants';

export const getRequestInit = (init: RequestInit) => {
  const env = process.env as NodeJS.ProcessEnv & {
    NODE_ENV: string;
    STORYBOOK: string;
  };
  const isDev = env?.NODE_ENV === 'development';
  const isStorybook = env?.STORYBOOK === 'true';
  if (isDev && isStorybook) {
    const overrideInit: RequestInit = {
      ...init,
      credentials: 'omit',
    };
    return overrideInit;
  }
  return init;
};

export const doubleEncode = (data: string) =>
  encodeURIComponent(encodeURIComponent(data));

export function buildURL(templateString: string, params: object) {
  const pattern = template.parse(templateString);

  return pattern.expand(params);
}

export function getSpaceFromSelection(selection = SELECTION_WEEK) {
  switch (selection) {
    case SELECTION_INTRADAY:
    case SELECTION_WEEK:
      return 'tick';
    default:
      return 'day';
  }
}

export function getSeriesFromSelection(selection = SELECTION_WEEK) {
  switch (selection) {
    case SELECTION_INTRADAY:
    case SELECTION_WEEK:
      return 's1';
    default:
      return '(CLOSE_CA)';
  }
}

function hasSeries(obj: object) {
  return 'rows' in obj;
}

export type Break = { from: number; to: number };

type Series = {
  series: {
    key: string;
    type: string;
    data: [number, number][];
    columns: any;
  }[];
  buckets: Bucket[];
};

type RawBucket = {
  selection: {
    start: number;
    stop: number;
  };
  sector: {
    timezone: {
      shortName: string;
      UTCoffsetMinutes: number;
    };
    close: number;
    open: number;
  };
  _links: {
    self: {
      href: string;
    };
  };
};

type Bucket = {
  close?: string;
  open?: string;
  stop?: string;
  start?: string;
  timezone: string;
  UTCOffsetMinutes: number;
  link: string;
  isFetching: boolean;
};

export async function getSeries(obj: any, type = 'line'): Promise<Series> {
  if (hasSeries(obj)) {
    const { series } = obj.rows[0].values;

    const parsedSeries = Object.entries(series).map(
      ([key, entry]: [string, any]) => ({
        key: key as string,
        type,
        data: entry.data,
        columns: entry.columns,
      }),
    );

    return { series: parsedSeries, buckets: [] };
  }

  const { graphdata } = obj._embedded;

  const buckets: Bucket[] = graphdata.map((bucket: RawBucket) => {
    const { selection, sector } = bucket;
    const { stop, start } = selection;
    const { timezone, close, open } = sector;
    return {
      close,
      open,
      stop,
      start,
      timezone: timezone.shortName,
      UTCOffsetMinutes: timezone.UTCoffsetMinutes,
      link: bucket._links.self.href,
      isFetching: true,
    };
  });

  return await getWeekGraphData(buckets);
}

export async function getSingleDay(bucket: Bucket) {
  const result = await fetch(
    bucket.link,
    getRequestInit({ credentials: 'include' }),
  );

  if (!result.ok) return null;

  return result.json();
}

export async function getWeekGraphData(buckets: Bucket[]): Promise<Series> {
  const result = await Promise.all(buckets.map(getSingleDay));
  const graphData: any = [];

  await Promise.all(
    result?.map(async (day) => {
      const { series } = await getSeries(day);
      graphData.push(series);
    }),
  );

  const allData: any = [];
  graphData.map((item: any) => allData.push(...item[0].data));

  const finalArray = [];
  finalArray.push({
    columns: ['TIME', 'LAST'],
    data: allData,
    type: 'line',
    key: 's1',
  });
  return { series: finalArray, buckets };
}

export async function getWeekGraphData2(buckets: Bucket[]): Promise<any> {
  const result = await Promise.all(buckets.map(getSingleDay));

  const data = await Promise.all(
    result?.map(async (day) => {
      const { series } = await getSeries(day);
      return series[0].data;
    }),
  );

  const series = {
    key: 's1',
    type: 'line',
    data,
    columns: ['TIME', 'LAST'],
  };
  return { series, buckets };
}

export function getBreaks(incomingBuckets: Bucket[]): Break[] {
  if (!Array.isArray(incomingBuckets) || incomingBuckets.length < 2) return [];

  const result = incomingBuckets.map((value, key, buckets) => {
    const next = buckets[key + 1];
    if (!next) return null;

    return {
      from: moment(value.close).valueOf(),
      to: moment(next.open).valueOf(),
    };
  });

  return result.filter((item) => item) as Break[];
}

type JawsHelperProps = {
  initiatorComponent: string;
  urlTemplate: string;
  baseUrl: string;
  [key: string]: any;
};

/**
 * @deprecated Use fetchJaws instead.
 */
export const jawsHelper = async ({
  urlTemplate,
  baseUrl,
  ...spec
}: JawsHelperProps) => {
  if (!baseUrl) {
    throw new Error(`jawsHelper: baseUrl is required`);
  }
  if (!urlTemplate) {
    throw new Error(`jawsHelper: urlTemplate is required`);
  }

  const pattern = template.parse(urlTemplate);
  const url = pattern.expand({ baseUrl, spec });
  const result = await fetch(url, getRequestInit({ credentials: 'include' }));
  const json = await result.json();

  if (!result.ok) throw json;
  return json;
};

type FetchJawsOptions = {
  initiatorComponent: string;
  urlTemplate: string;
  baseUrl: string;
} & Record<string, any>;
export async function fetchJaws({
  urlTemplate,
  baseUrl,
  ...spec
}: FetchJawsOptions) {
  const pattern = template.parse(urlTemplate);
  const url = pattern.expand({ baseUrl, spec });
  const result = await fetch(url, getRequestInit({ credentials: 'include' }));
  if (!result.ok) {
    const errorText = await result.text();
    throw new FetchError({
      message: `Failed to fetch instrument data for ${spec.itemSector}. ${errorText}`,
      method: 'fetchJaws',
      response: result,
    });
  }
  const json = await result.json();
  return json;
}

/**
 * An error that is thrown in in the context of data fetching for the component
 */
export class FetchError extends Error {
  componentName: string;
  method: string;
  response: any;

  constructor({
    message,
    method,
    response,
  }: {
    message: string;
    method: string;
    response?: any;
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent
    // constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only
    // available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.componentName = 'StockChartQuery';
    this.message = message;
    this.method = method;
    this.response = response;
  }
}

type Key = string | Record<string, any>;

export const createQueryKey = (...keys: Key[]) => [
  '@oms',
  'stockChartQuery',
  ...keys,
];

export const getSeriesQueryKey = (options: {
  itemSector: string | string[];
  dateRangeSelection: DateRangeSelection;
  lineStyle: string;
}) => createQueryKey('graphData', options);

export const getStartValue = (dateRangeSelection: DateRangeSelection) => {
  return moment()
    .subtract(MOMENT_PERIODS[dateRangeSelection])
    .startOf('day')
    .valueOf();
};

export const getStopValue = (dateRangeSelection: DateRangeSelection) => {
  if (dateRangeSelection === 'SELECTION_INTRADAY') return 'now';
  return moment()
    .subtract({ days: 1 })
    .endOf('day')
    .valueOf();
};

export const getMilliseconds = ({ sec = 0, min = 0, hour = 0, day = 0 }) => {
  //  milli sec * sec * min * hour
  const _sec = 1000 * sec;
  const _min = 1000 * 60 * min;
  const _hour = 1000 * 60 * 60 * hour;
  const _day = 1000 * 60 * 60 * 24 * day;
  return _sec + _min + _hour + _day;
};

export const getStaleTime = (dateRangeSelection: DateRangeSelection) => {
  if (dateRangeSelection.toLocaleLowerCase().includes('intraday')) {
    return getMilliseconds({ hour: 1 });
  }
  return getMilliseconds({ day: 1 });
};

interface State extends Omit<ContextTypes, 'ErrorBoundary' | 'Suggest'> {
  controller: StockChartControllerState;
  mainSeriesId: string;
}

type BuilderFunction = (
  args: State,
) => UseQueryOptions<unknown, unknown, unknown, QueryKey>;

export const queryOptionsBuilder = (fn: BuilderFunction) => (state: State) => {
  return fn(state);
};

export type QuerySeriesFetcherReturn = Highcharts.SeriesOptionsType & {
  meta?:
    | {
        type: 'main';
        itemSector: string;
      }
    | {
        type: 'indicator';
        interval: number;
        indicatorType: string;
      }
    | {
        type: 'comparison';
        itemSector: string;
      }
    | {
        type: 'addition';
        addition: string;
      };
};
