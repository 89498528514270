import React from 'react';
import { useJaws } from '@oms/jaws-react';
import { Table, Td, Th } from '@oms/ui-table';
import { Trans, t } from '@lingui/macro';
import { NoData, Loading } from '@oms/components-core';
import {
  generateSpec,
  UseDividendsArguments,
  DividendsProps,
  sortByTimeDsc,
} from './utils';

type DividendRow = {
  EVENT_DATE?: string;
  TIME?: number;
  ITEM?: string;
  DIVIDEND?: number;
  CURRENCY?: string;
  DIVIDEND_IN_CURRENCY?: number;
};

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `Dividends` component.
 */
export const useDividends = ({
  spec,
  options,
  itemSector,
}: UseDividendsArguments = {}) => {
  const { items, ...values } = useJaws(
    { ...generateSpec(itemSector), ...spec },
    options,
  );

  const data = items.valueSeq().toJS() as Array<DividendRow>;

  data.sort((a, b) => sortByTimeDsc(a, b));

  return {
    ...values,
    data,
  };
};

/**
 * **CAUTION: Must not be used on pages available without login**
 *
 * A.k.a. "Utbytter".
 *
 * A component which lists the recent dividends per itemSector or
 * per accross the entire stock exchange
 * @since 2.0.0
 */
export const Dividends = ({
  className = 'Dividends',
  spec,
  disableLoading = false,
  itemSector,
}: DividendsProps) => {
  const { resource, data, initialized, emptyData } = useDividends({
    spec,
    itemSector,
  });

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No dividend found`} />;
  }

  return (
    <Table className={className} width="100%" layout="auto">
      <thead>
        <tr>
          <Th type="dateTime">
            <Trans>Date</Trans>
          </Th>
          {!itemSector && (
            <Th type="text">
              <Trans>Ticker</Trans>
            </Th>
          )}
          <Th type="number">
            <Trans>Dividend</Trans>
          </Th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={`${item.ITEM}-${item.TIME}`}>
            <Td type="dateTime" formatString="DD.MM.YYYY">
              {item?.EVENT_DATE}
            </Td>
            {!itemSector && <Td>{item?.ITEM}</Td>}
            <Td type="number" data-testid={`row-${index}`} prefix="NOK">
              {item?.DIVIDEND ?? 0}
            </Td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
