/*
 * Holds generic validation functions. See utils/buyForm, utils/sellForm
 * for validation specific to a trade type
 */

export type LimitRule = {
  accuracy: number;
  lowLimit: number;
  highLimit: number;
  lowInclusive: boolean;
  highInclusive: boolean;
  highInfinity: boolean;
};

export type LotSizeRule = {
  lotSize: number;
};

/**
 * Validates if limit is divisible by the relevant
 * LOT_SIZE and TICK_SIZE of the current combination
 * of instrument and limit.
 *
 * @param {number} limit - The limit to be validated
 * @param {array} limitRules - A list of limit rules to validate against.
 * @return {boolean} True if valid, false when invalid
 */
export function isValidTickSize(
  limit: number,
  limitRules: LimitRule[],
): boolean {
  if (!limit || !limitRules) {
    return false;
  }

  // Find the applicable rule for this limit
  var limitRule = getLimitRule(limit, limitRules);

  if (!limitRule) return false;

  var tickSizeValid =
    limitRule.accuracy < 1
      ? floatSafeRemainder(limit, limitRule.accuracy) === 0
      : limit % limitRule.accuracy === 0;

  return tickSizeValid;
}

/**
 * Get the remainder of the input `val in step`, accounting for javacsript
 * floating point arithmetic rounding errors.
 */
function floatSafeRemainder(val: number, step: number) {
  var valDecCount = (val.toString().split('.')[1] || '').length;
  var stepDecCount = (step.toString().split('.')[1] || '').length;
  var decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
  var valInt = parseInt(val.toFixed(decCount).replace('.', ''));
  var stepInt = parseInt(step.toFixed(decCount).replace('.', ''));

  return (valInt % stepInt) / Math.pow(10, decCount);
}

/**
 * Validates if volume is divisible by the relevant
 * LOT_SIZE and TICK_SIZE of the current combination
 * of instrument and limit.
 */
export function isValidLotSize(volume: number, lotSizeRule: LotSizeRule) {
  if (!volume || !lotSizeRule) {
    return false;
  }

  const lotSizeValid = volume % lotSizeRule.lotSize === 0;

  return lotSizeValid;
}

/**
 * Fetch the tick size validation rules for a given
 * limit in a given ruleset
 */
export function getLimitRule(limit: number, limitRules: LimitRule[]) {
  if (!limitRules) {
    return null;
  }

  return (
    limitRules.find(rule => {
      const {
        lowLimit,
        highLimit,
        lowInclusive,
        highInclusive,
        highInfinity,
      } = rule;

      if (lowLimit < limit && highLimit > limit) {
        return true;
      } else if (lowLimit === limit && lowInclusive) {
        return true;
      } else if (highLimit === limit && highInclusive) {
        return true;
      } else if (limit > lowLimit && highInfinity) {
        return true;
      }
      return false;
    }) || null
  );
}

/**
 * Simply print "Påkrevet" if the item is not present.
 *
 * @param {any} value - The value to test.
 * @return {string} "Påkrevet" if the value is not defined. Undefined otherwise.
 */
export function validatePresent(value: any) {
  return !value ? 'Påkrevet' : undefined;
}
