import React from 'react';
import { Trans } from '@lingui/macro';
import {
  useMenuState,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItemRadio,
  MenuItemHTMLProps,
} from '@oms/ui-menu-bar';
import { Icon, light } from '@oms/ui-icon';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { useStockChartController } from '../StockChartController';
import { useStockChartContainerSize } from '../StockChartContainer';
interface LineStyleProps extends MenuItemHTMLProps {}

export const LineStyle = React.forwardRef(
  (props: LineStyleProps, ref: React.Ref<any>) => {
    const menu = useMenuState();
    const controller = useStockChartController();
    const { containerWidth } = useStockChartContainerSize();

    return (
      <>
        <MenuButton ref={ref} {...menu} {...props}>
          <Icon icon={light.faChartLine} color="inherit" />{' '}
          {containerWidth === 'sm' ? (
            <VisuallyHidden>
              <Trans>Line Style</Trans>
            </VisuallyHidden>
          ) : (
            <Trans>Line Style</Trans>
          )}
        </MenuButton>
        <Menu {...menu} aria-label="LineStyle">
          <MenuGroup {...menu}>
            <MenuItemRadio
              {...menu}
              name="lineStyle"
              value="line"
              checked={controller?.isChecked('lineStyle', 'line')}
              onClick={() => {
                controller?.setLineStyle('line');
              }}
            >
              Line
            </MenuItemRadio>
            <MenuItemRadio
              {...menu}
              name="lineStyle"
              value="bar"
              checked={controller?.isChecked('lineStyle', 'step')}
              onClick={() => {
                controller?.setLineStyle('step');
              }}
            >
              Step
            </MenuItemRadio>
            <MenuItemRadio
              {...menu}
              name="lineStyle"
              value="area"
              checked={controller?.isChecked('lineStyle', 'area')}
              onClick={() => {
                controller?.setLineStyle('area');
              }}
            >
              Area
            </MenuItemRadio>
            <MenuItemRadio
              {...menu}
              name="lineStyle"
              value="candlestick"
              checked={controller?.isChecked('lineStyle', 'candlestick')}
              onClick={() => {
                controller?.setLineStyle('candlestick');
                // TODO
                // ==========================
                // Candlestick doesn't work on intraday periods, hence we need to change the current
                // selected date range.
                if (
                  controller.state.dateRangeSelection
                    .toLowerCase()
                    .includes('intraday')
                ) {
                  controller.setDateRangeSelection('SELECTION_ONE_MONTH');
                }
              }}
            >
              Candlestick
            </MenuItemRadio>
          </MenuGroup>
        </Menu>
      </>
    );
  },
);
