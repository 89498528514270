import { useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import { useJaws } from '@oms/jaws-react';

import { Spec } from '@oms/jaws-react';

const MAX_POINTS_FACTOR = 1.5;

const columns = [
  'CHANGE',
  'CHANGE_PCT',
  'CLOSE',
  'CLOSENZ',
  'CLOSENZ_CA',
  'ITEM',
  'LAST',
  'LASTNZ',
  'LASTNZ_CA',
  'OPEN',
  'HIGH',
  'LOW',
  'TIME',
  'VOLUME',
  'TURNOVER',
  'ITEM_SECTOR',
];

export function buildSpec({ itemSector }: Spec): Spec {
  return {
    initiatorComponent: 'StockChartQuery',
    itemSector,
    columns: columns.join(),
  };
}

type JawsQueryProps = {
  chartInstance?: Highcharts.Chart | undefined;
  enabled?: boolean;
  itemSector: string | string[];
  maxPointsFactor?: number;
  refetch: () => void;
};
export function JawsQuery({
  chartInstance,
  enabled: enabledOption = true,
  itemSector,
  refetch,
}: JawsQueryProps) {
  const enabled = chartInstance && enabledOption;

  const { initialized, hasData, items } = useJaws(
    buildSpec({
      itemSector: [itemSector].flat().join(),
    }),
  );

  useEffect(() => {
    const updateSeries = (itemSector: string) => {
      const series = chartInstance?.series?.[0];

      if (series && 'points' in series) {
        const {
          TIME,
          LASTNZ_CA,
          LASTNZ,
          LAST = LASTNZ || LASTNZ_CA,
        } = Array.isArray(itemSector)
          ? items
              .find((item: any) => item.get('ITEM_SECTOR') === itemSector)
              .toJS()
          : items.first().toJS();

        const currentLength = series.data.length;
        const lastPoint = series.data[currentLength - 1];
        /*
        check series length
        */

        if (TIME > lastPoint?.x) {
          series.addPoint([TIME, LAST]);
          // const points = [];
          // points.push(series.points[series.points.length - 1]);
          chartInstance?.tooltip.refresh([
            series.points[series.points.length - 1],
          ]);
        }
      }
    };

    if (enabled && initialized && hasData) {
      [itemSector].flat().forEach((_itemSector) => {
        updateSeries(_itemSector);
      });
    }
  }, [initialized, hasData, items, enabled, chartInstance, itemSector]);
  return null;
}
