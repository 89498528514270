import React from 'react';
import { Trans, t } from '@lingui/macro';
import { MessageDescriptor } from '@lingui/core';
import { IndicatorType } from './StockChartController';

export const EXTRA_AXIS_INDICATOR_TYPES = [
  'rsi',
  'momentum',
  'roc',
  'stochastic',
];

export const SELECTION_INTRADAY_ONE_HOUR = 'SELECTION_INTRADAY_ONE_HOUR';
export const SELECTION_INTRADAY_TREE_HOURS = 'SELECTION_INTRADAY_TREE_HOURS';
export const SELECTION_INTRADAY = 'SELECTION_INTRADAY';
export const SELECTION_WEEK = 'SELECTION_WEEK';
export const SELECTION_HISTORIC = 'SELECTION_HISTORIC';
export const SELECTION_ONE_MONTH = 'SELECTION_ONE_MONTH';
export const SELECTION_THREE_MONTHS = 'SELECTION_THREE_MONTHS';
export const SELECTION_SIX_MONTHS = 'SELECTION_SIX_MONTHS';
export const SELECTION_ONE_YEAR = 'SELECTION_ONE_YEAR';
export const SELECTION_THREE_YEARS = 'SELECTION_THREE_YEARS';
export const SELECTION_FIVE_YEARS = 'SELECTION_FIVE_YEARS';

export const SELECTIONS = [
  SELECTION_INTRADAY_ONE_HOUR,
  SELECTION_INTRADAY_TREE_HOURS,
  SELECTION_INTRADAY,
  SELECTION_WEEK,
  SELECTION_HISTORIC,
  SELECTION_ONE_MONTH,
  SELECTION_THREE_MONTHS,
  SELECTION_SIX_MONTHS,
  SELECTION_ONE_YEAR,
  SELECTION_THREE_YEARS,
  SELECTION_FIVE_YEARS,
] as const;
export type SELECTION = typeof SELECTIONS[number];

export const PERIODS = {
  [SELECTION_INTRADAY_ONE_HOUR]: '1days',
  [SELECTION_INTRADAY_TREE_HOURS]: '1days',
  [SELECTION_INTRADAY]: '1days',
  [SELECTION_WEEK]: '5days',
  [SELECTION_HISTORIC]: '5years',
  [SELECTION_ONE_MONTH]: '1months',
  [SELECTION_THREE_MONTHS]: '3months',
  [SELECTION_SIX_MONTHS]: '6months',
  [SELECTION_ONE_YEAR]: '1years',
  [SELECTION_THREE_YEARS]: '3years',
  [SELECTION_FIVE_YEARS]: '5years',
} as const;

export const SPACES = {
  [SELECTION_INTRADAY_ONE_HOUR]: 'TICK',
  [SELECTION_INTRADAY_TREE_HOURS]: 'TICK',
  [SELECTION_INTRADAY]: 'TICK',
  [SELECTION_WEEK]: 'TICK',
  [SELECTION_HISTORIC]: 'DAY',
  [SELECTION_ONE_MONTH]: 'DAY',
  [SELECTION_THREE_MONTHS]: 'DAY',
  [SELECTION_SIX_MONTHS]: 'DAY',
  [SELECTION_ONE_YEAR]: 'DAY',
  [SELECTION_THREE_YEARS]: 'DAY',
  [SELECTION_FIVE_YEARS]: 'DAY',
} as const;

export const POINTS = {
  [SELECTION_INTRADAY_ONE_HOUR]: 1000,
  [SELECTION_INTRADAY_TREE_HOURS]: 1000,
  [SELECTION_INTRADAY]: 1000,
  [SELECTION_WEEK]: 200,
  [SELECTION_HISTORIC]: 1000,
  [SELECTION_ONE_MONTH]: 1000,
  [SELECTION_THREE_MONTHS]: 1000,
  [SELECTION_SIX_MONTHS]: 1000,
  [SELECTION_ONE_YEAR]: 1000,
  [SELECTION_THREE_YEARS]: 1000,
  [SELECTION_FIVE_YEARS]: 1000,
} as const;

export const SUFFIX = {
  '5days': '_7DAYS',
  '1months': '_1MONTH',
  '3months': '_3MONTHS',
  '6months': '_6MONTHS',
  '1years': '_1YEAR',
  '3years': '_3YEARS',
  '5years': '_5YEARS',
} as const;

export const MOMENT_PERIODS = {
  [SELECTION_INTRADAY_ONE_HOUR]: { months: 0 },
  [SELECTION_INTRADAY_TREE_HOURS]: { months: 0 },
  [SELECTION_INTRADAY]: { months: 0 },
  [SELECTION_WEEK]: { months: 0 },
  [SELECTION_HISTORIC]: { months: 0 },
  [SELECTION_ONE_MONTH]: { months: 1 },
  [SELECTION_THREE_MONTHS]: { months: 3 },
  [SELECTION_SIX_MONTHS]: { months: 6 },
  [SELECTION_ONE_YEAR]: { years: 1 },
  [SELECTION_THREE_YEARS]: { years: 3 },
  [SELECTION_FIVE_YEARS]: { years: 5 },
} as const;

export const BAR_SERIES = {
  turnover: 'TURNOVER',
  volume: 'VOLUME',
} as const;

export const RECOMMENDATION_TYPES = {
  1: 'Sell',
  2: 'Reduce',
  4: 'Accumulate',
  5: 'Buy',
} as const;

export const TYPE_BUY = 1;
export const TYPE_SELL = 0;

export const LINE_STYLE_LINE = 'line';
export const LINE_STYLE_AREA = 'area';
export const LINE_STYLE_CANDLE_STICK = 'candlestick';
export const LINE_STYLE_OHLC = 'ohlc';

export const LINE_STYLES = [
  LINE_STYLE_LINE,
  LINE_STYLE_AREA,
  LINE_STYLE_CANDLE_STICK,
  LINE_STYLE_OHLC,
] as const;
export type LINE_STYLE = typeof LINE_STYLES[number];

export const MAIN_SERIES_ID = 'main-series';

export const LOCAL_COLORS = [
  '#EA580C',
  '#22C55E',
  '#0EA5E9',
  '#EAB308',
  '#14B8A6',
  '#9333EA',
  '#DB2777',
  '#6366F1',
  '#059669',
  '#2563EB',
  '#65A30D',
  '#D97706',
  '#0891B2',
  '#C026D3',
  '#7C3AED',
];

export const INDICATOR_LABELS: Record<
  IndicatorType | 'rsi' | 'momentum' | 'roc',
  JSX.Element
> = {
  sma: <Trans>Simple moving average (SMA)</Trans>,
  ema: <Trans>Exponential moving average (EMA)</Trans>,
  wma: <Trans>Weighted moving average (EMA)</Trans>,
  momentum: <Trans>Momentum</Trans>,
  rsi: <Trans>Relative strenght index (RSI)</Trans>,
  roc: <Trans>Rate of change (ROC)</Trans>,
  priceenvelopes: <Trans>Envelope</Trans>,
  bb: <Trans>Bolinger bands</Trans>,
  stochastic: <Trans>Stochastic oscillator (%K,%D)</Trans>,
};

export const INDICATOR_LABELS_T: Record<
  IndicatorType | 'rsi' | 'momentum' | 'roc',
  MessageDescriptor
> = {
  sma: t`Simple moving average (SMA)`,
  ema: t`Exponential moving average (EMA)`,
  wma: t`Weighted moving average (EMA)`,
  momentum: t`Momentum`,
  rsi: t`Relative strenght index (RSI)`,
  roc: t`Rate of change (ROC)`,
  priceenvelopes: t`Envelope`,
  bb: t`Bolinger bands`,
  stochastic: t`Stochastic oscillator (%K,%D)`,
};
