import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const Row = css`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;

  & > td,
  & > th {
    display: inline-block;
    padding: 0;
    word-wrap: break-word;

    & > div,
    & > span {
      display: inline-block;
      max-width: calc(100% - 20px);
      padding: 2px 10px;
    }

    &.sr-only {
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
    }
  }

  & > th {
    flex-wrap: wrap;
  }

  &.interactive {
    cursor: pointer;
  }
`;

export const Cell = styled.td`
  ${({ expanded }: { expanded: boolean }) =>
    expanded &&
    css`
      display: block;
      position: relative;

      /* Subtract padding left and right */
      min-width: calc(100% - 16px);
      padding: 8px;
      text-align: right;
      background-color: #f8fcfd;

      > div {
        padding: 0;
      }

      ::before {
        content: attr(data-header);
        position: absolute;
        left: 20px;
        padding-right: 1em;
        font-weight: bold;
        white-space: nowrap;
      }
    `}
`;
