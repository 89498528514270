import React from 'react';
import { Spec } from '@oms/jaws-react';
import { Loading, NoData } from '@oms/components-core';
import { t } from '@lingui/macro';
import { useIndexMap, UseIndexMapReturn } from './useIndexMap';
import { ItemSectors } from './IndexMap';

interface IndexMapFetcherProps {
  /** A spec to override the default values of the component. */
  spec?: Spec & {
    /**
     * Which columns to subscribe to.
     * _Default: `ITEM, SECTOR, ITEM_SECTOR, LONG_NAME, LAST, LASTNZ, CHANGE_PCT, CLOSE, sector_open_time, sector_close_time`_
     */
    columns?: string;
  };
  /** The instruments to fetch data for */
  itemSectors?: ItemSectors;
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /**
   * A render function that exposes the results as well as metadata on the
   * query.
   *
   * @see See [the @oms/jaws-react documentation](https://www.npmjs.com/package/@oms/jaws-react)
   * for the most up-to-date information on what is exposed here.
   * @param {Object} renderProps - The object containing the returned values
   * @param {Object} renderProps.data - The subscribed dataset
   * @param {Number} renderProps.totalSize - The total size of the response as provided by jaws
   * @param {boolean} renderProps.hasData - Has the component completed fetching and has non-empty data?
   * @param {boolean} renderProps.emptyData - Has the component completed fetching and has empty data?
   * @param {boolean} renderProps.jawsFetching - Is the component currently fetching data?
   * @param {boolean} renderProps.initialized - Has the component received `initial_data_sent`?
   * @param {Jaws} renderProps.jaws - A reference to the Jaws instance (see `@oms/jaws`)
   * @param {Promise} renderProps.resource - An object with a promise that resolves when initialized
   */
  children: (jawsProps: UseIndexMapReturn) => any;
}

/**
 * **This component is part of the map package and requires the corresponding license**
 *
 * A component which fetches as set of indices with a snapshot of their
 * performance. This is a purely data-fetching component.
 *
 * @see Is a part of the [IndexMap](/#!/IndexMap) component.
 * @since 1.1.0
 */
export const IndexMapFetcher = ({
  spec,
  itemSectors,
  disableLoading = false,
  children,
}: IndexMapFetcherProps) => {
  const jawsProps = useIndexMap({ spec, itemSectors });

  const { resource, initialized, emptyData } = jawsProps;

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No indices found`} />;
  }

  return children(jawsProps);
};
