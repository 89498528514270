import React from 'react';
import { t } from '@lingui/macro';
import { NoData } from '@oms/components-core';
import useOrderDetails, { UseOrderDetailsArguments } from './useOrderDetails';

/**
 * An error that is thrown in in the context of data fetching for the component
 */
export class FetchError extends Error {
  componentName: string;
  method: string;
  response: any;

  constructor({
    message,
    method,
    response,
  }: {
    message: string;
    method: string;
    response?: any;
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent
    // constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only
    // available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.componentName = 'OrderFetcher';
    this.message = message;
    this.method = method;
    this.response = response;
  }
}

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * This is a Wrapper component that fetches details on a specified order.
 * This allows implementors to build their own UI using the same dataset as
 * `OrderDetails` uses.
 *
 * This component uses `useOrderDetails` internally and passes all props along to that.
 *
 * This component uses the function-as-child pattern, see the children prop.
 *
 */
export const OrderFetcher: React.FC<UseOrderDetailsArguments> = ({
  children,
  ...props
}) => {
  const { data, loading, error } = useOrderDetails(props);

  if (error) throw error;

  if (!data && !loading) return <NoData text={t`No order details found`} />;

  if (children && typeof children === 'function') {
    return children({ data, loading });
  }

  console.error(
    'OrderFetcher: This component uses the function-as-child pattern, children was:',
    typeof children,
  );
  return children;
};

export default OrderFetcher;
