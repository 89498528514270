import React, { useReducer } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import { Box } from '@oms/ui-box';
import { Button } from '@oms/ui-button';
import { DateInput } from '@oms/ui-date-input';
import { RenderField } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { Select } from '@oms/ui-select';
import { Wrap } from '@oms/ui-wrap';
import { I18n } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { useSuggest } from '@oms/components-suggest';

import { DEFAULT_DATE_FORMAT } from './constants';

type BuildDefaultFiltersArguments = {
  datePickerLocale?: string;
  /** Used to set/overwrite default filters */
  initialFilters?: Partial<FilterState>;
};

export const buildDefaultFilters = ({
  datePickerLocale = moment.locale(),
  initialFilters = {},
}: BuildDefaultFiltersArguments): FilterState => ({
  orderId: undefined,
  transactionId: undefined,
  tradeType: undefined,
  fromDate: moment()
    .subtract(1, 'month')
    .toDate(),
  toDate: moment().toDate(),
  ...initialFilters,
});

type FilterAction =
  | { type: 'fromDate'; payload: Date }
  | { type: 'toDate'; payload: Date }
  | { type: 'ticker'; payload?: FilterState['ticker'] }
  | { type: 'tradeType'; payload?: string }
  | { type: 'status'; payload: string }
  | { type: 'orderId'; payload?: string }
  | { type: 'transactionId'; payload?: string }
  | { type: 'clearTicker' }
  | { type: 'reset'; payload: FilterState };

export type FilterState = {
  orderId?: string;
  transactionId?: string;
  tradeType?: string;
  fromDate?: Date;
  toDate?: Date;
  ticker?: {
    ITEM: string;
    SECTOR: string;
    ISIN: string;
    LONG_NAME: string;
    TYPE: string;
    CURRENCY: string;
    meta: {
      newsweb?: boolean;
      company_info?: boolean;
      trades?: boolean;
      graph_data_tick?: boolean;
      financial_calendar?: boolean;
      recommendations?: boolean;
      substitute_type?: string;
      brokerstats?: boolean;
      dividends?: boolean;
      orders?: boolean;
      order_log?: boolean;
      estimates?: boolean;
    };
  };
};

const reducer = (state: FilterState, action: FilterAction): FilterState => {
  switch (action.type) {
    case 'fromDate':
      return { ...state, fromDate: action.payload };
    case 'toDate':
      return { ...state, toDate: action.payload };
    case 'ticker':
      return { ...state, ticker: action.payload };
    case 'tradeType':
      if (action.payload === 'ALL') {
        return { ...state, tradeType: undefined };
      }
      return { ...state, tradeType: action.payload };
    case 'orderId':
      return { ...state, orderId: action.payload };
    case 'transactionId':
      return { ...state, transactionId: action.payload };
    case 'clearTicker':
      return { ...state, ticker: undefined };
    case 'reset':
      return action.payload;
    default:
      return state;
  }
};

interface TransactionsFiltersProps {
  /** The function that is called whenever the filters are changed */
  onFiltersChange: (filters: FilterState) => void;
  /**
   * Unicode tokens for how the dates will be rendered to the user. Default of `P` is
   * a localized short format based on the configured locale.
   * @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
   */
  dateFormat?: string;
  /**
   * The time to delay each keystroke before dispatching an `onFiltersChange`
   */
  debounceTime?: number;
  /** Will be passed to the component */
  className?: string;
  /** The options passed to the trade type `<Select>` */
  tradeTypeOptions?: { label: string; value: any }[];
  /**
   * A locale code of the language that will be used in the date picker.
   * English is used by default. Keep in mind you need to make sure moment has
   * the required locale data by importing the locale files into the project,
   * for example `import 'moment/locale/sv';`
   */
  datePickerLocale?: string;
  /** Shows clickable button to execute search, instead of it happening automagically */
  enableSearchButton?: boolean;
  /** Used to set initial filters, overwrites defaults in component */
  initialFilters?: Partial<FilterState>;
}

/* We use nesting, not IDs */
/* eslint-disable jsx-a11y/label-has-for, react/no-unused-prop-types */

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * The filters used in filtering the `TransactionsArchive` table.
 *
 * `onFiltersChange` returns a `filters` shape in the form of:
 *
 * ```js
 * {
 *   orderId: '123',
 *   transactionId: '123',
 *   tradeType: 'BUY',
 *   fromDate: '06/06/2017', // Depends on dateFormat
 *   toDate: '07/06/2017', // Depends on dateFormat
 *   ticker: {
 *     ITEM: 'STL',
 *     SECTOR: 'OSE',
 *     LONG_NAME: 'Statoil',
 *     TYPE: 'EQUITIES'
 *     meta: {
 *       newsweb: true,
 *       company_info: true,
 *       trades: true,
 *       graph_data_tick: true,
 *       financial_calendar: true,
 *       recommendations: false,
 *       substitute_type: '',
 *       brokerstats: true,
 *       dividends: true,
 *       orders: true,
 *       order_log: true,
 *       estimates: false
 *     },
 *   },
 * }
 * ```
 *
 * @see Is a part of the [TransactionsArchive](/#!/TransactionsArchive) component.
 * @since 1.0.0
 */
export const TransactionsFilters = ({
  className = 'TransactionsFilters',
  dateFormat = DEFAULT_DATE_FORMAT,
  datePickerLocale = moment.locale(),
  tradeTypeOptions,
  onFiltersChange,
  debounceTime = 150,
  enableSearchButton = false,
  initialFilters = {},
}: TransactionsFiltersProps) => {
  const Suggest = useSuggest();

  const [debouncedOnFiltersChange] = useDebouncedCallback(
    onFiltersChange,
    debounceTime,
  );

  const [filters, dispatchFilterChange] = useReducer(
    (state: FilterState, action: FilterAction) => {
      const newState = reducer(state, action);
      if (!enableSearchButton) {
        debouncedOnFiltersChange(newState);
      }
      return newState;
    },
    buildDefaultFilters({
      datePickerLocale,
      initialFilters,
    }),
  );

  return (
    <I18n>
      {({ i18n }) => {
        /**
         * Select/Search/Combobox only accept strings as labels.
         * In order to convert them to strings we need access to the i18n object, which is only available
         * inside the return statement of the component.
         * @param message
         */
        const i18nToString = (message: MessageDescriptor): string =>
          i18n ? i18n._(message) : message.id;

        const tradeTypes = {
          ALL: i18nToString(t`All`),
          BUY: i18nToString(t`Buy`),
          SELL: i18nToString(t`Sale`),
        };

        return (
          <Box
            as="form"
            display="flex"
            flexWrap={{ base: 'wrap', sm: 'nowrap' }}
            className={className}
            data-testid="TransactionsFilters"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
              e.preventDefault()
            }
          >
            <Wrap gap={4}>
              <Box flex="1 0 15rem">
                <RenderField
                  as={Suggest}
                  name="ticker"
                  label={<Trans>Company ticker</Trans>}
                  onChange={(suggestion: any) =>
                    dispatchFilterChange({
                      type: 'ticker',
                      payload: suggestion,
                    })
                  }
                  onClear={() => dispatchFilterChange({ type: 'clearTicker' })}
                  inputProps={{
                    name: 'ticker',
                  }}
                  isClearable
                />
              </Box>

              <Box flex="1 0 15rem">
                <RenderField
                  as={TextInput}
                  name="orderId"
                  label={<Trans>Order number</Trans>}
                  value={filters.orderId}
                  onChange={(e: any) =>
                    dispatchFilterChange({
                      type: 'orderId',
                      payload: e.target.value,
                    })
                  }
                />
              </Box>

              <Box flex="1 0 15rem">
                <RenderField
                  as={TextInput}
                  name="transactionId"
                  label={<Trans>Transaction number</Trans>}
                  value={filters.transactionId}
                  onChange={(e: any) =>
                    dispatchFilterChange({
                      type: 'transactionId',
                      payload: e.target.value,
                    })
                  }
                />
              </Box>

              <Box flex="1 0 15rem">
                <RenderField
                  as={Select}
                  name="tradeType"
                  label={<Trans>Type</Trans>}
                  onChange={(changes: any) =>
                    dispatchFilterChange({
                      type: 'tradeType',
                      payload: changes,
                    })
                  }
                  value={filters.tradeType || 'ALL'}
                  items={tradeTypeOptions || ['ALL', 'BUY', 'SELL']}
                  itemToString={(key: any) => {
                    return (tradeTypes as any)[key] as string;
                  }}
                  className="TypeSelect Select"
                />
              </Box>

              <Box flex="1 0 15rem">
                <RenderField
                  as={DateInput}
                  name="fromDate"
                  label={<Trans>From date</Trans>}
                  dateFormat={dateFormat}
                  value={filters.fromDate}
                  startDate={filters.fromDate}
                  endDate={filters.toDate}
                  selectsStart
                  onChange={(date: Date) =>
                    dispatchFilterChange({ type: 'fromDate', payload: date })
                  }
                />
              </Box>

              <Box flex="1 0 15rem">
                <RenderField
                  as={DateInput}
                  name="toDate"
                  label={<Trans>To date</Trans>}
                  dateFormat={dateFormat}
                  value={filters.toDate}
                  startDate={filters.fromDate}
                  endDate={filters.toDate}
                  minDate={filters.fromDate}
                  selectsEnd
                  onChange={(date: Date) =>
                    dispatchFilterChange({ type: 'toDate', payload: date })
                  }
                />
              </Box>
              {enableSearchButton && (
                <Box
                  className="SearchButtonBox"
                  display="flex"
                  flex="1 0 15rem"
                  justifyContent="flex-end"
                  mb={1}
                >
                  <Button
                    className="SearchButtonBox"
                    onClick={() => debouncedOnFiltersChange(filters)}
                  >
                    <Trans>Search</Trans>
                  </Button>
                </Box>
              )}
            </Wrap>
          </Box>
        );
      }}
    </I18n>
  );
};

export default TransactionsFilters;
