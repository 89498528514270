import { Spec, Options, useJaws } from '@oms/jaws-react';

export const defaultSpec = {
  initiatorComponent: 'Quotelist',
  source: 'feed.ose.quotes.EQUITIES_OBX',
  columns:
    'ITEM_SECTOR,ITEM,LONG_NAME,SECTOR,LAST,CHANGE,CHANGE_PCT,BID,ASK,TURNOVER,TIME',
};

interface UseQuotelistArguments {
  spec?: Spec;
  options?: Options;
}

export const useQuotelist = ({
  spec: incomingSpec,
  options,
}: UseQuotelistArguments) => {
  const spec = { ...defaultSpec, ...incomingSpec };
  const { items, ...values } = useJaws(spec, options);

  return {
    ...values,
    data: items.toJS(),
  };
};
