import ReactFauxDOM from 'react-faux-dom';
import { treemap, treemapResquarify, hierarchy } from 'd3-hierarchy';
import { select } from 'd3-selection';
import { formatNumber } from '@oms/utils';

import { orientation, position, resizeName, resizeChange } from './utils';

interface TreemapProps {
  height: number;
  width: number;
  items: any;
  onNodeClicked?: (d: any) => void;
}

type TreemapReturnType = JSX.Element | null;

//@ts-ignore
export const Treemap = ({
  height,
  width,
  items,
  onNodeClicked,
}: TreemapProps): TreemapReturnType => {
  const div = select(ReactFauxDOM.createElement('div'))
    .style('position', 'relative')
    .style('width', `${width}px`)
    .style('height', `${height}px`);

  // Create root node from hierarchy data structure
  const root = hierarchy(items)
    .eachBefore((d) => {
      // eslint-disable-next-line no-param-reassign
      d.data.id = (d.parent ? `${d.parent.data.id}.` : '') + d.data.name;
    })
    .sum((data) => data.size)
    .sort((a: any, b: any) => a.size - b.size);

  // Create d3 TreeMap constructor
  const tree = treemap()
    .tile(treemapResquarify)
    .size([width, height])
    .paddingInner(1);

  // Initialize root node with TreeMap constructor
  tree(root);

  // Add graphics nodes with data
  const nodes = div
    .datum({ children: items.data })
    .selectAll('.node')
    .data(root.leaves());

  // Enter nodes and start appending cells
  const node = nodes
    .enter()
    .append('div')
    .attr('class', 'node')
    // Position the cell in 2D space
    .call(position)
    .style('background', (d) => d.data.background)
    .style('color', (d) => d.data.color);

  // Make labelWrapper which will contain text and handle click events
  let labelWrapper;
  if (onNodeClicked) {
    labelWrapper = node
      .append('button')
      .on('click', (d: any) => onNodeClicked(d))
      .attr('class', 'labels');
  } else {
    labelWrapper = node.append('div').attr('class', 'labels');
  }

  // Add first row of text and resize it
  labelWrapper
    .append('div')
    .attr('class', 'name')
    .text((d) => d.data.name)
    .style('text-align', 'center')
    .style('font-weight', 'bold')
    // Set text size
    .call(resizeName);

  // Add second row of text and resize it
  labelWrapper
    .append('div')
    .attr('class', 'change')
    .style('text-align', 'center')
    .text((d) => `${formatNumber(d.data.change)}%`)
    // Set text size
    .call(resizeChange);

  // Position wrapper in 2D space, flipping if not enough room landscape
  labelWrapper.call(orientation);

  return div?.node()?.toReact() || null;
};

export default Treemap;
