import React, { createContext, useContext } from 'react';
import styled from '@xstyled/emotion';
import { th } from '@xstyled/system';
import { forwardRefWithAs, ReactNodeNoStrings, mixins } from '@oms/ui-utils';
import { Separator } from '@oms/ui-separator';
import { Stack, StackProps } from '@oms/ui-stack';
import * as R from 'reakit/Toolbar';

export const ToolbarContext = createContext<any>({});
ToolbarContext.displayName = 'ToolbarContext';
export const useToolbarContext = (): R.ToolbarStateReturn =>
  useContext(ToolbarContext);

/**
 * A toolbar is a container for grouping a set of controls, such as buttons, menubuttons, or checkboxes.
 *
 * When a set of controls is visually presented as a group, the toolbar role can be used to communicate the presence
 * and purpose of the grouping to screen reader users. Grouping controls into toolbars can also be an effective way of
 * reducing the number of tab stops in the keyboard interface.
 *
 * [WAI-ARIA Toolbar](https://www.w3.org/TR/wai-aria-practices-1.1/#toolbar)
 */
export function Toolbar({
  children,
  orientation = 'horizontal',
  loop = true,
  baseId,
  currentId,
  'aria-label': ariaLabel,
  ...props
}: ToolbarProps) {
  const toolbar = R.useToolbarState({ loop, baseId, currentId, orientation });

  return (
    <ToolbarContext.Provider value={toolbar}>
      <R.Toolbar orientation={orientation} aria-label={ariaLabel} {...toolbar}>
        {(toolbarProps) => (
          <Stack
            {...(toolbarProps as any)}
            gap={0}
            {...props}
            orientation={orientation}
          >
            {children}
          </Stack>
        )}
      </R.Toolbar>
    </ToolbarContext.Provider>
  );
}

const Item = styled.button`
  ${mixins.borderBox}
  ${mixins.button}
  ${mixins.textButton}
  padding: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${th('toolbar.default')}
`;

/**
 * @memberof Toolbar
 * @param props
 */
export const ToolbarItem = forwardRefWithAs<{ index?: number }, 'button'>(
  function ToolbarItem({ as = Item, index, ...props }, ref) {
    const toolbar = useToolbarContext();

    return <R.ToolbarItem ref={ref} as={as} {...props} {...toolbar} />;
  },
);

/**
 * @memberof Toolbar
 * @param props
 */
export function ToolbarSeparator() {
  const toolbar = useToolbarContext();
  return <R.ToolbarSeparator {...toolbar} as={Separator} />;
}

export interface ToolbarProps
  extends R.ToolbarInitialState,
    Omit<StackProps, 'children'> {
  children: ReactNodeNoStrings;
  'aria-label': string;
}
