import React from 'react';
import { Text } from '@oms/ui-text';
import { Icon, light } from '@oms/ui-icon';
import { useStockChartController } from '../StockChartController';
import { useCompanyInfoQuery } from '../StockChartQuery';
import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { I18n as I18nStateReturn, MessageDescriptor } from '@lingui/core';

export interface TradingHoursProps {}

export function TradingHours(_props: TradingHoursProps) {
  const controller = useStockChartController();
  const companyData = useCompanyInfoQuery({
    itemSector: controller.state.main.itemSector,
  });
  const openTime = companyData.data?.sector_open_time
    ? new Date(companyData.data?.sector_open_time).toLocaleTimeString(
        undefined,
        {
          hour: '2-digit',
          minute: '2-digit',
        },
      )
    : null;
  const closeTime = companyData.data?.sector_close_time
    ? new Date(companyData.data?.sector_close_time).toLocaleTimeString(
        undefined,
        {
          hour: '2-digit',
          minute: '2-digit',
        },
      )
    : null;
  return openTime !== null && closeTime !== null ? (
    <I18n>
      {({ i18n }: { i18n: I18nStateReturn }) => {
        const i18nToString = (message: MessageDescriptor): string =>
          i18n ? i18n._(message) : message.id;
        return (
          <div
            style={{
              display: 'flex',
              marginLeft: 'auto',
              whiteSpace: 'nowrap',
            }}
          >
            <Icon
              icon={light.faClock}
              aria-hidden={false}
              aria-label={i18nToString(t`Opening hours`)}
            />
            <Text
              fontFamily="body"
              variant="body2"
              ml={2}
              style={{ wordBreak: 'no-break' }}
            >
              {openTime} - {closeTime}
            </Text>
          </div>
        );
      }}
    </I18n>
  ) : null;
}
