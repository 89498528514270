import React from 'react';
import { Trans } from '@lingui/macro';

export interface LoadingProps {
  promise?: Promise<void>;
}

export const Loading = ({ promise }: LoadingProps) => {
  // Suspense enabled loading
  if ('Suspense' in React) {
    throw promise;
  }

  // Fallback loading indicator
  return (
    <div className="loading-indicator">
      <Trans>Loading...</Trans>
    </div>
  );
};
