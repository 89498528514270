import { useQuery } from 'react-query';
import { useComponentsContext } from '@oms/components-config-context';
import {
  fetchMetaData,
  fetchGraphData,
  fetchBarData,
  fetchNewsData,
  fetchDividendsData,
  fetchCompanyInfoData,
} from './fetchers';
import {
  createQueryKey,
  getSeriesQueryKey,
  getStaleTime,
  getMilliseconds,
} from './utils';

import { DateRangeSelection, LineStyle } from '../StockChartController';

type UseMetaDataQueryOptions = {
  itemSector: string;
};
export function useMetaDataQuery({ itemSector }: UseMetaDataQueryOptions) {
  const { domainUrl, baseUrl } = useComponentsContext();
  return useQuery(
    createQueryKey('metaData', { itemSector }),
    () => fetchMetaData({ itemSector, domainUrl, baseUrl }),
    {
      staleTime: getMilliseconds({ day: 1 }),
    },
  );
}

type UseCompanyInfoQueryOptions = {
  itemSector: string;
};
export function useCompanyInfoQuery({
  itemSector,
}: UseCompanyInfoQueryOptions) {
  const { jawsUrl, baseUrl } = useComponentsContext();
  return useQuery(
    createQueryKey('companyInfo', { itemSector }),
    () => fetchCompanyInfoData({ itemSector, jawsUrl, baseUrl }),
    {
      staleTime: getMilliseconds({ hour: 1 }),
    },
  );
}

type UseGraphDataQueryOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  lineStyle?: LineStyle;
};
export function useGraphDataQuery({
  itemSector,
  dateRangeSelection,
  lineStyle = 'line',
}: UseGraphDataQueryOptions) {
  if (
    dateRangeSelection === 'SELECTION_INTRADAY_ONE_HOUR' ||
    dateRangeSelection === 'SELECTION_INTRADAY_TREE_HOURS'
  ) {
    dateRangeSelection = 'SELECTION_INTRADAY';
  }
  const { graphdataUrl, baseUrl } = useComponentsContext();
  return useQuery(
    getSeriesQueryKey({ itemSector, dateRangeSelection, lineStyle }),
    () =>
      fetchGraphData({
        itemSector,
        dateRangeSelection,
        graphdataUrl,
        baseUrl,
        lineStyle,
      }),
    {
      staleTime: getStaleTime(dateRangeSelection),
    },
  );
}

type UseVolumeQueryOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  enabled?: boolean;
};
export function useVolumeQuery({
  itemSector,
  dateRangeSelection,
  enabled,
}: UseVolumeQueryOptions) {
  const { graphdataUrl, baseUrl } = useComponentsContext();
  return useQuery(
    createQueryKey('volumeData', { itemSector, dateRangeSelection }),
    () =>
      fetchBarData({
        itemSector,
        dateRangeSelection,
        graphdataUrl,
        baseUrl,
        type: 'volume',
      }),
    {
      enabled,
      staleTime: getStaleTime(dateRangeSelection),
    },
  );
}

type UseTurnoverQueryOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  enabled?: boolean;
};
export function useTurnoverQuery({
  itemSector,
  dateRangeSelection,
  enabled,
}: UseTurnoverQueryOptions) {
  const { graphdataUrl, baseUrl } = useComponentsContext();
  return useQuery(
    createQueryKey('turnoverData', { itemSector, dateRangeSelection }),
    () =>
      fetchBarData({
        itemSector,
        dateRangeSelection,
        graphdataUrl,
        baseUrl,
        type: 'turnover',
      }),
    {
      enabled,
      staleTime: getStaleTime(dateRangeSelection),
    },
  );
}

type UseNewsQueryOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  enabled?: boolean;
};
export function useNewsQuery({
  itemSector,
  dateRangeSelection,
  enabled,
}: UseNewsQueryOptions) {
  const { baseUrl, jawsUrl } = useComponentsContext();
  return useQuery(
    createQueryKey('newsData', { itemSector, dateRangeSelection }),
    () =>
      fetchNewsData({
        itemSector,
        dateRangeSelection,
        jawsUrl,
        baseUrl,
      }),
    {
      enabled,
      staleTime: getStaleTime(dateRangeSelection),
    },
  );
}

type UseDividendsOptions = {
  itemSector: string;
  dateRangeSelection: DateRangeSelection;
  enabled?: boolean;
};
export function useDividendsQuery({
  itemSector,
  dateRangeSelection,
  enabled,
}: UseDividendsOptions) {
  const { baseUrl, jawsUrl } = useComponentsContext();
  return useQuery(
    createQueryKey('dividendsData', { itemSector, dateRangeSelection }),
    () =>
      fetchDividendsData({
        itemSector,
        dateRangeSelection,
        jawsUrl,
        baseUrl,
      }),
    {
      enabled,
      staleTime: getStaleTime(dateRangeSelection),
    },
  );
}
