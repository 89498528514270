import React, { useState, useCallback } from 'react';
import { Box } from '@oms/ui-box';
import { SuspenseWrapper } from '@oms/components-core';
import { Trans } from '@lingui/macro';
import { Heading } from '@oms/ui-heading';

import NewsFilters, { FilterState } from './NewsFilters';
import NewsTable from './NewsTable';
import NewsArticle from './NewsArticle';
import useNews from './useNews';

/**
 * TODO
 * 1) These filters differ from what is default in NewFilters. Update remove defaults, now we should choose where the defaults should be set
 * 2) This makes the component semi-controlled do we need this?
 * 3) TODO Handle this in a use effect inside NewsArchive
 * 4) This makes table dependent on NewsArchive and thus it cannot be used standalone.
 * 5) InteractiveList should NOT have circular navigation.
 */

export type Article = {
  COMPANY_TICKER?: string;
  HEADLINE?: string;
  MERK_ITEM_SECTOR?: string;
  OAX_ITEM_SECTOR?: string;
  OSE_ITEM_SECTOR?: string;
  REFERENCEID?: string;
  TEXT?: string;
  TIME?: number;
  URL?: string;
};

interface NewsArchiveProps {
  /** A className that will be passed to the containing element */
  className?: string;
  /**
   * A function which is called when the component receives data
   *
   * @see See the [NewsTable](/#!/NewsTable) component for details.
   */
  onDataChanged?: (
    items: { [key: string]: Article },
    // TODO 2)
    setSelectedKey: (key: string) => void,
  ) => void;
  /**
   * The function that will be called for every filter change
   *
   * @see See the [NewsFilters](/#!/NewsFilters) component for details.
   */
  onFilterChange?: (filters: FilterState) => void;
  /**
   * When an element in the list is selected this function will be called.
   *
   * @see See the [NewsTable](/#!/NewsTable) component for details.
   */
  onArticleSelect?: (article: Article) => void;
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/docs/Glossary.md#locationdescriptor).
   */
  linkTo?: any;
  /** Filters used to initialize the result set of the component on load */
  initialFilterValues?: Partial<FilterState>;
  /**
   * The fallback loading component for the news table component. By default
   * it does not render any loading component.
   */
  fallback?: React.ReactNode;
  /**
   * Props to spread into `NewsFilters`
   */
  filterProps?: Partial<React.ComponentProps<typeof NewsFilters>>;
}

/**
 * A component which shows an archive of news alongside a set of filters that
 * enables the users to search through and read them.
 *
 * @since 1.0.0
 */
export const NewsArchive = ({
  className = 'NewsArchive',
  linkTo = '/instrument/{ITEM_SECTOR}',
  // TODO 1)
  initialFilterValues = {},
  fallback = <div />,
  onDataChanged,
  onFilterChange,
  onArticleSelect,
  filterProps,
}: NewsArchiveProps) => {
  const [article, setArticle] = useState<Article>();
  const [filters, setFilters] = useState<FilterState>(initialFilterValues);
  const { data, ...jawsApi } = useNews({ filters });

  const handleDataChanged = useCallback(
    (
      items: { [key: string]: Article },
      // Sets the active key inside NewsTable
      setSelectedKey: any,
    ) => {
      if (!article && items) {
        const firstItem = Object.values(items)[0];
        const firstKey = Object.keys(items)[0];
        setArticle(firstItem);
        setSelectedKey(firstKey);
      }

      if (onDataChanged) onDataChanged(items, setSelectedKey);
    },
    [article, onDataChanged],
  );

  const handleFilterChange = (filters: FilterState) => {
    setFilters(filters);
    if (onFilterChange) onFilterChange(filters);
  };

  const handleSelect = (article: Article) => {
    setArticle(article);
    if (onArticleSelect) onArticleSelect(article);
  };

  return (
    <Box display="flex" flexWrap="wrap" className={className} width="100%">
      <Box
        width={{ base: 1, md: '20rem' }}
        display="flex"
        flexDirection="column"
        borderRight="sm"
        borderColor="border"
      >
        <Heading as="h2" variant="heading4" mb={4}>
          <Trans>News archive</Trans>
        </Heading>
        <NewsFilters
          onFilterChange={handleFilterChange}
          clearFilterText={
            <>
              <Trans>Showing</Trans> "{article?.HEADLINE}"
            </>
          }
          {...filterProps}
          initialFilterValues={filters}
        />
        <SuspenseWrapper fallback={fallback}>
          <NewsTable
            {...jawsApi}
            // TODO 4)
            data={data}
            onSelect={handleSelect}
            // TODO 3)
            onDataChanged={handleDataChanged}
          />
        </SuspenseWrapper>
      </Box>
      <Box flex="1 0 20rem">
        <NewsArticle
          headline={article?.HEADLINE}
          text={article?.TEXT}
          url={article?.URL}
          linkTo={linkTo}
        />
      </Box>
    </Box>
  );
};

export default NewsArchive;
