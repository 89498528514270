import React from 'react';
import moment from 'moment';
import template from 'url-template';
import { Trans } from '@lingui/macro';
import { light } from '@oms/ui-icon';

/** Transactions codes tied to fund transfers */
export const fundTransferCodes = ['FLT', 'FLI', 'KKI', 'KKT'];

export const mapType = (
  typeEnum?: 'BUY' | 'SELL',
  transactionCode = '',
): React.ReactNode => {
  if (transactionCode && fundTransferCodes.includes(transactionCode)) {
    return <Trans>Transfer</Trans>;
  }

  if (!typeEnum) return '-';

  return (
    {
      BUY: <Trans>Buy</Trans>,
      SELL: <Trans>Sale</Trans>,
    }[typeEnum] || '-'
  );
};

/** StatusEnums for orders */
export type Status =
  | 'RECEIVED'
  | 'REJECTED'
  | 'IN_PROCESSING'
  | 'FUTURE'
  | 'SETTLED'
  | 'CANCELLED'
  | 'EXPIRED'
  | 'WITHDRAWN'
  | 'EXPORTED'
  | 'MANUAL'
  | 'MANUAL_EXPIRED';

// Se http://manawiki.osl.manamind.com/index.php/Meglersystemer
export const mapStatus = (statusEnum: Status, isFund = false) => {
  if (isFund && statusEnum === 'IN_PROCESSING') return <Trans>Active</Trans>;

  return (
    {
      RECEIVED: <Trans>Received</Trans>,
      REJECTED: <Trans>Rejected</Trans>,
      IN_PROCESSING: <Trans>Listed on exchange</Trans>,
      FUTURE: <Trans>Waiting</Trans>,
      SETTLED: <Trans>Executed</Trans>,
      CANCELLED: <Trans>Cancelled</Trans>,
      EXPIRED: <Trans>Expired</Trans>,
      WITHDRAWN: <Trans>Withdrawn</Trans>,
      EXPORTED: <Trans>Exported</Trans>,
      MANUAL: <Trans>Manual</Trans>,
      MANUAL_EXPIRED: <Trans>Manually expired</Trans>,
    }[statusEnum] || '-'
  );
};

export const mapStatusIcon = (statusEnum: Status) =>
  ({
    RECEIVED: light.faInfoCircle, // light.faGear,
    REJECTED: light.faExclamationCircle, //faMinusCircle,
    IN_PROCESSING: light.faLandmark, // 'faUniversity',
    FUTURE: light.faSpinnerThird, //'faHourglass1',
    SETTLED: light.faCheckCircle,
    CANCELLED: light.faTimes, //faMinusCircle,
    EXPIRED: light.faTimes, // 'faHourglass3',
    WITHDRAWN: light.faTimes, //faHandStopO,
    EXPORTED: light.faExternalLink,
    MANUAL: light.faUserEdit, //'faExclamation',
    MANUAL_EXPIRED: light.faTimes, //'faHourglass3',
  }[statusEnum]);

export const orderByDate = (
  a: { expirationDate: moment.MomentInput },
  b: { expirationDate: moment.MomentInput },
) => {
  const firstDate = moment(a.expirationDate).format('x');
  const secondDate = moment(b.expirationDate).format('x');
  if (firstDate > secondDate) return -1;
  if (firstDate < secondDate) return 1;
  return 0;
};

export const buildURL = (templateString: string, params: object) => {
  const pattern = template.parse(templateString);

  return pattern.expand(params);
};

/**
 * Allows setting a max amount of decimals, not just having it default to 4 like the @oms/utils variant.
 * TODO: This is soon available in @oms/utils as well
 */
export const decimalPlaces = (num: number, max = 4) => {
  const match = num.toString().match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 2;
  }
  if (!match[1]) {
    return 2;
  }
  if (match[1].length > max) {
    return max;
  }
  if (match[1].length < 2) {
    return 2;
  }
  return match[1].length;
};
