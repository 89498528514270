import React from 'react';
import { formatLink } from '@oms/utils';
import { Link } from '@oms/ui-link';

interface NewsItemLinkProps {
  item: {
    HEADLINE: string;
    URL: string;
    MERK_ITEM_SECTOR: string;
    OSE_ITEM_SECTOR: string;
    OAX_ITEM_SECTOR: string;
  };
  newsItemLink: any;
  role?: string;
  className?: string;
}

export const NewsItemLink: React.FC<NewsItemLinkProps> = ({
  children,
  item,
  newsItemLink,
  ...rest
}) => {
  const {
    HEADLINE,
    URL,
    MERK_ITEM_SECTOR: MERK,
    OSE_ITEM_SECTOR: OSE,
    OAX_ITEM_SECTOR: OAX,
  } = item;

  if (newsItemLink) {
    // Find which (if any) item sector we can use for this instrument
    const itemSector = [MERK, OSE, OAX].find(
      (sector) => !!sector && sector !== 'All.OB',
    );

    const variables = {
      ...item,
      ITEM_SECTOR: itemSector,
    };

    return (
      <Link
        variant="inherit"
        to={formatLink(newsItemLink, variables)}
        {...rest}
      >
        {children || HEADLINE}
      </Link>
    );
  }
  if (URL) {
    return (
      <Link
        as="a"
        variant="inherit"
        href={URL}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children || HEADLINE}
      </Link>
    );
  }

  return <p {...rest}>{children || HEADLINE}</p>;
};

export default NewsItemLink;
