import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@lingui/react';
import { t } from '@lingui/macro';
import { formatLink, decimalPlaces } from '@oms/utils';
import { MemoizedTd as Td } from '@oms/ui-table';
import { Menu, MenuItem } from '@oms/ui-menu';
import { IconButton } from '@oms/ui-icon-button';
import { light } from '@oms/ui-icon';
import { Context as ComponentsContext } from '@oms/ui-components-context';

import supportedColumns from './columns';

export class Row extends React.PureComponent {
  static contextType = ComponentsContext;

  static propTypes = {
    /**
     * The columns to view.
     */
    columns: PropTypes.array.isRequired,
    instrument: PropTypes.shape({}),
    /**
     * When passed, this object will render an extra cell at the end of each
     * instrument's row containing contextual actions to that component.
     *
     * The documentation page's rendering of the type is bugged, the correct
     * type of this props is more akin to:
     *
     * ```
     * interface MenuProp {
     *   buttonContent: ReactNode,
     *   actions: Array<Action>,
     * }
     *
     * interface Action {
     *   label: String,
     *   to?: String | {
     *     pathname: String,
     *     query?: String
     *   },
     *   onClick?: Function,
     * }
     * ```
     *
     * The onClick function is passed the selected instrument as the first
     * argument and the event as the second argument.
     *
     * `to`-links are similar as other `linkTo` props, being RFC6570 compatible
     * templates.
     *
     * Be aware that passing this object as an inline object in the JSX will
     * break memoization and degrade performance as the table components rely on
     * a shallow prop equality comparison. It is therefore recommended to pass
     * this as either a `const` declared at the top of the file or a state
     * field to ensure the reference is stable.
     */
    menu: PropTypes.shape({
      disclosure: PropTypes.elementType,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          element: PropTypes.oneOfType([
            PropTypes.elementType.isRequired,
            PropTypes.string.isRequired,
          ]),
          onSelect: PropTypes.func,
          to: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
              pathname: PropTypes.string.isRequired,
              query: PropTypes.object.isRequired,
            }),
          ]),
        }),
      ),
    }),
    /**
     * The links on the instruments.
     *
     * The format of the links: Two formats are recognized. In both cases,
     * any strings are interpereted as an RFC6570 compatible template and
     * interpolated with the columns on the rows of the component.
     *
     * The two accepted inputs are the same as the react-router
     * `to`-prop. Either a string or an object.
     *
     * If an object is passed, it is assumed to be a [react-router location
     * descriptor](https://github.com/ReactTraining/react-router/blob/master/docs/Glossary.md#locationdescriptor).
     */
    linkTo: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        query: PropTypes.object.isRequired,
      }),
    ]),
    highlightChanges: PropTypes.bool,
  };

  render() {
    const {
      columns,
      instrument: values,
      menu,
      linkTo,
      highlightChanges,
    } = this.props;
    return (
      <tr>
        <Td
          type="symbol"
          ticker={values?.ITEM}
          name={values?.LONG_NAME}
          style={{ display: 'sticky', left: 0 }}
          data-testid="SYMBOL"
          linkTo={formatLink(linkTo, values)}
        />
        {columns.map((col) => {
          /**
           * Ticker/Long name is the hardcoded first item in the row
           * Inside QuotelistComponent thead we do something similar
           * QUOTELIST_TICKER
           */
          if (['ITEM', 'LONG_NAME'].includes(col)) {
            return null;
          }

          const { accessor, type, trend, flash } = supportedColumns[col];
          const value = accessor(values);

          if (highlightChanges) {
            return (
              <Td
                key={col}
                className={col}
                data-testid={col}
                type={type}
                trend={trend}
                flash={flash}
                timestamp={flash && values?.TIME}
                fractionDigits={
                  typeof value === 'number' && ['number'].includes(type)
                    ? decimalPlaces(value)
                    : undefined
                }
              >
                {value}
              </Td>
            );
          }

          return (
            <Td
              key={col}
              className={col}
              data-testid={col}
              type={type}
              trend={trend}
              flash={flash}
              timestamp={flash && values?.TIME}
              fractionDigits={
                typeof value === 'number' && ['number'].includes(type)
                  ? decimalPlaces(value)
                  : undefined
              }
            >
              {value}
            </Td>
          );
        })}
        {menu && (
          <Td className="contextButton">
            <I18n>
              {({ i18n }) => {
                const i18nToString = (message) =>
                  i18n ? i18n._(message) : message.id;
                return (
                  <Menu
                    disclosure={
                      menu.disclosure || (
                        <IconButton
                          icon={light.faEllipsisV}
                          aria-label={i18nToString(t`Menu`)}
                        />
                      )
                    }
                  >
                    {menu.actions.map((action, index) =>
                      action?.to ? (
                        <MenuItem
                          {...action}
                          key={index}
                          as={this.context.Link}
                          to={formatLink(action.to, values)}
                          onSelect={(event) => action.onSelect(values, event)}
                        >
                          {action.element}
                        </MenuItem>
                      ) : (
                        <MenuItem
                          {...action}
                          onSelect={(event) => action.onSelect(values, event)}
                          key={index}
                        >
                          {action.element}
                        </MenuItem>
                      ),
                    )}
                  </Menu>
                );
              }}
            </I18n>
          </Td>
        )}
      </tr>
    );
  }
}
