import template from 'url-template';
import { useQuery } from 'react-query';
import moment from 'moment';
import { DEFAULT_DATE_SERIALIZATION_FORMAT } from './constants';

type StockTransaction = {
  orderId?: string;
  transactionFee?: number;
  tickerCode?: string;
  price?: number;
  priceLocal?: number;
  totalPrice?: number;
  volume?: number;
  bankAccount?: string;
  instrumentAccount?: string;
  instrumentId?: string;
  instrumentName?: string;
  isin?: string;
  transactionCode?: string;
  reference?: string;
  stockExchangeId?: string;
  executedDate?: string;
  tradeType?: string;
  settledStatus?: string;
  settlementDate?: string;
};

type FundTransaction = {
  transactionId?: string;
  transactionFee?: number;
  tickerCode?: string;
  price?: number;
  priceLocal?: number;
  volume?: number;
  currencyRate?: number;
  currency?: string;
  instrumentId?: string;
  instrumentName?: string;
  isin?: string;
  transactionCode?: string;
  executedDate?: string;
  tradeType?: string;
  settlementDate?: string;
  rateDate?: string;
  orderCreatedDate?: string;
  cashAccount?: string;
  fundCurrency?: string;
};

type QueryOptions = {
  enabled?: boolean;
  suspense?: boolean;
};
type Type = 'fund' | 'stock';
type InstrumentType = 'EQUITIES' | 'FUNDS' | 'ETFS' | string;
type FundTicker = {
  ISIN: string;
  SECTOR: string;
  CURRENCY: string;
  TYPE?: InstrumentType;
};
type StockTicker = {
  ITEM: string;
  TYPE?: InstrumentType;
};

type Ticker<T extends Type> = T extends 'fund' ? FundTicker : StockTicker;
interface UseTransactionsArguments<T extends Type> {
  /**
   * Specifies what kind of transactions to search for.
   */
  type: T;
  baseUrl: string;
  transactionSearchUrl: string;
  /** The accountId used in search */
  accountId: number | string;
  /** The userId used in search  */
  userId: number | string;
  /** Used to parse `filters.fromDate` and `filters.toDate` */
  dateFormat?: string;
  /** @deprecated - use `dateSerializationFormat` instead */
  serializationFormat?: string;
  /** Used to serialize `filters.fromDate` and `filters.toDate` for use in the URL string */
  dateSerializationFormat?: string;
  /** The filters to use when fetching data */
  filters?: {
    [key: string]: any;
    ticker?: Ticker<T>;
    fromDate?: Date;
    toDate?: Date;
  };
  /** If the server-response is non-standard, use this to normalize it */
  responseNormalizer?: (responseResult: any) => any[];
}

type FetcherArgs<T extends Type> = {
  baseUrl: any;
  transactionSearchUrl: any;
  userId: any;
  accountId: any;
  type: T;
  filters: any;
  dateSerializationFormat: any;
  responseNormalizer?: UseTransactionsArguments<any>['responseNormalizer'];
};

type FetcherReturnType<T extends Type> = T extends 'fund'
  ? FundTransaction[]
  : StockTransaction[];

const fetcher = async <T extends Type>(
  queryKey: string,
  {
    accountId,
    baseUrl,
    dateSerializationFormat,
    filters,
    transactionSearchUrl,
    type,
    userId,
  }: FetcherArgs<T>,
  responseNormalizer: FetcherArgs<any>['responseNormalizer'],
): Promise<FetcherReturnType<T>> => {
  const urlPattern = template.parse(transactionSearchUrl);

  const fundTickerFilters = {
    isin: filters.ticker ? filters.ticker.ISIN : undefined,
    sector: filters.ticker ? filters.ticker.SECTOR : undefined,
    currency: filters.ticker ? filters.ticker.CURRENCY : undefined,
  };

  const stockTickerFilters = {
    item: filters.ticker ? filters.ticker.ITEM : undefined,
  };

  const url = urlPattern.expand({
    baseUrl,
    userId,
    type,
    accountId,
    filters: {
      ...filters,
      ...(type === 'fund' ? fundTickerFilters : stockTickerFilters),
      fromDate: moment(filters.fromDate).format(dateSerializationFormat),
      toDate: moment(filters.toDate).format(dateSerializationFormat),
    },
  });

  const data = await fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }
      return response.json();
    })
    .then((responseResult) => {
      if (responseNormalizer) return responseNormalizer(responseResult);
      return responseResult;
    });

  return data.map((transaction: FundTransaction | StockTransaction) => ({
    ...transaction,
    instrumentType: type,
  }));
};

// TODO: All the Typing here could probably be cleaned up a lot,
// but it's working as intended.
export const useTransactions = <T extends Type>(
  {
    baseUrl,
    type,
    accountId,
    userId,
    transactionSearchUrl,
    serializationFormat = DEFAULT_DATE_SERIALIZATION_FORMAT,
    dateSerializationFormat = serializationFormat, // Adds backwards compatibility for `serializationFormat`
    filters,
    responseNormalizer,
  }: UseTransactionsArguments<T>,
  options: QueryOptions = {
    enabled: !!type,
    suspense: false,
  },
) =>
  useQuery<FetcherReturnType<T>>(
    [
      'transactions',
      {
        accountId,
        baseUrl,
        dateSerializationFormat,
        filters,
        transactionSearchUrl,
        type,
        userId,
      },
    ],
    (queryKey, args) => fetcher(queryKey, args, responseNormalizer),
    options,
  );

export default useTransactions;
