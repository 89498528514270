import React from 'react';
import { useMenuBarState, MenuBar } from '@oms/ui-menu-bar';
import { useParentId } from '../StockChartContainer';
import { DateRangeToolBar } from './DateRangeToolbar';
import { TradingHours } from './TradingHours';
import { getStockChartBottomMenuId } from '../utils';
import { Scroll } from '@oms/ui-scroll';
import * as S from './styles';

export interface StockChartBottomBarProps {}

export function StockChartBottomBar(_props: StockChartBottomBarProps) {
  const id = useParentId();
  const menu = useMenuBarState();

  return (
    <MenuBar
      id={getStockChartBottomMenuId(id)}
      {...menu}
      style={{ height: '3rem' }}
    >
      <Scroll orientation="horizontal">
        <DateRangeToolBar />
      </Scroll>
      <S.Grid>
        <TradingHours />
      </S.Grid>
    </MenuBar>
  );
}
