//@ts-nocheck

import React from 'react';
import { Trans } from '@lingui/macro';
import { LinePath } from '@visx/shape';
import { scaleTime, scaleLinear } from '@visx/scale';

import { useComponentsContext } from '@oms/components-config-context';
import { Box } from '@oms/ui-box';
import {
  getSpaceFromSelection,
  getSeriesFromSelection,
  PERIODS,
  POINTS,
  buildURL,
} from '@oms/components-instrument-chart';
import { useQuery } from 'react-query';

export const background = '#f3f3f3';

/**
 * [0] Date in unix
 *
 * [1] TickValue
 */
type DataPoint = [number, number];

// accessors
const date = (d: DataPoint) => d[0];
const tick = (d: DataPoint) => d[1];

export type SparklineGraphProps = {
  width: number;
  height: number;
  itemSector: string;
  options?: { color?: string; strokeWidth?: number };
};

export default function SparklineGraph({
  width,
  height,
  itemSector,
  options = { color: 'blue', strokeWidth: 1.5 },
}: SparklineGraphProps) {
  const { data, isLoading } = useGraphData({
    itemSector,
    selection: 'SELECTION_INTRADAY',
  });
  if (isLoading)
    return (
      <Box height="100%" center>
        <Trans>Loading...</Trans>
      </Box>
    );
  if (width < 10 || data === undefined) return null;

  // scales
  const timeScale = scaleTime<number>({
    domain: [Math.min(...data.map(date)), Math.max(...data.map(date))],
  });
  const tickScale = scaleLinear<number>({
    domain: [
      Math.min(...data.map((d) => Math.min(tick(d), tick(d)))),
      Math.max(...data.map((d) => Math.max(tick(d), tick(d)))),
    ],
    nice: true,
  });

  timeScale.range([0, width]);
  tickScale.range([height, 0]);

  return (
    <svg width={width} height={height}>
      <LinePath
        data={data}
        x={(d) => timeScale(date(d)) ?? 0}
        y={(d) => tickScale(tick(d)) ?? 0}
        stroke={options.color}
        strokeWidth={options.strokeWidth}
      />
    </svg>
  );
}

const getGraphSeries = async (url: string) => {
  const response = await fetch(url);
  if (!response.ok)
    throw new Error(`Fetching graphdata with url "${url}" failed`);

  const { rows } = await response.json();
  const data = rows?.[0]?.values?.series?.s1?.data || [];
  return data as Array<DataPoint>;
};

type UseGraphDataArguments = {
  itemSector: string;
  selection: keyof typeof PERIODS;
};
const useGraphData = ({ itemSector, selection }: UseGraphDataArguments) => {
  const { baseUrl, graphdataUrl } = useComponentsContext();

  const url = buildURL(graphdataUrl, {
    baseUrl,
    itemSector: encodeURI(itemSector),
    space: getSpaceFromSelection(selection),
    series: getSeriesFromSelection(selection),
    query: {
      period: PERIODS[selection],
      points: POINTS[selection],
    },
  });

  const { data, ...queryState } = useQuery(
    ['graphData', itemSector],
    () => getGraphSeries(url),
    { staleTime: 30 * 1000 },
  );
  return { data, ...queryState };
};
