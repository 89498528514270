import React, { createContext, useContext } from 'react';
import { useImmerReducer, Reducer } from 'use-immer';
import { SELECTION } from '../constants';

export type ItemSector = string;

export type IndicatorType =
  | 'sma'
  | 'ema'
  | 'wma'
  | 'priceenvelopes'
  | 'bb'
  | 'stochastic'
  | 'roc';
// TODO extend

export type Indicator = {
  indicatorType: IndicatorType;
  interval: number;
};

export type Instrument = {
  itemSector: string;
  ticker: string;
  name: string;
  instrumentType?: 'OTHER' | 'EQUITIES' | 'PCC' | 'ETNS' | 'RIGHTS';
};

export type Addition = string;

export type DateRangeSelection = SELECTION;

export type LineStyle = string;

export type StockChartControllerState = {
  main: Instrument;
  comparisons: Instrument[];
  indicators: Indicator[];
  additions: Addition[];
  dateRangeSelection: DateRangeSelection;
  lineStyle: LineStyle;
};

export type StockChartControllerAction =
  // main
  | { type: 'SET_MAIN'; instrument: Instrument }
  // Line style
  | { type: 'SET_LINE_STYLE'; lineStyle: LineStyle }
  | { type: 'RESET_LINE_STYLE' }
  // DateRange
  | { type: 'SET_DATE_RANGE_SELECTION'; dateRangeSelection: DateRangeSelection }
  | { type: 'RESET_DATE_RANGE_SELECTION' }
  // Comparison
  | { type: 'ADD_COMPARISON'; instrument: Instrument }
  | { type: 'REMOVE_COMPARISON'; itemSector: string }
  | { type: 'CLEAR_COMPARISONS' }
  // Additions
  | { type: 'ADD_ADDITION'; addition: Addition }
  | { type: 'REMOVE_ADDITION'; addition: Addition }
  | { type: 'CLEAR_ADDITIONS' }
  // Indicators
  | ({ type: 'ADD_INDICATOR' } & Indicator)
  | ({ type: 'REMOVE_INDICATOR' } & Indicator)
  | { type: 'CLEAR_INDICATORS' };

export type OnStateChange = (
  action: StockChartControllerAction,
  state: StockChartControllerState,
  nextState: StockChartControllerState,
) => void;

const defaultInitialState: Omit<StockChartControllerState, 'main'> & {
  main: Partial<StockChartControllerState['main']>;
} = {
  main: {},
  comparisons: [],
  indicators: [],
  additions: [],
  dateRangeSelection: 'SELECTION_INTRADAY',
  lineStyle: 'line',
};

const stockChartControllerStateReducer: Reducer<
  StockChartControllerState,
  StockChartControllerAction
> = (draft, action) => {
  switch (action.type) {
    case 'SET_MAIN':
      return void (draft.main = action.instrument);
    case 'SET_LINE_STYLE':
      return void (draft.lineStyle = action.lineStyle);
    case 'SET_DATE_RANGE_SELECTION':
      return void (draft.dateRangeSelection = action.dateRangeSelection);
    case 'ADD_COMPARISON':
      return void draft.comparisons.push(action.instrument);
    case 'REMOVE_COMPARISON':
      return void (draft.comparisons = draft.comparisons.filter(
        (instrument) => instrument.itemSector !== action.itemSector,
      ));
    case 'CLEAR_COMPARISONS':
      return void (draft.comparisons = []);
    case 'ADD_ADDITION':
      return void draft.additions.push(action.addition);
    case 'REMOVE_ADDITION':
      return void (draft.additions = draft.additions.filter(
        (addition) => addition !== action.addition,
      ));
    case 'CLEAR_ADDITIONS':
      return void (draft.additions = []);
    case 'ADD_INDICATOR':
      return void draft.indicators.push({
        indicatorType: action.indicatorType,
        interval: action.interval,
      });
    case 'REMOVE_INDICATOR':
      return void (draft.indicators = draft.indicators.filter((indicator) => {
        if (indicator.indicatorType !== action.indicatorType) {
          return true;
        } else if (indicator.interval !== action.interval) {
          return true;
        } else {
          return false;
        }
      }));
    case 'CLEAR_INDICATORS':
      return void (draft.indicators = []);
    // 💭 TODO case "RESET":
    default:
      break;
  }
};

function useStockChartControllerState(
  initialState?: Partial<StockChartControllerState>,
  onStateChange?: OnStateChange,
) {
  const [state, dispatch] = useImmerReducer<
    StockChartControllerState,
    StockChartControllerAction
  >(
    (draft, action) => {
      const nextState = stockChartControllerStateReducer(draft, action);
      onStateChange?.(
        action,
        draft,
        (nextState as unknown) as StockChartControllerState,
      );
      return nextState;
    },
    {
      main: initialState?.main || {
        itemSector: 'EQNR.OSE',
        ticker: 'EQNR',
        name: 'Equinor',
        instrumentType: 'EQUITIES',
      },
      comparisons: initialState?.comparisons || [],
      additions: initialState?.additions || [],
      dateRangeSelection:
        initialState?.dateRangeSelection || 'SELECTION_INTRADAY',
      lineStyle: initialState?.lineStyle || 'line',
      indicators: initialState?.indicators || [],
    },
  );

  const actions = {
    setLineStyle: (lineStyle: LineStyle) => {
      dispatch({ type: 'SET_LINE_STYLE', lineStyle });
    },
    setDateRangeSelection: (dateRangeSelection: DateRangeSelection) => {
      dispatch({ type: 'SET_DATE_RANGE_SELECTION', dateRangeSelection });
    },
    addComparison: (instrument: Instrument) => {
      actions.clearIndicators();
      dispatch({ type: 'ADD_COMPARISON', instrument });
    },
    removeComparison: (itemSector: string) =>
      dispatch({ type: 'REMOVE_COMPARISON', itemSector }),
    toggleComparison: (instrument: Instrument) => {
      const exists = state.comparisons.find(
        (item) => item.itemSector === instrument.itemSector,
      );
      if (exists) {
        actions.removeComparison(instrument.itemSector);
      } else {
        actions.addComparison(instrument);
      }
    },
    clearComparisons: () => dispatch({ type: 'CLEAR_COMPARISONS' }),
    addAddition: (addition: Addition) =>
      dispatch({ type: 'ADD_ADDITION', addition }),
    removeAddition: (addition: Addition) =>
      dispatch({ type: 'REMOVE_ADDITION', addition }),
    toggleAddition: (addition: Addition) => {
      const exists = state.additions.find((item) => item === addition);
      if (exists) {
        actions.removeAddition(addition);
      } else {
        actions.addAddition(addition);
      }
    },
    addIndicator: (indicator: Indicator) => {
      // remove comparisons
      actions.clearComparisons();
      const exists = state.indicators.find((item) => {
        return (
          item.indicatorType === indicator.indicatorType &&
          item.interval === indicator.interval
        );
      });
      if (!exists) {
        dispatch({ type: 'ADD_INDICATOR', ...indicator });
      }
    },
    removeIndicator: (indicator: Indicator) =>
      dispatch({ type: 'REMOVE_INDICATOR', ...indicator }),
    clearIndicators: () => dispatch({ type: 'CLEAR_INDICATORS' }),
    setMain: (instrument: Instrument) =>
      dispatch({ type: 'SET_MAIN', instrument }),
  };

  const utils = {
    isChecked: (
      key: keyof Omit<StockChartControllerState, 'comparisons' | 'indicators'>,
      value: string,
    ) => {
      const field = state[key];
      return Array.isArray(field) ? field.includes(value) : field === value;
    },
  };

  return {
    state,
    ...actions,
    ...utils,
  };
}

export type UseStockChartControllerStateReturn = ReturnType<
  typeof useStockChartControllerState
>;

const context = createContext<UseStockChartControllerStateReturn | null>(null);
const { Provider } = context;

export function useStockChartController() {
  const c = useContext(context);
  if (c === null)
    throw new Error(
      'useStockChartController can only be used inside StockChartProvider/StockChartControllerProvider',
    );
  return c;
}

export interface StockChartControllerProviderProps {
  children: React.ReactNode;
  initialState?: Partial<StockChartControllerState>;
  onStateChange?: OnStateChange;
}
export function StockChartControllerProvider({
  initialState,
  children,
  onStateChange,
}: StockChartControllerProviderProps) {
  const value = useStockChartControllerState(initialState, onStateChange);
  return <Provider value={value}>{children}</Provider>;
}
