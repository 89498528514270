import React, { useState, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { I18n as I18nCore, MessageDescriptor } from '@lingui/core';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { ThSortable } from '@oms/ui-table';

import { defaultSpec } from './useQuotelist';
import { Row } from './Row';
import supportedColumns from './columns';
import { QuotelistComponentWrapper, Table } from './styles';

type GetColumnsArguments = {
  exclude?: string[];
  columns?: string;
};
const getColumns = ({ exclude = [], columns }: GetColumnsArguments) =>
  columns?.split(',').filter((col) => !exclude.includes(col));

export interface QuotelistComponentProps {
  /**
   * The dataset as recieved from QuotelistFetcher, useQuotelist or an equivalent
   * JawsComponent.
   */
  data: any;
  /**
   * Columns that are only included in the spec for metadata purposes. In other
   * words, these columns are not rendered out into the list.
   */
  exclude?: string[];
  highlightChanges?: boolean;
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/docs/Glossary.md#locationdescriptor).
   */
  linkTo?: any;
  /**
   * A comma-separated set of strings representing the columns to view.
   */
  columns?: string;
  /**
   * Determines how the `QuotelistComponent` component should render. Passing
   * `'normal'` will make the table scroll using a simple overflow along the
   * X-axis on mobile. `'flipped'` will "turn the table on its side" on
   * smaller screens, making for a more mobile optimized behavior on long
   * lists.
   */
  variant?: 'normal' | 'flipped';
  /**
   * When passed, this object will render an extra cell at the end of each
   * instrument's row containing contextual actions to that component.
   *
   * The documentation page's rendering of the type is bugged, the correct
   * type of this props is more akin to:
   *
   * ```
   * interface MenuProp {
   *   buttonContent: ReactNode,
   *   actions: Array<Action>,
   * }
   *
   * interface Action {
   *   label: String,
   *   to?: String | {
   *     pathname: String,
   *     query?: String
   *   },
   *   onClick?: Function,
   * }
   * ```
   *
   * The onClick function is passed the selected instrument as the first
   * argument and the event as the second argument.
   *
   * `to`-links are similar as other `linkTo` props, being RFC6570 compatible
   * templates.
   *
   * Be aware that passing this object as an inline object in the JSX will
   * break memoization and degrade performance as the table components rely on
   * a shallow prop equality comparison. It is therefore recommended to pass
   * this as either a `const` declared at the top of the file or a state
   * field to ensure the reference is stable.
   */
  menu?: {
    buttonContent: string;
    actions: {
      label: string;
      to: any;
    }[];
  };
  /** A class that will be passed to the wrapping element */
  className?: string;
}

/**
 * The "view" of the Quotelist component. Only renders the result of a query
 * that is passed to the `data` prop.
 *
 * @see Is a part of the [Quotelist](/#!/Quotelist) component.
 * @since 1.1.0
 */
export const QuotelistComponent = ({
  data = {},
  className = 'QuotelistComponent',
  columns = defaultSpec.columns,
  exclude = ['ITEM_SECTOR', 'SECTOR'],
  highlightChanges = false,
  linkTo = '/instrument/{ITEM_SECTOR}',
  menu,
  variant = 'normal',
}: QuotelistComponentProps) => {
  const [predicate, setPredicate] = useState(getColumns({ columns })?.[0]);
  const [ascending, setAscending] = useState(true);

  const filteredColumns = useMemo(() => getColumns({ exclude, columns }), [
    exclude,
    columns,
  ]);

  const sortedItems = useMemo(
    () =>
      Object.values(data).sort((a: any, b: any) => {
        if (!predicate) return 0;

        const aVal = a[predicate] ?? 0;
        const bVal = b[predicate] ?? 0;
        if (aVal < bVal) return ascending ? -1 : 1;
        if (aVal > bVal) return ascending ? 1 : -1;
        return 0;
      }),
    [data, predicate, ascending],
  );

  const handleSort = (col: string) => {
    if (col === predicate && !ascending) {
      // Reset
      setPredicate('ITEM_SECTOR');
      setAscending(true);
    } else {
      setPredicate(col);
      setAscending((ascending) => (predicate === col ? !ascending : true));
    }
  };

  if (
    !filteredColumns ||
    filteredColumns?.length <= ['ITEM', 'LONG_NAME'].length
  ) {
    return (
      <QuotelistComponentWrapper className={className}>
        Ingen kolonner valgt
      </QuotelistComponentWrapper>
    );
  }

  // Roles are used in the below HTML to ensure display modes set in CSS do
  // not override the roles of the elements, making the table inaccessible
  //
  // Inspect the DOM using the Firefox accessability inspector to verify
  // correct behavior.
  /* eslint-disable jsx-a11y/no-redundant-roles,react/no-array-index-key */

  return (
    <I18n>
      {({ i18n }: { i18n: I18nCore }) => {
        const i18nToString = (message: MessageDescriptor): string => {
          if (message) {
            return i18n ? i18n._(message) : message.id;
          }
          return '';
        };
        return (
          <QuotelistComponentWrapper className={className} variant={variant}>
            <Table variant={variant} layout="auto">
              <VisuallyHidden as="caption">
                <Trans>Quotelist</Trans>
              </VisuallyHidden>
              <thead role="rowgroup">
                <tr role="row">
                  <ThSortable
                    onClick={() => handleSort('ITEM')}
                    isSorted={predicate === 'ITEM'}
                    isSortedDesc={ascending}
                  >
                    {i18nToString((supportedColumns as any)['ITEM']?.heading)}
                  </ThSortable>
                  {filteredColumns.map((col: string) =>
                    /**
                     * Ticker/Long name is the hardcoded first item in the row
                     * Inside Row.js we do something similar
                     * QUOTELIST_TICKER
                     */
                    ['ITEM', 'LONG_NAME'].includes(col) ? null : (
                      <ThSortable
                        key={col}
                        className={col}
                        role="columnheader"
                        type={
                          supportedColumns[col as keyof typeof supportedColumns]
                            ?.type || 'text'
                        }
                        onClick={() => handleSort(col)}
                        isSorted={predicate === col}
                        isSortedDesc={ascending}
                      >
                        {i18nToString((supportedColumns as any)[col]?.heading)}
                      </ThSortable>
                    ),
                  )}
                  {menu && (
                    <th className="contextButton" role="columnheader">
                      <VisuallyHidden>
                        <Trans>Actions</Trans>
                      </VisuallyHidden>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedItems.map((instrument: any) => (
                  <Row
                    key={instrument?.ITEM}
                    columns={filteredColumns}
                    instrument={instrument}
                    menu={menu}
                    highlightChanges={highlightChanges}
                    linkTo={linkTo}
                  />
                ))}
              </tbody>
            </Table>
          </QuotelistComponentWrapper>
        );
      }}
    </I18n>
  );
};
