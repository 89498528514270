import React from 'react';
import {
  Instrument,
  Indicator,
  LineStyle,
  DateRangeSelection,
  Addition as TAddition,
  OnStateChange,
  StockChartControllerProvider,
} from './StockChartController';
import {
  StockChartContainer,
  StockChartContainerProps,
} from './StockChartContainer';
import { StockChartMenuBar } from './StockChartMenuBar';
import { StockChartGraph, StockChartGraphProps } from './StockChartGraph';
import { StockChartBottomBar } from './StockChartBottomBar';
import { StockChartActiveItems } from './StockChartActiveItems';

const defaultWatermark = 'Powered by Oslo Market Solutions. Part of Infront';
interface CustomMenuProps {
  /** Setting this option to false hides the main instrument selector*/
  includeMainInstrumentMenuBarItem?: never;
  /** Custom top menubar that replaces the default top menubar */
  menu?: JSX.Element;
}

interface WithoutCustomMenuProps {
  /** Setting this option to false hides the main instrument selector*/
  includeMainInstrumentMenuBarItem?: boolean;
  /** Custom top menubar that replaces the default top menubar */
  menu?: never;
}

export interface StockChartProps {
  itemSector?: string;
  /** Pass an instrument that should be displayed when StockChart is initialized. */
  initialMainInstrument?: Instrument;
  /** Pass an array of instruments that should be displayed when StockChart is initialized. */
  initialComparisons?: Instrument[];
  /** Pass an array of indicators that should be displayed when StockChart is initialized. */
  initialIndicators?: Indicator[];
  /** Pass a default line style that should be displayed when StockChart is initialized. */
  initialLineStyle?: LineStyle;
  /** Pass a default data range that should be used when StockChart is initialized. */
  initialDateRangeSelection?: DateRangeSelection;
  /** Pass an array of additions that should be displayed when StockChart is initialized. */
  initialAdditions?: TAddition[];
  /** Custom series data fetchers
   * @deprecated
   * */
  seriesQueries?: any;
  /** Custom series data fetchers */
  customSeriesQueries?: StockChartGraphProps['customSeriesQueries'];
  /** This function is called every time the internal state changes.
   * This can be useful if you're using the StockChart as a "controlled"
   * component, where you manage some or all of the state.
   * */
  onStateChange?: OnStateChange;
  className?: StockChartContainerProps['className'];
  style?: StockChartContainerProps['style'];
  /** The height of the HighCharts chart container.
   * Must be an absolute value.
   * Doesn't not include the height added by the top and bottom menu */
  chartHeight?: StockChartContainerProps['chartHeight'];
  /** The minimum height of the HighCharts chart container.
   * Must be an absolute value.
   * Doesn't not include the height added by the top and bottom menu */
  chartMinHeight?: StockChartContainerProps['chartMinHeight'];
  /** Setting this option to false hides the main instrument selector
   * @deprecated
   */
  includeMainInstrumentMenuBarItem?: boolean;
  /** Setting this option to false disables the main instrument selector*/
  allowChangeMainInstrument?: boolean;
  /** Custom top menubar that replaces the default top menubar */
  menu?: JSX.Element;
  /** Custom menu additions that are rendered into the additions menu */
  customAdditions?: JSX.Element;
  /**  Identifying text that appears as shaded text placed over the graph */
  watermark?: string;
  /**
   * Path where Highcharts will look for icons. Change this to use icons from a different server.
   * icons available at https://github.com/highcharts/highcharts/tree/master/gfx/stock-icons
   */
  iconsURL?: string;
  /** The group that will be used to fetch possible matches */
  searchGroup?: string;
}

/**
 * A chart that provides options for analyzing the historical share prices of an
 * instrument in order to forecast the direction of the price in the future.
 *
 * Various indicators are available for analyzing the stock. Additions like news
 * and dividends, comparison with other instruments and technical analyses like
 * SMA over a given period.
 *
 * `StockChartQueries` do not use `JawsProvider` and queries are configured via
 * `ComponentsContext`.
 *
 * This component can be styled by specifying the following tokens or
 * by defining custom styles for:
 *
 * `colors`:
 *  -'chart-background'
 *  -'chart-axis'
 *  -'chart-axis-label'
 *  -'chart-legend-label'
 *  -'chart-text'
 *  -'chart-last-pointer'
 *  -'chart-watermark'
 *  -'chart-toolbar-item'
 *  -'chart-toolbar-item-hover'
 *  -'chart-grid-line'
 *  -'chart-toggle-toolbar'
 *  -'chart-flag'
 * @since 2.7.0
 */
export function StockChart(
  props: Omit<StockChartProps, 'menu' | 'includeMainInstrumentMenuBarItem'> &
    WithoutCustomMenuProps,
): JSX.Element;
export function StockChart(
  props: Omit<StockChartProps, 'menu' | 'includeMainInstrumentMenuBarItem'> &
    CustomMenuProps,
): JSX.Element;
export function StockChart({
  itemSector,
  initialMainInstrument = itemSector
    ? { itemSector, ticker: '', name: '' }
    : undefined,
  initialComparisons,
  initialIndicators,
  initialAdditions,
  initialDateRangeSelection,
  initialLineStyle = 'line',
  onStateChange,
  menu,
  includeMainInstrumentMenuBarItem = !menu,
  allowChangeMainInstrument = true,
  seriesQueries,
  customSeriesQueries = seriesQueries,
  className,
  style: _style,
  chartHeight,
  chartMinHeight = '400px',
  customAdditions,
  watermark = defaultWatermark,
  iconsURL,
  searchGroup = 'SEARCH',
}: StockChartProps) {
  return (
    <StockChartContainer
      className={className}
      chartHeight={chartHeight}
      chartMinHeight={chartMinHeight}
    >
      <StockChartControllerProvider
        initialState={{
          main: initialMainInstrument,
          comparisons: initialComparisons,
          additions: initialAdditions,
          indicators: initialIndicators,
          dateRangeSelection: initialDateRangeSelection,
          lineStyle: initialLineStyle,
        }}
        onStateChange={onStateChange}
      >
        {menu ? (
          menu
        ) : (
          <StockChartMenuBar
            searchGroup={searchGroup}
            additions={customAdditions}
          />
        )}
        <StockChartActiveItems
          searchGroup={searchGroup}
          allowChangeMainInstrument={allowChangeMainInstrument}
        />
        <StockChartGraph
          customSeriesQueries={customSeriesQueries}
          watermark={watermark}
          iconsURL={iconsURL}
        />
        <StockChartBottomBar />
      </StockChartControllerProvider>
    </StockChartContainer>
  );
}
