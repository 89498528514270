import React, { useEffect, useState } from 'react';
import Mousetrap from 'mousetrap';
import * as R from 'reakit/Dialog';
import { useCombobox, UseComboboxProps } from 'downshift';
import { useDebouncedCallback } from 'use-debounce';
import { Box } from '@oms/ui-box';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { Backdrop } from '@oms/ui-backdrop';
import { Drawer } from '@oms/ui-drawer';
import { useMedia } from '@oms/ui-media';
import { Input, TextInputWrapper } from '@oms/ui-text-input';
import { Icon, light } from '@oms/ui-icon';
import { ClearButton } from '@oms/ui-icon-button';
import { Text } from '@oms/ui-text';
import { HighlightedText } from '@oms/ui-highlighted-text';
import {
  ItemContainer,
  GroupHeader,
  defaultItemToString,
  getSelected,
  groupBy,
  Item,
} from '@oms/ui-select';
import { Scroll } from '@oms/ui-scroll';
import {
  forwardRefWithAs,
  splitProps,
  noop,
  useId,
  systemProps,
} from '@oms/ui-utils';
import * as S from './styles';
import { RenderItem } from './examples/RenderItem';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

type DownshiftProps = Omit<UseComboboxProps<Item>, 'onInputValueChange'>;
interface SearchDialogProps extends DownshiftProps {
  dialogState: R.DialogStateReturn;
  /**
   * Whether or not the dialog should be a child of its parent.
   * Opening a nested orphan dialog will close its parent dialog
   * if hideOnClickOutside is set to true on the parent. It will be set to false if modal is false.
   */
  unstable_orphan?: boolean;
  /** Is the Search value clearable */
  isClearable?: boolean;
  disabled?: boolean;
  renderItem?: typeof RenderItem;
  /**
   * Called each time the selected options have changed.
   * Selection can be performed by option click,
   * Enter Key while option is highlighted or by blurring the menu
   * while an option is highlighted (Tab, Shift-Tab or clicking away).
   */
  onChange?: (event?: any) => void;
  /**
   * Called each time the input value has changed.
   */
  onInputValueChange?: (changes?: string | number) => void;
  /** Handle focus events on the Search component */
  onBlur?: (event?: any) => void;
  /** Handle focus events on the Search component */
  onFocus?: (event?: any) => void;
  id?: string;
  /** The minimum amount of characters the user has to type before the onInputChange/fetcher callback is called */
  minimumQueryLength?: number;
  debounce?: number;
  /**
   * Can be:
   * - **idle** if the query is idle.
   * - **loading** if the query is in a loading state.
   * - **error** if the query attempt resulted in an error.
   * - **success** if the query has received a response with no errors and is ready to display its data.
   */
  status?: 'idle' | 'loading' | 'error' | 'success';
  /** Property which should be used to group items by */
  groupByKey?: string;
  groupToString?: (groupValue: string) => string;
  /** A component that can render a preview of the */
  renderPreview?: ({
    item,
    hide,
  }: {
    item: Record<string, any>;
    hide: () => void;
  }) => JSX.Element;
  text?: {
    fieldLabel?: string | any;
    /** Placeholder for the Combobox/Select if value is undefined */
    placeholder?: string | any;
    /** Aria label (for assistive tech) */
    clearButtonLabel?: string | any;
    /** Close button label. Default is "Close" */
    closeButtonLabel?: string | any;
    select?: string | any;
    navigate?: string | any;
    close?: string | any;
    /** Message to show when no items are found/matched */
    noData?: string | any;
    /** Message to show when the minimum amount of query characters has not been typed yet*/
    minimumQueryLength?: string | any;
    /** Message to show when querying resulted in a error */
    error?: string | any;
  };
  /**
   * A keyboard combination that activates the search dialog.
   * On Mac this ends up mapping to command+k whereas on Windows and Linux it maps to ctrl+k.
   * @see https://craig.is/killing/mice
   * */
  activationKeys?: string | string[];
  /** Expose some internal methods for fine grained control of the component */
  imperativeHandle?: React.Ref<null | { reset: () => void }>;
}

interface SearchDialogRenderPreviewProps<Item = Record<string, any>> {
  item: Item;
  hide: () => void;
}

const getItems = (items: DownshiftProps['items'], groupByKey?: string) => {
  return groupByKey ? Object.values(groupBy(items, groupByKey)).flat() : items;
};

const SearchDialog = forwardRefWithAs<SearchDialogProps, 'input'>(
  function Search(
    {
      value,
      onChange,
      onBlur = noop,
      onFocus = noop,
      onInputValueChange = noop,
      onSelectedItemChange = noop,
      disabled,
      isClearable = true,
      renderItem: ItemRenderer = RenderItem,
      id = 'search',
      required,
      items = [],
      itemToString = defaultItemToString,
      debounce = 300,
      status,
      minimumQueryLength = 3,
      groupByKey,
      groupToString = (group: string) => group,
      renderPreview: PreviewRenderer,
      text = {
        fieldLabel: 'Search',
        placeholder: 'What are you searching for?',
        clearButtonLabel: 'Clear selection',
        closeButtonLabel: 'Close',
        select: 'to select',
        navigate: 'to navigate',
        close: 'to close',
        noData: 'No match found for query ',
        minimumQueryLength: `Type ${minimumQueryLength} or more characters to start searching `,
        error: 'Oops something went wrong',
      },
      activationKeys,
      dialogState,
      unstable_orphan,
      style,
      'aria-label': ariaLabel = text.fieldLabel,
      imperativeHandle,
      ...props
    },
    ref,
  ) {
    const {
      fieldLabel = 'Search',
      placeholder = 'What are you searching for?',
      clearButtonLabel = 'Clear selection',
      closeButtonLabel = 'Close',
      select = 'to select',
      navigate = 'to navigate',
      close = 'to close',
      noData = 'No match found for query ',
      minimumQueryLength: minimumQueryLengthMessage = `Type ${minimumQueryLength} or more characters to start searching `,
      error = 'Oops something went wrong',
    } = text;

    const labelId = useId(id);
    const errorId = useId(id);
    const messageId = useId(id);
    const media = useMedia();
    const [,] = splitProps(props, systemProps as any);

    const [debouncedOnInputValueChange] = useDebouncedCallback(
      onInputValueChange,
      debounce,
    );

    const [preview, setPreview] = useState<any>(
      () => getItems(items, groupByKey)[0],
    );

    useEffect(() => {
      setPreview(getItems(items, groupByKey)[0]);
    }, [items]);

    useEffect(() => {
      const keys = activationKeys;
      if (keys) {
        Mousetrap.bind(keys, () => {
          dialogState.show();
        });
      }

      return () => {
        if (keys) {
          Mousetrap.unbind(keys);
        }
      };
    }, []);

    const {
      selectedItem,
      inputValue,
      highlightedIndex,
      getComboboxProps,
      getInputProps,
      getMenuProps,
      getItemProps,
      getLabelProps,
      reset,
    } = useCombobox({
      id,
      labelId,
      items: getItems(items, groupByKey),
      itemToString,
      defaultHighlightedIndex: 0,
      onStateChange: ({
        type,
        selectedItem,
        inputValue,
        highlightedIndex,
      }: any) => {
        switch (type) {
          //  @ts-ignore falls through
          case useCombobox.stateChangeTypes.InputBlur:
            return;
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.FunctionSelectItem:
            if (selectedItem) {
              dialogState.hide();
              onChange?.(selectedItem);
              // reset?
            }
            break;
          case useCombobox.stateChangeTypes.InputChange:
            // Only runs when the user types in the input field
            debouncedOnInputValueChange(inputValue);
            break;
          case useCombobox.stateChangeTypes.FunctionReset:
            // Unlike the regular combobox we don't want to call
            // onChange here when clearing the search field
            // onChange?.('');
            break;

          case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
          case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
          case useCombobox.stateChangeTypes.InputKeyDownHome:
          case useCombobox.stateChangeTypes.InputKeyDownEnd:
            if (!media.isMobile) {
              setPreview(getItems(items, groupByKey)[highlightedIndex]);
            }
            break;
          default:
            break;
        }
      },
      stateReducer: (state, actionsAndChanges) => {
        const { type, changes } = actionsAndChanges;
        switch (type) {
          /**
           * This allows use to highlight the first item,
           * but only select the item if clicked/on key down enter
           */
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...state,
              isOpen: false,
              highlightedIndex: -1,
            };
          default:
            return changes;
        }
      },
    });

    const showNoDataMessage =
      items?.length === 0 &&
      inputValue?.length >= minimumQueryLength &&
      status === 'success';
    const showMinimumQueryLength = inputValue?.length < minimumQueryLength;
    const showErrorMessage = status === 'error';

    React.useImperativeHandle(
      imperativeHandle,
      () => ({
        reset,
      }),
      [imperativeHandle, reset],
    );

    const scrollBoxRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
      const scrollBox = scrollBoxRef.current!;
      if (dialogState.modal && dialogState.visible) {
        disableBodyScroll(scrollBox);
      }
      return () => enableBodyScroll(scrollBox);
    }, [dialogState.modal, dialogState.visible]);

    React.useEffect(() => {
      if (!dialogState.visible) {
        reset();
      }
    }, [dialogState.visible]);

    /*
    Drawer            - overflowY auto
    > Box             - overflowY hidden
    >> DialogContent  - overflowY auto
    >>> Scroll        - overflowY auto
    */

    const search = (
      <Box
        id="Box"
        display="flex"
        flexDirection="column"
        flex={1}
        overflow="hidden"
        {...getComboboxProps({})}
      >
        <S.Header>
          <Box display="flex">
            <TextInputWrapper
              as="form"
              flex={1}
              height="3rem"
              data-state={
                (disabled && 'disabled') || (status === 'error' && 'error')
              }
            >
              <label {...getLabelProps()} style={{ marginLeft: '0.5rem' }}>
                {/** VisuallyHidden somehow receives focus  */}
                <VisuallyHidden tabIndex={-1}>{fieldLabel}</VisuallyHidden>
                <Icon icon={light.faSearch} />
              </label>
              <Input
                {...getInputProps({
                  ref,
                  placeholder,
                  onFocus,
                  onBlur,
                  'aria-describedby': messageId,
                  'aria-invalid': status === 'error',
                  'aria-errormessage': errorId,
                  autoFocus: true,
                  spellCheck: false,
                  autoCorrect: 'off',
                })}
                enterKeyHint="go"
              />
              {isClearable && (
                <span>
                  <ClearButton aria-label={clearButtonLabel} onClick={reset} />
                </span>
              )}
              {status === 'loading' && (
                <span>
                  <S.Spinner icon={light.faSpinnerThird} />
                </span>
              )}
              {status === 'error' && (
                <span>
                  <Icon icon={light.faExclamationCircle} />
                </span>
              )}
            </TextInputWrapper>
            <S.CloseButton onClick={dialogState.hide}>
              {closeButtonLabel}
            </S.CloseButton>
          </Box>
        </S.Header>
        <S.DialogContent id="S.DialogContent">
          <Scroll
            ref={scrollBoxRef}
            id="Scroll"
            showFade={false}
            display="flex"
            flexDirection="column"
            flex={1}
            backgroundColor="surface-1"
            padding={0}
          >
            <Box
              p={
                showNoDataMessage || showMinimumQueryLength || showErrorMessage
                  ? 2
                  : undefined
              }
            >
              <Text id={messageId} aria-live="polite">
                {showNoDataMessage && noData + ' '}
                {showNoDataMessage ? (
                  <b style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>
                    {inputValue}
                  </b>
                ) : null}
                {showMinimumQueryLength && minimumQueryLengthMessage}
              </Text>
              <Text id={errorId}>{showErrorMessage && error}</Text>
            </Box>
            <S.Listbox {...getMenuProps({})}>
              {groupByKey
                ? Object.entries(groupBy(items, groupByKey)).reduce(
                    (
                      result = { sections: [], itemIndex: 0 },
                      [group, items],
                      groupIndex,
                    ) => {
                      result.sections.push(
                        <section key={groupIndex}>
                          <GroupHeader id={groupToString(group)}>
                            {groupToString(group)}
                          </GroupHeader>
                          <S.Listbox aria-labelledby={groupToString(group)}>
                            {items.map((item) => {
                              const isSelected = getSelected(
                                  item,
                                  selectedItem,
                                  itemToString,
                                ),
                                index = result.itemIndex++,
                                isHighlighted = highlightedIndex === index;
                              return (
                                <ItemRenderer
                                  key={`${groupToString(group)}-${itemToString(
                                    item,
                                  )}`}
                                  item={item}
                                  inputValue={inputValue}
                                  isHighlighted={isHighlighted}
                                  isSelected={isSelected}
                                  itemToString={itemToString}
                                  onPreview={setPreview}
                                  hasPreview={
                                    !media.isMobile && !!PreviewRenderer
                                  }
                                  // inversion
                                  ItemContainer={ItemContainer}
                                  HighLightedText={HighlightedText}
                                  {...getItemProps({
                                    item,
                                    index,
                                  })}
                                />
                              );
                            })}
                          </S.Listbox>
                        </section>,
                      );
                      return result;
                    },
                    { sections: [] as JSX.Element[], itemIndex: 0 },
                  ).sections
                : items.map((item, index) => {
                    const isSelected = getSelected(
                        item,
                        selectedItem,
                        itemToString,
                      ),
                      isHighlighted = highlightedIndex === index;

                    return (
                      <ItemRenderer
                        key={itemToString(item)}
                        item={item}
                        inputValue={inputValue}
                        isHighlighted={isHighlighted}
                        isSelected={isSelected}
                        itemToString={itemToString}
                        setPreview={setPreview}
                        selectIcon={
                          <Icon
                            ml="auto"
                            mr={2}
                            icon={light.faLevelDown}
                            rotation={90}
                          />
                        }
                        hasPreview={!media.isMobile && !!PreviewRenderer}
                        // inversion
                        ItemContainer={ItemContainer}
                        HighLightedText={HighlightedText}
                        {...getItemProps({
                          item,
                          index,
                        })}
                      />
                    );
                  })}
            </S.Listbox>
          </Scroll>
          {!media.isMobile && PreviewRenderer ? (
            <Box
              overflow="auto"
              flex={1}
              as="article"
              display="flex"
              flexDirection="column"
              borderLeft="sm"
              borderLeftColor="border"
              maxWidth="50%"
              minWidth="50%"
            >
              <PreviewRenderer item={preview} hide={dialogState.hide} />
            </Box>
          ) : null}
        </S.DialogContent>
      </Box>
    );

    if (media.isMobile) {
      return (
        <>
          <Drawer
            data-search-dialog="true"
            {...dialogState}
            placement="sheet"
            aria-label={ariaLabel}
          >
            {search}
          </Drawer>
        </>
      );
    }
    return (
      <>
        <Backdrop
          {...dialogState}
          variant={unstable_orphan ? 'orphan' : 'default'}
        >
          <R.Dialog
            {...dialogState}
            unstable_orphan={unstable_orphan}
            data-search-dailog="true"
            data-modal="true"
            aria-label={ariaLabel}
          >
            {(dialogProps) => (
              <S.Dialog {...dialogProps} style={style}>
                {search}
                <S.Footer>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flex={PreviewRenderer ? 1 / 2 : 1}
                  >
                    <Box center>
                      <S.Kbd>↵</S.Kbd>
                      <S.KbdText>{select}</S.KbdText>
                    </Box>
                    <Box center ml={4}>
                      <S.Kbd>↑</S.Kbd>
                      <S.Kbd>↓</S.Kbd>
                      <S.KbdText>{navigate}</S.KbdText>
                    </Box>
                    <Box center ml={4}>
                      <S.Kbd>Esc</S.Kbd>
                      <S.KbdText>{close}</S.KbdText>
                    </Box>
                  </Box>
                </S.Footer>
              </S.Dialog>
            )}
          </R.Dialog>
        </Backdrop>
      </>
    );
  },
);

interface UseSearchDialogInitialState extends R.DialogInitialState {}
interface UseSearchDialogStateReturn extends R.DialogStateReturn {}
interface SearchDialogDisclosureProps extends R.DialogDisclosureProps {}

function useSearchDialogState(options?: R.DialogInitialState) {
  return R.useDialogState({ ...options, animated: true });
}

const SearchDialogDisclosure = R.DialogDisclosure;

export function useSearchDialogImperativeHandle() {
  const ref = React.useRef<{
    reset: () => void;
  } | null>(null);
  return ref;
}

export {
  useSearchDialogState,
  SearchDialogDisclosure,
  SearchDialog,
  UseSearchDialogInitialState,
  UseSearchDialogStateReturn,
  SearchDialogDisclosureProps,
  SearchDialogProps,
  SearchDialogRenderPreviewProps,
};
