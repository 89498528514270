/* eslint-disable react/no-find-dom-node, react/no-array-index-key, react/no-unused-prop-types */
import React, { useState } from 'react';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { formatDate, decimalPlaces, getSectorFromItemSector } from '@oms/utils';
import { Trans, t } from '@lingui/macro';
import { NoData, Loading } from '@oms/components-core';
import { Table, Td, Th, TableToggleRowsButton } from '@oms/ui-table';
import { TrendArrowIcon } from '@oms/ui-icon';

import { getSource } from './utils';

const TRADES = 'trades';

const startOfDay = new Date().setUTCHours(
  0, // hours
  0, // minutes
  0, // seconds
  0, // millis
);

const defaultSpec = {
  initiatorComponent: 'Trades',
  type: 'history',
  columns: 'TICK_CHANGE, TIME, PRICE, VOLUME, ID',
  ranking: '-TIME',
  start: startOfDay,
  stop: 'now',
  limit: 5,
};

type BuildSpecArguments = {
  itemSector: string;
  limit?: number;
  spec?: Spec;
};

const buildSpec = ({ itemSector, limit, spec }: BuildSpecArguments) => {
  const sector = getSectorFromItemSector(itemSector)?.toLowerCase();

  return {
    ...defaultSpec,
    filter: `ITEM_SECTOR==s${itemSector}`,
    source: getSource(sector),
    limit: limit && Number.isFinite(limit) ? limit : undefined,
    ...spec,
  };
};

export interface UseTradesArguments {
  /** A spec to override the default values of the component  */
  spec?: Spec;
  /** The itemSector to be used in fetching data */
  itemSector: string;
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * The `limit` to use when the component is expanded. Can be overridden using
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
  /** Options used in fetch: `forceHTTPConnection` and `disableAutoSubscribe` */
  options?: Options;
}

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `Trades` component.
 */
export const useTrades = (
  {
    spec,
    itemSector,
    initialLimit = 5,
    initialExpanded,
    expandedLimit = 20,
    onExpandToggled,
    options,
  }: UseTradesArguments = { itemSector: '' },
) => {
  if (!itemSector)
    throw new Error(
      `useTrades: itemSector is required, received: ${itemSector}`,
    );

  const [limit, setLimit] = useState(
    initialExpanded ? expandedLimit : initialLimit,
  );
  const [expanded, setExpanded] = useState(initialExpanded);
  const toggleExpanded = () => {
    const newValue = !expanded;
    setExpanded(newValue);
    setLimit(newValue ? expandedLimit : initialLimit);

    if (onExpandToggled) {
      onExpandToggled({ newValue });
    }
  };

  const builtSpec = buildSpec({ itemSector, limit, spec });
  const { items, ...values } = useJaws(builtSpec, options);
  const showType = builtSpec.columns.includes('TYPE');

  return {
    ...values,
    showType,
    columns: showType ? 4 : 3,
    toggleExpanded,
    expanded,
    data: items
      .sortBy((item) => item.get('TIME'))
      .reverse()
      .toJS(),
  };
};

export interface TradesProps {
  /** A spec to override the default values of the component  */
  spec?: Spec & {
    /**
     * The source to fetch data from. Determined dynamically based on the
     * input `itemSector`. _Default: feed.${sector}.quotes.${list}_
     */
    source?: string;
    /**
     * Which columns to display in the table. Supports the following optional
     * columns; `TYPE`.
     * _Default: TICK_CHANGE, TIME, PRICE, VOLUME, ID_
     */
    columns?: string;
    /** The number of rows in the table. _Default: 5_ */
    limit?: number;
    /** The field the selection should be ranked on. _Default: -TIME_ */
    ranking?: string;
  };
  /** The itemSector to be used in fetching data */
  itemSector: string;
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * The `limit` to use when the component is expanded. Can be overridden usen
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * When set to `true`, the component will render a button in the table footer
   * which expands to see all available levels when clicked.
   */
  showMoreButton?: boolean;
  /** Will be passed to the component */
  className?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /** Enables the TrendElement */
  showTrendElement?: boolean;
  /** TrendElement component to use */
  trendElement?: ({
    trend,
  }: {
    trend: 'pos' | 'neg' | 'flat';
  }) => React.ReactNode;
}

/**
 * A component which lists the most recent of today's trades on an instrument.
 * This results in a list of the trades on an instrument with information like
 * price and volume.
 *
 * @since 1.0.0
 */
export const Trades = ({
  className = 'Trades',
  itemSector,
  spec,
  initialLimit = 5,
  initialExpanded = false,
  expandedLimit = 20,
  showMoreButton = true,
  onExpandToggled,
  disableLoading = false,
  showTrendElement = false,
  trendElement = TrendArrowIcon,
}: TradesProps) => {
  const {
    resource,
    data,
    initialized,
    emptyData,
    showType,
    expanded,
    toggleExpanded,
  } = useTrades({
    spec,
    itemSector,
    initialLimit,
    initialExpanded,
    expandedLimit,
    onExpandToggled,
  });

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No trades found`} />;
  }

  const entries = Object.entries(data);
  const rows = Array(expanded ? expandedLimit : initialLimit)
    .fill(null)
    .map((_, i) => entries[i] || null);

  return (
    <>
      <Table className={className}>
        <thead>
          <tr>
            {showTrendElement && (
              <Th className="TREND" width="60px" textAlign="center">
                <Trans>Trend</Trans>
              </Th>
            )}
            <Th className="TIME">
              <Trans>Time</Trans>
            </Th>
            <Th type="number" className="PRICE">
              <Trans>Price</Trans>
            </Th>
            <Th type="integer" className="VOLUME">
              <Trans>Volume</Trans>
            </Th>
            {showType && (
              <Th type="text" className="TYPE">
                <Trans>Type</Trans>
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map((entry: any, index) => {
            if (!entry)
              return (
                <tr key={`emtpy-row-${index}`} data-testid={`row-${index}`}>
                  {showTrendElement && <Td />}
                  <Td />
                  <Td />
                  <Td />
                  {showType && <Td />}
                </tr>
              );

            const [key, item] = entry;
            const { TICK_CHANGE, TIME, PRICE, VOLUME, TYPE } = item;

            const getTrend = () => {
              if (TICK_CHANGE > 0) return 'pos';
              if (TICK_CHANGE < 0) return 'neg';
              return 'flat';
            };

            return (
              <tr key={key} data-testid={`row-${index}`}>
                {showTrendElement && (
                  <Td className="TREND" textAlign="center">
                    {trendElement({ trend: getTrend() })}
                  </Td>
                )}

                <Td className="TIME">
                  <time>{formatDate(TIME, ['HH:mm:ss', 'DD. MMM'])}</time>
                </Td>
                <Td
                  type="number"
                  fractionDigits={decimalPlaces(PRICE)}
                  className="PRICE"
                >
                  {PRICE}
                </Td>
                <Td type="integer" className="VOLUME">
                  {VOLUME}
                </Td>
                {showType && (
                  <Td type="text" className="TYPE">
                    {TYPE || '-'}
                  </Td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {showMoreButton && (
        <TableToggleRowsButton
          onClick={toggleExpanded}
          aria-controls={TRADES}
          style={{ width: '100%' }}
          aria-label={
            expanded
              ? 'Toggle trades, show additional content'
              : 'Toggle trades, hide additional content'
          }
        >
          {expanded ? <Trans>Show fewer</Trans> : <Trans>Show more</Trans>}
        </TableToggleRowsButton>
      )}
    </>
  );
};
