import * as React from 'react';
import { UIProvider } from '@oms/ui-provider';
import Jaws from '@oms/jaws';
import {
  JawsProvider,
  defaultContextValue as defaultJawsValue,
} from '@oms/jaws-react';
import {
  Provider as SuggestProvider,
  defaultContextValue as defaultSuggestValue,
} from '@oms/components-suggest';
import {
  Provider as ConfigProvider,
  defaultContextValues as defaultConfig,
} from '@oms/components-config-context';

type ContextType = {
  suggest?: React.ComponentProps<typeof SuggestProvider>['value'];
  config?: React.ComponentProps<typeof ConfigProvider>['value'];
  language?: React.ComponentProps<typeof UIProvider>['language'];
  components?: React.ComponentProps<typeof UIProvider>['components'];
  theme?: React.ComponentProps<typeof UIProvider>['theme'];
  global?: React.ComponentProps<typeof UIProvider>['global'];
  jaws: {
    instance: typeof Jaws;
  };
  children: React.ReactNode;
};

const getDefaultLanguage = () => {
  // Jest doesn't like it when this is written inline in the deafult values
  // below. Writing it as a function works fine. Babel issue?
  return window?.navigator?.language || 'en';
};

// TODO use the new exported default values from OMS UI
export const OMSContext = ({
  suggest = defaultSuggestValue,
  config = defaultConfig,
  language = getDefaultLanguage(),
  jaws = defaultJawsValue,
  components,
  theme,
  global,
  children,
}: ContextType) => (
  <JawsProvider value={jaws}>
    <UIProvider
      language={language}
      components={components}
      theme={theme}
      global={global}
    >
      <SuggestProvider value={suggest}>
        <ConfigProvider value={config}>{children}</ConfigProvider>
      </SuggestProvider>
    </UIProvider>
  </JawsProvider>
);

export { defaultSuggestValue, defaultConfig };
