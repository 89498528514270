import React, { HTMLProps } from 'react';
import { I18n } from '@lingui/react';
import { css } from '@emotion/core';

const styles = css`
  text-align: center;
  padding: 50px 0;
`;

export interface NoDataProps {
  text: {
    id: string;
  };
}

export const NoData = ({
  text,
  ...props
}: NoDataProps & HTMLProps<HTMLDivElement>) => (
  <I18n>
    {({ i18n }) => (
      /* i18n will be undefined if app is not wrapped in I18nProvider */
      <div css={styles} className="no-data" {...props}>
        {i18n ? i18n._(text) : text.id}
      </div>
    )}
  </I18n>
);
