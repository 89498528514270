export const gicsMap: { [key: string]: string } = {
  '10': 'Energi',
  '15': 'Materialer',
  '20': 'Industri',
  '25': 'Forbruksvarer',
  '30': 'Konsumvarer',
  '35': 'Helsevern',
  '40': 'Finans',
  '45': 'IT',
  '50': 'Telekom',
  '55': 'Forsyning',
  '60': 'Eiendom',
};

/**
 * Takes a gics code of an arbitrary length (like 10102050 or 1010) and
 * uses the leading two digits to return the category of the gics code.
 * @param {string} input - The gics code.
 * @return {number} a human readable name for the gics code, or "Andre" if the
 * code is not known
 */
export default function mapGics(input: string) {
  const twoFirstChars = String(input).substr(0, 2);
  return gicsMap[twoFirstChars] || 'Andre';
}
