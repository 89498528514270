import template from 'url-template';

type JawsHelperProps = {
  initiatorComponent: string;
  urlTemplate: string;
  baseUrl: string;
  [key: string]: any;
};

const jawsHelper = async ({
  urlTemplate,
  baseUrl,
  ...spec
}: JawsHelperProps) => {
  if (!baseUrl) {
    throw new Error(`jawsHelper: baseUrl is required`);
  }
  if (!urlTemplate) {
    throw new Error(`jawsHelper: urlTemplate is required`);
  }

  const pattern = template.parse(urlTemplate);
  const url = pattern.expand({ baseUrl, spec });
  const result = await fetch(url, { credentials: 'include' });
  const json = await result.json();

  if (!result.ok) throw json;
  return json;
};

export default jawsHelper;
