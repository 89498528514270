import React from 'react';
import { css } from '@emotion/core';

const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;
`;

export interface VisuallyHiddenProps {
  /** The text that will be rendered for the screen reader */
  children: React.ReactNode;
}

/**
 * Renders a string that will be hidden from a sighted user's application, but
 * visible to screen reader users. This is very useful for giving screen reader
 * users required context that may appear elsewhere or be inferred from
 * non-textual elements.
 *
 * @since 1.0.0
 */
export const VisuallyHidden = ({ children }: VisuallyHiddenProps) => (
  <div css={visuallyHidden}>{children}</div>
);
