import React, { ReactNode } from 'react';
import { forwardRefWithAs, Size, SystemProps } from '@oms/ui-utils';
import * as S from './styles';

type Variants = 'primary' | 'secondary' | 'tertiary';

export interface ChipProps extends SystemProps {
  children: React.ReactNode;
  size?: Size;
  /** @deprecated Content left, e.g. <Icon /> */
  leftAddon?: ReactNode;
  /** @deprecated Content right, e.g. <Icon /> */
  rightAddon?: ReactNode;
  /**  Content left, e.g. <Icon /> */
  leftElement?: ReactNode;
  /**  Content right, e.g. <Icon /> */
  rightElement?: ReactNode;
  variant?: Variants;
}

/**
 * Chips represent complex entities in small blocks, such as a contact.
 * A chip can contain several different elements such as avatars, text, and icons.
 */
export const Chip = forwardRefWithAs<ChipProps, 'button'>(function Chip(
  {
    children,
    leftAddon,
    leftElement = leftAddon,
    rightAddon,
    rightElement = rightAddon,
    size = 'sm',
    variant = 'primary',
    ...props
  },
  ref,
) {
  return (
    <S.Chip ref={ref} type="button" variant={variant} size={size} {...props}>
      {leftElement && (
        <span data-left="" style={{ display: 'inline-block' }}>
          {leftElement}
        </span>
      )}
      <S.Inner
        as="span"
        ml={leftElement ? 2 : undefined}
        mr={rightElement ? 2 : undefined}
        fontSize="md"
        data-inner=""
      >
        {children}
      </S.Inner>
      {rightElement && (
        <span data-right="" style={{ display: 'inline-block' }}>
          {rightElement}
        </span>
      )}
    </S.Chip>
  );
});
