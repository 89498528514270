import { useQuery } from 'react-query';
import template from 'url-template';
import moment from 'moment';
import { DEFAULT_DATE_SERIALIZATION_FORMAT } from './constants';
import { Status } from './utils';

type StockOrder = {
  orderId?: string;
  instrumentName?: string;
  instrumentId?: string;
  tickerCode?: string;
  tradeType?: string;
  orderType?: string;
  stockExchangeId?: string;
  /** ISO-string */
  createdDate?: string;
  /** ISO-string */
  expirationDate?: string;
  /** ISO-string */
  lastModifiedDate?: string;
  meanRate?: number;
  totalVolume?: number;
  remainingVolume?: number;
  hiddenVolume?: number;
  stopLossLimit?: number;
  limit?: number;
  orderSpecification?: string;
  orderStatus?: Status;
}; // TODO?: Different back-ends might have different structures

type FundOrder = {
  orderId?: string;
  instrumentId?: string;
  isin?: string;
  tradeType?: string;
  orderType?: string;
  stockExchangeId?: string;
  /** ISO-string */
  createdDate?: string;
  /** ISO-string */
  expirationDate?: string;
  /** ISO-string */
  lastModifiedDate?: string;
  meanRate?: number;
  totalVolume?: number;
  remainingVolume?: number;
  orderSpecification?: string;
  orderStatus?: Status;
  orderAmount?: number;
}; // TODO?: Different back-ends might have different structures

type Type = 'fund' | 'stock';

type UseOrdersArguments<T extends Type> = {
  /** Used to fetch LONG_NAME for funds */
  baseUrl: string;
  orderSearchUrl: string;
  /**
   * Specifies what kind of transactions to search for.
   */
  type: T;
  /** The userId used in search  */
  userId: number | string;
  accountId: number | string;
  /** The filters to use when fetching data */
  filters?: {
    ticker?: { ITEM: string };
    fromDate?: Date;
    toDate?: Date;
    status?: string;
    [key: string]: any;
  };
  /** Used to serialize `filters.fromDate` and `filters.toDate` for use in the URL string */
  dateSerializationFormat: any;
  /** If the server-response is non-standard, use this to normalize it */
  responseNormalizer?: (responseResult: any) => any[];
};

type QueryOptions = {
  enabled?: boolean;
  suspense?: boolean;
};

type UseOrderReturnType<T extends Type> = T extends 'fund'
  ? FundOrder
  : StockOrder;

const fetcher = async (...args: any[]) => {
  const [
    baseUrl,
    orderSearchUrl,
    userId,
    accountId,
    type,
    filters,
    dateSerializationFormat = DEFAULT_DATE_SERIALIZATION_FORMAT,
    responseNormalizer,
  ] = args as [
    string,
    string,
    'fund' | 'stock' | 'fund-and-stock',
    number | string,
    number | string,
    any,
    any,
    UseOrdersArguments<any>['responseNormalizer'],
  ];
  const urlPattern = template.parse(orderSearchUrl);
  const url = urlPattern.expand({
    baseUrl,
    type,
    accountId,
    userId,
    filters: {
      ...filters,
      tradeType: filters.tradeType === 'ALL' ? undefined : filters.tradeType,
      fromDate: moment(filters.fromDate).format(dateSerializationFormat),
      toDate: moment(filters.toDate).format(dateSerializationFormat),
    },
  });

  const data = await fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`${response.status} - ${response.statusText}`);
      }
      return response.json();
    })
    .then((responseResult) => {
      if (responseNormalizer) return responseNormalizer(responseResult);
      return responseResult;
    });

  return data.map((order: any) => ({ ...order, instrumentType: type }));
};

export const useOrders = <T extends Type>(
  {
    baseUrl,
    orderSearchUrl,
    type,
    userId,
    accountId,
    filters,
    dateSerializationFormat,
    responseNormalizer,
  }: UseOrdersArguments<T>,
  options: QueryOptions = {
    enabled: !!type,
    suspense: false,
  },
) => {
  return useQuery(
    [
      baseUrl,
      orderSearchUrl,
      userId,
      accountId,
      type,
      filters,
      dateSerializationFormat,
      responseNormalizer,
    ],
    (...args) => fetcher(...args, responseNormalizer),
    options,
  );
};

export default useOrders;
