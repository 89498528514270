import React, { ReactNode } from 'react';
import { useDisclosureState, DisclosureContent } from 'reakit/Disclosure';
import { Rover } from 'reakit/Rover';
import { motion } from 'framer-motion';
import { splitProps, systemProps, SystemProps } from '@oms/ui-utils';
import { Box } from '@oms/ui-box';
import { Icon, light } from '@oms/ui-icon';
import { useAccordionGroup } from './AccordionGroup';
import * as S from './styles';

const animationStates = {
  hidden: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
    transitionEnd: {
      display: 'none',
    },
  },
  visible: {
    opacity: 1,
    height: 'auto',
  },
};

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface AccordionProps extends SystemProps {
  headingLevel?: HeadingLevel;
  header: ReactNode;
  children: ReactNode;
  rightElement?: (open: boolean) => JSX.Element | JSX.Element;
}

export function Accordion({
  headingLevel = 'h2',
  header,
  children,
  rightElement = (open) => (
    <Icon
      icon={light.faAngleRight}
      size="lg"
      rotation={open ? 90 : undefined}
    />
  ),
  ...props
}: AccordionProps) {
  const [system] = splitProps(props, systemProps as any),
    rover = useAccordionGroup(),
    disclosure = useDisclosureState();
  return (
    <S.Container {...system}>
      <Rover {...rover}>
        {(roverProps) => {
          return (
            <Box as={headingLevel}>
              <S.Disclosure
                {...roverProps}
                {...disclosure}
                data-open={disclosure.visible}
              >
                {header}
                {typeof rightElement === 'function'
                  ? rightElement(disclosure.visible)
                  : rightElement}
              </S.Disclosure>
            </Box>
          );
        }}
      </Rover>
      <DisclosureContent {...disclosure}>
        {({ hidden, styles, ...disclosureProps }: any) => (
          <motion.div
            {...disclosureProps}
            initial="hidden"
            animate={hidden ? 'hidden' : 'visible'}
            variants={animationStates}
          >
            <S.DisclosureContent>{children}</S.DisclosureContent>
          </motion.div>
        )}
      </DisclosureContent>
    </S.Container>
  );
}
