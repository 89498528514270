import { css } from '@emotion/core';
import { activeColor, borderColor, lightGray } from './colors';
import { mobile } from './sizes';

export const basicTable = css`
  width: 100%;
  border-collapse: collapse;
`;

export const interactiveTableList = css`
  padding: 0;
  list-style: none;

  & > li b {
    display: inline-block;
  }

  & > li:not(:first-of-type) {
    border-bottom: 1px solid ${borderColor};

    @media (min-width: ${mobile}) {
      border-right: 1px solid ${borderColor};
    }

    &:nth-of-type(2) {
      border-top: 1px solid ${borderColor};
    }

    &:nth-of-type(odd) {
      background-color: ${lightGray};
    }

    &:hover {
      background-color: ${activeColor};
    }

    &:global(.active) {
      background-color: ${activeColor};

      @media (min-width: ${mobile}) {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 0px;
          right: -2px;
          width: 0;
          height: 0px;
          border-top: 21px solid rgba(0, 0, 0, 0);
          border-right: 21px solid #fff;
          border-bottom: 21px solid rgba(0, 0, 0, 0);
        }
      }
    }
  }

  & button {
    overflow: hidden;
    display: inline-block;
  }
`;

export const linkBtn = css`
  overflow: hidden;
  display: inline-block;
  width: 100%;
  padding: 0;
  cursor: pointer;
  font-size: 1em;
  font-weight: normal;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: none;
  border: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: #000 dotted 2px;
    color: #000;
  }
`;

export const treemap = css`
  & button {
    cursor: pointer;
    color: inherit;
    background: none;
    border: none;
  }
`;
