import React from 'react';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { Box } from '@oms/ui-box';
import { t } from '@lingui/macro';
import { NoData, Loading } from '@oms/components-core';

import leftSide from './leftSide';
import rightSide from './rightSide';

const defaultSpec = {
  initiatorComponent: 'CompanyInfo',
  columns:
    'ITEM, ISIN, SYMBOL_INDEX, GICS_CODE, SECTOR_INDEX_MEMBER_OF,' +
    'TOTAL_NUMBER_OF_SHARES, ISSUED_NUMBER_OF_SHARES, info.ORGANISATION_NUMBER, info.LOGO_FILENAME,' +
    'info.DESCRIPTION_NO, info.COMPANY_NAME, info.COMPANY_URL,info.TELEPHONE',
  leftJoins:
    'feed.ob.companyinfo.SYMBOL_INDICES:REALTIME as info on SYMBOL_INDEX',
};

interface UseCompanyInfoArguments {
  spec?: Spec;
  itemSector: string;
  options?: Options;
}

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `CompanyInfo` component.
 */
export const useCompanyInfo = ({
  spec,
  itemSector,
  options,
}: UseCompanyInfoArguments) => {
  const { items, ...values } = useJaws(
    { ...defaultSpec, itemSector, ...spec },
    options,
  );

  return {
    ...values,
    data: items.first()?.toJS(),
  };
};

interface CompanyInfoProps {
  /** A spec to override the default values of the component. */
  spec?: Spec & {
    /**
     * The source to use when joining in the organization number. _Default:
     * feed.ob.companyinfo.SYMBOL_INDICES:REALTIME
     * as info on SYMBOL_INDEX
     */
    leftJoins?: string;
  };
  /**
   * A render prop which returns nodes representing the left side of the
   * component.
   * The function is given the jaws data as its input. A sensible default
   * implementation is used if this prop is not provided.
   */
  leftSide?: (data: any, showNumberOfShares: any) => React.ReactNode;
  /**
   * A render prop which returns nodes representing the right side of the
   * component.
   * The function is given the jaws data as its input. A sensible default
   * implementation is used if this prop is not provided.
   */
  rightSide?: (data: any) => React.ReactNode;
  /** The itemSector to be used in fetching data */
  itemSector: string;
  /** Will be passed to the component */
  className?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /** If false, hides number of shares in bottom row */
  showNumberOfShares?: boolean;
}

/**
 * A component which displays most of the basic information about
 * a company, such as name, logo, links, etc.
 *
 * Requires company logos to be available on the server from the `/static/logos`
 * path. Filenames are fetched from jaws.
 *
 * @since 1.0.0
 */
export const CompanyInfo = ({
  className = 'CompanyInfo',
  itemSector,
  spec,
  leftSide: renderLeft = leftSide,
  rightSide: renderRight = rightSide,
  showNumberOfShares = true,
  disableLoading = false,
}: CompanyInfoProps) => {
  const { resource, data, initialized, emptyData } = useCompanyInfo({
    spec,
    itemSector,
  });

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No company info found`} />;
  }

  return (
    <Box
      display="flex"
      flexWrap={{ base: 'wrap', sm: 'nowrap' }}
      className={className}
    >
      <Box width={{ base: 1, sm: 1 / 2 }}>
        {renderLeft(data, showNumberOfShares)}
      </Box>
      <Box width={{ base: 1, sm: 1 / 2 }}>{renderRight(data)}</Box>
    </Box>
  );
};

export default CompanyInfo;
