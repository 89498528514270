import React, { useEffect, useState, useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { I18n as I18nCore, MessageDescriptor } from '@lingui/core';
import { Select } from '@oms/ui-select';
import { Combobox } from '@oms/ui-combobox';
import { RenderField } from '@oms/ui-field';
import { Wrap } from '@oms/ui-wrap';
import { Box } from '@oms/ui-box';

import supportedColumns from './columns';
import presets, { Presets } from './filters';
import { defaultSpec } from './useQuotelist';
import { Spec } from '@oms/jaws-react';
import { Market, List, Sector, Meta } from './types';

const options = Object.entries(supportedColumns)
  .filter(([key, value]) => {
    /**
     * Ticker/Long name are hardcoded and cant' be optional
     * Search for QUOTELIST_TICKER for related code parts
     */
    return !['ITEM', 'LONG_NAME'].includes(key);
  })
  .map(([key, value]) => ({
    label: value.heading,
    value: key,
  }));

/* eslint-disable jsx-a11y/label-has-for,jsx-a11y/label-has-associated-control */

const getSource = (selectedMarket?: Market, selectedList?: List) => {
  if (!selectedMarket) return null;
  if (selectedList) return `${selectedMarket.value}.${selectedList.value}`;

  return selectedMarket.value;
};

const getSectorFilter = (selectedMarket?: Market, sector?: Sector['value']) => {
  if (!sector) return null;
  if (selectedMarket?.value === 'feed.ose.quotes') {
    return `SECTOR_INDEX_MEMBER_OF==s${sector}`;
  }
  return `GICS_CODE_LEVEL_1==s${sector}`;
};

export interface QuotelistToolbarProps {
  /**
   * A spec to override the default values of the component. Most of the spec
   * is being controlled by the QuotelistToolbar component, so you may need to
   * implement your own Toolbar component if you wish to have more
   * fine-grained control over the spec.
   *
   * @see See the [QuotelistFetcher](/#!/QuotelistFetcher) component for available
   * options.
   */
  initialSpec?: Spec;
  /**
   * A callback that will be invoked after the preset spec has been loaded and
   * data has been requested. Includes some metadata like the spec and which
   * filters are selected.
   *
   * @param {Object} spec - The Jaws spec that has been loaded
   * @param {Object} meta - Metadata about the state of the filters component
   * @param {Object} meta.selectedMarket - The current selected market/selection. Contains a label and a value
   * @param {Object} meta.selectedList - The current selected list of the market Contains a label and a value
   * @param {Object} meta.selectedSector - The current selected sector (SECTOR_INDEX_MEMBER_OF for OB). Contains a label and a value
   */
  onLoad?: (spec: Spec, meta: Meta) => void;
  /**
   * A callback that will be invoked whenever the spec changes. This
   * essentially means whenever a filter is changed by a user. Includes some
   * metadata like the spec and which filters are selected.
   *
   * @param {Object} spec - The Jaws spec that has been loaded
   * @param {Object} meta - Metadata about the state of the filters component
   * @param {Object} meta.selectedMarket - The current selected market/selection. Contains a label and a value
   * @param {Object} meta.selectedList - The current selected list of the market Contains a label and a value
   * @param {Object} meta.selectedSector - The current selected sector (SECTOR_INDEX_MEMBER_OF for OB). Contains a label and a value
   */
  onSpecChange?: (spec: Spec, meta: Meta) => void;
  /**
   * Determines which filters should be rendered. If you require a more
   * fine-grained set of instruments you may have to implement a custom
   * `QuotelistToolbar` component.
   */
  preset?:
    | 'ose-stocks'
    | 'sse-nordic-stocks'
    | 'sse-stocks'
    | 'cse-stocks'
    | 'commodities'
    | 'nor-indices'
    | 'interests';
  /** A class that will be passed to the wrapping form */
  className?: string;
  /** @ignore Needs to be more thought out / polished to be part of official API*/
  presetModifier?: (preset: Presets[string]) => Presets[string];
}

/**
 * The toolbar is the component in charge of handling the user's input and
 * returning a spec that is passed to the `useQuotelist` hook. It may
 * be useful to think of the `Toobar` as an uncontrolled input of spec changes,
 * providing an abstraction over the user's inputs.
 *
 * The component uses the provided preset to determine which markets, lists and
 * sectors are available to select. On load the `onLoad` callback is called,
 * which provides the parent the initial spec. After which the `onSpecChance`
 * callback is invoked every time the user changes their preferences.
 *
 * An `initialSpec` can be provided, but keep in mind that it may conflict with
 * the presets. If you find this limiting, consider implementing a new
 * `QuotelistToolbar` component and composing it together with the other parts
 * of the `Quotelist` component.
 *
 * @see Is a part of the [Quotelist](/#!/Quotelist) component.
 * @since 1.1.0
 */
export const QuotelistToolbar = ({
  initialSpec = defaultSpec,
  className = 'QuotelistToolbar',
  preset = 'ose-stocks',
  onLoad,
  onSpecChange,
  presetModifier,
}: QuotelistToolbarProps) => {
  const {
    selectedPreset,
    initialSelectedList,
    initialSelectedMarket,
    initialSelectedSector,
  } = useMemo(() => {
    const selectedPreset = presetModifier
      ? presetModifier(presets[preset])
      : presets[preset];

    const initialSelectedMarket = selectedPreset?.markets?.find(
      (market) => market.selected,
    );
    const initialSelectedList = initialSelectedMarket?.lists?.find(
      (list) => list.selected,
    );
    const initialSelectedSector = initialSelectedMarket?.sectors?.find(
      (sector) => sector.selected,
    );

    return {
      selectedPreset,
      initialSelectedMarket,
      initialSelectedList,
      initialSelectedSector,
    };
  }, [preset, presetModifier]);

  const [spec, setSpec] = useState<Spec>({
    ...initialSpec,
    source: getSource(initialSelectedMarket, initialSelectedList),
  });
  const [meta, setMeta] = useState<Meta>({
    selectedMarket: initialSelectedMarket,
    selectedList: initialSelectedList,
    selectedSector: initialSelectedSector,
  });

  useEffect(() => {
    if (onLoad) {
      onLoad(spec, meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onLoad]);

  const getSelectedColumns = (columns: string) => {
    if (!columns) return [];
    return columns
      .split(',')
      .map((col) => options.find((opt) => opt.value === col))
      .filter(Boolean);
  };

  const handleMarketChange = (selectedMarket: Market) => {
    const { selectedList } = meta;
    /**
     * When the selected market changes, so does the available options for list selection.
     * This function tries to find the best new option
     */
    const getNewSelectedList = () => {
      if (!selectedMarket?.lists) return undefined;

      /**
       * If there's already a selected list we need to check if the new market
       * also has that list.
       * I.e: If tegningsretter is selected for Oslo Børs it should still
       * be selected when changing market to Euronext Expand
       */
      if (selectedList) {
        const selectedListInMarket = selectedMarket.lists.find(
          (list) => list.value === selectedList.value,
        );
        if (selectedListInMarket) return selectedListInMarket;
      }

      /**
       * When there's no existing list selection, we want to return the default list for the market.
       * If no default is found, return the first option.
       */
      return (
        selectedMarket.lists.find((list) => list.selected) ||
        selectedMarket.lists[0]
      );
    };

    const newSelectedList = getNewSelectedList();

    setSpec((spec) => ({
      ...spec,
      source: getSource(selectedMarket, newSelectedList),
    }));
    setMeta((meta) => ({
      ...meta,
      selectedMarket,
      selectedList: newSelectedList,
      selectedSector: undefined,
    }));
  };

  const handleListChange = (selectedList: List) => {
    setSpec((spec) => ({
      ...spec,
      source: getSource(selectedMarket, selectedList),
    }));
    setMeta((meta) => ({ ...meta, selectedList }));
  };

  const handleSectorChange = (selectedSector: Sector) => {
    const { selectedMarket } = meta;
    setSpec((spec) => ({
      ...spec,
      // TODO This will overwrite implementation provided filters
      filter: getSectorFilter(selectedMarket, selectedSector.value),
    }));
    setMeta((meta) => ({
      ...meta,
      selectedSector,
    }));
  };

  const handleColumnsChange = (columns: { value: string }[]) => {
    const alwaysInSpec = 'ITEM,LONG_NAME,ITEM_SECTOR,SECTOR';
    const extraColumns = `${alwaysInSpec},${columns
      ?.map((column) => column.value)
      ?.join(',')}`;
    setSpec((spec) => ({
      ...spec,
      columns: columns.length ? extraColumns : alwaysInSpec,
    }));
  };

  useEffect(() => {
    if (onSpecChange) {
      onSpecChange(spec, meta);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spec, meta]);

  const { selectedMarket, selectedList, selectedSector } = meta;

  return (
    <I18n>
      {({ i18n }: { i18n: I18nCore }) => {
        const i18nToString = (message: MessageDescriptor): string => {
          if (message) {
            return i18n ? i18n._(message) : message.id;
          }
          return '';
        };
        const selectText = t`Select`;
        return (
          <Box as="form" mb={4} className={className}>
            <Wrap gap={4}>
              {!!selectedPreset?.markets?.length && (
                <Box flex="1 0 16rem">
                  <RenderField
                    name="market"
                    label={<Trans>Market</Trans>}
                    as={Select}
                    value={selectedMarket}
                    items={(selectedPreset.markets as unknown) as any}
                    onChange={handleMarketChange}
                    className="MarketSelect"
                  />
                </Box>
              )}
              {!!selectedPreset?.selections?.length && (
                <Box flex="1 0 16rem">
                  <RenderField
                    name="Selection"
                    label={<Trans>Selection</Trans>}
                    as={Select}
                    value={selectedMarket}
                    items={(selectedPreset.markets as unknown) as any}
                    onChange={handleMarketChange}
                    className="MarketSelect"
                  />
                </Box>
              )}
              {!!selectedMarket?.lists?.length && (
                <Box flex="1 0 16rem">
                  <RenderField
                    name="Selection"
                    label={<Trans>Selection</Trans>}
                    as={Select}
                    value={selectedList}
                    items={(selectedMarket.lists as unknown) as any}
                    onChange={handleListChange}
                    className="ListSelect"
                  />
                </Box>
              )}
              {!!selectedMarket?.sectors?.length && (
                <Box flex="1 0 16rem">
                  <RenderField
                    name="Sector"
                    label={<Trans>Sector</Trans>}
                    as={Select}
                    value={selectedSector}
                    items={(selectedMarket.sectors as unknown) as any}
                    onChange={handleSectorChange}
                    className="SectorSelect"
                  />
                </Box>
              )}
            </Wrap>
            <Box mt={4}>
              <RenderField
                name="Columns"
                label={<Trans>Columns</Trans>}
                as={Combobox}
                placeholder={i18n ? i18n._(selectText) : selectText.id}
                isMulti
                value={getSelectedColumns(spec.columns)}
                items={options}
                itemToString={(item: any) => i18nToString(item?.label)}
                onChange={handleColumnsChange}
                className="ColumnsSelect"
              />
            </Box>
          </Box>
        );
      }}
    </I18n>
  );
};
