import React from 'react';
import ReactDOM from 'react-dom';
import { Trans } from '@lingui/macro';
import { MenuItemHTMLProps } from '@oms/ui-menu-bar';
import { Icon, light } from '@oms/ui-icon';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { useStockChartController } from '../StockChartController';
import {
  useStockChartContainerNode,
  useStockChartContainerSize,
} from '../StockChartContainer';
import {
  useSmartSearchState,
  SmartSearchDisclosure,
  SmartSearchDialog,
} from '@oms/components-smart-search';

interface ComparisonsProps extends MenuItemHTMLProps {
  /** The group that will be used to fetch possible matches */
  searchGroup?: string;
}

export const Comparisons = React.forwardRef(
  (props: ComparisonsProps, ref: React.Ref<any>) => {
    const controller = useStockChartController();
    const dialog = useSmartSearchState({ modal: false });
    const node = useStockChartContainerNode();
    const { containerWidth } = useStockChartContainerSize();
    return (
      <>
        <SmartSearchDisclosure ref={ref} {...dialog} {...props} as="button">
          <Icon icon={light.faPlusCircle} color="inherit" />
          {containerWidth === 'sm' ? (
            <VisuallyHidden>
              <Trans>Compare</Trans>
            </VisuallyHidden>
          ) : (
            <Trans>Compare</Trans>
          )}
        </SmartSearchDisclosure>
        {node
          ? ReactDOM.createPortal(
              <SmartSearchDialog
                group={props?.searchGroup}
                dialogState={dialog}
                onChange={(change) => {
                  const payload = {
                    itemSector: change.ITEM_SECTOR!,
                    ticker: change.ITEM!,
                    name: change.LONG_NAME!,
                    instrumentType: change.TYPE!,
                  };
                  if (
                    'ITEM_SECTOR' in change &&
                    typeof change.ITEM_SECTOR === 'string'
                  ) {
                    controller.addComparison(payload);
                  }
                }}
                // TODO Translate
                aria-label="Add comparisons"
                unstable_orphan={true}
              />,
              node,
            )
          : null}
      </>
    );
  },
);
