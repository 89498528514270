import React, { PropsWithChildren } from 'react';
import { Trans } from '@lingui/macro';
import flattenChildren from 'react-flatten-children';
import {
  useMenuState,
  Menu,
  MenuButton,
  MenuItemCheckbox,
  MenuSeparator,
  MenuItemHTMLProps,
} from '@oms/ui-menu-bar';
import { Icon, light } from '@oms/ui-icon';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { useStockChartController } from '../StockChartController';
import { useStockChartContainerSize } from '../StockChartContainer';

interface AdditionsProps extends MenuItemHTMLProps {
  children?: JSX.Element;
}

export const Additions = React.forwardRef(
  (
    { children, ...props }: PropsWithChildren<AdditionsProps>,
    ref: React.Ref<any>,
  ) => {
    const menu = useMenuState();
    const controller = useStockChartController();
    const { containerWidth } = useStockChartContainerSize();

    return (
      <>
        <MenuButton ref={ref} {...menu} {...props}>
          <Icon icon={light.faStars} color="inherit" />
          {containerWidth === 'sm' ? (
            <VisuallyHidden>
              <Trans>Additions</Trans>
            </VisuallyHidden>
          ) : (
            <Trans>Additions</Trans>
          )}
        </MenuButton>
        <Menu {...menu} aria-label="Additions">
          <MenuItemCheckbox
            {...menu}
            name="additions"
            value="news"
            checked={controller?.isChecked('additions', 'news')}
            onClick={() => {
              controller?.toggleAddition('news');
            }}
          >
            <Trans>News</Trans>
          </MenuItemCheckbox>
          <MenuItemCheckbox
            {...menu}
            name="additions"
            value="dividends"
            checked={controller?.isChecked('additions', 'dividends')}
            onClick={() => {
              controller?.toggleAddition('dividends');
            }}
          >
            <Trans>Dividends</Trans>
          </MenuItemCheckbox>
          <MenuSeparator {...menu} />
          <MenuItemCheckbox
            {...menu}
            name="additions"
            value="turnover"
            checked={controller?.isChecked('additions', 'turnover')}
            onClick={() => {
              controller?.toggleAddition('turnover');
            }}
          >
            <Trans>Turnover</Trans>
          </MenuItemCheckbox>
          <MenuItemCheckbox
            {...menu}
            name="additions"
            value="volume"
            checked={controller?.isChecked('additions', 'volume')}
            onClick={() => {
              controller?.toggleAddition('volume');
            }}
          >
            <Trans>Volume</Trans>
          </MenuItemCheckbox>
          {children ? <MenuSeparator {...menu} /> : null}
          {children
            ? React.Children.map(flattenChildren(children), (child: any) => {
                if (typeof child === 'object' && child.type === Addition) {
                  return (
                    <MenuItemCheckbox
                      key={child.props.value}
                      {...menu}
                      name={child.props.value}
                      value={child.props.value}
                      checked={controller?.isChecked(
                        'additions',
                        child.props.value,
                      )}
                      onClick={() => {
                        controller?.toggleAddition(child.props.value);
                      }}
                    >
                      {child.props.children}
                    </MenuItemCheckbox>
                  );
                }

                return null;
              })
            : null}
        </Menu>
      </>
    );
  },
);

interface AdditionProps {
  value: string;
  children: string;
}

export function Addition({
  value,
  children,
}: AdditionProps): JSX.Element | null {
  throw new Error(`Can't use Addition outside of the additions prop.`);
  // eslint-disable-next-line no-unreachable
  return null;
}
