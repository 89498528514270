import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useComponentsContext } from '@oms/components-config-context';
import { logger } from '@oms/utils';
import { t } from '@lingui/macro';

import jawsHelper from './jawsHelper';
import {
  buildURL,
  getSeries,
  getSpaceFromSelection,
  getSeriesFromSelection,
  getBreaks,
  Break,
  getRequestInit,
} from './utils';
import {
  SELECTION,
  PERIODS,
  MOMENT_PERIODS,
  BAR_SERIES,
  POINTS,
  RECOMMENDATION_TYPES,
  TYPE_SELL,
  SELECTION_INTRADAY,
} from './constants';
import { I18n } from '@lingui/core';
import { withI18n } from '@lingui/react';

const log = logger('AnalysisChartFetcher');

function getSearchParams(url: string) {
  if (url) {
    const paramsUrl = new URL('http:' + url);
    const searchParams = new URLSearchParams(paramsUrl.search);
    searchParams.delete('period');
    searchParams.delete('points');
    return searchParams;
  }
}

const sortByX = (a: { x: number }, b: { x: number }) => a.x - b.x;
function invalidateEntries<T>(object: {
  [key: string]: T | undefined;
}): { [key: string]: T } {
  return Object.entries(object).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: {
        ...value,
        stale: true,
      },
    }),
    {},
  );
}

/**
 * An error that is thrown in in the context of data fetching for the component
 */
export class FetchError extends Error {
  componentName: string;
  method: string;
  response: any;

  constructor({
    message,
    method,
    response,
  }: {
    message: string;
    method: string;
    response?: any;
  }) {
    // Pass remaining arguments (including vendor specific ones) to parent
    // constructor
    super(message);

    // Maintains proper stack trace for where our error was thrown (only
    // available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.componentName = 'AnalysisDataFetcher';
    this.message = message;
    this.method = method;
    this.response = response;
  }
}

export interface UseInstrumentChartArguments {
  itemSector: string;
  labels: {
    candlestick: string;
    news: string;
    dividends: string;
    volume: string;
    turnover: string;
    transactions: string;
  };
  selection?: SELECTION;
  transactionsParams?: { [key: string]: string };
  newsSpec?: { [key: string]: string };
  dividendsSpec?: { [key: string]: string };
}

export interface Addition {
  name: string;
  active: boolean;
  stale: boolean;
  flags: boolean;
  color: string;
  requireLogin?: boolean;
}

export interface Compare {
  stale: boolean;
  instrument?: {
    itemSector: string;
    item: string;
    sector: string;
  };
}

export type TechnicalIndicatorType =
  | 'sma'
  | 'ema'
  | 'wma'
  | 'envelope'
  | 'bb'
  | 'stochastic';

export interface TechnicalIndicator {
  stale: boolean;
  indicator: TechnicalIndicatorType;
  interval: string;
}

export interface Main {
  stale: boolean;
  instrument?: {
    itemSector: string;
    item: string;
    sector: string;
  };
  series?: {
    key: string;
    type: string;
    data: [number, number][];
    columns: any;
  };
}

export type Compares = Record<string, Compare>;

export type TechnicalIndicatorsType = {
  [key in TechnicalIndicatorType]?: TechnicalIndicator;
};

export type Additions = Record<string, Addition>;

export interface UseInstrumentChartReturn {
  /** The main series that is currently selected */
  main: Main;
  /** Any breaks in the series, for example used to prune data when the exchange is closed */
  breaks: Break[];
  /** Any instruments that are used to compare with the main instrument */
  compares: Compares;
  /** Indicators used to analyze the main instrument using a set of algorigthms */
  technicalIndicators: TechnicalIndicatorsType;
  /** Any other data added to the chart, like news and trades */
  additions: Additions;
  /** Fetch and add an instrument to compare the main series with */
  addCompare: (itemSector: string) => void;
  /** Calculate and add an indicator to analyze the main series */
  addTechnicalIndicator: (
    indicator: TechnicalIndicatorType,
    interval: string,
  ) => void;
  /** Fetch and add an addition to the main series, like news and trades */
  activateAddition: (key: string) => void;
  /** Removes the given compare from the chart */
  removeCompare: (key: string) => void;
  /** Removes the given technical indicator from the chart */
  removeTechnicalIndicator: (key: TechnicalIndicatorType) => void;
  /** Removes the given addition from the chart */
  removeAddition: (key: string) => void;
  /** The chart is currently loading the main series */
  loading: boolean;
  refetch: () => void;
}

export const useInstrumentChart = ({
  itemSector,
  labels,
  selection = SELECTION_INTRADAY,
  transactionsParams,
  newsSpec,
  dividendsSpec,
}: UseInstrumentChartArguments): UseInstrumentChartReturn => {
  const {
    domainUrl,
    transactionsUrl,
    graphdataUrl,
    jawsUrl,
    baseUrl,
  } = useComponentsContext();
  const [main, setMain] = useState<Main>({
    stale: true,
    instrument: undefined,
    series: undefined,
  });
  const [compares, setCompares] = useState<Compares>({});
  const [breaks, setBreaks] = useState<Break[]>([]);
  const [technicalIndicators, setTechnicalIndicators] = useState<
    TechnicalIndicatorsType
  >({});
  // TODO this needs to change to be an uncontrolled prop to work well with InstrumentChart
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [additions, setAdditions] = useState<Additions>({
    candlestick: {
      name: labels.candlestick,
      active: false,
      stale: true,
      flags: false,
      color: '#008763',
    },
    news: {
      name: labels.news,
      active: false,
      stale: true,
      flags: true,
      color: '#00BD3C',
    },
    dividends: {
      name: labels.dividends,
      active: false,
      stale: true,
      flags: true,
      color: '#FF7738',
    },
    volume: {
      name: labels.volume,
      active: false,
      stale: true,
      flags: false,
      color: '#38C0FF',
    },
    turnover: {
      name: labels.turnover,
      active: false,
      stale: true,
      flags: false,
      color: '#FA4F00',
    },
    // TODO Does not work atm
    transactions: {
      name: labels.transactions,
      active: false,
      stale: true,
      flags: true,
      color: '#00ABFA',
      requireLogin: true,
    },
  });

  useEffect(() => {
    log('Run initialize');
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    log('itemSector changed. Is now', itemSector);

    if (itemSector) {
      handleItemSectorChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemSector]);

  useEffect(() => {
    log('main, compares or additions changed. Is now', {
      main,
      compares,
      additions,
    });

    if (main?.instrument) {
      try {
        fetchDataWhereNeeded();
      } catch (error) {
        setError(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [main, compares, additions]);

  useEffect(() => {
    log('selection changed. Is now', selection);

    try {
      invalidateAll();
    } catch (error) {
      setError(error);
    }
  }, [selection]);

  const handleItemSectorChange = async () => {
    try {
      await removeAllTechnicalIndicators();
      await invalidateAll();
      await initialize();
    } catch (error) {
      setError(error);
    }
  };

  const initialize = async () => {
    try {
      await fetchInstrumentMetadata(itemSector);
    } catch (error) {
      setError(error);
    }
  };

  const fetchInstrumentMetadata = async (passedItemSector: string) => {
    const isMain = passedItemSector === itemSector;
    const url = buildURL(domainUrl, {
      baseUrl,
      query: { itemSector: passedItemSector },
    });

    const result = await fetch(
      url,
      getRequestInit({
        credentials: 'include',
      }),
    );

    if (!result.ok) {
      const errorText = await result.text();
      throw new FetchError({
        message: `Failed to fetch instrument metadata for ${passedItemSector}. ${errorText}`,
        method: 'fetchInstrumentMetadata',
        response: result,
      });
    }

    const instrument = (await result.json())[0];

    if (isMain) {
      setMain((main: Main) => ({
        ...main,
        instrument,
        stale: true,
      }));
    } else {
      setCompares((compares: Compares) => ({
        ...compares,
        [passedItemSector]: {
          ...compares[passedItemSector],
          instrument,
          stale: true,
        },
      }));
    }
  };

  const fetchGraphdata = async (itemSector: string) => {
    const isMain = itemSector === main.instrument?.itemSector;

    const url = buildURL(graphdataUrl, {
      baseUrl,
      itemSector: encodeURI(itemSector),
      space: getSpaceFromSelection(selection),
      series: getSeriesFromSelection(selection),
      query: {
        period: PERIODS[selection],
        points: POINTS[selection],
      },
    });

    const result = await fetch(
      url,
      getRequestInit({
        credentials: 'include',
      }),
    );

    if (!result.ok) {
      throw new FetchError({
        message: `Fetching graphdata failed. ${await result.text()}`,
        method: 'fetchGraphdata',
        response: result,
      });
    }

    const { series, buckets } = await getSeries({
      data: await result.json(),
      searchParams: getSearchParams(url),
    });
    const breaks = getBreaks(buckets);

    if (isMain) {
      setBreaks(breaks);
      setMain((mainState: Main) => ({
        ...mainState,
        series: series[0],
        stale: false,
      }));
    } else {
      setBreaks(breaks);
      setCompares((comparesState: Compares) => ({
        ...comparesState,
        [itemSector]: {
          ...comparesState[itemSector],
          series: series[0],
          stale: false,
        },
      }));
    }

    Object.values(technicalIndicators)
      .filter((item) => item?.stale)
      .forEach((item) => {
        if (!item) return;
        const { indicator, interval } = item;
        addTechnicalIndicator(indicator, interval);
      });
  };

  const getStopValue = () => {
    if (selection === 'SELECTION_INTRADAY') return 'now';
    return moment()
      .subtract({ days: 1 })
      .endOf('day')
      .valueOf();
  };

  const fetchNews = async () => {
    const { item, sector } = main.instrument || {};

    const result = await jawsHelper({
      initiatorComponent: 'AnalysisDataFetcher',
      component: 'analysisChartNews',
      urlTemplate: jawsUrl,
      baseUrl,
      itemSector: `${item}.${sector}`,
      start: moment()
        .subtract(MOMENT_PERIODS[selection])
        .startOf('day')
        .valueOf(),
      stop: getStopValue(),
      ...newsSpec,
    });

    setAdditions((additions: Additions) => ({
      ...additions,
      news: {
        ...additions.news,
        stale: false,
        series: {
          data: result.rows
            .map((row: { values: object }) => ({
              ...row.values,
              infoKey: 'news',
              title: 'N', // Shown on flag in chart
            }))
            .reverse(),
        },
      },
    }));
  };

  const fetchCompanyReports = async () => {
    const { item } = main.instrument || {};

    const { rows } = await jawsHelper({
      initiatorComponent: 'AnalysisDataFetcher',
      urlTemplate: jawsUrl,
      baseUrl,
      type: 'history',
      source: 'feed.hb.companyreports.EQUITIES',
      columns: 'TITLE as text, UPLOAD_TIME as x, RECOMMENDATION_TYPE as type',
      filter: `COMPANY_TICKER==s${item}`,
      space: 'TICK',
      start: moment()
        .subtract(MOMENT_PERIODS[selection])
        .startOf('day')
        .valueOf(),
      stop: getStopValue(),
    });

    if (!rows || !rows.length) return;

    setAdditions((additions: Additions) => ({
      ...additions,
      companyreport: {
        ...additions.companyreport,
        stale: false,
        series: {
          data: rows
            .map((row: { values: { type: 1 | 2 | 4 | 5 } }) => ({
              ...row.values,
              infoKey: 'companyreport',
              title: RECOMMENDATION_TYPES[row.values.type][0].toUpperCase(),
            }))
            .sort(sortByX),
        },
      },
    }));
  };

  const fetchDividends = async () => {
    const { item, sector } = main.instrument || {};

    const { rows } = await jawsHelper({
      initiatorComponent: 'AnalysisDataFetcher',
      component: 'analysisChartDividends',
      urlTemplate: jawsUrl,
      baseUrl,
      itemSector: `${item}.${sector}`,
      start: moment()
        .subtract(MOMENT_PERIODS[selection])
        .startOf('day')
        .valueOf(),
      stop: getStopValue(),
      ...dividendsSpec,
    });

    if (!rows || !rows.length) return;

    setAdditions((additions: Additions) => ({
      ...additions,
      dividends: {
        ...additions.dividends,
        stale: false,
        series: {
          data: rows
            .map(
              (row: {
                values: { x: number; text: string; currency: string };
              }) => ({
                ...row.values,
                infoKey: 'dividends',
                title: 'D', // Shown on flag in chart
                x: moment(row.values.x, 'YYYYMMDD').valueOf(),
                text: row.values.text,
                currency: row.values.currency,
              }),
            )
            .reverse(),
        },
      },
    }));
  };

  const fetchVolume = () => fetchBars('volume');

  const fetchTurnover = () => fetchBars('turnover');

  const fetchBars = async (key: 'turnover' | 'volume') => {
    const { itemSector } = main.instrument || {};

    const url = buildURL(graphdataUrl, {
      baseUrl,
      itemSector,
      space: getSpaceFromSelection(selection),
      series: `(${BAR_SERIES[key]})`,
      query: {
        period: PERIODS[selection],
        points: POINTS[selection],
      },
    });

    const result = await fetch(
      url,
      getRequestInit({
        credentials: 'include',
      }),
    );

    if (!result.ok) {
      throw new FetchError({
        message: `Fetching bars failed. ${await result.text()}`,
        method: 'fetchBars',
        response: result,
      });
    }

    const { series } = await getSeries({
      data: await result.json(),
      searchParams: getSearchParams(url),
    });

    setAdditions((additions: Additions) => ({
      ...additions,
      [key]: {
        ...additions[key],
        stale: false,
        series: series[0],
      },
    }));
  };

  const fetchCandleStick = async () => {
    const { itemSector } = main.instrument || {};

    const url = buildURL(graphdataUrl, {
      baseUrl,
      itemSector,
      space: 'day',
      series: '(OPEN,HIGH,LOW,CLOSE_CA)',
      query: {
        period: PERIODS[selection],
        points: POINTS[selection],
      },
    });

    const result = await fetch(
      url,
      getRequestInit({
        credentials: 'include',
      }),
    );

    if (!result.ok) {
      throw new FetchError({
        message: `Fetching candlesticks failed. ${await result.text()}`,
        method: 'fetchCandleStick',
        response: result,
      });
    }

    const { series } = await getSeries({
      data: await result.json(),
      type: 'candlestick',
      searchParams: getSearchParams(url),
    });

    setAdditions((additions: Additions) => ({
      ...additions,
      candlestick: {
        ...additions.candlestick,
        stale: false,
      },
    }));
    setMain((mainState: Main) => ({
      ...mainState,
      series: series[0],
    }));
  };

  const fetchTransactions = async () => {
    const { item, sector } = main.instrument || {};

    const url = buildURL(transactionsUrl, {
      baseUrl,
      backend: 'probroker',
      instrumentId: `${sector}:${item}`,
      fromDate: moment()
        .subtract(MOMENT_PERIODS[selection])
        .format('YYYY-MM-DD'),
      settledStatus: '0,1,7',
      ...transactionsParams,
    });

    const result = await fetch(
      url,
      getRequestInit({
        credentials: 'include',
      }),
    );

    if (!result.ok) {
      throw new FetchError({
        message: `Fetching transactions failed. ${await result.text()}`,
        method: 'fetchTransactions',
        response: result,
      });
    }

    type Row = { type: 0 | 1; tradeTime: number; volume: number };
    let rows: Row[];
    try {
      rows = await result.json();
    } catch (error) {
      throw new FetchError({
        message: `Parsing transactions data failed`,
        method: 'fetchTransactions',
        response: result,
      });
    }

    setAdditions((additions: Additions) => ({
      ...additions,
      transactions: {
        ...additions.transactions,
        stale: false,
        series: {
          data: rows
            .map((row: Row) => ({
              infoKey: 'transactions',
              title: row.type === TYPE_SELL ? 'S' : 'B',
              x: row.tradeTime,
              text: row.volume,
            }))
            .reverse(),
        },
      },
    }));
  };

  const fetchCompares = () =>
    Object.values(compares)
      .filter((item) => item.stale)
      .map(async (item) => {
        if (!item.instrument) return Promise.resolve();
        try {
          await fetchGraphdata(item.instrument?.itemSector);
        } catch (error) {
          setError(error);
        }
      });

  const fetchAdditions = () => {
    return Object.entries(additions)
      .filter(([_, addition]) => addition.active && addition.stale)
      .map(([key]) => {
        switch (key) {
          case 'news':
            return fetchNews();
          case 'companyreport':
            return fetchCompanyReports();
          case 'dividends':
            return fetchDividends();
          case 'volume':
            return fetchVolume();
          case 'turnover':
            return fetchTurnover();
          case 'transactions':
            return fetchTransactions();
          case 'candlestick':
            return fetchCandleStick();
          default:
            throw new FetchError({
              message: `The addition type ${key} is not supported!`,
              method: 'fetchAdditions',
            });
        }
      });
  };

  const activateAddition = (key: string) => {
    setAdditions((additions: Additions) => ({
      ...additions,
      [key]: {
        ...additions[key],
        active: true,
        stale: true,
      },
    }));
    fetchDataWhereNeeded();
  };

  const addTechnicalIndicator = (
    indicator: TechnicalIndicatorType,
    interval: string,
  ) => {
    // We defer calculating indicators to Highstock
    setTechnicalIndicators((technicalIndicators: TechnicalIndicatorsType) => ({
      ...technicalIndicators,
      [indicator]: {
        ...technicalIndicators[indicator],
        indicator,
        interval,
      },
    }));
  };

  const addCompare = async (itemSector: string) => {
    try {
      await fetchInstrumentMetadata(itemSector);
    } catch (error) {
      setError(error);
    }
  };

  const removeCompare = (key: string) => {
    setCompares(({ [key]: ignored, ...compares }) => compares);
  };

  const removeAddition = (key: string) => {
    setAdditions((additions: Additions) => ({
      ...additions,
      [key]: {
        ...additions[key],
        active: false,
      },
    }));

    if (key === 'candlestick') {
      setMain((mainState: Main) => ({
        ...mainState,
        stale: true,
      }));
    }
  };

  const removeTechnicalIndicator = (key: TechnicalIndicatorType) => {
    setTechnicalIndicators(
      ({ [key]: ignored, ...technicalIndicators }) => technicalIndicators,
    );
  };

  const removeAllTechnicalIndicators = async () => setTechnicalIndicators({});

  const invalidateAll = async () => {
    setMain((mainState: Main) => ({
      ...mainState,
      stale: true,
    }));
    setCompares((comparesState: Compares) =>
      invalidateEntries<Compare>(comparesState),
    );
    setTechnicalIndicators((technicalIndicators: TechnicalIndicatorsType) =>
      invalidateEntries<TechnicalIndicator>(technicalIndicators),
    );
    setAdditions((additions: Additions) =>
      invalidateEntries<Addition>(additions),
    );
  };

  const fetchDataWhereNeeded = async () => {
    try {
      if (!main || !main.instrument) {
        throw new FetchError({
          message: 'Invalid main instrument',
          method: 'fetchDataWhereNeeded',
        });
      }

      setLoading(true);

      // Fetch all data in parallel
      await Promise.all([
        main.stale ? fetchGraphdata(main.instrument.itemSector) : null,
        ...fetchCompares(),
        ...fetchAdditions(),
      ]);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  if (error) throw error;

  return {
    main,
    breaks,
    compares,
    technicalIndicators,
    additions,
    loading,
    addTechnicalIndicator,
    addCompare,
    activateAddition,
    removeCompare,
    removeAddition,
    removeTechnicalIndicator,
    refetch: fetchDataWhereNeeded,
  };
};

export interface AnalysisDataFetcherProps {
  children: (data: UseInstrumentChartReturn) => React.ReactNode;
  itemSector: string;
  i18n?: I18n;
  selection?: SELECTION;
  transactionsParams?: Record<string, string>;
  newsSpec?: Record<string, string>;
  dividendsSpec?: Record<string, string>;
}

const InstrumentChartFetcher = ({
  children,
  itemSector,
  i18n,
  selection = SELECTION_INTRADAY,
  transactionsParams = {},
  newsSpec = {},
  dividendsSpec = {},
}: AnalysisDataFetcherProps) => {
  const candlestickLabel = t`Candlestick`;
  const newsLabel = t`News`;
  const dividendsLabel = t`Dividends`;
  const volumeLabel = t`Volume`;
  const turnoverLabel = t`Turnover`;
  const transactionsLabel = t`Transactions`;

  const data = useInstrumentChart({
    itemSector,
    labels: {
      candlestick: i18n ? i18n._(candlestickLabel) : candlestickLabel.id,
      news: i18n ? i18n._(newsLabel) : newsLabel.id,
      dividends: i18n ? i18n._(dividendsLabel) : dividendsLabel.id,
      volume: i18n ? i18n._(volumeLabel) : volumeLabel.id,
      turnover: i18n ? i18n._(turnoverLabel) : turnoverLabel.id,
      transactions: i18n ? i18n._(transactionsLabel) : transactionsLabel.id,
    },
    selection,
    transactionsParams,
    newsSpec,
    dividendsSpec,
  });

  return <>{children(data)}</>;
};

const WrappedInstrumentChartFetcher = (withI18n()(
  InstrumentChartFetcher,
) as unknown) as (props: Omit<AnalysisDataFetcherProps, 'i18n'>) => JSX.Element;
export { WrappedInstrumentChartFetcher as InstrumentChartFetcher };
