import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Loading, NoData } from '@oms/components-core';
import { Abbr } from '@oms/components-typography';
import {
  InteractiveList,
  InteractiveListHead,
  InteractiveListBody,
  InteractiveListRow,
  InteractiveListHeadingCell,
  InteractiveListCell,
} from '@oms/ui-interactive-list';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import { Spec } from '@oms/jaws-react';
import { useTickers } from './useTickers';
import SparklineGraph, { SparklineGraphProps } from './SparklineGraph';
import { ParentSize } from '@visx/responsive';
import { Box } from '@oms/ui-box';

interface TickerTableProps {
  /** A string of comma-separated itemSectors for the instruments to show data for */
  itemSector?: string;
  /**
   * A spec to override the default values of the component.
   * @see See the [TickersFetcher](/#!/TickersFetcher) component for options.
   */
  spec?: Spec;
  /** A function that is called every time a row is clicked/selected. Returns the item sector of the selected item */
  onSelect?: (item: string) => void;
  /** Will be passed to the wrapping element */
  className?: string;
  /** Show spark lines as last column */
  showSparklines?: boolean;
  /** Options passed along to SparklineGraph */
  sparklineOptions?: SparklineGraphProps['options'];
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /** Itemsector of the initial selected instrument */
  initialSelected?: string;
}

/* eslint-disable react/no-array-index-key */
/**
 * A interactive list component that lists a set of instruments.
 * Renders as a list of radio buttons when the onSelect prop is provided, else it renders a list of links.
 * Works well as a sidebar of [InstrumentChart](/#!/InstrumentChart).
 *
 * @since 1.1.0
 */
export const TickerTable = ({
  className = 'TickerTable',
  itemSector = 'OSEBX.OSE, OBX.OSE, OMXS30.OMX, OMXN40.OMX, I:DAX.GER, I:N100.EUR, I:DJI.DJ, I:COMP.NAS, I:SP500.SP, I:HSI.HSI, NI225.OSA',
  onSelect,
  spec,
  showSparklines = false,
  sparklineOptions,
  disableLoading = false,
  initialSelected = 'OSEBX.OSE',
}: TickerTableProps) => {
  const { data, initialized, resource, emptyData } = useTickers({
    itemSector,
    spec: { initiatorComponent: 'TickerTable', ...spec },
  });

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No instruments found`} />;
  }

  return (
    <>
      <VisuallyHidden id="ticker-list">
        <Trans>Significant instruments</Trans>
      </VisuallyHidden>
      <InteractiveList
        className={className}
        aria-labelledby="ticker-list"
        onSelect={onSelect}
        initialSelected={initialSelected}
      >
        <InteractiveListHead>
          <InteractiveListRow>
            <InteractiveListHeadingCell type="text">
              <Trans>Name</Trans>
            </InteractiveListHeadingCell>
            <InteractiveListHeadingCell type="number">
              <Trans>Last</Trans>
            </InteractiveListHeadingCell>
            <InteractiveListHeadingCell type="percent">
              <Abbr title={t`Change in percent`}>
                <Trans>+/-%</Trans>
              </Abbr>
            </InteractiveListHeadingCell>
            {showSparklines && <InteractiveListHeadingCell children="" />}
          </InteractiveListRow>
        </InteractiveListHead>
        <InteractiveListBody>
          {Object.entries(data).map(([key, values]: any) => {
            const { LONG_NAME, LAST, CHANGE_PCT, ITEM_SECTOR } = values;
            return (
              <InteractiveListRow
                key={key}
                data-testid={`row-${LONG_NAME}`}
                value={ITEM_SECTOR}
              >
                <InteractiveListCell type="text" verticalAlign="middle">
                  {LONG_NAME}
                </InteractiveListCell>
                <InteractiveListCell type="number" verticalAlign="middle">
                  {LAST}
                </InteractiveListCell>
                <InteractiveListCell
                  type="percent"
                  trend
                  verticalAlign="middle"
                >
                  {CHANGE_PCT}
                </InteractiveListCell>
                {showSparklines && (
                  <InteractiveListCell
                    className="SparklineCell"
                    verticalAlign="middle"
                    height="60px"
                  >
                    <Box
                      className="SparklineContainer"
                      height="100%"
                      maxHeight="40px" // 60px height - 20px padding
                      center
                    >
                      <ParentSize>
                        {({ width, height }) => (
                          <SparklineGraph
                            width={width}
                            height={height}
                            itemSector={ITEM_SECTOR}
                            options={sparklineOptions}
                          />
                        )}
                      </ParentSize>
                    </Box>
                  </InteractiveListCell>
                )}
              </InteractiveListRow>
            );
          })}
        </InteractiveListBody>
      </InteractiveList>
    </>
  );
};

export default TickerTable;
