import React, { forwardRef, useRef } from 'react';
import {
  defaultItemToString,
  RenderItemProps as SelectRenderItemProps,
} from '@oms/ui-select';
import { Icon, light } from '@oms/ui-icon';
import { useForkRef } from '@oms/ui-utils';
import { HighlightedText as Highlighter } from '@oms/ui-highlighted-text';
import useDoubleClick from 'use-double-click';

export interface SearchDialogRenderItemProps extends SelectRenderItemProps {
  inputValue?: string;
  HighlightedText?: typeof Highlighter;
  item: any;
  onPreview: React.Dispatch<any>;
  onClick: any;
  hasPreview: boolean;
}

export const RenderItem = forwardRef(function RenderItem(
  {
    item,
    inputValue,
    isHighlighted,
    isSelected,
    itemToString = defaultItemToString,
    onPreview,
    onClick,
    ItemContainer,
    HighlightedText = Highlighter,
    hasPreview,
    ...props
  }: SearchDialogRenderItemProps,
  ref: React.Ref<any>,
) {
  const innerRef = useRef<any>(null);
  const forkedRef = useForkRef(ref, innerRef);

  useDoubleClick({
    ref: innerRef,
    latency: 300,
    onSingleClick: hasPreview ? () => onPreview(item) : onClick,
    onDoubleClick: hasPreview ? onClick : undefined,
  });

  if (!itemToString) {
    return null;
  }
  return (
    <ItemContainer
      ref={forkedRef}
      {...props}
      data-highlighted={isHighlighted}
      data-selected={isSelected}
    >
      <HighlightedText search={inputValue || ''} autoEscape={true}>
        {itemToString(item)}
      </HighlightedText>
      {isSelected && !isHighlighted && <Icon ml="auto" icon={light.faCheck} />}
      {isHighlighted && hasPreview && (
        <Icon
          ml="auto"
          size="lg"
          mr={2}
          icon={light.faLevelDown}
          rotation={90}
        />
      )}
    </ItemContainer>
  );
});
