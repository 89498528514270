import React from 'react';
import { isMarkup, sanitize } from '@oms/utils';
import { Heading } from '@oms/ui-heading';
import { Text } from '@oms/ui-text';
import { Box } from '@oms/ui-box';
import { Separator } from '@oms/ui-separator';
import { Link } from '@oms/ui-link';
import { Icon, light } from '@oms/ui-icon';

import { Trans } from '@lingui/macro';

import * as S from './styles';

/**
 * TODO
 * 1) Should this not be !text?, and is the default assignment (headline = '') not going to render this useless?
 * 2) Create pre variant in oms/ui-text;
 * 3) Links can be used without the to prop
 * 4) Links and Buttons should accepts the width prop (or all system props)
 * 5) Separators should accept margin/system props
 */

interface NewsArticleProps {
  /** The component to use when rendering out a heading */
  headingElement?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  headline?: string;
  linkTo?: any;
  /**
   * The body of the text. If it is markup it will be sanitized and rendered as
   * HTML
   */
  text?: string;
  /**
   * An optional URL. If provided it will be rendered as a clickable link below
   * the heading
   */
  url?: string;
}

/**
 * The article section of the `NewsArchive` component. This component renders
 * a readable article using semantic markup. When HTML is passed into the `text`
 * prop it will be sanitized and rendered out as HTML.
 *
 * Note the visually hidden button at the end of the article that enables a
 * keyboard navigating user to return focus to where they came from.
 *
 * @see Is a part of the [NewsArchive](/#!/NewsArchive) component.
 * @since 1.0.0
 */
export const NewsArticle = ({
  headingElement = 'h2',
  headline = '',
  url,
  text = '',
}: NewsArticleProps) => {
  // TODO 1)
  if (!headline) return null;

  return (
    <Box
      as="article"
      display="flex"
      flexDirection="column"
      tabIndex={-1}
      px={4}
    >
      <Heading as={headingElement} variant="heading4">
        {headline}
      </Heading>
      {url && (
        <div>
          <Link
            as="a"
            // TODO 3)
            to=""
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            rightAddon={<Icon icon={light.faExternalLink} />}
            variant="link"
            // TODO 4)
            style={{ width: 'auto', marginTop: '1rem' }}
          >
            <Trans>Open on NewsWeb</Trans>
          </Link>
        </div>
      )}
      <Separator
        // TODO 5)
        orientation="horizontal"
        style={{ marginTop: '1rem' }}
      />

      {isMarkup(text) ? (
        <Text pt={4} dangerouslySetInnerHTML={sanitize(text)} />
      ) : (
        <Text
          as="pre"
          pt={2}
          // TODO 2)
          css={S.preformattedText}
        >
          {text}
        </Text>
      )}
    </Box>
  );
};

export default NewsArticle;
