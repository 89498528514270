import React, { useState, useCallback } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';
import { usePopper } from 'react-popper-2';
import { forwardRefWithAs, noop, splitProps, systemProps } from '@oms/ui-utils';
import {
  ListBox,
  ItemContainer,
  Container,
  GroupHeader,
  defaultItemToString,
  groupBy,
  RenderItem,
  Item,
} from '@oms/ui-select';
import { Input, TextInputWrapper } from '@oms/ui-text-input';
import { Icon, light } from '@oms/ui-icon';
import { ClearButton, ListboxButton } from '@oms/ui-icon-button';
import { Box } from '@oms/ui-box';
import { Chip } from '@oms/ui-chip';
import { useFilteredItems } from './utils';
import { ComboboxProps } from './types';
import * as S from './styles';

/**
 * A searchable (single) listbox widget that
 * is functionally similar to an HTML data list element.
 */
export const Combobox = forwardRefWithAs<ComboboxProps, 'input'>(
  function Combobox(
    {
      onChange = noop,
      onBlur,
      onFocus,
      isMulti,
      // Destructure isMulti and use it immediately to assign a default value
      value = isMulti ? [] : '',
      disabled,
      isClearable,
      itemToString = defaultItemToString,
      id,
      name,
      labelId,
      placeholder = 'Select',
      ariaMenuButtonLabel = 'Toggle options',
      ariaClearButtonLabel = isMulti
        ? 'Clear all selected options'
        : 'Clear selection option',
      'aria-describedby': describedBy,
      'aria-invalid': invalid,
      'aria-errormessage': errorMessage,
      required,
      noMatchFoundMessage = 'No match found',
      items = [],
      renderItem: ItemRenderer = RenderItem,
      defaultSelectedItem,
      defaultSelectedItems = [],
      groupByKey,
      groupToString = (group: string) => group,
      ...props
    },
    ref,
  ) {
    const [system] = splitProps(props, systemProps as any);

    const [inputText, setInputText] = useState(
      isMulti ? '' : itemToString(value),
    );
    const setInputValue = useCallback(
      (value: any) => setInputText(itemToString(value)),
      [itemToString],
    );
    /*
     *  Using useState instead of useRef. useState effectively provides a callback ref,
     *  which triggers an update in usePopper as the ref is assigned.
     */
    const [element, registerElement] = useState<HTMLElement | null>(null);
    const [popper, registerPopper] = useState<HTMLElement | null>(null);
    const { styles, attributes, forceUpdate } = usePopper(element, popper, {
      placement: 'bottom-start',
    });

    const {
      getSelectedItemProps,
      getDropdownProps,
      addSelectedItem,
      removeSelectedItem,
      selectedItems,
      reset: resetMulti,
    } = useMultipleSelection({
      defaultSelectedItems,
      itemToString,
      initialSelectedItems: ((isMulti ? value || [] : []) as unknown) as Item[],
      onStateChange: ({ type, selectedItems }) => {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.SelectedItemClick:
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes
            .SelectedItemKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionAddSelectedItem:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
            if (selectedItems && isMulti) {
              onChange(selectedItems);
            }
            break;
          case useMultipleSelection.stateChangeTypes.FunctionReset:
            if (isMulti) {
              onChange([]);
            }
            break;
          default:
            break;
        }
      },
    });

    const filteredItems = useFilteredItems(
      inputText,
      items,
      selectedItems,
      itemToString,
    );

    const {
      isOpen,
      getToggleButtonProps,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
      selectItem,
      reset,
      selectedItem: itemInState,
    } = useCombobox({
      labelId,
      inputId: id,
      itemToString,
      selectedItem: !isMulti ? (value as Item) : undefined,
      defaultSelectedItem,
      inputValue: inputText,
      items: groupByKey
        ? Object.values(groupBy(filteredItems, groupByKey)).flat()
        : filteredItems,
      defaultHighlightedIndex: 0,
      onStateChange: ({ inputValue, type, selectedItem }: any) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(inputValue);
            if (!inputValue && isClearable) {
              reset();
            }
            break;
          //  @ts-ignore falls through
          case useCombobox.stateChangeTypes.InputBlur:
            if (!inputText) return;
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            if (selectedItem && isMulti) {
              setInputValue('');
              addSelectedItem(selectedItem);
              selectItem((null as unknown) as Item);
            }
            if (selectedItem && !isMulti) {
              setInputValue(selectedItem);
              onChange(selectedItem);
            }
            if (!selectedItem && !isMulti) {
              setInputValue(itemInState);
            }
            break;
          case useCombobox.stateChangeTypes.FunctionReset:
            if (!isMulti) {
              setInputValue('');
              onChange('');
            }
            break;
          default:
            break;
        }
      },
      stateReducer: (_state, actionsAndChanges) => {
        const { type, changes } = actionsAndChanges;
        switch (type) {
          /**
           * This allows use to highlight the first item,
           * but only select the item if clicked/on key down enter
           case useCombobox.stateChangeTypes.InputBlur:
             return {
               ...state,
               isOpen: false,
               highlightedIndex: -1,
              };
          */
          default:
            return changes;
        }
      },
    });

    const handleClear = () => (isMulti ? resetMulti() : reset());

    const hasSelectedItems = isMulti
      ? selectedItems?.length > 0
      : !!itemInState;

    const hasSelectableItems = filteredItems?.length > 0;

    const getSelected = (item: Item) =>
      itemInState ? itemToString(itemInState) === itemToString(item) : null;

    const inputProps = {
      ref,
      id,
      name,
      placeholder,
      disabled,
      required,
      onBlur,
      onFocus,
      'aria-describedby': describedBy,
      'aria-invalid': invalid,
      'aria-errormessage': errorMessage,
      style: {
        width: '100%',
        paddingLeft: 0,
      },
    };

    React.useEffect(() => {
      if (isOpen) {
        forceUpdate?.();
      }
    }, [isOpen, forceUpdate]);

    return (
      <Container {...system}>
        <TextInputWrapper
          data-state={(disabled && 'disabled') || (invalid && 'error')}
          {...getComboboxProps({
            ref: registerElement,
            style: {
              display: 'flex',
              height: 'auto',
              minHeight: '2.5rem',
            },
          })}
        >
          <S.Wrap>
            {isMulti &&
              selectedItems.map((selectedItem, index) => (
                <Box key={`selected-item-${index}`} p={1}>
                  <Chip
                    as="div"
                    {...getSelectedItemProps({
                      selectedItem,
                      index,
                    })}
                  >
                    {itemToString(selectedItem)}
                    <span
                      style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
                      onClick={() => removeSelectedItem(selectedItem)}
                    >
                      <Icon icon={light.faTimes} />
                    </span>
                  </Chip>
                </Box>
              ))}
            <Box flex="1 0 4rem" p={1}>
              <Input
                {...getInputProps(
                  isMulti
                    ? {
                        ...getDropdownProps({
                          preventKeyAction: isOpen,
                          ...inputProps,
                        }),
                        disabled,
                      }
                    : {
                        ...getDropdownProps({
                          ...inputProps,
                        }),
                        disabled,
                      },
                )}
              />
            </Box>
          </S.Wrap>
          <Box display="flex" mr={1}>
            {isClearable && hasSelectedItems && (
              <ClearButton
                onClick={handleClear}
                tabIndex={-1}
                aria-label={ariaClearButtonLabel}
              />
            )}
            <ListboxButton
              isOpen={isOpen}
              {...getToggleButtonProps({
                disabled,
                'aria-label': ariaMenuButtonLabel,
                type: 'button',
              })}
            />
          </Box>
        </TextInputWrapper>
        <ListBox
          isOpen={isOpen}
          {...getMenuProps({
            ref: registerPopper,
            style: styles.popper,
            ...attributes.popper,
          })}
        >
          {!isOpen ? null : hasSelectableItems && groupByKey ? (
            Object.entries(groupBy(items, groupByKey)).reduce(
              (
                result = { sections: [], itemIndex: 0 },
                [group, items],
                groupIndex,
              ) => {
                result.sections.push(
                  <li key={groupIndex}>
                    <GroupHeader>{groupToString(group)}</GroupHeader>
                    <ul>
                      {items.map((item, itemIndex) => {
                        const isSelected = getSelected(item),
                          index = result.itemIndex++,
                          isHighlighted = highlightedIndex === index;
                        return (
                          <ItemRenderer
                            key={`${groupToString(group)}-${itemIndex}`}
                            item={item}
                            isHighlighted={isHighlighted}
                            isSelected={isSelected}
                            itemToString={itemToString}
                            ItemContainer={ItemContainer}
                            {...getItemProps({
                              item,
                              index,
                            })}
                          />
                        );
                      })}
                    </ul>
                  </li>,
                );
                return result;
              },
              { sections: [] as JSX.Element[], itemIndex: 0 },
            ).sections
          ) : hasSelectableItems ? (
            filteredItems.map((item, index) => {
              const isSelected = getSelected(item),
                isHighlighted = highlightedIndex === index;

              return (
                <ItemRenderer
                  key={`${item}${index}`}
                  item={item}
                  isHighlighted={isHighlighted}
                  isSelected={isSelected}
                  itemToString={itemToString}
                  ItemContainer={ItemContainer}
                  {...getItemProps({ item, index })}
                />
              );
            })
          ) : (
            <ItemContainer>{noMatchFoundMessage}</ItemContainer>
          )}
        </ListBox>
      </Container>
    );
  },
);
