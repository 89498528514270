/**
 * Returns the amount of decimal places a number should have, given OMS'
 * business logic. Currently:
 *
 * 4 decimals if amount is greater, otherwise number of decimals with a minimum of 2.
 * @param {number} num - The number to calcuate the amount of decimals on
 * @param {number} max - Optional max amount of decimals, default: 4.
 * @return {number} The number of decimals we should use according to our
 * business logic
 */
export default function decimalPlaces(num: number, max = 4) {
  const match = num.toString().match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) {
    return 2;
  }
  if (!match[1]) {
    return 2;
  }
  if (match[1].length > max) {
    return max;
  }
  if (match[1].length < 2) {
    return 2;
  }
  return match[1].length;
}
