import React from 'react';
import { formatLink } from '@oms/utils';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { ElementObserver } from '@oms/ui-element-query';
import { Table, Th, Td, Thead, Tr, Tbody } from '@oms/ui-table';

import { FilterState } from './TransactionsFilters';

import { mapType, orderByDate } from './utils';
import { DEFAULT_DATE_SERIALIZATION_FORMAT } from './constants';
import useTransactions from './useTransactions';
import { useComponentsContext } from '@oms/components-config-context';

/* eslint-disable react/no-unused-prop-types */

interface TransactionsTableProps {
  /** The currently logged in user */
  userId: string | number;
  /** The currently logged in user's selected account */
  accountId: string | number;
  /**
   * A momentjs format string that the date picker will use. Default of `L` is
   * a localized short format based on the configured momentjs locale.
   */
  dateFormat?: string;
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  linkTo?: any;

  /**
   * The links that lead to an order details page.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  orderLink?: any;
  /**
   * The links that lead to a transaction details page.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  transactionLink?: any;
  /** Will be passed to the component */
  className?: string;
  /** The entrypoint to the api. */
  baseUrl?: string;
  /** The endpoint used to search for transactions. String is interpereted as an RFC6570 compatible template */
  transactionSearchUrl?: string;
  /**
   * Specifies what kind of transaction to search for
   */
  type: 'fund' | 'stock' | 'fund-and-stock';
  /** @deprecated - use `dateSerializationFormat` instead */
  serializationFormat?: string;
  /** Used to serialize `filters.fromDate` and `filters.toDate` for use in the URL string */
  dateSerializationFormat?: string;
  filters: FilterState;
  /** If the server-response is non-standard, use this to normalize it */
  responseNormalizer?: (responseResult: any) => any[];
  suspense?: boolean;
}

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * Displays a table of the provided user's transaction history. Use this together with a custom Filter component and useTransactions,
 * or if you want to provide your own data.
 *
 * @see Is a part of the [TransactionsArchive](/#!/TransactionsArchive) component.
 * @since 1.0.0
 */
export const TransactionsTable = ({
  type = 'fund-and-stock',
  className = 'TransactionsTable',
  accountId,
  userId,
  baseUrl: baseUrlProp,
  transactionSearchUrl: transactionSearchUrlProp,
  filters,
  linkTo = '/instrument/{ITEM_SECTOR}',
  orderLink = '/order/{orderId}{?type}',
  transactionLink = '/order/{orderId}/transaction/{transactionId}{?type}',
  dateFormat = 'L',
  serializationFormat = DEFAULT_DATE_SERIALIZATION_FORMAT,
  dateSerializationFormat = serializationFormat, // Adds backwards compatibility for `serializationFormat`
  responseNormalizer,
  suspense = false,
}: TransactionsTableProps) => {
  const { baseUrl, transactionSearchUrl } = useComponentsContext();

  const { data: fundTransactions = [] } = useTransactions(
    {
      baseUrl: baseUrlProp || baseUrl,
      transactionSearchUrl: transactionSearchUrlProp || transactionSearchUrl,
      type: 'fund',
      userId,
      accountId,
      filters,
      dateSerializationFormat,
      responseNormalizer,
    },
    {
      enabled: type.includes('fund'),
      suspense,
    },
  );

  const { data: stockTransactions = [] } = useTransactions(
    {
      baseUrl: baseUrlProp || baseUrl,
      transactionSearchUrl: transactionSearchUrlProp || transactionSearchUrl,
      type: 'stock',
      userId,
      accountId,
      filters,
      dateSerializationFormat,
      responseNormalizer,
    },
    {
      enabled: type.includes('stock'),
      suspense,
    },
  );

  const items = [...fundTransactions, ...stockTransactions];
  const showPrice = type === 'stock';
  return (
    <I18n>
      {({ i18n }) => {
        const trans = (key: any) => (i18n ? i18n._(key) : key.id);
        return (
          <ElementObserver
            breakpoints={{
              widths: {
                0: 'small',
                769: 'normal',
              },
            }}
            render={({ observedElementProps, widthMatch }: any) => (
              <Table
                className={className}
                data-testid="TransactionsTable"
                layout="auto"
                variant={widthMatch === 'small' ? 'flipped' : 'normal'}
                {...observedElementProps}
              >
                <Thead>
                  <Tr>
                    <Th className="ticker text">
                      <Trans>Company</Trans>
                    </Th>
                    <Th className="type text">
                      <Trans>Type</Trans>
                    </Th>
                    <Th className="totalprice number" textAlign="right">
                      <Trans>Total price</Trans>
                    </Th>
                    <Th className="volume number" textAlign="right">
                      <Trans>Volume</Trans>
                    </Th>
                    {showPrice && (
                      <Th className="price number" textAlign="right">
                        <Trans>Price</Trans>
                      </Th>
                    )}
                    <Th className="executed date" weight={1.1}>
                      <Trans>Executed</Trans>
                    </Th>
                    <Th className="transaction-id" weight={1.3}>
                      <Trans>Transaction</Trans>
                    </Th>
                    <Th className="order-id" weight={1.2}>
                      <Trans>Order no.</Trans>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.length === 0 ? (
                    <Tr>
                      <Td colSpan={showPrice ? 8 : 7} textAlign="center">
                        <Trans>No data found</Trans>
                      </Td>
                    </Tr>
                  ) : null}
                  {items.sort(orderByDate).map((item: any) => {
                    const {
                      tickerCode,
                      stockExchangeId,
                      transactionId,
                      orderId,
                      tradeType,
                      volume,
                      price,
                      executedDate,
                      transactionFee,
                      instrumentType,
                      instrumentName,
                      isin,
                      priceLocal,
                      transactionCode,
                    } = item;
                    const isFund = instrumentType === 'fund';
                    const pricePerShare = isFund ? priceLocal : price;

                    let totalPrice;
                    if (tradeType === 'BUY') {
                      totalPrice = volume * pricePerShare + transactionFee;
                    } else if (tradeType === 'SELL') {
                      totalPrice = volume * pricePerShare - transactionFee;
                    }

                    const ITEM_SECTOR = isFund
                      ? `${isin}.OMFF`
                      : `${tickerCode}.${stockExchangeId}`;

                    const name = isFund
                      ? instrumentName || isin
                      : instrumentName || tickerCode;

                    const variables = {
                      ...item,
                      ITEM_SECTOR,
                      type: instrumentType,
                      accountId,
                    };

                    const key = JSON.stringify(item);

                    const showCompanyLink =
                      (isFund && isin) || (tickerCode && stockExchangeId);

                    return (
                      <Tr className="transactionRow" key={key}>
                        <Td
                          type="text"
                          className="ticker text"
                          data-header={trans(t`Company`)}
                          linkTo={
                            showCompanyLink
                              ? formatLink(linkTo, variables)
                              : undefined
                          }
                        >
                          {name}
                        </Td>
                        <Td
                          type="text"
                          className="type text"
                          data-header={trans(t`Type`)}
                        >
                          {mapType(tradeType, transactionCode) as any}
                        </Td>
                        <Td
                          type="number"
                          className="totalprice number"
                          data-header={trans(t`Total price`)}
                        >
                          {totalPrice}
                        </Td>
                        <Td
                          className="volume number"
                          data-header={trans(t`Volume`)}
                          type={isFund ? 'number' : 'integer'}
                          fractionDigits={isFund ? 4 : undefined}
                        >
                          {volume}
                        </Td>
                        {showPrice && (
                          <Td
                            className="price number"
                            type="number"
                            data-header={trans(t`Price`)}
                          >
                            {pricePerShare}
                          </Td>
                        )}
                        <Td
                          type="dateTime"
                          className="executed date"
                          data-header={trans(t`Executed`)}
                          formatString={dateFormat}
                        >
                          {executedDate}
                        </Td>
                        <Td
                          className="transaction-id"
                          data-header={trans(t`Transaction`)}
                          linkTo={
                            transactionId
                              ? formatLink(transactionLink, variables)
                              : undefined
                          }
                        >
                          {transactionId || '-'}
                        </Td>
                        <Td
                          className="order-id"
                          data-header={trans(t`Order no.`)}
                          linkTo={
                            orderId
                              ? formatLink(orderLink, variables)
                              : undefined
                          }
                        >
                          {orderId || '-'}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
          />
        );
      }}
    </I18n>
  );
};

export default TransactionsTable;
