import React, { useRef } from 'react';
import moment from 'moment';
import { Stack } from '@oms/ui-stack';
import { Box } from '@oms/ui-box';
import { Button } from '@oms/ui-button';
import { Icon, light } from '@oms/ui-icon';
import { Table, Th, Td } from '@oms/ui-table';
import { Heading } from '@oms/ui-heading';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { formatNumber, formatInteger } from '@oms/utils';
import ReactToPrint from 'react-to-print';
import { decimalPlaces, fundTransferCodes, mapType } from './utils';
import { ElementObserver } from '@oms/ui-element-query';

/**
 * TODO
 * 1) Remove cloneElement as elements might null/false, or flatten children.
 * 2) Orientation prop is not working
 */

interface OrderDetailsProps {
  /**
   * Data object containing transaction details.
   */
  data: {
    createdDate?: number | string;
    transactionData?: {
      instrumentName?: string;
      isin?: string;
      transactionId?: number;
      bankAccount?: string;
      executedDate?: string;
      rateDate?: string;
      settlementDate?: string;
      transactionFee?: number;
      stockExchangeId?: string;
      tradeType?: 'BUY' | 'SELL';
      fullName?: string;
      volume?: number;
      price?: number;
      cashAccount?: string;
      currency?: string;
      fundCurrency?: string;
      priceLocal?: number;
      currencyRate?: number;
      orderCreatedDate?: string;
      transactionCode?: string;
    };
    contractNotes?: any[];
  };
  /**
   * Classname applied to the component
   */
  className?: string;
  /**
   * Specifies what kind of instrument, this affects fields like rate date and stock exhange ID.
   */
  instrumentType: 'fund' | 'stock';
  /**
  /**
   * If provided shows customer number in OrderDetails header
   */
  customerId?: string | number;
  /**
   * A momentjs format string that the component will use to display dates.
   *
   * The default will display a localized short format based on the configured
   * momentjs locale.
   */
  dateFormat?: string;
  /** Org.num to show if not present in transactionData */
  orgNum?: string;
  /** Customer name to show if not present in transactionData */
  customerName?: string;
}

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * Component showing transaction details for an order including dates, accounts, volume, price, contract notes, and total.
 * Used e.g when a user clicks a transactionId in `TransactionsArchive`.
 *
 * Expected data-structure is found in prop-description.
 *
 * This component is used by the `OrderComponent` to display details
 * fetched with `OrderFetcher`, but can also be used by itself by providing it your own data.
 *
 * @see See [OrderComponent](/#!/OrderComponent) for a composition of the fetcher- and details-components.
 * @see See [OrderFetcher](/#!/OrderFetcher) for the fetcher-component.
 * @since 1.4.0
 */
export const OrderDetails = ({
  data = {},
  className = 'OrderDetails',
  customerId,
  dateFormat = 'L',
  instrumentType,
  customerName,
  orgNum,
}: OrderDetailsProps) => {
  const orderDetailsRef = useRef<HTMLDivElement>(null!);
  const { createdDate, transactionData = {}, contractNotes = [] } = data;

  const sortByExecutedDateAsc = (
    a: { executedDate: string },
    b: { executedDate: string },
  ) => {
    const firstDate = moment(a.executedDate).format('x');
    const secondDate = moment(b.executedDate).format('x');
    if (firstDate > secondDate) return 1;
    if (firstDate < secondDate) return -1;
    return 0;
  };

  const {
    instrumentName,
    isin,
    transactionId,
    bankAccount,
    executedDate,
    rateDate,
    settlementDate,
    transactionFee,
    stockExchangeId,
    tradeType,
    fullName,
    volume,
    price,
    cashAccount,
    currency,
    fundCurrency,
    priceLocal,
    currencyRate,
    orderCreatedDate,
    transactionCode,
  } = transactionData;

  const isFund = instrumentType === 'fund';
  const isTransfer =
    transactionCode && fundTransferCodes.includes(transactionCode);

  const pricePerShare = isFund ? priceLocal : price;
  const orderDate = isFund ? orderCreatedDate : createdDate;
  const hideBankAccount = isFund && tradeType === 'BUY';

  const getTotalPrice = () => {
    if (!volume || !pricePerShare) return '-';

    const fee = transactionFee || 0;
    if (tradeType === 'BUY') {
      return volume * pricePerShare + fee;
    }
    if (tradeType === 'SELL') {
      return volume * pricePerShare - fee;
    }
    return '-';
  };

  return (
    <I18n>
      {({ i18n }) => {
        const trans = (key: any) => (i18n ? i18n._(key) : key.id);
        return (
          <Stack
            gap={4}
            className={className}
            data-testid="OrderDetails"
            ref={orderDetailsRef}
          >
            <Box display="flex">
              <Box
                flex="1"
                data-testid="header"
                display="flex"
                flexDirection="column"
                className="OrderDetails-header"
              >
                <Heading as="h2" variant="heading5">
                  <Trans>Transaction no.</Trans> {transactionId || '-'}
                </Heading>
                <Heading as="h3" variant="heading6">
                  {fullName || customerName}
                </Heading>
                {!!orgNum && (
                  <Heading as="h3" variant="heading6">
                    <Trans>Organization no.</Trans> {orgNum}
                  </Heading>
                )}
              </Box>
              <ReactToPrint
                trigger={() => (
                  <Button
                    className="printButton"
                    rightAddon={<Icon icon={light.faPrint} />}
                  >
                    <Trans>Print</Trans>
                  </Button>
                )}
                content={() => orderDetailsRef.current}
                removeAfterPrint
              />
            </Box>

            <Table
              variant="keyValue"
              width="50%"
              minWidth="300px"
              data-testid="headerTable"
              className="OrderDetails-headerTable"
            >
              {!isTransfer && (
                <tr>
                  <Th>
                    <Trans>Order date</Trans>
                  </Th>
                  <Td textAlign="right">
                    {orderDate ? moment(orderDate).format(dateFormat) : '-'}
                  </Td>
                </tr>
              )}

              <tr>
                <Th>
                  <Trans>Executed date</Trans>
                </Th>
                <Td textAlign="right">
                  {executedDate ? moment(executedDate).format(dateFormat) : '-'}
                </Td>
              </tr>

              <tr>
                <Th>
                  <Trans>Settlement date</Trans>
                </Th>
                <Td textAlign="right">
                  {settlementDate
                    ? moment(settlementDate).format(dateFormat)
                    : '-'}
                </Td>
              </tr>

              {isFund && (
                <tr>
                  <Th>
                    <Trans>Rate date</Trans>
                  </Th>
                  <Td textAlign="right">
                    {rateDate ? moment(rateDate).format(dateFormat) : '-'}
                  </Td>
                </tr>
              )}
              {customerId && (
                <tr>
                  <Th>
                    <Trans>Customer no.</Trans>
                  </Th>
                  <Td textAlign="right">{customerId}</Td>
                </tr>
              )}
              {!hideBankAccount && (
                <tr>
                  <Th>
                    <Trans>Bank account</Trans>
                  </Th>
                  <Td textAlign="right">
                    {isFund ? cashAccount : bankAccount}
                  </Td>
                </tr>
              )}
              <tr>
                <Th>
                  <Trans>Order type</Trans>
                </Th>
                <Td textAlign="right">{mapType(tradeType, transactionCode)}</Td>
              </tr>
              {!isFund && (
                <tr>
                  <Th>
                    <Trans>Exchange</Trans>
                  </Th>
                  <Td textAlign="right">Oslo Børs ({stockExchangeId})</Td>
                </tr>
              )}
            </Table>
            <ElementObserver
              breakpoints={{
                widths: {
                  0: 'small',
                  769: 'normal',
                },
              }}
              render={({ observedElementProps, widthMatch }: any) => (
                <Table
                  data-testid="detailsTable"
                  width="100%"
                  layout="auto"
                  minWidth="220px"
                  variant={widthMatch === 'small' ? 'flipped' : 'normal'}
                  className="OrderDetails-detailsTable"
                  {...observedElementProps}
                >
                  <thead>
                    <tr>
                      {!!contractNotes.length && (
                        <Th type="dateTime">
                          <Trans>Executed</Trans>
                        </Th>
                      )}
                      <Th type="text">
                        <Trans>Name</Trans>
                      </Th>
                      <Th type="number">
                        {isFund ? (
                          <Trans>Shares</Trans>
                        ) : (
                          <Trans>Quantity</Trans>
                        )}
                      </Th>
                      <Th type="number">
                        {isFund ? (
                          <Trans>
                            Price per share in {fundCurrency || 'NOK'}
                          </Trans>
                        ) : (
                          <Trans>Price</Trans>
                        )}
                      </Th>
                      {isFund && (
                        <Th type="number">
                          <Trans>Currency rate</Trans>
                        </Th>
                      )}
                      <Th type="number">
                        {isFund ? (
                          <Trans>Total price in {currency}</Trans>
                        ) : (
                          <Trans>Total price</Trans>
                        )}
                      </Th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!contractNotes.length &&
                      contractNotes
                        .sort(sortByExecutedDateAsc)
                        .map((contractNote) => (
                          <tr key={contractNote.transactionId}>
                            <Td data-label={trans(t`Executed`)}>
                              {contractNote.executedDate
                                ? moment(contractNote.executedDate).format(
                                    `LT ${dateFormat}`,
                                  )
                                : '-'}
                            </Td>
                            <Td data-label={trans(t`Name`)} type="text">
                              {contractNote.instrumentName}
                            </Td>
                            <Td
                              data-label={trans(t`Quantity`)}
                              textAlign="right"
                            >
                              {formatInteger(contractNote.volume || '0')}
                            </Td>
                            <Td data-label={trans(t`Price`)} textAlign="right">
                              {contractNote.price !== undefined
                                ? formatNumber(
                                    contractNote.price,
                                    decimalPlaces(contractNote.price, 8),
                                  )
                                : '-'}
                            </Td>
                            <Td
                              data-label={trans(t`Total price`)}
                              textAlign="right"
                            >
                              {formatNumber(
                                contractNote.volume * contractNote.price || '0',
                                2,
                              )}
                            </Td>
                          </tr>
                        ))}
                    {!contractNotes.length && (
                      <tr>
                        <Td data-label={trans(t`Name`)}>{instrumentName}</Td>
                        <Td
                          data-label={trans(isFund ? t`Shares` : t`Quantity`)}
                          className="number"
                          textAlign="right"
                        >
                          {volume !== undefined
                            ? formatNumber(volume, decimalPlaces(volume, 8))
                            : '-'}
                        </Td>
                        <Td
                          data-label={trans(
                            isFund
                              ? t`Price per share in ${fundCurrency || 'NOK'}`
                              : t`Price`,
                          )}
                          className="number"
                          textAlign="right"
                        >
                          {price !== undefined
                            ? formatNumber(price, decimalPlaces(price, 8))
                            : '-'}
                        </Td>
                        {isFund && (
                          <Td
                            data-label={trans(t`Currency rate`)}
                            className="number"
                            textAlign="right"
                          >
                            {currencyRate
                              ? formatNumber(
                                  currencyRate,
                                  decimalPlaces(currencyRate, 6),
                                )
                              : '-'}
                          </Td>
                        )}
                        <Td
                          data-label={trans(
                            isFund
                              ? t`Total price in ${currency}`
                              : t`Total price`,
                          )}
                          textAlign="right"
                        >
                          {volume && pricePerShare
                            ? formatNumber(volume * pricePerShare, 2)
                            : '-'}
                        </Td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            />

            <Box display="flex" flexWrap="wrap" className="OrderDetails-footer">
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                mb={4}
                minWidth="220px"
              >
                <Heading as="h3" variant="heading5">
                  {instrumentName}
                </Heading>
                <div>ISIN: {isin}</div>
              </Box>

              <Table
                width="50%"
                layout="auto"
                density="dense"
                data-testid="footerTable"
                variant="keyValue"
                minWidth="220px"
                className="OrderDetails-footerTable"
              >
                <tbody>
                  <tr>
                    <th>
                      {isFund ? (
                        <Trans>Fee</Trans>
                      ) : (
                        <Trans>Transaction fee</Trans>
                      )}
                    </th>
                    <Td className="number">
                      {formatNumber(transactionFee || '0', 2)}
                    </Td>
                  </tr>
                  <tr>
                    <th>
                      <Trans>Amount</Trans>
                    </th>
                    <td className="number">
                      {volume && pricePerShare
                        ? formatNumber(volume * pricePerShare, 2)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {tradeType === 'BUY' ? (
                        <Trans>To pay</Trans>
                      ) : (
                        <Trans>Total received</Trans>
                      )}
                    </th>
                    <td className="number">
                      {formatNumber(getTotalPrice() || '0', 2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Box>
          </Stack>
        );
      }}
    </I18n>
  );
};

export default OrderDetails;
