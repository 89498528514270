import React from 'react';
import { useMenuBarState, MenuBar, MenuBarItem } from '@oms/ui-menu-bar';
import { useParentId } from '../StockChartContainer';
import { getStockChartTopMenuId } from '../utils/id';
import { Box } from '@oms/ui-box';
import { Comparisons } from './Comparisons';
import { Indicators } from './Indicators';
import { Additions } from './Additions';
import { LineStyle } from './LineStyle';
import { Fullscreen } from './Fullscreen';

export interface StockChartMenuBarProps {
  /** @deprecated */
  includeMainInstrumentMenuBarItem?: boolean;
  additions?: JSX.Element;
  /** The group that will be used to fetch possible matches */
  searchGroup?: string;
}

export function StockChartMenuBar({
  additions,
  searchGroup,
}: StockChartMenuBarProps) {
  const id = useParentId();
  const menu = useMenuBarState();
  return (
    <>
      <MenuBar
        {...menu}
        id={getStockChartTopMenuId(id)}
        style={{ zIndex: 11, height: 'auto', minHeight: '2.5rem' }}
      >
        <Box
          display="flex"
          flex={1}
          style={{ overflowX: 'auto', overflowY: 'hidden' }}
        >
          <MenuBarItem {...menu} as={Comparisons} searchGroup={searchGroup} />
          <MenuBarItem {...menu} as={Indicators} />
          <MenuBarItem {...menu} as={Additions}>
            {additions}
          </MenuBarItem>
          <MenuBarItem {...menu} as={LineStyle} />
        </Box>
        <Box borderLeft="sm" borderColor="border" mx={2} />
        <MenuBarItem {...menu} as={Fullscreen} />
      </MenuBar>
    </>
  );
}
