import { t } from '@lingui/macro';

const getColumn = (column) => (values) => values[column];

/**
 * Supported fields are defined here to hide the complexity of rendering the
 * cells. This means the user only needs to specify the `spec` prop, leaving the
 * rest of the behavior to the component.
 *
 * Custom behavior requires implementing a component using the QuotesFetcher.
 *
 * Consider implementing more columns here before exposing an API to do the same
 * to keep API surface minimal.
 *
 * This behavior may be exposed at a later point. Right now it's not to prevent
 * breaking changes to APIs while we figure out the best tradeoff of ease of use
 * vs. flexibility.
 *
 * Synthetic composite columns like `CLOSE_OR_LAST` should also be considered.
 */
export const columns = {
  ITEM: {
    heading: t`Ticker`,
    accessor: getColumn('ITEM'),
    type: 'text',
  },
  LONG_NAME: {
    heading: t`Name`,
    accessor: getColumn('LONG_NAME'),
    type: 'text',
  },
  LAST: {
    heading: t`Last`,
    accessor: getColumn('LAST'),
    type: 'number',
    flash: true,
  },
  CHANGE: {
    heading: t`Change`,
    accessor: getColumn('CHANGE'),
    type: 'number',
    flash: true,
    trend: true,
  },
  CHANGE_PCT: {
    heading: t`Change %`,
    accessor: getColumn('CHANGE_PCT'),
    type: 'percent',
    flash: true,
    trend: true,
  },
  CLOSE: {
    heading: t`Close`,
    accessor: getColumn('CLOSE'),
    type: 'number',
    trend: true,
  },
  ASK: {
    heading: t`Ask`,
    accessor: getColumn('ASK'),
    type: 'number',
    flash: true,
  },
  BID: {
    heading: t`Bid`,
    accessor: getColumn('BID'),
    type: 'number',
    flash: true,
  },
  HIGH: {
    heading: t`High`,
    accessor: getColumn('HIGH'),
    type: 'number',
    flash: true,
  },
  LOW: {
    heading: t`Low`,
    accessor: getColumn('LOW'),
    type: 'number',
  },
  VOLUME: {
    heading: t`Volume`,
    accessor: getColumn('VOLUME'),
    type: 'number',
    flash: true,
  },
  TURNOVER: {
    heading: t`Turnover`,
    accessor: getColumn('TURNOVER'),
    type: 'numberWithUnit',
  },
  TIME: {
    heading: t`Time`,
    accessor: getColumn('TIME'),
    type: 'dateTime',
  },
  CHANGE_7DAYS_PCT: {
    heading: t`7 days`,
    accessor: getColumn('CHANGE_7DAYS_PCT'),
    type: 'percent',
    trend: true,
  },
  CHANGE_1MONTH_PCT: {
    heading: t`1 month`,
    accessor: getColumn('CHANGE_1MONTH_PCT'),
    type: 'percent',
    trend: true,
  },
  CHANGE_YEAR_PCT: {
    heading: t`YTD`,
    accessor: getColumn('CHANGE_YEAR_PCT'),
    type: 'percent',
    trend: true,
  },
  CHANGE_1YEAR_PCT: {
    heading: t`1 year`,
    accessor: getColumn('CHANGE_1YEAR_PCT'),
    type: 'percent',
    trend: true,
  },
  CHANGE_3YEARS_PCT: {
    heading: t`3 years`,
    accessor: getColumn('CHANGE_3YEARS_PCT'),
    type: 'percent',
    trend: true,
  },
  CHANGE_5YEARS_PCT: {
    heading: t`5 years`,
    accessor: getColumn('CHANGE_5YEARS_PCT'),
    type: 'percent',
    trend: true,
  },
};

export default columns;
