export const SIMap: { [key: string]: string } = {
  OENG: 'Energi',
  OBMG: 'Materialer',
  OING: 'Forbruksvarer',
  OCDG: 'Konsumvarer',
  OHCG: 'Helsevern',
  OFING: 'Finans',
  OTECG: 'IT',
  OTELG: 'Telekom',
  OUTG: 'Forsyning',
  OREG: 'Eiendom',
};

/**
 * Takes a SECTOR_INDEX code (i.e. OFING for Finance) and returns the category name.
 * OSE-instruments has a field named SECTOR_INDEX_MEMBER_OF for getting sector membership.
 * @param {string} input - SECTOR_INDEX.
 * @return {number} a human readable name for the SECTOR_INDEX, or "Andre" if the
 * index is not known
 */
export default function mapSI(input: string) {
  return SIMap[input] || 'Andre';
}
