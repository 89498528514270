import { JawsApi, Spec, useJaws, Options } from '@oms/jaws-react';
import { ItemSectors } from './IndexMap';

export const defaultSpec: Spec = {
  initiatorComponent: 'IndexMapFetcher',
  columns:
    'ITEM, SECTOR, ITEM_SECTOR, LONG_NAME, LAST, LASTNZ, CHANGE_PCT, CLOSE, sector_open_time, sector_close_time',
  itemSector: [
    'OSEBX.OSE',
    'OMXN40.OMX',
    'I:RTSI.MISX',
    'I:DAX.GER',
    'I:N100.EUR',
    'I:COMP.NAS',
    'I:SP500.SP',
    'I:DJI.DJ',
    'I:HSI.HSI',
    'NI225.OSA',
    'OMXS30.SSE',
    'I:UKX.FTSE',
  ].join(),
};

type BuildSpecArguments = {
  itemSectors?: ItemSectors;
  spec?: Spec;
};

const buildSpec = ({ spec, itemSectors }: BuildSpecArguments) => {
  if (!Array.isArray(itemSectors) || !itemSectors.length) {
    console.error(
      'useIndexMap: Expected itemSectors of type array, but got:',
      itemSectors,
    );
    return;
  }

  return {
    ...defaultSpec,
    ...spec,
    itemSector:
      itemSectors.join() || spec?.itemSector || defaultSpec.itemSector,
  };
};

export interface useIndexMapArguments {
  spec?: Spec;
  options?: Options;
  itemSectors?: ItemSectors;
}

export type UseIndexMapReturn = Omit<JawsApi, 'items'> & {
  data: Record<
    string,
    {
      ITEM_SECTOR: string;
      LONG_NAME: string;
      [key: string]: string | number;
    }
  >;
};

export const useIndexMap = ({
  spec,
  options,
  itemSectors = defaultSpec.itemSector.split(','),
}: useIndexMapArguments): UseIndexMapReturn => {
  const { items, ...values } = useJaws(
    buildSpec({ itemSectors, spec }),
    options,
  );

  return {
    ...values,
    data: items.toJS(),
  };
};
