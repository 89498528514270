import React, { useState } from 'react';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { Trans, t } from '@lingui/macro';
import { Table, Th, TableToggleRowsButton } from '@oms/ui-table';
import { Abbr } from '@oms/components-typography';
import { NoData, Loading } from '@oms/components-core';
import { sortItemsByJawsKey } from './utils';
import { Row } from './Row';
import { AuctionRow } from './AuctionRow';

const ORDER_DEPTH = 'order-depth';

type BuildSpecOptions = {
  itemSector: string;
  hasLevel2: boolean;
  levels: number;
  spec?: Spec;
};

const buildSpec = ({
  itemSector,
  hasLevel2,
  levels,
  spec,
}: BuildSpecOptions) => {
  if (hasLevel2) {
    return {
      initiatorComponent: 'OrderDepth',
      generators: [
        {
          columns: [
            'BIDVOL',
            'ASKVOL',
            'BID',
            'ASK',
            'BIDNBR',
            'ASKNBR',
            'AUCTION_ASK1',
            'AUCTION_ASKVOL1',
            'AUCTION_BID1',
            'AUCTION_BIDVOL1',
          ].join(),
          convert: 'levels',
          dataSet: 'orders',
          limit: levels,
          itemSector,
          ...spec,
        },
        {
          columns: [
            'AUCTION_STATUS_INDICATOR',
            'INDICATIVE_AUCTION_PRICE',
            'INDICATIVE_AUCTION_VOLUME',
          ].join(),
          itemSector,
        },
      ],
    };
  }

  return {
    initiatorComponent: 'OrderDepth',
    columns:
      'BID_VOL as BIDVOL, ASK_VOL as ASKVOL, BID, ASK, AUCTION_ASK1, AUCTION_ASKVOL1, AUCTION_BID1, AUCTION_BIDVOL1',
    itemSector,
    ...spec,
  };
};

export interface UserOrderDepthArguments {
  spec?: Spec;
  itemSector: string;
  initialLevels: number;
  initialExpanded: boolean;
  expandedLevels: number;
  hasLevel2: boolean;
  disableAuctionSupport: boolean;
  options?: Options;
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
}

/**
 * A fetcher is the stateful logic of an OMS component. This React hook exposes
 * all the data required to render a `CompanyInfo` component.
 */
export const useOrderDepth = ({
  spec,
  itemSector,
  initialLevels,
  initialExpanded,
  expandedLevels,
  hasLevel2,
  disableAuctionSupport,
  options,
  onExpandToggled,
}: UserOrderDepthArguments) => {
  const [levels, setLevels] = useState(initialLevels);
  const [expanded, setExpanded] = useState(initialExpanded);
  const toggleExpanded = () => {
    const newValue = !expanded;
    setExpanded(newValue);
    setLevels(newValue ? expandedLevels : initialLevels);

    if (onExpandToggled) {
      onExpandToggled({ newValue });
    }
  };

  const { items, ...values } = useJaws(
    buildSpec({ itemSector, hasLevel2, levels, spec }),
    options,
  );

  let data: any = items;
  const {
    AUCTION_STATUS_INDICATOR,
    INDICATIVE_AUCTION_PRICE,
    INDICATIVE_AUCTION_VOLUME,
  } = items.find((item) => item.get('AUCTION_STATUS_INDICATOR'))?.toJS() || {};

  const isAuction = AUCTION_STATUS_INDICATOR === 1;
  const shouldShowAuction = !disableAuctionSupport && hasLevel2 && isAuction;

  // Calculate max and min values based on dataset
  const { maxVolume, minPrice } = data.reduce(
    (a: any, b: any) => ({
      // Guard against NaN result of Math.max and Math.min
      maxVolume:
        Math.max(b.get('BIDVOL'), b.get('ASKVOL'), a.maxVolume) || a.maxVolume,
      minPrice: Math.min(b.get('BID'), b.get('ASK'), a.minPrice) || a.minPrice,
    }),
    {
      minPrice: Number.MAX_VALUE,
      maxVolume: 0,
    },
  );

  data = data.filter(
    (item: any) => !item.has('generator') || item.get('generator') === 0,
  );
  data = sortItemsByJawsKey(data);
  data = data.slice(0, levels);
  data = data
    .map((item: any) =>
      item.merge({
        minPrice,
        percentOfMax: {
          ask: Math.max(
            item.get('ASKVOL') ? (item.get('ASKVOL') / maxVolume) * 100 : 0,
            3,
          ),
          bid: Math.max(
            item.get('BIDVOL') ? (item.get('BIDVOL') / maxVolume) * 100 : 0,
            3,
          ),
        },
      }),
    )
    .toJS();

  return {
    ...values,
    auction: {
      isAuction,
      shouldShowAuction,
      indicativePrice: INDICATIVE_AUCTION_PRICE,
      indicativeVolume: INDICATIVE_AUCTION_VOLUME,
    },
    maxVolume,
    minPrice,
    data,
    toggleExpanded,
    expanded,
  };
};

export interface OrderDepthProps {
  /**
   * A spec to override the default values of the component. The values vary
   * between wether the `hasLevel2` props is set or not, and you will probably
   * not need to use this. It is advised that you read the source code of this
   * component if you really wish to override the default spec
   */
  spec?: Spec;
  /** The itemSector to be used in fetching data */
  itemSector: string;
  /**
   * Will show only one level unless this prop is set to `true`. This is because
   * not all users have the permission to see all levels. If the user has this
   * permission, set this prop to `true` and the amount of levels specified in
   * the `levels` prop will be shown
   */
  hasLevel2?: boolean;
  /**
   * The *initial* number of levels to be shown. If the `showMoreButton` props
   * is set, then the user will be able to override this and see all available
   * levels when they click "show more"
   */
  initialLevels?: number;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * all available levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * The `limit` to use when the component is expanded. Can be overridden usen
   * `spec.limit`.
   */
  expandedLevels?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
  /**
   * When set to `true`, the component will render a button in the table footer
   * which expands to see all available levels when clicked. This button will
   * only be rendered if the `hasLevel2` prop is `true`, regardless of this prop
   */
  showMoreButton?: boolean;
  /** Will be passed to the component */
  className?: string;
  /**
   * Removes the auction line showing the indicative price and volume during
   * the opening minutes of trading
   */
  disableAuctionSupport?: boolean;
  /** Disables the loading indicator */
  disableLoading?: boolean;
}

/**
 * A component which lists all the _"levels"_ of trades on an instrument. This
 * means it lists all buy and sell orders in the market for a particular
 * security. This is useful if you wish to see if there is sufficient liquidity
 * to sell a particular security, you can quickly check how many orders exist,
 * and at which prices.
 *
 * @since 1.0.0
 */
export const OrderDepth = ({
  className = 'OrderDepth',
  itemSector,
  initialLevels = 5,
  initialExpanded = false,
  expandedLevels = 100,
  hasLevel2 = false,
  showMoreButton = true,
  spec,
  onExpandToggled,
  disableAuctionSupport = false,
  disableLoading = false,
}: OrderDepthProps) => {
  const {
    resource,
    data,
    initialized,
    emptyData,
    auction,
    toggleExpanded,
    expanded,
  } = useOrderDepth({
    spec,
    itemSector,
    hasLevel2,
    disableAuctionSupport,
    initialLevels,
    initialExpanded,
    expandedLevels,
    onExpandToggled,
  });

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No data found`} />;
  }

  return (
    <>
      <Table id={ORDER_DEPTH} width="100%" className={className}>
        <thead>
          <tr>
            {hasLevel2 && (
              <Th type="integer" className="BIDNBR">
                <Abbr title={t`Number of orders`}>
                  <Trans>No.</Trans>
                </Abbr>
              </Th>
            )}
            <Th type="integer" className="BIDVOL">
              <Trans>Volume</Trans>
            </Th>
            <Th type="number" className="BID">
              <Trans>Bid</Trans>
            </Th>
            <Th textAlign="center" className="bars" colSpan={2}>
              <Trans>Order depth</Trans>
            </Th>
            <Th type="number" className="ASK">
              <Trans>Ask</Trans>
            </Th>
            <Th type="integer" className="ASKVOL">
              <Trans>Volume</Trans>
            </Th>
            {hasLevel2 && (
              <Th type="integer" className="ASKNBR">
                <Abbr title={t`Number of orders`}>
                  <Trans>No.</Trans>
                </Abbr>
              </Th>
            )}
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([key, values]: any, index) => (
            <Row item={values} key={key} hasLevel2={hasLevel2} index={index} />
          ))}
        </tbody>
        <tfoot>
          {auction.shouldShowAuction && (
            <AuctionRow
              price={auction.indicativePrice}
              volume={auction.indicativeVolume}
            />
          )}
        </tfoot>
      </Table>
      {hasLevel2 && showMoreButton && (
        <TableToggleRowsButton
          onClick={toggleExpanded}
          aria-controls={ORDER_DEPTH}
          style={{ width: '100%' }}
          aria-label={
            expanded
              ? 'Toggle order depth, show additional content'
              : 'Toggle order depth, hide additional content'
          }
        >
          {expanded ? <Trans>Show fewer</Trans> : <Trans>Show more</Trans>}
        </TableToggleRowsButton>
      )}
    </>
  );
};
