import React, { createContext, useContext, ReactNode } from 'react';
import { useRoverState } from 'reakit/Rover';
import { useId } from '@oms/ui-utils';
export const AccordionContext = createContext<any>({});
AccordionContext.displayName = 'AccordionContext';
export const useAccordionGroup = (): any => useContext(AccordionContext);

export function AccordionGroup({
  children,
  loop = true,
  orientation = 'vertical',
  ...config
}: AccordionGroupProps) {
  const id = useId('accordion-group');
  const rover = useRoverState({ loop, orientation, baseId: id, ...config });
  return (
    <AccordionContext.Provider value={rover}>
      {children}
    </AccordionContext.Provider>
  );
}
export interface AccordionGroupProps {
  children: ReactNode;
  loop?: boolean;
  orientation?: 'vertical' | 'horizontal';
  activeId?: string;
}
