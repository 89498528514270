import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useFullScreen } from '@oms/ui-fullscreen';
import { useResizeObserver } from '@oms/ui-element-query';
import { useForkRef, SystemProps } from '@oms/ui-utils';
import { useDebounce } from 'use-debounce';
import { Box } from './styles';
import { getStockChartContainerId } from '../utils/id';

let id = 0;

const NodeContext = createContext<HTMLDivElement | null>(null);

export function useStockChartContainerNode() {
  const c = useContext(NodeContext);
  return c;
}

const SizeContext = createContext<{
  rect: ReturnType<typeof useResizeObserver>[1]['contentRect'] | null;
  containerWidth: 'sm' | 'md' | 'lg';
}>({ rect: null, containerWidth: 'sm' });

//

export function useStockChartContainerSize() {
  const c = useContext(SizeContext);
  return c;
}

const FullscreenContext = createContext<ReturnType<
  typeof useFullScreen
> | null>(null);

export function useFullscreen() {
  const c = useContext(FullscreenContext);
  return c;
}
export const useStockChartContainerFullscreen = useFullscreen;

const IdContext = createContext(id);

export function useParentId() {
  const c = useContext(IdContext);
  return c;
}

export interface StockChartContainerProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  chartHeight?: SystemProps['height'];
  chartMinHeight?: SystemProps['minHeight'];
}

export function StockChartContainer({
  children,
  chartHeight,
  chartMinHeight,
  ...props
}: React.PropsWithChildren<StockChartContainerProps>) {
  useEffect(() => {
    id++;
  }, []);

  const ref = useRef<HTMLDivElement | null>(null);
  const [resizeRef, entry] = useResizeObserver();
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const forkedRef = useForkRef(ref, resizeRef);
  const fullscreen = useFullScreen(ref);

  const [rect] = useDebounce(entry?.contentRect, 200, { maxWait: 300 });
  const containerWidth = useMemo(() => {
    if (!rect) return 'sm';
    if (rect?.width < 768) return 'sm';
    if (rect?.width < 1024) return 'md';
    return 'lg';
  }, [rect]);

  return (
    <Box
      {...props}
      ref={forkedRef}
      id={getStockChartContainerId(id)}
      height={chartHeight}
      minHeight={fullscreen.isFullscreen ? '90vh' : chartMinHeight}
      maxHeight={fullscreen.isFullscreen ? '90vh' : chartMinHeight}
    >
      <IdContext.Provider value={id}>
        <NodeContext.Provider value={node}>
          <FullscreenContext.Provider value={fullscreen}>
            <SizeContext.Provider value={{ rect, containerWidth }}>
              {children}
            </SizeContext.Provider>
          </FullscreenContext.Provider>
          <div
            // modals render here
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: 10,
            }}
          >
            <div
              // modals render here
              ref={setNode}
              style={{
                pointerEvents: 'all',
              }}
            />
          </div>
        </NodeContext.Provider>
      </IdContext.Provider>
    </Box>
  );
}
