import { isValidElement } from 'react';

const buttonInputTypes = [
  'button',
  'color',
  'file',
  'image',
  'reset',
  'submit',
];

/**
 * Checks whether `element` is a native HTML button element or not.
 *
 * @example
 * import { isButton } from "@oms/ui-utils";
 *
 * isButton(document.querySelector("button")); // true
 * isButton(document.querySelector("input[type='button']")); // true
 * isButton(document.querySelector("div")); // false
 * isButton(document.querySelector("input[type='text']")); // false
 *
 * @returns {boolean}
 * @see https://github.com/reakit/reakit/blob/master/packages/reakit-utils/src/isButton.ts
 */
export function isButton(
  element: Element
): element is HTMLButtonElement | HTMLInputElement {
  if (element.tagName === 'BUTTON') return true;
  if (element.tagName === 'INPUT') {
    const input = element as HTMLInputElement;
    return buttonInputTypes.indexOf(input.type) !== -1;
  }

  return false;
}

/**
 * Checks whether `arg` is an object or not.
 *
 * @returns {boolean}
 * @see https://github.com/reakit/reakit/blob/master/packages/reakit-utils/src/isObject.ts
 */
export function isObject(arg: any): arg is object {
  return typeof arg === 'object' && arg != null;
}

/**
 * Checks whether `arg` is an integer or not.
 *
 * @example
 * import { isInteger } from "reakit-utils";
 *
 * isInteger(1); // true
 * isInteger(1.5); // false
 * isInteger("1"); // true
 * isInteger("1.5"); // false
 * @see https://github.com/reakit/reakit/blob/master/packages/reakit-utils/src/isInteger.ts
 */
export function isInteger(arg: any): boolean {
  if (typeof arg === 'number') {
    return Math.floor(arg) === arg;
  }
  return String(Math.floor(Number(arg))) === arg;
}

/**
 * Checks whether `arg` is empty or not.
 *
 * @example
 * import { isEmpty } from "reakit-utils";
 *
 * isEmpty([]); // true
 * isEmpty(["a"]); // false
 * isEmpty({}); // true
 * isEmpty({ a: "a" }); // false
 * isEmpty(); // true
 * isEmpty(null); // true
 * isEmpty(undefined); // true
 * isEmpty(""); // true
 * @see https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx
 */
export function isEmpty(arg: any): boolean {
  if (Array.isArray(arg)) return !arg.length;
  if (isObject(arg)) return !Object.keys(arg).length;
  if (arg == null) return true;
  if (arg === '') return true;
  return false;
}

/**
 * Checks whether `arg` is a promise or not.
 *
 * @returns {boolean}
 * @see https://github.com/reakit/reakit/blob/master/packages/reakit-utils/src/isPromise.ts
 */
export function isPromise<T>(arg: T | Promise<T>): arg is Promise<T> {
  return Boolean(arg && (arg as Promise<T>).then);
}

/**
 * Function that checks if a component is a JSX element.
 * @param {*} element - Component, typically child in React.Children
 */
export function isJSXElement(element: React.ReactElement) {
  return isValidElement(element);
}

/**
 * Checks whether or not a value is a string.
 *
 * @param value
 * @see https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx
 */
export function isString(value: any): value is string {
  return typeof value === 'string';
}

/**
 * Checks whether or not a value is a number.
 *
 * @param value
 * @see https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx
 */
export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

/**
 * Checks whether or not a value is a function.
 *
 * @param value
 * @see https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx
 */
export function isFunction(value: any): value is Function {
  return !!(value && {}.toString.call(value) == '[object Function]');
}

/**
 * Checks whether or not a value is a boolean.
 *
 * @param value
 * @see https://github.com/reach/reach-ui/blob/master/packages/utils/src/index.tsx
 */
export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean';
}
