import { Market, Selection, Sector } from './types';

export const optiqSectors: Sector[] = [
  { value: null, label: 'Alle', selected: true },
  { value: 'OENG', label: 'Energi' },
  { value: 'OBMG', label: 'Materialer' },
  { value: 'OING', label: 'Forbruksvarer' },
  { value: 'OCDG', label: 'Konsumvarer' },
  { value: 'OHCG', label: 'Helsevern' },
  { value: 'OFING', label: 'Finans' },
  { value: 'OTECG', label: 'IT' },
  { value: 'OTELG', label: 'Telekom' },
  { value: 'OUTG', label: 'Forsyning' },
  { value: 'OREG', label: 'Eiendom' },
];

export const gicsSectors: Sector[] = [
  { value: null, label: 'Alle', selected: true },
  { value: '10', label: 'Energi' },
  { value: '15', label: 'Materialer' },
  { value: '20', label: 'Forbruksvarer' },
  { value: '25', label: 'Konsumvarer' },
  { value: '30', label: 'Helsevern' },
  { value: '40', label: 'Finans' },
  { value: '45', label: 'IT' },
  { value: '50', label: 'Telekom' },
  { value: '55', label: 'Forsyning' },
  { value: '60', label: 'Eiendom' },
];

export type Presets = {
  [key: string]: {
    markets?: Market[];
    selections?: Selection[];
  };
};

/**
 * Describes the filters and their values using a set of presets.
 *
 * For now, there is intentionally no API for extending this preset list or
 * providing a custom filter set in order to encourage a small API surface while
 * we slowly move forward adding new features as we actually need them.
 *
 * The recommended approach is to implement the filter component if custom
 * functionality isn needed or add more presets here if they are absolutely
 * needed (adding more countries for example).
 *
 * When the lists property is provided, they are appended to the source.
 */
export const presets: Presets = {
  // TODO i18n
  'ose-stocks': {
    markets: [
      {
        label: 'Alle',
        value: 'feed.ob.quotes.EQUITIES+PCC',
        sectors: [],
      },
      {
        label: 'Oslo Børs',
        value: 'feed.ose.quotes',
        sectors: optiqSectors,
        selected: true,
        lists: [
          {
            label: 'Alle aksjer',
            value: 'EQUITIES',
          },
          {
            label: 'OBX',
            value: 'EQUITIES_OBX',
            selected: true,
          },
          {
            label: 'Øvrige aksjer',
            value: 'EQUITIES_NOT_OBX',
          },
          {
            label: 'Egenkapitalbevis',
            value: 'PCC',
          },
          {
            label: 'Warranter',
            value: 'WARRANTS',
          },
          {
            label: 'ETN-er',
            value: 'ETNS',
          },
          {
            label: 'ETP-er',
            value: 'ETFS',
          },
          {
            label: 'Tegningsretter',
            value: 'RIGHTS',
          },
        ],
      },
      {
        label: 'Euronext Expand',
        value: 'feed.oax.quotes',
        sectors: [],
        lists: [
          {
            label: 'Alle',
            value: 'EQUITIES+RIGHTS',
            selected: true,
          },
          {
            label: 'Aksjer',
            value: 'EQUITIES',
          },
          {
            label: 'Tegningsretter',
            value: 'RIGHTS',
          },
        ],
      },
      {
        label: 'Euronext Growth',
        value: 'feed.merk.quotes',
        sectors: [],
        lists: [
          {
            label: 'Alle',
            value: 'EQUITIES+PCC+RIGHTS',
            selected: true,
          },
          {
            label: 'Egenkapitalbevis',
            value: 'PCC',
          },
          {
            label: 'Aksjer',
            value: 'EQUITIES',
          },
          {
            label: 'Tegningsretter',
            value: 'RIGHTS',
          },
        ],
      },
    ],
  },

  'sse-nordic-stocks': {
    markets: [
      {
        label: 'Stockholm',
        value: 'feed.sse.quotes',
        sectors: gicsSectors,
        selected: true,
        lists: [
          {
            label: 'Alle aksjer',
            value: 'EQUITIES',
            selected: true,
          },
          {
            label: 'OMXS30',
            value: 'OMXS30',
          },
        ],
      },
      {
        label: 'Copenhagen',
        value: 'feed.cse.quotes.EQUITIES',
        sectors: gicsSectors,
      },
      {
        label: 'Helsinki',
        value: 'feed.hex.quotes.EQUITIES',
        sectors: gicsSectors,
      },
      {
        label: 'Reykjavik',
        value: 'feed.icex.quotes.EQUITIES',
        sectors: gicsSectors,
      },
    ],
  },

  'cse-stocks': {
    markets: [
      {
        label: 'Alle',
        value: 'feed.cse.quotes.EQUITIES',
      },
      {
        label: 'Nasdaq Copenhagen',
        value: 'feed.cse.quotes',
        selected: true,
        lists: [
          {
            label: 'Alle aksjer',
            value: 'EQUITIES',
          },
          {
            label: 'Large Cap',
            value: 'EQUITIES_LARGE_CAP',
            selected: true,
          },
          {
            label: 'Mid Cap',
            value: 'EQUITIES_MID_CAP',
          },
          {
            label: 'Small Cap',
            value: 'EQUITIES_SMALL_CAP',
          },
          {
            label: 'OMXC25',
            value: 'EQUITIES_OMXC25',
          },
        ],
      },
    ],
  },

  'sse-stocks': {
    markets: [
      {
        label: 'Nasdaq Stockholm',
        value: 'feed.sse.quotes',
        selected: true,
        lists: [
          {
            label: 'Alle aksjer',
            value: 'EQUITIES',
          },
          {
            label: 'Large Cap',
            value: 'EQUITIES_LARGE_CAP',
            selected: true,
          },
          {
            label: 'Mid Cap',
            value: 'EQUITIES_MID_CAP',
          },
          {
            label: 'Small Cap',
            value: 'EQUITIES_SMALL_CAP',
          },
        ],
      },
      {
        label: 'First North',
        value: 'feed.fnse.quotes.EQUITIES',
      },
      {
        label: 'Spotlight',
        value: 'feed.xsat.quotes.EQUITIES',
      },
      {
        label: 'NGM Equity',
        value: 'feed.xngm-nest.quotes.EQUITIES_EQST',
      },
      {
        label: 'NGM SME',
        value: 'feed.nsme-nsse.quotes.EQUITIES',
      },
    ],
  },

  commodities: {
    markets: [
      {
        label: 'Commodities',
        value: 'feed.components.index.COMMODITIES',
        selected: true,
      },
    ],
  },

  'nor-indices': {
    selections: [
      {
        label: 'Norwegian sector indices',
        value: 'feed.components.index.NOR_SECTOR_INDICES',
        selected: true,
      },
      {
        label: 'All norwegian indices',
        value: 'feed.components.index.NOR_INDICES_ALL',
      },
      {
        label: 'International indices',
        value: 'feed.components.index.INTERNATION_INDICES',
      },
    ],
  },

  interests: {
    markets: [
      {
        label: 'Interests',
        value: 'feed.components.index.INTERESTS',
      },
    ],
  },

  /*
  Currencies most likely require a different set of columns, so we
  need to think about how to solve this in the best manner. A new
  `CurrencyList` component perhaps?

  'currencies': {
    markets: [{
      label: 'Currencies',
      value: 'feed.components.index.CURRENCIES',
    }],
  },
  */
};

export default presets;
