import React, { useRef, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import indicators from 'highcharts/indicators/indicators-all';
import panes from 'highcharts/modules/drag-panes';
import annotations from 'highcharts/modules/annotations-advanced';
import price from 'highcharts/modules/price-indicator';
import tools from 'highcharts/modules/stock-tools';
import { useTheme } from '@xstyled/emotion';
import { formatNumber } from '@oms/utils';
import {
  useStockChartController,
  IndicatorType,
} from '../StockChartController';
import { useStockChartContainerSize } from '../StockChartContainer';
import {
  useCompanyInfoQuery,
  useGraphDataQueries,
  useNewsQuery,
  useVolumeQuery,
  useTurnoverQuery,
  useDividendsQuery,
  useCustomQueries,
  JawsQuery,
  UseCustomQueriesOptions,
} from '../StockChartQuery';
import {
  MAIN_SERIES_ID,
  EXTRA_AXIS_INDICATOR_TYPES,
  LOCAL_COLORS,
} from '../constants';
import { getMilliseconds } from '../StockChartQuery/utils';
import {
  getComparisonColor,
  getIndicatorColor,
  opacify,
  parseColor,
} from '../utils';

// init the module
indicators(Highcharts);
panes(Highcharts);
annotations(Highcharts);
price(Highcharts);
tools(Highcharts);

let CONTAINER_ID = 0;

const getMainSeriesId = () => MAIN_SERIES_ID;
const getVolumeSeriesId = (id = CONTAINER_ID) => `volume-${id}`;
const getTurnoverSeriesId = (id = CONTAINER_ID) => `turnover-${id}`;
const getComparisonSeriesId = (comparisonId: number, id = CONTAINER_ID) =>
  `comparison-${comparisonId}-${id}`;
const getNewsSeriesId = (id = CONTAINER_ID) => `news-${id}`;
const getDividendSeriesId = (id = CONTAINER_ID) => `dividend-${id}`;
const getMainAxisId = () => 'main-axis';
const getVolumeAxisId = () => 'volume-axis';
const getTurnoverAxisId = () => 'turnover-axis';
const getIndicatorTypeAxisId = (indicator: string) => `${indicator}-axis`;

function getTimestampFromLastUpdate(series: any): number | undefined {
  if (typeof series === 'object' && 'data' in series) {
    const lastIndex = series?.data?.length - 1;
    const timestamp = series?.data?.[lastIndex]?.[0];
    return timestamp;
  }
}

export interface StockChartGraphProps {
  customSeriesQueries?: UseCustomQueriesOptions;
  /**  Identifying text that appears as shaded text placed over the graph */
  watermark?: string;
  /** Path where Highcharts will look for icons. Change this to use icons from a different server. */
  iconsURL?: string;
}

export function StockChartGraph({
  customSeriesQueries = [],
  watermark,
  iconsURL,
}: StockChartGraphProps) {
  const chart = useRef<{
    chart: Highcharts.Chart;
    container: HTMLElement;
  } | null>(null);

  const theme = useTheme();
  const colors = theme?.colors;
  const { rect } = useStockChartContainerSize();
  const { state } = useStockChartController();

  useEffect(() => {
    chart.current?.chart.reflow();
  }, [rect]);

  const { data: companyInfo } = useCompanyInfoQuery({
    itemSector: state.main.itemSector,
  });

  const [main] = useGraphDataQueries({
    itemSector: state.main.itemSector,
    dateRangeSelection: state.dateRangeSelection,
    lineStyle: state.lineStyle,
  });

  const news = useNewsQuery({
    itemSector: state.main.itemSector,
    dateRangeSelection: state.dateRangeSelection,
    enabled: state.additions.includes('news'),
  });

  const volume = useVolumeQuery({
    itemSector: state.main.itemSector,
    dateRangeSelection: state.dateRangeSelection,
    enabled: state.additions.includes('volume'),
  });

  const turnover = useTurnoverQuery({
    itemSector: state.main.itemSector,
    dateRangeSelection: state.dateRangeSelection,
    enabled: state.additions.includes('turnover'),
  });

  const comparisonsQueries = useGraphDataQueries({
    itemSector: state.comparisons.map((instrument) => instrument.itemSector),
    dateRangeSelection: state.dateRangeSelection,
  });

  const dividends = useDividendsQuery({
    itemSector: state.main.itemSector,
    dateRangeSelection: state.dateRangeSelection,
    enabled: state.additions.includes('dividends'),
  });

  const customQueries = useCustomQueries(customSeriesQueries);

  // This effect sets the x-axis extremes for intraday date range selections
  const previousDateRangeSelection = useRef<string | null>(null);
  useEffect(() => {
    if (previousDateRangeSelection.current !== state.dateRangeSelection) {
      if (
        state.dateRangeSelection.toLowerCase().includes('intraday') &&
        state.dateRangeSelection !== 'SELECTION_INTRADAY'
      ) {
        const max = getTimestampFromLastUpdate((main as any)?.data?.series);

        if (max) {
          previousDateRangeSelection.current = state.dateRangeSelection;
          const min =
            max -
            getMilliseconds({
              hour:
                state.dateRangeSelection === 'SELECTION_INTRADAY_ONE_HOUR'
                  ? 1
                  : 3,
            });
          chart.current?.chart.xAxis[0].setExtremes(min);
        }
      } else {
        chart.current?.chart.zoomOut();
      }
    }
  }, [state.dateRangeSelection, previousDateRangeSelection, main]);

  useEffect(() => {
    CONTAINER_ID++;
  }, [state.lineStyle, state.comparisons, state.additions]);

  const highchartsOptions = useMemo(() => {
    const additionalAxisCount = (() => {
      let yAxisCount = 0;

      ['volume', 'turnover'].forEach((addition) => {
        const included = state.additions.includes(addition);
        if (included) {
          yAxisCount++;
        }
      });

      EXTRA_AXIS_INDICATOR_TYPES.forEach((indicator) => {
        const included = state.indicators
          .map((entry) => entry.indicatorType)
          .includes(indicator as IndicatorType);
        if (included) {
          yAxisCount++;
        }
      });

      return yAxisCount;
    })();

    const getOffsetFactor = (index: number) => {
      let total = 0;
      if (state.additions.includes('volume')) total += 1;
      if (state.additions.includes('volume')) total += 1;
      return total + index;
    };

    const toPercent = (num: number) => {
      if (num === Infinity) {
        return `35%`;
      }
      return `${Math.floor(num)}%`;
    };

    let yAxis: Highcharts.Options['yAxis'] = [
      {
        id: getMainAxisId(),
        crosshair: true,
        labels: {
          format: state.comparisons.length > 0 ? '{value} %' : '{value}',
          align: 'left',
          style: {
            color: colors['chart-axis'] ?? '#666666',
          },
        },
        height: additionalAxisCount > 0 ? '65%' : '100%',
        resize: {
          enabled: true,
        },
        plotLines: [
          {
            color: colors?.pos ?? '#1A796F',
            dashStyle: 'ShortDash', // Style of the plot line. Defaults to solid
            value:
              companyInfo?.CLOSE ||
              companyInfo?.CLOSENZ ||
              companyInfo?.CLOSENZ_CA, // Value of where the line will appear
            width: 1, // Width of the line
          },
          //TODO These only make sense on intra day charts as HIGH/LOW values are from today and not HIGH/LOW for the selected date range
          // events > redraw | load console.log(this.yAxis[0].max);
          {
            // Plot lines are updated in JawsQuery
            color: 'lightgrey', // Color value
            dashStyle: 'ShortDash', // Style of the plot line. Defaults to solid
            value: companyInfo?.HIGH, // Value of where the line will appear
            width: 1, // Width of the line
          },
          {
            // Plot lines are updated in JawsQuery
            color: 'lightgrey', // Color value
            dashStyle: 'ShortDash', // Style of the plot line. Defaults to solid
            value: companyInfo?.LOW, // Value of where the line will appear
            width: 1, // Width of the line
          },
        ],
      },
      {
        id: getVolumeAxisId(),
        visible: state.additions.includes('volume'),
        top: '65%',
        height: toPercent(35 / additionalAxisCount),
        offset: 0,
        crosshair: true,
        labels: {
          align: 'left',
        },
      },

      {
        id: getTurnoverAxisId(),
        visible: state.additions.includes('turnover'),
        top: state.additions.includes('volume')
          ? toPercent(65 + 35 / additionalAxisCount)
          : `65%`,
        height: toPercent(35 / additionalAxisCount),
        offset: 0,
        crosshair: true,
        labels: {
          align: 'left',
        },
      },
      ...(state.indicators
        .map((entry) => entry.indicatorType)
        .filter((entry) => EXTRA_AXIS_INDICATOR_TYPES.includes(entry))
        .map((entry, index) => {
          return {
            id: getIndicatorTypeAxisId(entry),
            top: toPercent(
              65 + (35 / additionalAxisCount) * getOffsetFactor(index),
            ),
            height: toPercent(35 / additionalAxisCount),
            offset: 0,
            crosshair: true,
            labels: {
              align: 'left',
            },
          };
        }) as any),
    ];

    let options: Highcharts.Options = {
      chart: {
        events: {},
        backgroundColor: colors['chart-background'] ?? 'transparent',
      },
      rangeSelector: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      navigation: {
        iconsURL: iconsURL,
      },
      navigator: {
        enabled: state.lineStyle !== 'candlestick',
      },
      tooltip: {
        enabled: true,
        shared: true,
        useHTML: true,
        borderWidth: 0,
        valueDecimals: 2, // TODO should be based of tickSize
        dateTimeLabelFormats: {
          millisecond: '%A, %b %e, %H:%M:%S.%L',
          second: '%A, %b %e, %H:%M:%S',
          minute: '%A, %b %e, %H:%M',
          hour: '%A, %b %e, %H:%M',
          day: '%A, %b %e, %Y',
          week: '%A, %b %e, %Y',
          month: '%B %Y',
          year: '%Y',
        },
        shadow: false,
      },
      title: {
        text: watermark,
        x: 65,
        y: -100,
        verticalAlign: 'bottom',
        align: 'left',
        style: {
          color:
            colors['chart-watermark'] ??
            opacify(parseColor(colors?.['text-light']) ?? '#333333', 22),
          fontSize: '20px',
          fontFamily: 'system-ui',
        },
        floating: true,
      },
      time: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
      yAxis,
      xAxis: {
        ordinal: state.dateRangeSelection.toLowerCase().includes('intraday')
          ? false
          : true,
        //  type: 'datetime',
        //  tickPixelInterval: 150,
        //  max: getXAxisMax((main as any)?.data?.series?.data),
        //  tickInterval: 60 * 60 * 1000,
        labels: {
          style: {
            color: colors['chart-axis-label'] ?? '#333',
          },
        },
      },
      plotOptions: {
        candlestick: {
          lineColor: colors?.neg ?? '#D11814',
          color: opacify(parseColor(colors?.neg) ?? '#D11814', 'BB'),
          upColor: opacify(parseColor(colors?.pos) ?? '#1A796F', 'BB'),
          upLineColor: colors?.pos ?? '#1A796F',
          ...(state.comparisons.length > 0
            ? {
                compare: 'percent',
                compareBase: 0,
              }
            : {}),
        },
        line: {
          step: state.lineStyle === 'step' ? 'center' : undefined,
          ...(state.comparisons.length > 0
            ? {
                compare: 'percent',
                compareBase: 0,
              }
            : {}),
        },
        series: {
          //pointInterval: 1000 * 60 * 5,
          gapSize: 0,
          dataGrouping: {
            enabled: false,
          },
          tooltip: {
            valueDecimals: 2,
          },
          stickyTracking: true,
        },
      },
      series:
        main.status === 'success'
          ? [
              // Main
              // ==================================
              {
                id: getMainSeriesId(),
                name: state.main.name,
                yAxis: getMainAxisId(),
                ...(main as any)?.data?.series,
                type:
                  state.lineStyle === 'step'
                    ? 'line'
                    : state.lineStyle || 'line',
                color:
                  state.lineStyle === 'candlestick'
                    ? opacify(parseColor(colors?.neg) ?? '#D11814', 'BB')
                    : colors['chart-1'] ?? '#00685e', // <- affects both line color, zoom container color and lastPrice backgroundColor
                lastVisiblePrice: {
                  enabled: true,
                  label: {
                    enabled: true,
                    formatter: function(value: number) {
                      return formatNumber(value);
                    },
                  },
                },
                meta: {
                  type: 'main',
                  itemSector: state.main.itemSector,
                },
              },
              // Indicators (on main yAxis / separate yAxis)
              // ==================================
              ...(state.indicators.filter(Boolean).map((indicator, index) => {
                return {
                  yAxis: EXTRA_AXIS_INDICATOR_TYPES.includes(
                    indicator.indicatorType,
                  )
                    ? getIndicatorTypeAxisId(indicator.indicatorType)
                    : getMainAxisId(),
                  type: indicator.indicatorType.toLocaleLowerCase(),
                  color: getIndicatorColor(index),
                  linkedTo: getMainSeriesId(),
                  params: {
                    period: indicator.interval,
                  },
                  meta: {
                    type: 'indicator',
                    interval: indicator.interval,
                    indicatorType: indicator.indicatorType,
                  },
                };
              }) || []),
              // Comparisons (on main yAxis)
              // ==================================
              ...(comparisonsQueries
                .filter(Boolean)
                .map((query: any, index) => {
                  return {
                    id: getComparisonSeriesId(index),
                    name: `${state.comparisons[index].name}`,
                    yAxis: getMainAxisId(),
                    ...query?.data?.series,
                    type: 'line',
                    color: getComparisonColor(index),
                    lastVisiblePrice: {
                      enabled: true,
                      label: {
                        enabled: true,
                        backgroundColor: getComparisonColor(index),
                        formatter: function(value: number) {
                          return formatNumber(value);
                        },
                      },
                    },
                    meta: {
                      type: 'comparison',
                      itemSector: state.comparisons[index].itemSector,
                    },
                  };
                }) || []),
              // Volume (addition / separate yAxis)
              // ==================================
              volume.status === 'success' && state.additions.includes('volume')
                ? // createYAxis
                  {
                    id: getVolumeSeriesId(),
                    name: `${state.main.name} volume`,
                    yAxis: getVolumeAxisId(),
                    ...(volume as any)?.data?.series,
                    type: 'column',
                    color: opacify(LOCAL_COLORS[11]),
                    meta: {
                      type: 'addition',
                      addition: 'volume',
                    },
                  }
                : undefined,
              // Turnover (addition / separate yAxis)
              // ==================================
              turnover.status === 'success' &&
              state.additions.includes('turnover')
                ? {
                    id: getTurnoverSeriesId(),
                    name: `${state.main.name} turnover`,
                    yAxis: getTurnoverAxisId(),
                    ...(turnover as any)?.data?.series,
                    type: 'column',
                    color: opacify(LOCAL_COLORS[12]),
                    meta: {
                      type: 'addition',
                      addition: 'turnover',
                    },
                  }
                : undefined,
              // News (flag on main series/yAxis)
              // ==================================
              news.status === 'success' && state.additions.includes('news')
                ? {
                    id: getNewsSeriesId(),
                    type: 'flags',
                    onSeries: getMainSeriesId(),
                    name: `${state.main.name} news`,
                    data: (news.data as any)?.rows?.map((entry: any) => ({
                      ...entry.values,
                      title: 'N',
                    })),
                    shape: 'squarepin',
                    color: LOCAL_COLORS[13],
                    meta: {
                      type: 'addition',
                      addition: 'news',
                    },
                  }
                : undefined,
              // Dividends (flag on main series/yAxis)
              // ==================================
              dividends.status === 'success'
                ? {
                    id: getDividendSeriesId(),
                    type: 'flags',
                    onSeries: getMainSeriesId(),
                    name: `${state.main.name} dividends`,
                    data: (dividends.data as any)?.rows?.map(
                      (entry: { values: { x: string } }) => {
                        // entry.values.x format = YYYYMMDD
                        const YYYY = Number(entry.values.x.slice(0, 4));
                        const MM = Number(entry.values.x.slice(4, 6));
                        const DD = Number(entry.values.x.slice(6, 8));
                        const date = new Date();
                        date.setFullYear(YYYY);
                        date.setMinutes(MM);
                        date.setDate(DD);
                        return {
                          x: date.getTime(),
                          title: 'D',
                        };
                      },
                    ),
                    shape: 'squarepin',
                    color: LOCAL_COLORS[14],
                    meta: {
                      type: 'addition',
                      addition: 'dividends',
                    },
                  }
                : undefined,
              ...(customQueries.map((query) => query.data) || []),
            ]
              .flat()
              .filter(Boolean)
          : undefined,
    };
    return options;
  }, [
    state,
    main,
    comparisonsQueries,
    companyInfo,
    customQueries,
    dividends,
    news,
    turnover,
    volume,
    watermark,
    colors,
    iconsURL,
  ]);

  return (
    <div
      id={`oms-stock-chart-loading-indicator-${CONTAINER_ID}`}
      style={main.isFetching ? { cursor: 'progress', flex: 1 } : { flex: 1 }}
    >
      <HighchartsReact
        // redraw chart hack
        key={CONTAINER_ID}
        ref={chart}
        highcharts={Highcharts}
        options={highchartsOptions}
        constructorType="stockChart"
        className="oms-stock-chart"
      />
      <JawsQuery
        chartInstance={chart.current?.chart}
        itemSector={[
          state.main.itemSector,
          ...state.comparisons.map((c) => c.itemSector),
        ]}
        dateRangeSelection={state.dateRangeSelection}
        lineStyle={state.lineStyle}
      />
    </div>
  );
}
