import styled, { css } from '@xstyled/emotion';

type ChartWrapperProps = {
  labelClassName: string;
  clickable?: boolean;
};

const ChartWrapper = styled.box`
  .highcharts-data-label {
    height: 0;
    width: 0;
  }

  .highcharts-data-label > span {
    display: inline-block;
    position: relative !important;
  }

  .highcharts-reset-zoom {
    display: none;
  }

  ${({ labelClassName, clickable }: ChartWrapperProps) => css`
    .${labelClassName} {
      box-sizing: border-box;
      position: relative;
      color: white;
      padding: 8px;
      min-width: 140px;
      transition: transform 0.2s;

      // Normalize button vs. div
      font-size: 13px;
      font-weight: 400;
      font-family: inherit;
      display: block;
      background-color: rgba(0, 0, 0, 0);
      text-align: inherit;
      line-height: 15px;
      margin: 0;
      border: 0;

      ${clickable &&
        css`
          cursor: pointer;
          &:focus,
          &:hover {
            transform: scale(1.05);
          }
        `}

      &.pos {
        background-color: pos;
        border: 1px solid;
        border-color: pos;
      }

      &.neg {
        background-color: neg;
        border: 1px solid;
        border-color: neg;
      }

      &.flat {
        background-color: #4f4f4f;
        border: 1px solid #4f4f4f;
      }

      &--top {
        bottom: 5px;

        .index-map__arrow {
          border-top-style: solid;
          border-top-color: inherit;
          border-top-width: 5px;
          margin-top: 9px;
        }
      }

      &--bottom {
        top: 60px;

        .index-map__arrow {
          border-bottom-style: solid;
          border-bottom-color: inherit;
          border-bottom-width: 5px;
          margin-top: -4px;
          top: 0;
        }
      }

      &--right {
        left: 70px;

        .index-map__arrow {
          left: 0;
          width: 1px;
          margin-left: -1px;
          border-right: 5px solid transparent;
        }
      }

      &--left {
        right: 70px;

        .index-map__arrow {
          right: 0;
          width: 1px;
          margin-right: -1px;
          border-left: 5px solid transparent;
        }
      }

      &--bottom.closed {
        top: 75px;
      }

      &--hidePoint.highcharts-point {
        display: none;
      }

      .index-map__name {
        font-weight: 700;
      }

      .index-map__last,
      .index-map__change {
        display: inline-block;
      }

      .index-map__change {
        float: right;
      }

      .index-map__arrow {
        position: absolute;
        height: 0;
        width: 2px;

        &--up {
          border-bottom: 5px solid inherit;
        }
      }
    }
  `}
`;

export default ChartWrapper;
