import React, { createContext, useContext, ReactNode } from 'react';
import { noop, mapProps, ResponsiveValue } from '@oms/ui-utils';
import { Text } from '@oms/ui-text';
import { CloseButton } from '@oms/ui-icon-button';
import { Backdrop } from '@oms/ui-backdrop';
import * as R from 'reakit/Dialog';
import * as S from './styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export const DIALOG_HEADER_ID = Symbol('dialogHeaderId');
const getHeaderId = (dialog: { baseId: string }) => `${dialog.baseId}-header`;

export const DialogContext = createContext<any>({});
DialogContext.displayName = 'DialogContext';

export const useDialog = (options?: Parameters<typeof R.useDialogState>[0]) =>
  R.useDialogState({ ...options, animated: true });

type PlacementTypes = 'start' | 'center' | 'end';
type PlacementString =
  | 'start'
  | 'center'
  | 'end'
  | 'start center'
  | 'start end'
  | 'center start'
  | 'center end'
  | 'end start'
  | 'end center';

function getPlacement(placement: NonNullable<ResponsiveValue<string>>) {
  const flexProp = mapProps({
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  });

  const splitProps = (str: string) => {
    const [x, y] = str.split(' ') as [PlacementTypes, PlacementTypes];
    return {
      alignItems: flexProp(x),
      justifyContent: y ? flexProp(y) : flexProp(x),
    };
  };

  if (typeof placement === 'string') {
    return splitProps(placement);
  }
  return Object.entries(placement).reduce(
    (acc, [key, value]) => {
      if (value) {
        const { alignItems, justifyContent } = splitProps(value);
        acc.alignItems[key] = alignItems;
        acc.justifyContent[key] = justifyContent;
      }
      return acc;
    },
    { alignItems: {}, justifyContent: {} } as {
      alignItems: { [key: string]: any };
      justifyContent: { [key: string]: any };
    },
  );
}

type DialogRoles = 'dialog' | 'alertdialog';

export interface DialogProps extends Omit<R.DialogProps, 'aria-labelledby'> {
  role?: DialogRoles;
  /** To prevent accidental data loss, an alert dialog should focus the least destructive action button when it opens.
   */
  initialFocusRef?: R.DialogProps['unstable_initialFocusRef'];
  onDismiss?: () => void;
  placement?: ResponsiveValue<PlacementString>;
  maxWidth?: ResponsiveValue<string>;
  width?: ResponsiveValue<string | number>;
  'aria-labelledby'?: string | typeof DIALOG_HEADER_ID;
}

/**
 * Dialog
 * @param props
 * @namespace
 */
export function Dialog({
  role,
  onDismiss = noop,
  initialFocusRef,
  children,
  width = '100%',
  maxWidth = '40rem',
  placement = 'center',
  'aria-labelledby': ariaLabelledBy,
  ...dialog
}: DialogProps) {
  const hide = () => {
    onDismiss();
    dialog?.hide?.();
  };
  const dialogState = { ...dialog, hide };
  const { alignItems, justifyContent } = getPlacement(placement);
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const scrollBox = ref.current!;
    if (dialog.modal && dialog.visible) {
      disableBodyScroll(scrollBox);
    }
    return () => enableBodyScroll(scrollBox);
  }, [dialog.modal, dialog.visible]);

  return (
    <DialogContext.Provider value={dialogState}>
      <Backdrop
        {...(dialogState as any)}
        alignItems={alignItems}
        justifyContent={justifyContent}
        p={4}
        variant={dialog.unstable_orphan ? 'orphan' : 'default'}
      >
        <R.Dialog
          ref={ref}
          {...dialogState}
          unstable_initialFocusRef={initialFocusRef}
          data-modal="true"
          role={role}
          aria-labelledby={
            ariaLabelledBy === DIALOG_HEADER_ID
              ? getHeaderId(dialog)
              : ariaLabelledBy
          }
        >
          {(dialogProps) => (
            <S.Dialog
              as="section"
              {...dialogProps}
              width={width}
              maxWidth={maxWidth}
            >
              {children}
            </S.Dialog>
          )}
        </R.Dialog>
      </Backdrop>
    </DialogContext.Provider>
  );
}

export interface DialogHeaderProps {
  children: ReactNode;
  id?: string;
  /**
   * Label for close button
   * @deprecated
   */
  'aria-label'?: string | any;
  ariaCloseButtonLabel?: string | any;
}

/**
 * @memberof Dialog
 * */
export function DialogHeader({
  children,
  id,
  'aria-label': ariaLabel = 'close',
  ariaCloseButtonLabel = ariaLabel,
}: DialogHeaderProps) {
  const dialog = useContext(DialogContext);
  return (
    <S.Header aria-labelledby={id ?? getHeaderId(dialog)}>
      <Text id={id ?? getHeaderId(dialog)} as="h1" variant="heading6" mt={1}>
        {children}
      </Text>
      <CloseButton
        aria-label={ariaCloseButtonLabel}
        onClick={dialog.hide}
        size="sm"
      />
    </S.Header>
  );
}

/**
 * @implements Stack
 * @memberof Dialog
 */
export const DialogActions = S.Actions;
/**
 * Accessible Disclosure component that controls visibility of a section of content (Dialog)
 * @memberof Dialog
 */
export const DialogDisclosure = R.DialogDisclosure;

/**
 * @implements Box
 * @memberof Dialog
 */
export const DialogContent = S.Content;
