import React, { useState } from 'react';
import classNames from 'classnames';
import { Trans, t } from '@lingui/macro';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { NoData, Loading } from '@oms/components-core';
import { Td, TableToggleRowsButton } from '@oms/ui-table';
import { Link } from '@oms/ui-link';
import {
  getItemFromItemSector,
  formatLink,
  formatDate,
  sanitize,
} from '@oms/utils';
import { Box } from '@oms/ui-box';
import {
  InteractiveList,
  InteractiveListHead,
  InteractiveListBody,
  InteractiveListRow,
  InteractiveListHeadingCell,
  InteractiveListCell,
} from '@oms/ui-interactive-list';
import { Heading } from '@oms/ui-heading';
import { Text } from '@oms/ui-text';
import { Scroll } from '@oms/ui-scroll';

import NewsItemLink from './NewsItemLink';

const NEWS = 'news';

export const defaultSpec = {
  initiatorComponent: 'News',
  source: 'feed.news.ob.ALL',
  columns:
    'REFERENCEID, COMPANY_TICKER, TIME, HEADLINE, URL, TEXT,' +
    'ose.ITEM_SECTOR as OSE_ITEM_SECTOR,' +
    'oax.ITEM_SECTOR as OAX_ITEM_SECTOR,' +
    'merk.ITEM_SECTOR as MERK_ITEM_SECTOR',
  leftJoins:
    'feed.ose.quotes.EQUITIES+PCC+ETPS+RIGHTS+WARRANTS as ose on COMPANY_TICKER,' +
    'feed.oax.quotes.EQUITIES+RIGHTS as oax on COMPANY_TICKER,' +
    'feed.merk.quotes.EQUITIES+PCC+RIGHTS as merk on COMPANY_TICKER',
  space: 'TICK',
  type: 'history',
  ranking: '-TIME',
  stop: 'now',
  limit: 5,
};

export const finwireSpec = {
  initiatorComponent: 'News',
  source: 'feed.news.finwire.ALL',
  columns:
    'REFERENCEID, COMPANY_TICKER, TIME, HEADLINE, URL, TEXT,' +
    'sse.ITEM_SECTOR as SSE_ITEM_SECTOR',
  leftJoins: 'feed.sse.quotes.EQUITIES+RIGHTS as sse on COMPANY_TICKER',
  space: 'TICK',
  type: 'history',
  ranking: '-TIME',
  stop: 'now',
  limit: 5,
};

type BuildSpecOptions = {
  itemSector?: string;
  spec?: Spec;
  limit?: number;
};

const buildSpec = ({ itemSector, spec, limit }: BuildSpecOptions) => {
  const item = itemSector ? getItemFromItemSector(itemSector) : null;

  return {
    ...(spec?.source?.includes('finwire') ? finwireSpec : defaultSpec),
    filter: item ? `ITEM==sAll&&COMPANY_TICKER==s${item}` : 'ITEM==sAll',
    limit: limit && Number.isFinite(limit) ? limit : undefined,
    ...spec,
  };
};

export type UseNewsDataArguments = {
  spec?: Spec;
  itemSector?: string;
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * The `limit` to use when the component is expanded. Can be overridden using
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;
  options?: Options;
};

export const useNews = (
  {
    spec,
    itemSector,
    initialLimit = 5,
    initialExpanded,
    expandedLimit = 15,
    onExpandToggled,
    options,
  }: UseNewsDataArguments = { itemSector: '' },
) => {
  const [limit, setLimit] = useState(
    initialExpanded ? expandedLimit : initialLimit,
  );
  const [expanded, setExpanded] = useState(initialExpanded);

  const toggleExpanded = () => {
    const newValue = !expanded;
    setExpanded(newValue);
    setLimit(newValue ? expandedLimit : initialLimit);

    if (onExpandToggled) {
      onExpandToggled({ newValue });
    }
  };

  const { items, ...values } = useJaws(buildSpec({ spec, limit, itemSector }), {
    forceHTTPConnection: true,
    ...options,
  });

  const moreThenFiveRows = items.size >= 5;

  return {
    ...values,
    toggleExpanded,
    moreThenFiveRows,
    expanded,
    data: items.sortBy((item) => -item.get('TIME')).toJS(),
  };
};

const createLink = (item: Record<string, string>, linkTo?: string) => {
  if (linkTo) {
    const {
      MERK_ITEM_SECTOR: MERK,
      OSE_ITEM_SECTOR: OSE,
      OAX_ITEM_SECTOR: OAX,
    } = item;

    const itemSector = [MERK, OSE, OAX].find(
      (sector) => !!sector && sector !== 'All.OB',
    );

    if (!itemSector && linkTo.includes('ITEM_SECTOR')) {
      return undefined;
    }

    const variables = {
      ...item,
      ITEM_SECTOR: itemSector,
    };

    return formatLink(linkTo, variables);
  }

  return item.URL;
};

export interface NewsProps {
  /** A spec to override the default values of the component. */
  spec?: Spec & {
    /** The source to fetch data from. _Default: feed.ose.quotes.EQUITIES_ */
    source?: string;
    /** The number of rows in the table. _Default: 5_ */
    limit?: number;
    /** The field the selection should be ranked on. _Default: -CHANGE_PCT_ */
    ranking?: string;
  };
  /**
   * When defined, the component will show news for the specified instrument
   * only. This is rougly equal to adding it to the `spec`'s filter, only it
   * also makes sure the "Ticker"-column is hidden.
   */
  itemSector?: string;
  /**
   * A momentjs format string that the component will use to display dates. When
   * an array is provided, the first value will be used for formatting times
   * that are within the current day, otherwise the second format string is used.
   *
   * The default will display the time when the time is on the current day,
   * otherwise a date using a localized short format based on the configured
   * momentjs locale.
   */
  dateFormat?: string | string[];
  /**
   * When set to `true`, this option will make the component render a preview
   * pane on the right side of the news item list.
   */
  withPreview?: boolean;
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/docs/Glossary.md#locationdescriptor).
   */
  linkTo?: any;
  /**
   * The page that is linked to on when clicking on a news story. If `null`,
   * then a link to newsweb will be used when available.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/docs/Glossary.md#locationdescriptor).
   */
  newsItemLink?: any;
  /**
   * The page that the *More news* link will link to. If `null`, then this link
   * will not be rendered.
   *
   * The two accepted inputs are the same as the react-router `to`-prop.
   * Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/docs/Glossary.md#locationdescriptor).
   */
  archiveLink?: any;
  /** Will be passed to the component */
  className?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
  /**
   * The *initial* amount of trades to show. If the `showMoreButton` props is
   * set, then the user will be able to override this and see `expandedLimit`
   * trades when they click "show more". Can be overridden using `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  initialLimit?: number;
  /**
   * When set to `true`, the table will start in the expanded mode where
   * `expandedLimit` levels are shown.
   */
  initialExpanded?: boolean;
  /**
   * The `limit` to use when the component is expanded. Can be overridden using
   * `spec.limit`.
   *
   * Pass `Infinity` for the complete dataset.
   */
  expandedLimit?: number;
  /**
   * A function that allows you to subscribe to the use event of the user
   * toggling the expand on and off. Useful for counting clicks for billing
   * purposes.
   */
  onExpandToggled?: ({ newValue }: { newValue: boolean }) => void;

  showMoreButton?: boolean;

  /** Options used in fetch: `forceHTTPConnection` and `disableAutoSubscribe` */
  options?: Options;
}

/* eslint-disable react/no-array-index-key, react/no-danger, react/no-unused-prop-types */
/**
 * A component which lists the most recent news today. Has an option for
 * previewing the news article alongside the list.
 *
 * For showing news for only one instrument, see the `itemSector` prop.
 *
 * @since 1.0.0
 */
export const News = ({
  linkTo = '/instrument/{ITEM_SECTOR}',
  newsItemLink = '/news?filter=COMPANY_TICKER==s{COMPANY_TICKER}',
  archiveLink = null,
  itemSector,
  className = 'News',
  withPreview = false,
  spec,
  disableLoading = false,
  dateFormat = ['LT', 'L'],
  initialLimit = 5,
  initialExpanded = false,
  expandedLimit = 15,
  showMoreButton = false,
  onExpandToggled,
}: NewsProps) => {
  const {
    resource,
    data,
    initialized,
    emptyData,
    expanded,
    toggleExpanded,
    moreThenFiveRows,
  } = useNews({
    spec,
    itemSector,
    initialLimit,
    initialExpanded,
    expandedLimit,
    onExpandToggled,
  });

  // Use default assignment over initialState as
  // initialState doesn't update when data is resolved

  const [
    selectedKey = Object.keys(data)[0] as string,
    setSelectedKey,
  ] = useState<string>();

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No news found`} />;
  }

  return (
    <Box
      display="flex"
      flexWrap="wrap"
      className={classNames(className, { withPreview })}
    >
      <Box flex="1 0 20rem" display="flex" flexDirection="column">
        <InteractiveList
          initialSelected={withPreview ? selectedKey : undefined}
          onSelect={withPreview ? setSelectedKey : undefined}
          layout="auto"
        >
          <InteractiveListHead>
            <InteractiveListHeadingCell type="dateTime">
              <Trans>Time</Trans>
            </InteractiveListHeadingCell>
            {!itemSector ? (
              <InteractiveListHeadingCell type="text">
                <Trans>Ticker</Trans>
              </InteractiveListHeadingCell>
            ) : (
              <></>
            )}
            <InteractiveListHeadingCell type="text">
              <Trans>Headline</Trans>
            </InteractiveListHeadingCell>
          </InteractiveListHead>
          <InteractiveListBody>
            {Object.entries(data).map(([key, item]: any[]) => {
              return (
                <InteractiveListRow
                  key={key}
                  value={key}
                  linkTo={
                    !withPreview ? createLink(item, newsItemLink) : undefined
                  }
                  data-testid="interactive-list-row"
                  {...(!withPreview && newsItemLink === null
                    ? ({ target: '_blank', rel: 'noopener noreferrer' } as any)
                    : {})}
                >
                  <InteractiveListCell
                    type="dateTime"
                    formatString={dateFormat as any}
                  >
                    {item?.TIME}
                  </InteractiveListCell>
                  {!itemSector ? (
                    <InteractiveListCell
                      type="text"
                      data-testid="ticker"
                      linkTo={linkTo ? createLink(item, linkTo) : undefined}
                    >
                      {item?.COMPANY_TICKER}
                    </InteractiveListCell>
                  ) : (
                    <></>
                  )}
                  <InteractiveListCell
                    type="text"
                    truncate={withPreview}
                    maxWidth={withPreview ? '15rem' : null}
                    data-testid="headline"
                  >
                    {item?.HEADLINE}
                  </InteractiveListCell>
                </InteractiveListRow>
              );
            })}
          </InteractiveListBody>
        </InteractiveList>
        {showMoreButton && moreThenFiveRows && (
          <TableToggleRowsButton
            onClick={toggleExpanded}
            aria-controls={NEWS}
            style={{ width: '100%' }}
            aria-label={
              expanded
                ? 'Toggle trades, show additional content'
                : 'Toggle trades, hide additional content'
            }
          >
            {expanded ? <Trans>Show fewer</Trans> : <Trans>Show more</Trans>}
          </TableToggleRowsButton>
        )}
        {archiveLink !== null && (
          <nav className="news-navigation" data-testid="newsNav">
            <Link
              variant="link"
              to={createLink(data[selectedKey], archiveLink)}
            >
              <Trans>More news</Trans>
            </Link>
          </nav>
        )}
      </Box>
      {withPreview && (
        <Box
          as="article"
          flex="1 1 20rem"
          py={2}
          tabIndex={0}
          data-testid="sidebar"
        >
          <Scroll maxHeight={{ md: '16rem' }} px={4} showFade={false}>
            <Heading as="h3" variant="heading5">
              <NewsItemLink
                item={data[selectedKey]}
                newsItemLink={newsItemLink}
                data-testid="newsItemLink"
              >
                {data[selectedKey]?.HEADLINE}
              </NewsItemLink>
            </Heading>
            <Text mt={1}>{data[selectedKey]?.COMPANY_TICKER}</Text>
            <Text mt={1}>{formatDate(data[selectedKey]?.TIME)}</Text>
            <Text
              mt={4}
              variant="body2"
              dangerouslySetInnerHTML={sanitize(data[selectedKey]?.TEXT)}
            />
          </Scroll>
        </Box>
      )}
    </Box>
  );
};
