import React from 'react';
import { Trans } from '@lingui/macro';
import { Toolbar, ToolbarItem } from '@oms/ui-toolbar';
import { Box } from '@oms/ui-box';
import { MenuItemHTMLProps } from '@oms/ui-menu-bar';
import { useStockChartController } from '../StockChartController';
import * as S from './styles';

export interface DateRangeToolBarProps extends MenuItemHTMLProps {}

export const DateRangeToolBar = React.forwardRef(function DateRangeToolBar(
  props: DateRangeToolBarProps,
  ref: React.Ref<any>,
) {
  const controller = useStockChartController();

  return (
    <Box mr={2}>
      <Toolbar
        ref={ref}
        aria-label="Date range selection"
        {...props}
        pt={'3px'}
      >
        {controller.state.lineStyle !== 'candlestick' ? (
          <>
            <ToolbarItem
              as={S.DateRangeButton}
              aria-selected={controller?.isChecked(
                'dateRangeSelection',
                'SELECTION_INTRADAY_ONE_HOUR',
              )}
              onClick={() => {
                controller?.setDateRangeSelection(
                  'SELECTION_INTRADAY_ONE_HOUR',
                );
              }}
            >
              <Trans>1H</Trans>
            </ToolbarItem>
            <ToolbarItem
              as={S.DateRangeButton}
              aria-selected={controller?.isChecked(
                'dateRangeSelection',
                'SELECTION_INTRADAY_TREE_HOURS',
              )}
              onClick={() => {
                controller?.setDateRangeSelection(
                  'SELECTION_INTRADAY_TREE_HOURS',
                );
              }}
            >
              <Trans>3H</Trans>
            </ToolbarItem>
            <ToolbarItem
              as={S.DateRangeButton}
              aria-selected={controller?.isChecked(
                'dateRangeSelection',
                'SELECTION_INTRADAY',
              )}
              onClick={() => {
                controller?.setDateRangeSelection('SELECTION_INTRADAY');
              }}
            >
              <Trans>Intraday</Trans>
            </ToolbarItem>
          </>
        ) : null}
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_WEEK',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_WEEK');
          }}
        >
          <Trans>1W</Trans>
        </ToolbarItem>
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_ONE_MONTH',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_ONE_MONTH');
          }}
        >
          <Trans>1M</Trans>
        </ToolbarItem>
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_THREE_MONTHS',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_THREE_MONTHS');
          }}
        >
          <Trans>3M</Trans>
        </ToolbarItem>
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_SIX_MONTHS',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_SIX_MONTHS');
          }}
        >
          <Trans>6M</Trans>
        </ToolbarItem>
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_ONE_YEAR',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_ONE_YEAR');
          }}
        >
          <Trans>1Y</Trans>
        </ToolbarItem>
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_THREE_YEARS',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_THREE_YEARS');
          }}
        >
          <Trans>3Y</Trans>
        </ToolbarItem>
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_FIVE_YEARS',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_FIVE_YEARS');
          }}
        >
          <Trans>5Y</Trans>
        </ToolbarItem>
        <ToolbarItem
          as={S.DateRangeButton}
          aria-selected={controller?.isChecked(
            'dateRangeSelection',
            'SELECTION_HISTORIC',
          )}
          onClick={() => {
            controller?.setDateRangeSelection('SELECTION_HISTORIC');
          }}
        >
          <Trans>Max</Trans>
        </ToolbarItem>
      </Toolbar>
    </Box>
  );
});
