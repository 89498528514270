import React from 'react';
import { forwardRefWithAs } from '@oms/ui-utils';
import { TextInput, TextInputProps } from '@oms/ui-text-input';

export interface TextareaProps extends TextInputProps {}

export const Textarea = forwardRefWithAs<TextInputProps, 'textarea'>(
  ({ as: _as, ...props }, ref) => (
    <TextInput
      as="textarea"
      ref={ref}
      style={{ height: 'auto' }}
      p={2}
      {...props}
    />
  ),
);

Textarea.displayName = 'Textarea';
