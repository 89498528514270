/**
 * Gets the ITEM and SECTOR from from an ITEM_SECTOR string
 * @param {string} itemSector - The ITEM_SECTOR to format
 * @return {array} The result as an array of [item, sector]
 */
export function getItemAndSectorFromItemSector(
  itemSector: string,
): [string, string | null] {
  if (itemSector == null) {
    throw new Error(
      `ITEM_SECTOR '${itemSector}' is not parseable into an item and sector pair`,
    );
  }

  const index = itemSector.lastIndexOf('.');

  if (index < 1) {
    return [itemSector, null];
  }

  return [itemSector.substr(0, index), itemSector.substr(index + 1)];
}

/**
 * Gets the ITEM from an ITEM_SECTOR string
 * @param {string} itemSector - The ITEM_SECTOR to format
 * @return {array} The ITEM in the ITEM_SECTOR
 */
export function getItemFromItemSector(itemSector: string) {
  return getItemAndSectorFromItemSector(itemSector)[0];
}

/**
 * Gets the SECTOR from an ITEM_SECTOR string
 * @param {string} itemSector - The ITEM_SECTOR to format
 * @return {array} The SECTOR in the ITEM_SECTOR
 */
export function getSectorFromItemSector(itemSector: string) {
  return getItemAndSectorFromItemSector(itemSector)[1];
}
