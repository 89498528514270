// @ts-nocheck
import memoize from 'memoize-one';
import color from 'color';
import { formatNumber } from '@oms/utils';

import {
  COLORS,
  SELECTION_WEEK,
  SELECTION,
  TI_COLORS,
  EXTRA_AXIS_INDICATOR_TYPES,
} from './constants';
import { Break } from './utils';
import { Compare, Addition, Main, TechnicalIndicatorsType } from './fetcher';

const generateAdditionalAxis = (axis: any, id: string, label: string) => [
  ...axis,
  {
    id,
    label,
    visible: false,
  },
];

interface BuildOptionsArguments {
  main: Main;
  selection?: SELECTION;
  compares: Record<string, Compare>;
  additions: Record<string, Addition>;
  technicalIndicators?: TechnicalIndicatorsType;
  colors: string[];
  technicalIndicatorColors?: string[];
  indicatorsColors?: string[];
  breaks?: Break[];
}

// Don't merge input arguments into an object as that will break memoization
// TODO I just broke memoization
export const buildOptions = ({
  main,
  selection,
  compares,
  additions,
  colors = COLORS,
  breaks,
  technicalIndicatorColors = TI_COLORS,
  technicalIndicators = {},
}: BuildOptionsArguments) => {
  if (main.series == null) return null;

  let yAxis = [
    {
      gridLineColor: '#e6e6e6',
      gridLineDashStyle: 'line',
      gridLineWidth: 2,
      title: {
        text: null,
      },
    },
  ];

  const type = main.series.type ? main.series.type : 'line';

  const series = [
    // Main series
    {
      id: 'main',
      name: main?.instrument?.LONG_NAME,
      data: main.series.data,
      color: colors[0],
      type,
    },
    // Compares
    ...Object.values(compares)
      .filter((item) => item && item.series)
      .map((item, i) => ({
        id: item?.instrument?.itemSector,
        name: item?.instrument?.LONG_NAME,
        data: item.series.data,
        color: colors[i + 1],
      })),
    // Additions
    ...Object.entries(additions)
      .filter(([_, item]) => item.active && item.series)
      .map(([key, item]) => {
        let yAxisId = 0;
        if (key === 'volume' || key === 'turnover') {
          yAxisId = key;
          yAxis = generateAdditionalAxis(yAxis, key, item.name);
        }

        const thisSeries = {
          yAxis: yAxisId,
          name: item.name,
          type: 'column',
          data: item.series.data,
          color: item.color,
        };

        if (item.flags) {
          return {
            ...thisSeries,
            type: 'flags',
            onSeries: 'main',
            shape: 'squarepin',
          };
        }

        return thisSeries;
      }),
    // Technical Analysis
    ...Object.values(technicalIndicators).map((item, i) => {
      const { indicator, interval } = item;

      let yAxisId = 0;
      if (EXTRA_AXIS_INDICATOR_TYPES.includes(indicator)) {
        yAxisId = indicator;
        yAxis = generateAdditionalAxis(yAxis, indicator, indicator);
      }

      return {
        color: color(technicalIndicatorColors[i]).string(),
        yAxis: yAxisId,
        type: indicator.toLowerCase(),
        linkedTo: 'main',
        params: {
          period: interval,
        },
      };
    }),
  ];

  const plotLines = breaks.map((element) => ({
    value: element.from,
    width: 1,
    color: 'black',
    dashStyle: 'dash',
  }));

  let xAxis = {
    breaks,
    plotLines,
    tickPositions: undefined,
    labels: { format: undefined },
  };

  if (selection === SELECTION_WEEK && plotLines[0] !== undefined) {
    const oneDayInMilliseconds = 24 * 3600 * 1000;
    // There is only four plotlines, but we want 5 tickPositions. We get the fifth by adding a day to the 4th plotline value
    const endDayTimes = [
      plotLines[0].value,
      plotLines[1].value,
      plotLines[2].value,
      plotLines[3].value,
      plotLines[3].value + oneDayInMilliseconds,
    ];
    const tickPositions = [];

    // The plotlines are at end day (16.30). We want the ticks at 12.00 so we subtract 4h30m from the plotline value
    for (let i = 0; i < endDayTimes.length; i += 1) {
      tickPositions[i] = endDayTimes[i] - (oneDayInMilliseconds / 24) * 4.5;
    }

    xAxis = {
      breaks,
      plotLines,
      tickPositions,
      labels: {
        format: '{value:%e. %b}',
      },
    };
  }

  return {
    series,
    navigator: {
      enabled: false,
    },
    plotOptions: {
      line: {
        step: true,
        compare: Object.values(compares).length > 0 ? 'percent' : 'none',
        tooltip: {
          followPointer: false,
          followTouchMove: false,
        },
      },
      flags: {
        events: {
          click(e: any) {
            if (e.point.url) {
              const win = window.open(e.point.url, 'AnalysisChart');
              win?.focus();
            }
          },
        },
        tooltip: {
          shared: true,
          pointFormatter() {
            const label = `<span style="color:${this.color}">${this.series.name}</span>`;
            switch (this.infoKey) {
              case 'news':
                return `${label}: <span>${this.text}</span>`;
              case 'companyreport':
                return (
                  '<span>' +
                  `Anbefaling: ${RECOMMENDATION_TYPES[this.type]}` +
                  '</span><br/>' +
                  `<span>${this.text}</span>`
                );
              case 'dividends':
                return (
                  `${label}: <span>` +
                  `${formatNumber(this.text)} ${this.currency}` +
                  '</span>'
                );
              case 'transactions': {
                const tradeType = this.type === TYPE_BUY ? 'Kjøp' : 'Salg';
                return `${`${label}: <span>` +
                  `${tradeType}: ${formatNumber(this.volume, 0)}` +
                  `</span><br/>` +
                  '<span>' +
                  `Pris: ${formatNumber(this.price)} `}${this.currency ||
                  'NOK'}</span>`;
              }
              default:
                return '';
            }
          },
        },
      },
    },
    xAxis,
    yAxis,
  };
};

export default memoize(buildOptions);
