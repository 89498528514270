import React, { createContext, useContext, ReactNode } from 'react';
import { Box, BoxProps } from '@oms/ui-box';
import * as R from 'reakit/Tab';
import * as S from './styles';

type TabVariant = { variant?: 'primary' | 'secondary' | 'tertiary' };
type LabelProps = { 'aria-label': string };
type DescribedByProps = { 'aria-describedby': string };

export type TabListProps = BoxProps &
  (LabelProps | DescribedByProps) &
  TabVariant;

export const TabContext = createContext<any>({});
TabContext.displayName = 'TabContext';
export const useTabContext = (): R.TabStateReturn => useContext(TabContext);

/**
 * Tabs Provider. Should wrap all tab components
 */
export function Tabs({
  children,
  orientation = 'horizontal',
  selectedId,
  ...config
}: R.TabInitialState & { children: ReactNode }) {
  const tabs = R.useTabState({ selectedId, orientation, ...config });
  return <TabContext.Provider value={tabs}>{children}</TabContext.Provider>;
}

/**
 * TabList component
 */
export function TabList({ children, ...props }: TabListProps) {
  const tab = useTabContext();

  return (
    <R.TabList
      as={Box}
      display="flex"
      flexDirection={tab.orientation === 'horizontal' ? 'row' : 'column'}
      {...(props as Omit<BoxProps, 'as'>)}
      {...tab}
    >
      {children}
    </R.TabList>
  );
}

export interface TabProps extends BoxProps, TabVariant {
  id?: string;
}
/**
 * Tab Component
 */
export function Tab({ children, id, variant = 'primary', ...props }: TabProps) {
  const tab = useTabContext();
  return (
    <R.Tab
      type="button"
      as={S.Tab}
      variant={variant}
      {...props}
      {...tab}
      id={id}
    >
      {children}
    </R.Tab>
  );
}

export interface TabPanel extends BoxProps {}
/**
 * TabPanel component
 */
export function TabPanel({ children, ...boxProps }: TabPanel) {
  const tab = useTabContext();
  const tabpanel = R.useTabPanel(tab);
  return (
    <Box
      display="flex"
      flexDirection="column"
      {...boxProps}
      {...(tabpanel as any)}
    >
      {typeof children === 'function'
        ? children(tab)
        : tabpanel.hidden
        ? null
        : children}
    </Box>
  );
}
