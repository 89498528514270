import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Table as UITable } from '@oms/ui-table';

// @ts-ignore
export const Container = styled.section`
  ${({ withSidebar }: any) =>
    withSidebar &&
    css`
      display: flex;
      max-width: 100%;

      & > :first-child {
        min-width: 20rem;
        max-width: 20rem;
        padding-right: 1rem;
      }

      & > :last-child {
        flex: 1;
        /*
         * Required to constrain content to flexbox and make it scroll on both
         * mobile and desktop
         */
        overflow: auto;
      }
    `}
`;

// @ts-ignore
export const Table = styled(UITable)`
  .LAST,
  .CHANGE,
  .CHANGE_PCT,
  .CLOSE,
  .ASK,
  .BID,
  .HIGH,
  .LOW,
  .VOLUME,
  .TURNOVER,
  .TIME,
  .CHANGE_7DAYS_PCT,
  .CHANGE_1MONTH_PCT,
  .CHANGE_YEAR_PCT,
  .CHANGE_1YEAR_PCT,
  .CHANGE_3YEARS_PCT,
  .CHANGE_5YEARS_PCT {
    text-align: right;
  }
`;

// @ts-ignore
export const QuotelistComponentWrapper = styled.div`
  ${({ variant, breakpoint }: any) => {
    switch (variant) {
      case 'normal':
        return css`
          overflow-x: auto;
          overflow-y: hidden;

          max-width: 100%;
          background:
            /* Shadow covers */ linear-gradient(
              white 30%,
              rgba(255, 255, 255, 0)
            ),
            linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
            /* Shadows */
              radial-gradient(
                farthest-side at 50% 0,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
              ),
            radial-gradient(
                farthest-side at 50% 100%,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0)
              )
              0 100%;
          background-repeat: no-repeat;
          background-color: white;
          background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
          background-position: 0 0, 100%, 0 0, 100%;
          background-attachment: local, local, scroll, scroll;
        `;
      case 'flipped':
        return css`
          @media screen and (min-width: ${breakpoint}) {
            overflow-x: auto;
            overflow-y: hidden;

            max-width: 100%;
            background:
              /* Shadow covers */ linear-gradient(
                white 30%,
                rgba(255, 255, 255, 0)
              ),
              linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
              /* Shadows */
                radial-gradient(
                  farthest-side at 50% 0,
                  rgba(0, 0, 0, 0.2),
                  rgba(0, 0, 0, 0)
                ),
              radial-gradient(
                  farthest-side at 50% 100%,
                  rgba(0, 0, 0, 0.2),
                  rgba(0, 0, 0, 0)
                )
                0 100%;
            background-repeat: no-repeat;
            background-color: white;
            background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
            background-position: 0 0, 100%, 0 0, 100%;
            background-attachment: local, local, scroll, scroll;
          }
        `;
      default:
        return null;
    }
  }}
`;
