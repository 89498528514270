import React, { useReducer } from 'react';
import moment from 'moment';
import { useSuggest } from '@oms/components-suggest';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { Stack } from '@oms/ui-stack';
import { Wrap } from '@oms/ui-wrap';
import { Box } from '@oms/ui-box';
import { RenderField } from '@oms/ui-field';
import { TextInput } from '@oms/ui-text-input';
import { DateInput } from '@oms/ui-date-input';
import { Clickable } from '@oms/ui-clickable';
import { Text } from '@oms/ui-text';

/**
 * TODO
 * 1) RenderField should accept flex props.
 * 2) Move away from moment?
 * 3) Check value type on DateInput (string & Date) | undefined
 * 4) InitialValues is called initialFilter in parent (NewsArchive)
 * 5) This kind of works, but DateInput is using date-fns.
 */

export interface FilterState {
  /** A text to filter headlines by */
  headline?: string;
  /** A ticker to filter the results by */
  ticker?: string;
  /**
   * A `REFERENCEID` to filter the dataset by. The `REFERENCEID` is the
   * closest approximation to an actual unique ID that exists.
   *
   * When passed, a special button will be rendered that allows the user to
   * clear the preset filter.
   */
  referenceId?: string;
  /**
   * A date which filters the results from a point
   * going forward
   */
  start?: Date;
  /**
   * A date which filters the results from a point
   * going backward
   */
  stop?: Date;
}

type FilterAction =
  | { type: 'headline'; payload: string }
  | { type: 'start'; payload: Date }
  | { type: 'stop'; payload: Date }
  | { type: 'ticker'; payload: string }
  | { type: 'clearTicker' }
  | { type: 'reset'; payload: FilterState };

const getInitialState = (
  datePickerLocale: string = moment.locale(),
): FilterState => ({
  headline: '',
  ticker: '',
  start: moment()
    .locale(datePickerLocale)
    .subtract(1, 'week')
    .toDate(),
  stop: moment()
    .locale(datePickerLocale)
    .toDate(),
  referenceId: undefined,
});

const reducer = (
  state: FilterState = getInitialState(moment.locale()),
  action: FilterAction,
): FilterState => {
  switch (action.type) {
    case 'headline':
      return { ...state, headline: action.payload };
    case 'start':
      return { ...state, start: action.payload };
    case 'stop':
      return { ...state, stop: action.payload };
    case 'ticker':
      return { ...state, ticker: action.payload };
    case 'clearTicker':
      return { ...state, ticker: undefined };
    case 'reset':
      return action.payload;
    default:
      return state;
  }
};

interface NewsFiltersProps {
  /** A className that will be passed to the containing element */
  className?: string;
  /**
   * The function that will be called for every filter change
   *
   * @param {string} filters.referenceId A `REFERENCEID` to filter the dataset
   * by. The `REFERENCEID` is the closest approximation to an actual unique ID
   * that exists.
   * @param {string} filters.headline The headline
   * @param {string} filters.ticker The selected ticker
   * @param {number} filters.start The start date
   * @param {number} filters.stop The stop date
   */
  onFilterChange: (filters: FilterState) => void;
  /** The initial values to display in the filters */
  initialFilterValues?: Partial<FilterState>;
  /**
   * Unicode tokens for how the dates will be rendered to the user
   * @see https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
   */
  dateFormat?: string;
  // TODO 5)
  /**
   * A locale code of the language that will be used in the date picker.
   * moment.locale() is used by default. Keep in mind you need to make sure moment has
   * the required locale data by importing the locale files into the project,
   * for example `import 'moment/locale/sv';`
   */
  datePickerLocale?: string;
  /**
   * The text to show when initialValues.referenceId has been passed
   */
  clearFilterText?: React.ReactNode;
}

/**
 * The filters section of the `NewsArchive` component. This component renders
 * a set of controls the user can interact with and returns them with a callback
 * prop.
 *
 * @see Is a part of the [NewsArchive](/#!/NewsArchive) component.
 * @since 1.0.0
 */
export const NewsFilters = ({
  className = 'NewsFilters',
  dateFormat = 'P', //'dd. MMM yyyy',
  // TODO 2)
  datePickerLocale = moment.locale(),
  // TODO 4)
  initialFilterValues = {},
  onFilterChange,
  clearFilterText,
}: NewsFiltersProps) => {
  const Suggest = useSuggest();

  const [state, dispatch] = useReducer(
    (state: FilterState, action: FilterAction) => {
      // Compute the new state
      const newState = reducer(state, action);
      // Call parent callback with new State
      onFilterChange(newState);
      // Return new state
      return newState;
    },
    {
      ...getInitialState(datePickerLocale),
      ...initialFilterValues,
    },
  );

  if (state.referenceId) {
    return (
      <Text className={className} p={2}>
        <Clickable
          onClick={() =>
            dispatch({
              type: 'reset',
              payload: getInitialState(datePickerLocale),
            })
          }
        >
          {clearFilterText}
        </Clickable>
      </Text>
    );
  }

  return (
    <Stack className={className} gap={4} mb={4} pr={2}>
      <I18n>
        {({ i18n }) => {
          const title = t`Title`;
          return (
            <RenderField
              as={TextInput}
              name="NewsFilters-textSearch"
              label={<Trans>Title</Trans>}
              placeholder={i18n ? i18n._(title) : title.id}
              value={state.headline}
              onChange={(event: any) =>
                dispatch({ type: 'headline', payload: event.target.value })
              }
            />
          );
        }}
      </I18n>
      <RenderField
        as={Suggest}
        name="NewsFilters-company"
        label={<Trans>Company</Trans>}
        onChange={(item: any) => {
          dispatch({ type: 'ticker', payload: item.ITEM });
        }}
        isClearable
        initialValue={state.ticker}
      />
      <Wrap gap={4}>
        <Box flex="1 1 10rem">
          <RenderField
            as={DateInput}
            name="NewsFilters-fromDate"
            label={<Trans>From date</Trans>}
            locale={datePickerLocale}
            dateFormat={dateFormat}
            startDate={state.start}
            endDate={state.stop}
            selectsStart
            // TODO 3)
            value={state.start as any}
            onChange={(date: Date) =>
              dispatch({ type: 'start', payload: date })
            }
            // TODO 1)
          />
        </Box>
        <Box flex="1 1 10rem">
          <RenderField
            as={DateInput}
            name="NewsFilters-toDate"
            label={<Trans>To date</Trans>}
            locale={datePickerLocale}
            dateFormat={dateFormat}
            startDate={state.start}
            endDate={state.stop}
            minDate={state.start}
            selectsEnd
            // TODO 3)
            value={state.stop as any}
            onChange={(date: Date) => dispatch({ type: 'stop', payload: date })}
            // TODO 1)
          />
        </Box>
      </Wrap>
    </Stack>
  );
};

export default NewsFilters;
