import React from 'react';
import { useJaws, Spec, Options } from '@oms/jaws-react';
import { Loading, NoData } from '@oms/components-core';
import { Table, Th, Td } from '@oms/ui-table';
import { Trans, t } from '@lingui/macro';

const defaultSpec = {
  initiatorComponent: 'Investors',
  type: 'table',
  limit: 10,
  generators: [
    {
      convert: 'levels',
      columns: 'TOP_INVESTOR_, TOP_INVESTOR_KEY_, TOP_POSITION_SHARES_',
    },
    {
      columns: 'TOP_TOTAL',
    },
  ],
};

type BuildSpecArguments = {
  spec?: Spec;
  itemSector: string;
};

const buildSpec = ({ spec, itemSector }: BuildSpecArguments) => ({
  ...defaultSpec,
  itemSector,
  ...spec,
});

export interface UseInvestorsArguments {
  spec?: Spec;
  itemSector: string;
  options?: Options;
}

export const useInvestors = ({
  spec,
  itemSector,
  options,
}: UseInvestorsArguments) => {
  const { items, ...values } = useJaws(
    buildSpec({ spec, itemSector }),
    options,
  );

  const investors = items
    .filter((item) => item.get('generator') === 0)
    .sortBy((item) => item.get('TOP_POSITION_SHARES_'))
    .reverse()
    .toJS();
  const total = items
    .filter((item) => item.get('generator') === 1)
    ?.first()
    ?.toJS();

  return {
    ...values,
    data: {
      investors,
      total,
    },
  };
};

export interface InvestorsProps {
  /** The instrument to show data for */
  itemSector: string;
  /** A spec to override the default values of the component. */
  spec?: Spec & {
    /** The source to fetch data from. _Default: feed.ose.quotes.EQUITIES_ */
    source?: string;
    /** The number of rows in the table. _Default: 10_ */
    limit?: number;
  };
  /** Will be passed to the `<table>` */
  className?: string;
  /** Disables the loading indicator */
  disableLoading?: boolean;
}

/**
 * A component which lists the current top shareholders of a company.
 *
 * Does not stream, only uses HTTP.
 *
 * @since 1.0.0
 */
export const Investors = ({
  className = 'Investors',
  itemSector,
  spec,
  disableLoading = false,
}: InvestorsProps) => {
  const {
    data: { investors, total },
    initialized,
    resource,
    emptyData,
  } = useInvestors({
    itemSector,
    spec,
    options: { forceHTTPConnection: true },
  });

  if (!initialized && !disableLoading) {
    if (!resource) return null;
    return <Loading promise={resource.promise} />;
  }

  if (emptyData) {
    return <NoData text={t`No investors found`} />;
  }

  return (
    <Table className={className}>
      <thead>
        <tr>
          <Th type="text">
            <Trans>Top investors</Trans>
          </Th>
          <Th type="percent">
            <Trans>Pct. of shares</Trans>
          </Th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(investors).map(([key, values]: any, index) => {
          const { TOP_TOTAL } = total;
          const {
            TOP_INVESTOR_,
            TOP_INVESTOR_KEY_,
            TOP_POSITION_SHARES_,
          } = values;

          return (
            <tr key={TOP_INVESTOR_KEY_} data-testid={`row-${index}`}>
              <Td type="text">{TOP_INVESTOR_}</Td>
              <Td type="percent">{(TOP_POSITION_SHARES_ / TOP_TOTAL) * 100}</Td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default Investors;
