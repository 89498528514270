import React, { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import {
  useMenuState,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemHTMLProps,
  MenuSeparator,
} from '@oms/ui-menu-bar';
import { Icon, light } from '@oms/ui-icon';
import {
  Dialog,
  DialogHeader,
  useDialog,
  DialogDisclosure,
} from '@oms/ui-dialog';
import { Button } from '@oms/ui-button';
import { Stack } from '@oms/ui-stack';
import { NumberInput } from '@oms/ui-number-input';
import { RenderField } from '@oms/ui-field';
import { VisuallyHidden } from '@oms/ui-visually-hidden';
import {
  useStockChartController,
  IndicatorType,
} from '../StockChartController';
import {
  useStockChartContainerNode,
  useStockChartContainerSize,
} from '../StockChartContainer';
import { getIndicatorLabel } from '../utils';

export interface IndicatorsProps extends MenuItemHTMLProps {}

export const Indicators = React.forwardRef(
  (props: IndicatorsProps, ref: React.Ref<any>) => {
    const menu = useMenuState();
    const controller = useStockChartController();
    //
    const dialog = useDialog({ modal: false });
    const node = useStockChartContainerNode();
    const { containerWidth } = useStockChartContainerSize();

    const [indicatorType, setIndicatorType] = useState<
      IndicatorType | undefined
    >(undefined);
    const [intervalValue, setIntervalValue] = useState<number | string>(14);

    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setIntervalValue(event.target.value || '');
      },
      [],
    );

    const submit = useCallback(
      (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (indicatorType && intervalValue) {
          controller?.addIndicator({
            indicatorType,
            interval: Number(intervalValue),
          });
        }
        dialog.hide();
      },
      [controller, dialog, indicatorType, intervalValue],
    );

    return (
      <>
        <MenuButton ref={ref} {...menu} {...props} /*title="Indicators" t``*/>
          <Icon
            icon={light.faFunction}
            color="inherit"
            fontSize={'150%' as any}
          />

          {containerWidth === 'sm' ? (
            <VisuallyHidden>
              <Trans>Indicators</Trans>
            </VisuallyHidden>
          ) : (
            <Trans>Indicators</Trans>
          )}
        </MenuButton>
        <Menu {...menu} aria-label="Indicators">
          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('sma');
            }}
          >
            {getIndicatorLabel('sma')}
          </MenuItem>
          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('ema');
            }}
          >
            <Trans>Exponential moving average (EMA)</Trans>
          </MenuItem>
          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('wma');
            }}
          >
            {getIndicatorLabel('wma')}
          </MenuItem>
          {/** --- TODO --- */}
          {/*<MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('rsi');
            }}
          >
            {getIndicatorLabel('rsi')}
          </MenuItem>
          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('momentum');
            }}
          >
            {getIndicatorLabel('momentum')}
          </MenuItem>*/}
          {/** --- END of TODO --- */}
          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('roc');
            }}
          >
            {getIndicatorLabel('roc')}
          </MenuItem>

          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('bb');
            }}
          >
            {getIndicatorLabel('bb')}
          </MenuItem>
          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('priceenvelopes');
            }}
          >
            {getIndicatorLabel('priceenvelopes')}
          </MenuItem>
          <MenuItem
            {...menu}
            as={DialogDisclosure}
            {...dialog}
            onClick={() => {
              menu.hide();
              setIndicatorType('stochastic');
            }}
          >
            {getIndicatorLabel('stochastic')}
          </MenuItem>
          <MenuSeparator {...menu} />
          <MenuItem {...menu} onClick={controller.clearIndicators}>
            <Trans>Clear indicators</Trans>
          </MenuItem>
        </Menu>
        {node && indicatorType
          ? ReactDOM.createPortal(
              <I18n>
                {({ i18n }) => {
                  const i18nToString = (message: MessageDescriptor): string =>
                    i18n ? i18n._(message) : message.id;
                  return (
                    <Dialog
                      {...dialog}
                      aria-label={i18nToString(t`Add indicator`)}
                      style={{
                        zIndex: 2,
                      }}
                      unstable_orphan={true}
                    >
                      <DialogHeader>
                        {getIndicatorLabel(indicatorType)}
                      </DialogHeader>
                      <Stack as="form" onSubmit={submit} gap={4} p={4}>
                        <RenderField
                          name={`${indicatorType}-period`}
                          as={NumberInput}
                          label="Period"
                          value={intervalValue}
                          onChange={onChange}
                        />
                        <Button type="submit">
                          <Trans>Add</Trans>
                        </Button>
                      </Stack>
                    </Dialog>
                  );
                }}
              </I18n>,
              node,
            )
          : null}
      </>
    );
  },
);
